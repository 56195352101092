import ResetPassword from '../components/resetPassword';
import React from "react";

function ResetPasswordContainer(props) {
  const { match: { params: { otp: encryptedOtp, email: encryptedEmail } = {} } = {}} = props;
  const otp = atob(encryptedOtp);
  const email = atob(encryptedEmail);

  return (<ResetPassword otp={otp} email={email} />);
}

export default ResetPasswordContainer;

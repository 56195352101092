import { listsConstants } from '../constants';

export function lists(state = {}, action) {
  switch (action.type) {
    case listsConstants.CREATE_REQUEST:
      return {
          loading: true
      };
    case listsConstants.CREATE_SUCCESS:
      return {
        loading: false,
        data: action
      };
    case listsConstants.CREATE_FAILURE:
      return {
        loading: false,
        error: action.error
      };
    case listsConstants.GETALL_REQUEST:
      return {
          ...state,
          loading: false
      };
    case listsConstants.GETALL_SUCCESS:
      let rawData = action.lists;
      return {
        loading: false,
        ...rawData
      };
    case listsConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case listsConstants.DELETE_REQUEST:
      return {
          ...state,
          loading: false
      };
    case listsConstants.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case listsConstants.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case listsConstants.LIST_SUBSCRIBE_REQUEST:
      return {
          ...state,
          loading: false
      };
    case listsConstants.LIST_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case listsConstants.LIST_SUBSCRIBE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
    return state
  }
}

'use strict';
import React, {Component} from 'react';
import {connect} from "react-redux";
import ChatListsTab from './ChatListsTab';
import ChatSearch from '../chatSearch';
import OptionMenu from '../../common/optionMenu/OptionMenu';
import { getExistChat } from '../../../../helpers';
import cs from 'classnames';
import s from './chatLists.module.scss';
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

import {
  alertActions,
  setActiveChat,
  deleteActiveChat,
  userActions,
  setChatContentType,
  getChatLists,
  chatActions,
  createGroupChat,
  leaveChat,
} from '../../../../actions';
import {
  SINGLE_CHAT_TYPE,
  GROUP_CHAT_TYPE,
  CHAT_CONTENT_CHAT_MODULE,
} from '../../../../constants';
import {
  singleChatData as activeSingleChatDataPropType,
  groupChatData as activeGroupChatDataPropType,
  isUndefined,
  user as userPropType,
} from '../../../../misc/propTypes';
import moment from 'moment';

import SingleChatLists from './SingleChatLists';
import GroupChatLists from './GroupChatLists';
import AllChatLists from './AllChatLists';

import {
  CreateSingleChat as CreateSingleModal,
  CreateGroupChat as CreateGroupModal
} from '../chatModals';
import { toast } from "react-toastify";
import msgs from "../../../../messages/en/main";
import firebase from "firebase";


export class ChatLists extends Component {
  constructor(props) {
    super(props);
    this.tabsLabels = ["All", "People", "Groups"];

    this.state = {
      singleChatLists: [],
      groupChatLists: [],
      activeTab: this.tabsLabels[0],
      listFoundChat: [],
      chatLists: [],
      showModal: '',
    };

    const { dispatch, history, alert } = this.props;

    const unlisten = history.listen((location, action) => {
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });
    this.onWillUnmountFns = [unlisten];
  }

  async componentDidMount() {
    const { dispatch } = this.props;

    getChatLists()(dispatch);
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch } = this.props;
    const prevActiveChat = prevProps.activeChatData.data;
    const prevChatType = prevActiveChat ? prevActiveChat.type : '';
    const prevChatId = prevActiveChat ? prevActiveChat.id : '';
    const issetActiveChat = this.props.activeChatData.data;
    const activeSingleChatList = this.state.singleChatLists;
    this.props.activeChatData.data && this.props.activeChatData.data.length != 0 && this.props.activeChatData.data.type == 'single' && this.state.singleChatLists.length == 0 && this.setState(({}) => ({singleChatLists: [...this.state.singleChatLists, this.props.activeChatData.data]}));
    if (!issetActiveChat && prevChatType === 'single' && prevChatId !== '') {
      const updatedChatList = activeSingleChatList.filter( chat => chat.id !== prevChatId );
      this.setState(({}) => ({singleChatLists: updatedChatList}));
      // updateSingleChats(updatedChatList)(dispatch);
    }
  }

  onTabClick = (index) => {
    const { singleChatLists, groupChatLists } = this.state;

    let chatLists = [];

    if (this.tabsLabels[index] === 'People') {
      chatLists = singleChatLists;
    } else if (this.tabsLabels[index] === 'Group') {
      chatLists = groupChatLists;
    } else {
      chatLists = singleChatLists;
    }

    this.setState({activeTab: this.tabsLabels[index]});
    this.setState({chatLists: chatLists});
  }

  setListFoundChat = ({foundSingleChatList, foundGroupChatList}) => {
    this.setState(({}) => ({groupChatLists: foundGroupChatList}));
    this.setState(({}) => ({singleChatLists: foundSingleChatList}));
  }

  closeChatModal = () => {
    this.setState({ showModal: '' });
  }

  renderChatModal = () => {
    const { showModal } = this.state;
    const { dispatch, user: { id: userId }, friends, searchMsg } = this.props;

    switch(showModal) {
      case SINGLE_CHAT_TYPE: {
        return (
          <CreateSingleModal
            userId={userId}
            userList={friends}
            searchUserChat={(rawUser) => dispatch(userActions.searchUserChat(rawUser)) }
            onClickOutside={this.closeChatModal}
            clearSearchResults={() => dispatch(userActions.clearSearchResults())}
            chatOpen={this.chatOpen}
            searchMsg={searchMsg}
          />
        );
      }

      case GROUP_CHAT_TYPE: {
        return (
          <CreateGroupModal
            userId={userId}
            userList={friends}
            createGroupChat={this.groupChatOpen}
            updateGroupChat={true}
            searchUserChat={(rawUser) => dispatch(userActions.searchUserChat(rawUser)) }
            onClickOutside={this.closeChatModal}
            clearSearchResults={() => dispatch(userActions.clearSearchResults())}
            searchMsg={searchMsg}
          />
        )
      }

      default: {
        return null;
      }
    }
  };

  chatOpen = async (recipientId) => {
    if (!recipientId) return this.closeChatModal();

    const friendId = Number(recipientId);

    const { dispatch, chatListData: { chats } } = this.props;
    const requestData = { user_id: friendId, type: 1 };
    const chat = getExistChat(chats, friendId) || await dispatch(chatActions.createSingleChat(requestData)) || [];

    setActiveChat(chat)(dispatch);
    setChatContentType(CHAT_CONTENT_CHAT_MODULE)(dispatch);
    console.log('chatOpen')
    this.closeChatModal();
  };

  groupChatOpen = async ({ 
    title, 
    description, 
    chat_img, 
    members,
  }) => {
    if (!title) return this.closeChatModal();

    const { dispatch, onChatClick} = this.props;
    await createGroupChat({ type: 0, title, description, chat_img, members })(dispatch);

    onChatClick();
    this.closeChatModal();
  };

  deleteSingleChat = async (chat_id) => {
    const { dispatch } = this.props;

    try {
      leaveChat(chat_id)(dispatch);
      toast.success(msgs.CHAT_DELETED_SUCCESSFULLY);
    } catch (e) {
      console.error('deleteSingleChat error: ', e);
      toast.error(msgs.CHAT_DELETION_FAILURE);
    }
  }

  onChatClick = (id) => {
    const {
      dispatch,
      onChatClick: afterClick,
      chatListData: { chats },
      messagesLoading,
      activeChatId,
    } = this.props;

    if(messagesLoading || activeChatId === id) return;

    const chat = chats.find(chat => chat.id === id);
    setActiveChat({...chat, type: chat.chatType ? SINGLE_CHAT_TYPE : GROUP_CHAT_TYPE})(dispatch);
    afterClick();
  }

  setModal = function(type) {
    this.setState({ showModal: type })
  }

  sortChatListByTimestamp = (a,b) => {
      const chatHoistingAttibuteA = a.lastMessageTimestamp * 1000 || new Date(a.created_at).getTime();
      const chatHoistingAttibuteB = b.lastMessageTimestamp * 1000 || new Date(a.created_at).getTime();
      let c = chatHoistingAttibuteA;
      let d = chatHoistingAttibuteB;

      if (c < d) return 1;
      else if (c > d) return -1;

      return 0;
  }

  leaveSelectedChat = (chatId, event) => {
    const { dispatch, activeChatId } = this.props;
    if(activeChatId === chatId) {
      dispatch(deleteActiveChat());
    }
    leaveChat(chatId)(dispatch);

    event.stopPropagation();
  }

  setChatImg = (chat) => {
    const singleChatType = 1;
    const groupChatType = 0;
  
    switch(chat.chatType) {
      case singleChatType:
        const { user } = this.props;
        const singleChatMember = chat.members.find(member => member.userId !== user.id) || {};
        console.log('ChatList.js, singleChatMember: ', singleChatMember);
        return <img src={singleChatMember.profile_image || '/frontend/images/no-image.jpg'} alt={singleChatMember.userName}/>;
      case groupChatType:
        return <img src={chat.chat_img} alt={chat.name}/>;
      default:
        break;
    }
  }

  render() {
    const {
      activeChatData,
      chatListData: { loading, chats },
    } = this.props;
    const { activeTab } = this.state;
    // chats.sort((a,b) => this.sortChatListByTimestamp(a,b));
    const allChats = chats && chats.slice().sort((a,b) => this.sortChatListByTimestamp(a,b));
    const singleChatList = chats && allChats.filter(chat => chat.chatType === 1);
    const groupChatList = chats && allChats.filter(chat => chat.chatType === 0);
    return (
      <div>
        <header className={cs(s.headerChatList, 'col-sm-12')}>
          <h4>Messages</h4>
          <div className={s.newChatBtn}>
            New chat
            <i className="fas fa-chevron-down"/>
            <OptionMenu
              active={false}
              styles={s.newChatMenu}
              options={
                [{
                  title: "New Chat",
                  onClick: () => { this.setState({ showModal: SINGLE_CHAT_TYPE }) },
                },{
                  title: "New Group Chat",
                  onClick: () => { this.setState({ showModal: GROUP_CHAT_TYPE }) },
                }]
              }
            />
          </div>
        </header>

        <ChatSearch
          singleChatLists={singleChatList}
          groupChatLists={groupChatList}
          chatType={activeTab}
          setListFoundChat={this.setListFoundChat}
        />

        <div className={s.tabs}>
          <ol className={s.tabList}>
            {this.tabsLabels.map((tabLabel, index) => {
              return (
                <ChatListsTab
                  activeTab={activeTab}
                  key={tabLabel}
                  tabIndex={index}
                  label={tabLabel}
                  onTabClick={this.onTabClick}
                />
              );
            })}
          </ol>

          {(activeTab === '' || activeTab === 'All') && (
            <div className={cs(s.chatList, "chat-list-container")} >
              <AllChatLists
                  loading={loading}
                  list={allChats}
                  onChatClick={this.onChatClick}
                  activeChatDataId={activeChatData.data && activeChatData.data.id}
                  leaveGroupChat={this.leaveSelectedChat}
                  setChatImg={this.setChatImg}
              />
            </div>
          )}

          {activeTab === 'People' && (
            <div className={cs(s.chatList, "chat-list-container")} >
              <SingleChatLists
                loading={loading}
                list={singleChatList}
                containerStyle={s.chatList}
                onChatClick={this.onChatClick}
                activeChatDataId={activeChatData.data && activeChatData.data.id}
                deleteSingleChat={this.leaveSelectedChat}
                setChatImg={this.setChatImg}
                />
            </div>
          )}

          {activeTab === 'Groups' && (
            <div className={cs(s.chatList, "chat-list-container")}>
              <GroupChatLists
                loading={loading}
                list={groupChatList}
                containerStyle={s.chatList}
                activeChatDataId={activeChatData.data && activeChatData.data.id}
                onChatClick={this.onChatClick}
                leaveGroupChat={this.leaveSelectedChat}
                setChatImg={this.setChatImg}
                />
            </div>
          )}
        </div>
        {this.renderChatModal()}
      </div>
    );
  }
}

ChatLists.propTypes = {
  activeChatData: PropTypes.shape({
    data: PropTypes.oneOfType([activeSingleChatDataPropType, activeGroupChatDataPropType, isUndefined]),
  }).isRequired,
  user: userPropType.isRequired,
  history: PropTypes.object.isRequired,
  onChatClick: PropTypes.func.isRequired,
  chatListData: PropTypes.object.isRequired,
  setActiveChat: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    alert: state.alert,
    activeChatData: state.chat.activeChatData,
    chatListData: state.chat.chatListData,
    friends: state.chatusersearch.data != undefined ? state.chatusersearch.data : [],
    searchMsg: state.chatusersearch.error,
    activeChatId: state.chat.activeChatData.data ? state.chat.activeChatData.data.id : null,
    messagesLoading: state.chat.activeChatData.loading,
  };
};

export default withRouter(connect(mapStateToProps)(ChatLists));

// function formatMessageTimestamp(timestamp){
//   return moment.utc(timestamp).local().format('hh:mm A');
// }

// function mergeGroupChatLists(userId, groupChatId, groupChatData, currentChatList) {
//   const memberKey = Object.keys(groupChatData.members || {}).find(id => id === userId + '');

//   const userLeftChat = ((groupChatData.members || {})[memberKey] || {}).status === false;

//   let groupChatList = [...currentChatList];

//   const groupChatMessages = groupChatData.data;

//   const targetChatIndex = groupChatList.findIndex(i => i.id === groupChatId);

//   const getLastMessage = groupChatMessages => {
//     if (!groupChatMessages) return;

//     const messages = Object.values(groupChatMessages).sort((a, b) => (b.timestamp - a.timestamp));

//     return messages[0];
//   };

//   const lastMessage = getLastMessage(groupChatMessages);

//   const groupName = groupChatData.groupName || '';

//   if (targetChatIndex > -1) {
//     if (userLeftChat) {
//       //on leave chat
//       groupChatList = groupChatList.filter((i, index) => targetChatIndex !== index);
//     } else {
//       groupChatList[targetChatIndex] = {
//         ...groupChatList[targetChatIndex],
//         ...groupChatData,
//         groupName: groupChatData.groupName || '',
//       };

//       if (lastMessage) {
//         Object.assign(groupChatList[targetChatIndex], {
//           lastMessage: lastMessage.messageText,
//           timestamp: lastMessage.timestamp || firebase.database.ServerValue.TIMESTAMP, //formatMessageTimestamp(lastMessage.timestamp),
//         });
//       }
//     }
//   } else if (!userLeftChat) {
//     groupChatList.push({
//       id: groupChatId,
//       createdBy: groupChatData.createdBy,
//       members: groupChatData.members,
//       groupDescription: groupChatData.groupDescription,
//       groupName,
//       groupImage: groupChatData.groupImage,
//       lastMessage: lastMessage ? lastMessage.messageText : '',
//       timestamp: groupChatData.timestamp || firebase.database.ServerValue.TIMESTAMP,
//     });
//   }

//   return groupChatList;
// }

import { get, post, remove, getAuthHeaders, handleResponse, } from './helpers';
import { APP2_URL } from '../mainurl';

const getCheckUsernameUrl = (username) => (`${APP2_URL}checkUsername/${username}`);
const subscriptionPlansUrl = `${APP2_URL}getSubscriptionPlans`;
const setcheckTokenLife = `${APP2_URL}checkTokenLife`;
const signUpUrl = `${APP2_URL}register`;
const getCheckPhoneOccupiedUrl = (phone) => `${APP2_URL}checkPhoneExists/${phone}`;
const setPhoneIsVerifiedUrl = `${APP2_URL}setPhoneIsVerified`;
const getCheckEmailOccupiedUrl = (email) => `${APP2_URL}checkEmailExists/${email}`;
const setEmailIsVerifiedUrl = `${APP2_URL}setEmailIsVerified`;
const getDeleteFirebaseAuthUserUrl = (uid) => `${APP2_URL}deleteFirebaseAuthUser/${uid}`;
const deleteTemporaryUserRequest = (userId) => `${APP2_URL}delNotRegisteredUser/${userId}`;

export function saveAnswers(data) {
  return post(signUpUrl, data, getAuthHeaders())
    .then(handleResponse);
}

export const registerUser = saveAnswers;

export function checkUsername(username) {
  return get(getCheckUsernameUrl(username), getAuthHeaders())
    .then(handleResponse);
}

export function checkTokenLife(token) {
  return post(setcheckTokenLife,token, getAuthHeaders())
    .then(handleResponse);
}
export function getSubscriptionPlans() {
  return get(subscriptionPlansUrl, getAuthHeaders())
    .then(handleResponse);
}
export function checkPhoneOccupied(phone) {
  return get(getCheckPhoneOccupiedUrl(phone), getAuthHeaders())
    .then(handleResponse);
}

export function setPhoneIsVerified(data) {
  return post(setPhoneIsVerifiedUrl, data, getAuthHeaders())
    .then(handleResponse);
}

export function checkEmailOccupied(email) {
  return get(getCheckEmailOccupiedUrl(email), getAuthHeaders())
    .then(handleResponse);
}

export function setEmailIsVerified(data) {
  return post(setEmailIsVerifiedUrl, data, getAuthHeaders())
    .then(handleResponse);
}

export function deleteFirebaseAuthUser(uid) {
  return remove(getDeleteFirebaseAuthUserUrl(uid), getAuthHeaders())
    .then(handleResponse);
}

export function deleteUser(userId) {
  return remove(deleteTemporaryUserRequest(userId), getAuthHeaders())
    .then(handleResponse);
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import BottomWidget from '../common/BottomWidget';
import LoadingIcon from '../common/LoadingIcon';

import { userActions,alertActions,sparksActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class HelpCenterContent extends Component {
  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    const { user,holdflag } = this.props;
    if (user && holdflag == false) {
        //dispatch(sparksActions.getAll(user.id));
    }
  }

  render()
  {
    const { contentData,loading,alert } = this.props;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;

    return (
      <div className="sports-main content-part">
        <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          <div className="faq-in">
            <h1>Help Center</h1>
              <div className="bs-example">
                <div className="panel-group" id="accordion">
                  <div className="panel panel-default">
                    {loading ? (
                      <div className="loading-tweet">
                        <LoadingIcon />
                      </div>
                      ) : ('')
                    }
                    {contentData.length > 0 ? (
                        contentData.map((row,index) =>
                        <div className="panel panel-default" key={index}>
                          <div className="panel-heading">
                            <h4 className="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion" href={"#collapse"+index} aria-expanded="false" className="collapsed">
                                {row.heading}</a>
                            </h4>
                          </div>
                          <div id={"collapse"+index} className="panel-collapse collapse" aria-expanded="false" style={{height: '0px'}}>
                            <div className="panel-body">
                              <div dangerouslySetInnerHTML={{__html: row.content}} />
                            </div>
                          </div>
                        </div>
                        )
                      ) : ('N/A')
                    }
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 text-center">
          <BottomWidget />
        </div>
      </div>
    )
  }
}

HelpCenterContent.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    alert: state.alert,
    contentData: ownProps.contentData != undefined ? ownProps.contentData : [],
    loading: ownProps.loading ? ownProps.loading : false,
  };
}
export default withRouter(connect(mapStateToProps)(HelpCenterContent));

import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';
import { momentsActions,alertActions } from '../../../actions';
import BlockedContent from '../common/BlockedContent';
import ImgPreview from './ImgPreview';

const st = {
  float:'right'
};

class MomentSmallWidget extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      visible : 10,
    };
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 10};
    });
  }

  handleClicked(moment_id)
  {
      const { dispatch,loggeduser } = this.props;
      const data = {
        moment_id: moment_id,
        user_id: loggeduser.id,
      }
      dispatch(momentsActions.deleteMoment(data));
  };

  render(){
    const { momentslist, loggeduser, username, isHidden, isHiddenByYou } = this.props;

    if((isHidden || isHiddenByYou ) && username) {
      return (
        <BlockedContent
          username={username}
          isHidden={isHidden} 
          isHiddenByYou={isHiddenByYou} 
        />
      );
    }

    if(momentslist.length === 0) {
      return (
        <div className="profile">
          <h3>N/A</h3>
        </div>
      );
    }


    return (
      <div className="row">
        {momentslist.length > 0 ?
          (
            momentslist.slice(0,this.state.visible).map((moment,index) =>
            <div key={index} className="twiter-left1 pro-moment profile-mid">
              <div className="row">
                <div className="col-sm-4">
                  <div className="profile-mom-left">
                    { moment.file ? <ImgPreview src={moment.file} /> : null }
                  </div>
                </div>
                <div className="col-sm-6 padd-left">
                  <div className="profile-moment-right">
                    <Link to={"/instants/view/"+moment.moment_id}>{moment.title}</Link>
                    <h4>{moment.isPublished == '1' ? 'Published' : 'Draft'}</h4>
                    <h5>{moment.category_title}</h5>
                  </div>
                </div>
                {loggeduser.id == moment.user_id ?
                (<div className="col-sm-2">
                    <div style={st} className="dropdown moment-show">
                      <button className="btn-arrow" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <i className="fas fa-chevron-down"></i>
                      </button>
                     <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                       <Link className="dropdown-item" to={'/instants/edit/'+moment.moment_id}>Edit Instant</Link>
                       <button onClick={() => this.handleClicked(moment.moment_id)} className="dropdown-item" data-item={moment .username+'/status/'+moment.post_id}>Delete Instant</button>
                      </div>
                    </div>
                </div>
              ) : ('')}
              </div>
            </div>
            )
          ) : ('')}
          {momentslist != undefined && momentslist.length > 0 ?
           (this.state.visible < momentslist.length ? (
              <button onClick={this.loadMore} type="button" className="load-more btn btn-danger">Load more</button>
            ) : ('')) : ('')
          }
      </div>
    )
  }
}

function mapStateToProps(state,ownProps){
  return {
    loggeduser: state.authentication.user,
    momentslist: ownProps.momentslist ? ownProps.momentslist : [] ,
    isHidden: state.profileinfo.data != undefined && state.profileinfo.data.blockByUser == 1 ? true : false,
    isHiddenByYou: state.profileinfo.data != undefined && state.profileinfo.data.blockByYou,
  };
}

export default connect(mapStateToProps)(MomentSmallWidget);

import React, {Component} from 'react';
import UserSideBarSection from '../common/UserSideBarSection';

export default class MobileSettingSection extends Component {
  render(){
    return (
      <div className="content-part sports-main">
        <div className="container">
          <div className="row">
            <UserSideBarSection/>
            <div className="col-sm-6">
              <div className="twiter-left1 access-mid sett-block">
                <h3>Mobile</h3>
                <p>Expand your experience, get closer, and stay current.</p>
                <div className="add-your-phone">
                 <form>
                  <h4>Add your phone number</h4>
                    <p>We will text a verification code to this number. Standard SMS
                         fees may apply.</p>
                   <div className="row">
                     <div className="col-sm-5">
                     <div className="country-label">
                       <label>Country/region</label>
                     </div>
                   </div>
                   <div className="col-sm-7">
                     <div className="country-inpu">
                       <input type="text"/>
                     </div>
                   </div>
                   </div>
                    <div className="row">
                     <div className="col-sm-5">
                     <div className="country-label">
                       <label>Phone number</label>
                     </div>
                   </div>
                   <div className="col-sm-7">
                     <div className="country-inpu">
                       <input type="text"/>
                     </div>
                   </div>
                   </div>
                   <div className="row">
                     <div className="col-sm-5">
                   </div>
                   <div className="col-sm-7">
                     <div className="access-mid-btm-right">
                       <input value="CONTINUE" type="submit"/>
                     </div>
                   </div>
                   </div>
                   </form>
                </div>
                <div className="mobile-app">
                  <h4>Mobile app</h4>
                    <h5>Download 2cents app now</h5>
                   <button><img src="/frontend/images/app-download-btn1.png"/></button>
                   <button><img src="/frontend/images/app-download-btn2.png"/></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

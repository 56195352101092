import {
  SET_CHAT_CONTENT,
  CHAT_CONTENT_ACTIVE_OR_NEW,
} from '../../constants';


export default function contentType(state = CHAT_CONTENT_ACTIVE_OR_NEW, action) {
  switch (action.type) {
    case SET_CHAT_CONTENT:
      return action.data;
    default:
      return state;
  }
}

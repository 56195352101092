import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import cs from "classnames";

import Modal from "../index";
import { userActions } from "../../../actions";
import s from "./referral.module.scss";

const ReferralModal = ({ showReferralModal, referralLink, showReferralNow, userId, dispatch, planStatus, countOfInvitedUsers=0 }) => {
  const [showModal, setShowModal] = React.useState(showReferralModal && showReferralNow && planStatus !== 'free');
  const [dontShowAgain, setShowModalAgain] = React.useState(false);
  const referralLinkInput = React.useRef(null);
  
  const closeModal = () => {
    setShowModal(false);

    dispatch(userActions.setReferralModalVisability(userId, dontShowAgain));
  };

  const onCheckbox = () => setShowModalAgain(!dontShowAgain);

  const onReferralButtonClick = () => {
    referralLinkInput.current.select();
    document.execCommand('copy');
    toast.success("Referral link was copied");
  };
  
  return (
    showModal ? (
      <Modal>
        <div className={s.modal}>
          <div className={s.container}>
            <button onClick={closeModal} className={s.closeReferral}>
              <i className="fas fa-times"></i>
            </button>
            <div className={s.referralLogo}>
              <img src="/frontend/images/invite-friends-logo.png" />
            </div>
            <div className={s.referralInfo}>
              <p>Invite <b className={s.count}>{`${10 - countOfInvitedUsers} people`}</b> to register using the referral link and get <b className={s.referralBenefit}>a lifetime free account</b> for 2Cents</p>
            </div>
            <div className={s.referralModalActions}>
              <input type="text" className={s.referralTextInput} ref={referralLinkInput} defaultValue={referralLink} />
              <button onClick={onReferralButtonClick} className={cs(s.referralButton, s.copyLink)}>
                <i className={cs("fas fa-link", s.linkIcon)}></i>
                Copy link
              </button>
              <div className={s.dontShowBlock}>
                <label className={s.checkBoxWrapper}>
                  <input checked={dontShowAgain} onChange={onCheckbox} type="checkbox" className={s.dontShowBox} />
                  <span className={s.text}>Don't show again</span>
                </label>
              </div>
              <div className={s.closeDetatilsBlock}>
                <button onClick={closeModal} className={cs(s.referralButton, s.close)}>Close</button>
                <Link className={cs(s.referralButton, s.details)} to="/settings/referral">{"Details >"}</Link>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    ) : null
  );
}

ReferralModal.defaultProps = {
  show_referral_modal: 1,
  showReferralNow: true,
};

ReferralModal.propTypes = {
  show_referral_modal: PropTypes.oneOf([0, 1]),
  referralLink: PropTypes.string.isRequired,
  showReferralNow: PropTypes.bool,
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = ({ userData: { showReferralModal, data }, authentication: { user } }) => ({
  showReferralModal: data ? data.show_referral_modal : '',
  referralLink: user ? user.referral : '',
  planStatus: user ? user.plan_status : '',
  userId: data ? data.id : '',
  showReferralNow: showReferralModal,
  countOfInvitedUsers:  data ? data.count_referral_users : '',
});

export default connect(mapStateToProps, null)(ReferralModal);
import React, {Component} from 'react';
import {connect} from 'react-redux';
import ManageSparkType from './ManageSparkType';
import { momentsActions,alertActions } from '../../../actions';

const st = {
  width:'100%',
  height:'300px',
}

class searchTweets extends Component {
  constructor(props){
    super(props);
    this.state = {
      visible: 5
    };

    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 10};
    });
  }

  handleTweetSelect(string)
  {
      const { dispatch,user } = this.props;
      const data = {
        keyword: string,
        user_id: user.id
      }
      dispatch(momentsActions.getTweetBySearch(data));
  }

  // handleKeyUp = (event) =>{
  //   const { dispatch,user } = this.props;
  //     var stringVal = event.target.value;
  //     if(stringVal.trim())
  //     { var val = event.target.value;}
  //     else { var val = ''; }
  //
  //     const data = {
  //       search: val,
  //       user_id: user.id
  //     }
  //     dispatch(momentsActions.searchUserByTweet(data));
  // }

  myCallback = (dataFromChild) => {
      this.props.callbackFromMainParent(dataFromChild);
  }

  searchByTweet = () => {
    if (this.searchInput.value && this.searchInput.value.trim()) {
      const { dispatch,user } = this.props;
      const data = {
        keyword: this.searchInput.value,
        user_id: user.id
      }
      dispatch(momentsActions.getTweetBySearch(data));
    }
  }

  render()
  {
    const {momentstweetpost,searchTweetList,user,selectedSparks = []} = this.props;
    if (searchTweetList.length != undefined && searchTweetList.length > 8) {
      searchTweetList.splice(8, searchTweetList.length);
    }
    return(
          <div id="sparks3" className="tabcontent" style={{display: "none",}}>
            <div className="spark-account">
              <input
                ref={searchInput => this.searchInput = searchInput}
                type="text"
                className="account-input"
                placeholder="Search 2Cents accounts"
                name="searchtext"
                // onChange={this.handleKeyUp}
              />
              <button type="submit" onClick={this.searchByTweet}><i className="fas fa-search" /></button>
              <div className="srch-pop">
                <ul className="users">
                {searchTweetList.length != undefined ?
                  searchTweetList.map((userAccount, index) => (
                      <li key={index}><button onClick={() => this.handleTweetSelect(userAccount.string)} > <h6>{userAccount.string}</h6></button></li>
                  )) : (  <p>No Tweet Found.</p>)
                }
                </ul>
              </div>
            </div>
            {momentstweetpost.tweetMomentData != undefined ?
              (momentstweetpost.tweetMomentData.searchTweets.slice(0,this.state.visible).map((tweet, index) => {
                const isSelected = selectedSparks.filter((spark) => spark.post_id === tweet.post_id).length !== 0;

                return <ManageSparkType key={index} rawData={tweet} index={index} type='tweet'
                                        callbackFromParent={this.myCallback} isSelected={isSelected}/>
              })) : (<p>No Spark Found</p>)
            }

            {momentstweetpost.tweetMomentData != undefined ?
              (this.state.visible < momentstweetpost.tweetMomentData.searchTweets.length ? (
                 <button onClick={this.loadMore} type="button" className="load-more btn btn-danger">Load more</button>
               ) : ('')) : ('')
             }
        </div>
    )
  }
}

function mapStateToProps(state,ownProps) {
    return {
        user: state.authentication.user,
        //searchTweets: ownProps.searchTweets != undefined ? ownProps.searchTweets : [],
        momentstweetpost: state.momentstweetpost != undefined ? state.momentstweetpost : [],
        searchTweetList: state.momentstweetpost.searchTweetList != undefined ? state.momentstweetpost.searchTweetList : [],
    };
}
export default connect(mapStateToProps)(searchTweets);

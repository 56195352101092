import {getAppHeaders, handleResponse} from '../helpers';
import {APP_URL, APP2_URL} from '../mainurl';

export const suggestionService = {
  getAll,
  FollowUnfollow,
  FollowResponse
};

function getAll(user) {
  const data = {
    user_id: user
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}suggestionFriend`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function FollowUnfollow(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}follow`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function FollowResponse(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}followResponse`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

import React, {Component} from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import VoteActionView from './VoteActionView';
import VotePollView from './VotePollView';
import ListComments from './ListComments';
import SparkVideoSection from './SparkVideoSection';
import SparkImageSection from './SparkImageSection';
import LoadingIcon from '../common/LoadingIcon';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';
import {createDisplayedSpark, formatDateFromUTCtoLocal} from '../../../helpers';
import { alertActions,sparksActions, urlActions} from '../../../actions';
import { charCounter } from '../../../helpers';
import Params from '../../../config/Params';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ResparkedInstantView from './ResparkedInstantView';

class SingleView extends Component {

  static defaultProps = {
    maxCharCount: Params.textareaMaxCharCount
  }

  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    this.POST_SECTION = 'post';
    this.LIKE_SECTION = 'likes';
    this.RESPARK_SECTION = 'resparks';

    this.state = {
      comment_text : '',
      title: '',
      button: false,
      openedSection: this.POST_SECTION,
    };
    this.goBack = this.goBack.bind(this);
    this.focus = this.focus.bind(this);
  }

  LikeUnlikeSpark = (p_id) => {
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    const { user, history } = this.props;

    if(!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    }
    else {
      if (user && p_id) {
        const sendRaw = {
          post_id: p_id,
          user_id: user.id,
        }
        dispatch(sparksActions.LikeUnlikeSpark(sendRaw, 'single'));
      }
    }
  }

  close = () => {
    const { match: { path }, history } = this.props;
    const username = this.props.match.params.profilename;
    let rootPath = '';
    const isList = path.includes('/list-detail');
    if (path.startsWith('/:profilename') && !isList) {
      rootPath = username;
    } else if (path.startsWith('/instants')) {
      rootPath = 'instants/view/' + this.props.match.params.slug;
    } else if (isList) {
      rootPath = `${username}/list-detail/${this.props.match.params.l_id}`;
    }
    
    $('html, body').css('overflow', '');

    history.replace(`/${rootPath}`);
  }

  goBack(){
    const { user, history } = this.props;
    const username = this.props.match.params.profilename;
      $('html, body').css('overflow', '');

      if(user)
        history.goBack();
      else
        history.push(`/${username}`);
  }

  goNext = (username,post_id,type) => {
    const { history } = this.props;
      if(type == 'moment')
      {
        $('button.close').trigger('click');
        history.push('/instants/view/'+post_id);
      }
      else {
        history.push('/'+username+'/status/'+post_id);
      }
  }

  inSideClick = (event) => {
    event.stopPropagation();
  }

  outSideClick = (event) => {
    this.close();
  }

  focus() {
    this.textInput.focus();
  }

  CommentPost = (p_id) => {
    const { dispatch, history } = this.props;
    const { user } = this.props;
    const { comment_text } = this.state;

    if(!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    } else {
      if (user && p_id && comment_text) {
        const sendRaw = {
          post_id: p_id,
          user_id: user.id,
          comment_text: comment_text,
        }

        dispatch(sparksActions.CommentPost(sendRaw, 'single'));

        this.setState({
          comment_text: '',
          button: false,
        });
      } else {
        toast.error(msgs.ENTER_A_COMMENT_TO_REPLY);
      }
    }
  }

  UndoReSprak = (p_id) => {
    const { dispatch, history } = this.props;
    const { user } = this.props;

    if(!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    }
    else {
      if (user && p_id) {
        const sendRaw = {
          post_id: p_id,
          user_id: user.id,
          retweet_status: 'retweet',
        }
        dispatch(sparksActions.ReSparkPost(sendRaw, 'single'));

        this.setState({title: ''});
      }
      else {
        toast.error(msgs.SOMETHING_WENT_WRONG);
      }
    }
  }

  handleChange = (event) => {
      const { name, value } = event.target;
      this.setState({
              [name]: value
      });
      this.ButtonActiveOrNot(event);
  }

  // ReSparkPost = (p_id) => {
  //   const { dispatch, history } = this.props;
  //   const { user } = this.props;
  //   const {title} = this.state;

  //   if(!user) {
  //     dispatch(urlActions.savePreloginUrl());
  //     history.push('/login');
  //   }
  //   else {
  //     if (user && p_id) {
  //       const sendRaw = {
  //         post_id: p_id,
  //         user_id: user.id,
  //         title: title,
  //       }
  //       dispatch(sparksActions.ReSparkPost(sendRaw, 'single'));

  //       this.setState({title: ''});
  //     }
  //     else {
  //       toast.error(msgs.SOMETHING_WENT_WRONG);
  //     }
  //   }
  // }

  PopUpData = (sendRaw) => {
    const { dispatch, user, history } = this.props;

    if(!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    } else if(sendRaw) {
      sendRaw.type = 'single';
      dispatch(sparksActions.ReplyPopUpData(sendRaw));
      }
  }

  shouldComponentUpdate(prevProps,nextProps){
    return true;
  }

  handleBlockUser(user_id){
    const { dispatch, user, history } = this.props;

    if(!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    }
    else {
      const blockData = {
        receiver_id: user_id,
        user_id: user.id,
      }

      dispatch(sparksActions.blockUser(blockData));
    }
  }

  handleMuteUser(user_id){
    const { dispatch,user, history } = this.props;

    if(!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    }
    else {
      const muteData = {
        muted_user_id: user_id,
        user_id: user.id,
      }

      dispatch(sparksActions.muteUnmuteAccount(muteData));
    }
  }

  handleDeleteSpark(post_id,index){
    const { dispatch,user, history } = this.props;

    if(!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    }
    else {
      const postdata = {
        post_id: post_id,
        user_id: user.id,
        index: index,
      }

      dispatch(sparksActions.deleteSpark(postdata));
    }
  }

  copyToClipboard = (e) => {
    const url = window.location.href;
    $('<input>').val(url).appendTo('body').select()
    document.execCommand('copy');
    toast.success(msgs.LINK_COPIED);
  };

  chatOpen = (otherId, displayName, displayImage, fullName) => {

   const { dispatch, user, history } = this.props;

   if(!user) {
     dispatch(urlActions.savePreloginUrl());
     history.push('/login');
   }
   else {
     if (otherId && user.id) {
       let rawUser = {
         user_id: user.id,
         otherId: otherId,
         displayName: displayName,
         displayImage: displayImage,
         displayFullName: fullName,
         type: "single",
         status: true,
         msg: "fetching info in process",
       };

       localStorage.setItem('chatOpen', JSON.stringify(rawUser));

       setTimeout(function () {
         $('#friend-list-action button#close-hidden').trigger('click');
       }.bind(this), 500);

     }
     else {
       alert("unable to open data");
     }
   }
  };

  componentDidMount(){
    setTimeout(function() {
      $('a.hashtag-link').click(function(e){
          e.stopPropagation();
      });
    }.bind(this), 500);
  }
  
  goToProfile = (username) => {
    const { history } = this.props;
    this.close();
    window.scrollTo(0, 0);
    history.push('/' + username);
  }

  ButtonActiveOrNot = (event) => {
    let { value } = (event || window.event).target;
    value = value.trim();

    if (value && value.length > 0) {
      this.setState({ button: true });
    }
    else {
      this.setState({ button: false });
    }
  };

  deleteComment = (comment_id, index) => {
    const { dispatch, user } = this.props;

    if (user) {
      const commentData = {
        comment_id: comment_id,
        user_id: user.id,
        index: index,
      }

      dispatch(sparksActions.deleteComment(commentData));
    }
  }

  renderLikesResparks = (likeData, title) => {
    return (
      <div>
        <header className="likes-header">{title}</header>
        {likeData && likeData.length > 0 ? likeData.map(user => (
          <div key={user.user_id} className="liked-user">
            <div className="user-info">
              <img onClick={() => this.goToProfile(user.username)} src={user.profile_image || '/frontend/images/user-img.png'} style={{ cursor: 'pointer' }} alt="logo" />
              <div className="username  max-width-50">
                <h4 title={user.full_name} style={{ cursor: 'pointer' }} onClick={() => this.goToProfile(user.username)} className="text-overflow-ellipsis">{user.full_name}</h4>
                <h5 title={user.username} style={{ cursor: 'pointer' }} onClick={() => this.goToProfile(user.username)} className="text-overflow-ellipsis">{user.username}</h5>
              </div>
            </div>
          </div>
        )) : ""}
      </div>
    )
  }

  renderPostContent = () => {
    const { sparksingle, user, maxCharCount, profileInfo, history } = this.props;
    const { comment_text, button, isLikeSectionOpen } = this.state;
    return (
      <div className="pop-top-left">
        {sparksingle.loading != false ? (
          <div className="loading-tweet">
            <LoadingIcon />
          </div>
          ) : ('')
        }
        {!sparksingle.loading && sparksingle.error ? (<div className="text-center">Sorry, that Spark data doesn't exist!</div>) : ''}
        {!sparksingle.loading && sparksingle.msg === "User`s spark is protected." && (<div className="text-center">{sparksingle.msg}</div>)}
        {sparksingle.items != undefined ? (
          sparksingle.items.map((raw,index) =>
                <div key={index}>
                  <div className="pop-top-left-img" onClick={() => this.goToProfile(raw.at_username)}>
                    <img src={raw.profile_image ? raw.profile_image : '/frontend/images/user-img.png'} style={{ cursor: 'pointer' }} alt="logo" />
                  </div>
                  <div className="pop-name  text-overflow-ellipsis  max-width-50">
                    <h4 title={raw.name} style={{ cursor: 'pointer' }} onClick={() => this.goToProfile(raw.at_username.substr(1))}>{raw.name}</h4>
                    <h5 title={raw.at_username} style={{ cursor: 'pointer' }} onClick={() => this.goToProfile(raw.at_username.substr(1))}>{raw.at_username}</h5>
                  </div>
                  <div className="pop-right">
                    <div className="dropdown">
                      <button className="" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-chevron-down" />
                      </button>
                      <div className="dropdown-menu single-menu-list" aria-labelledby="dropdownMenuButton">
                        <span className="dropdown-item" onClick={this.copyToClipboard}>Copy link to clipboard</span>
                        {user && (!user || raw.user_id != user.id ? (
                          <div>
                            <span onClick={() => this.handleBlockUser(raw.user_id)} className="dropdown-item">{profileInfo && profileInfo.blockByYou == "1" ? "Unblock" : "Block" } {raw.at_username}</span>
                            <span onClick={(e) => this.handleMuteUser(raw.user_id)} className="dropdown-item" data-item={raw.username+'/status/'+raw.post_id}>Mute Account</span>
                          </div>
                        ) : (''))}
                        {user && user.id == raw.user_id ?
                            (<span onClick={() => this.handleDeleteSpark(raw.post_id,index)} className="dropdown-item">Delete Spark</span>) : ('')
                        }
                      </div>
                    </div>
                  </div>
                  {raw.retweetData.length > 0 && raw.posttype != 'moment' ? (
                    raw.retweetData.map((resparkData,index) =>
                      <div className="item-header pop-mid full-width" key={index} onClick={() => this.goNext(resparkData.username,resparkData.post_id)} data-id={resparkData.post_id}>
                        <Link title={resparkData.username} className="text-overflow-ellipsis" to="/">{resparkData.username}</Link>
                          <div>
                              { createDisplayedSpark(resparkData.post_title) }
                              { resparkData.posttype === 'poll' && <VotePollView data={resparkData}/> }
                              { resparkData.posttype === 'image' &&  <SparkImageSection sparkImageData={resparkData} /> }
                              { resparkData.posttype === 'video' &&  <SparkVideoSection sparkVideoData={resparkData} /> }
                          </div>
                      </div>
                    )
                    ) : ('')
                  }
                  {raw.retweet_id != 0  && raw.posttype != 'moment' && (raw.retweetData == undefined || raw.retweetData.length == 0 )  ? (
                     <>
                     <br/>
                      <div className="pop-mid" style={{width: "100%",}}>
                        <div>This Spark is unavailable</div>
                      </div>
                      </>
                    ) : ('')
                  }
                  {raw.posttype == 'image' ?
                    <SparkImageSection sparkImageData={raw} />
                    : ('')
                  }
                  {raw.posttype == 'video' ?
                    <SparkVideoSection sparkVideoData={raw} />
                    : ('')
                  }
                  {raw.posttype == 'poll' ? (
                    raw.pollVoteStatus == 0 && raw.user_id != user.id && raw.poll_expiry == 1 ? (
                      <VoteActionView singlePollData={raw}/>
                      ) : (
                      <VotePollView data={raw}/>
                      )
                    ) : ('')
                  }
                  
                  
                  {raw.posttype != 'moment' && raw ? (
                      <div className="spark-text">
                          { createDisplayedSpark(raw.post_title) }
                      </div>
                    ) : raw.momentData && <ResparkedInstantView raw={raw} parent={this} history={history} />
                  }
                  <div className="time">{ formatDateFromUTCtoLocal(raw.created) }</div>

                  <div className="retweet">
                    <h4>
                      <span onClick={() => raw.totalRetweet && this.setState({  openedSection: this.RESPARK_SECTION })}>
                        <b>{raw.totalRetweet}</b> ReSpark 
                      </span>
                      <span onClick={() => raw.totallike && this.setState({  openedSection: this.LIKE_SECTION })}>
                        <b> {raw.totallike}</b> Likes
                      </span>
                    </h4>
                  </div>
                  <div className="tweet-right-btm-icon">
                    <ul>
                      <li>
                        <button title="Comment" onClick={this.focus} className={raw.commentStatus > '0' ? "social-active" : ""} disabled={!user}>
                          <i className="far fa-comment" />{raw.totalComment}
                        </button>
                      </li>
                      <li>
                          {raw.retweetStatus == '0' ? (
                              <button
                                title="ReSpark"
                                onClick={() => this.PopUpData(raw)}
                                disabled={!user}
                                data-toggle={user ? "modal" : ""}
                                data-target={"#respark-modal"}
                                data-id={raw.post_id}
                                className={raw.retweetStatus == '1' ? "social-active" : ""}
                              >
                                <i className="fas fa-reply" />
                                {raw.totalRetweet}
                              </button>
                            ) : (
                              <button
                                title="Undo Spark"
                                onClick={() => this.UndoReSprak(raw.post_id)}
                                disabled={!user} data-id={raw.post_id}
                                className={raw.retweetStatus == '1' ? "social-active" : ""}
                              >
                                <i className="fas fa-reply" />
                                {raw.totalRetweet}
                              </button>
                            )
                          }
                      </li>
                      <li>
                        <button title="Like" onClick={() => this.LikeUnlikeSpark(raw.post_id)} className={raw.likeStatus == '1' ? "social-active" : ""} disabled={!user} >
                          <i className={raw.likeStatus == '1' ? "fas fa-heart": "far fa-heart"} />{raw.totallike}
                        </button>
                      </li>
                    </ul>
                  </div>
                  {user && (<div className="reply">
                    <div className="twit-mid-img">
                      <img src={user && user.profile_image != undefined ? user.profile_image : "/frontend/images/user-img.png"} alt="logo" />
                    </div>
                    <div className="twi-mid-right">
                        <textarea ref={(input) => { this.textInput = input; }} maxLength={maxCharCount} placeholder="What’s going on?" name="comment_text" onChange={this.handleChange} value={comment_text} />
                        {charCounter(comment_text, maxCharCount)}
                        <div className="mid-icon">
                          { button ? <button onClick={() => this.CommentPost(raw.post_id)}>Comment</button> : <button disabled={true}>Comment</button> }
                        </div>
                    </div>
                  </div>)}
                  <ListComments CommentData={raw} deleteComment={this.deleteComment} goToProfile={this.goToProfile} />
                </div>
            )
          ) : ('')
        }
      </div>
    )
  }

  renderSwitchSection = () => {
    const { sparksingle } = this.props;
    const { openedSection } = this.state;

    switch (openedSection) {
      case this.POST_SECTION: {
        return this.renderPostContent();
      }
      case this.LIKE_SECTION: {
        return this.renderLikesResparks(sparksingle.items[0].likeData, 'Liked by');
      }
      case this.RESPARK_SECTION:
        return this.renderLikesResparks(sparksingle.items[0].resparkedUsers, 'Resparked by');
      default: {
        return this.renderPostContent();
      }
    }
  }

  render(){
    const { profileInfo } = this.props;
    const { openedSection } = this.state;

    if (profileInfo != undefined) {
      profileInfo.blockByUser === '1' && this.close();
    }

    return (
      <div className="overlay" onClick={this.outSideClick}>
        <div className="modal tweet-modal" id={"tweet-modal"} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog create-new" role="document" onClick={this.inSideClick} style={{ maxWidth: openedSection !== this.POST_SECTION ? 350 : 570, transition: 'max-width 0.1s' }}>
            <div className="modal-content" >
              <button type="button" className="close" onClick={ () => openedSection !== this.POST_SECTION ? this.setState({ openedSection: this.POST_SECTION }) : this.close() }>
                <span aria-hidden="true">×</span>
              </button>
              {
                this.renderSwitchSection()
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SingleView.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    sparksingle: state.sparksingle,
    user: state.authentication.user,
    alert: state.alert,
    post_id: ownProps.post_id,
    url: ownProps.match.url,
    profileInfo: state.profileinfo ? state.profileinfo.data : {},
  };
}
// const SingleViewWithRouter = withRouter(SingleView);
export default withRouter(connect(mapStateToProps)(SingleView));

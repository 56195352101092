import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import msgs from '../../../messages/en/main';

import { toast } from 'react-toastify';

import { alertActions,suggestionActions,listsActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class ListMemberSubscriberWidget extends PureComponent {

  constructor(props){
    super(props);
    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });
  }

  FollowUnfollowUser = (follower_id) => {
    const { dispatch } = this.props;
    const { loggeduser,l_id } = this.props;

    if(loggeduser && follower_id){
          const sendRaw = {
            follower_id : follower_id,
            user_id: loggeduser.id,
            list_id: l_id
          }
          dispatch(suggestionActions.FollowUnfollowOther(sendRaw,'','listsection'));
    }
    else {
       toast.error(msgs.SOMETHING_WENT_WRONG);
    }
  }

  FollowResponse = (other_id,action) => {
    const { dispatch } = this.props;
    const { loggeduser,l_id } = this.props;

    if(loggeduser && other_id && action){
          const sendRaw = {
            other_id : other_id,
            user_id: loggeduser.id,
            action: action,
            list_id: l_id
          }
          dispatch(suggestionActions.FollowResponse(sendRaw,'','listsection'));
    }
    else {
       toast.error(msgs.SOMETHING_WENT_WRONG);
    }
  }

  RemoveUser = (member_id) => {
      this.setState({ submitted: true });
      const { dispatch,loggeduser,l_id } = this.props;

      if(loggeduser.id && member_id && l_id && dispatch)
      {
        const data = {
          user_id: loggeduser.id,
          list_id: l_id,
          member_id: member_id,
          action: "delete"
        }
        //console.log(data);
        dispatch(listsActions.addDeleteMembers(data));
      }
  }

  goToProfile = (username) => {
    const { history } = this.props;
    history.push('/' + username)
  }

  render(){
    let {lists,loggeduser,type,owner_id} = this.props;

    return (

         <div className="block-acc-btm bor-top">

          {lists.length > 0 ?
            (
              lists.map((list,index) =>
               <div key={index} className="block-acc-btm1">
                 <div className="row">
                    <div className="col-sm-2">
                      <span className="twit-mid-img" onClick={() => this.goToProfile(list.username)}>
                        <img src={list.profile_image ? list.profile_image : '/frontend/images/user-img.png'} alt="profile image"/>
                      </span>
                    </div>
                    <div className="col-sm-4 padd-left">
                      <div className="block-acc-info">
                        <h4 onClick={() => this.goToProfile(list.username)}>
                        <strong className="list-name word-break">{list.name}</strong>
                        <b className="list-username">{list.at_username}</b>
                        </h4>
                      </div>
                    </div>
                    <div className="col-sm-6" id="list-add-follow-action">
                      {list.id != loggeduser.id ? (
                          list.followStatus == '0' ? (
                              <div>
                                <button className="button" data-id={list.id} onClick={() => this.FollowUnfollowUser(list.id)}>Follow</button>
                                {type != undefined && type != "subscriber" && loggeduser.id == owner_id ? (
                                    <button className="button" data-id={list.id} onClick={() => this.RemoveUser(list.id)}>
                                      <i className="active">Remove</i>
                                      <i className="hover-active">Remove</i>
                                    </button>
                                  ) : ('')
                                }
                              </div>
                            ) : (
                                list.followStatus == '1' ? (
                                  <div>
                                    <button className="button" data-id={list.id} onClick={() => this.FollowUnfollowUser(list.id)}>
                                      <i className="active">Following</i>
                                      <i className="hover-active">Unfollow</i>
                                    </button>
                                    {type != undefined && type != "subscriber" && loggeduser.id == owner_id ? (
                                        <button className="button" data-id={list.id} onClick={() => this.RemoveUser(list.id)}>
                                          <i className="active">Remove</i>
                                          <i className="hover-active">Remove</i>
                                        </button>
                                      ) : ('')
                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <button className="button" data-id={list.id}  onClick={() => this.FollowResponse(list.id,'cancel')}>
                                      <i className="active">Pending</i>
                                      <i className="hover-active">Cancel</i>
                                    </button>
                                    {type != undefined && type != "subscriber" && loggeduser.id == owner_id ? (
                                        <button className="button" data-id={list.id} onClick={() => this.RemoveUser(list.id)}>
                                          <i className="active">Remove</i>
                                          <i className="hover-active">Remove</i>
                                        </button>
                                      ) : ('')
                                    }
                                  </div>
                                )
                            )
                        ) : ('')
                      }
                    </div>
                 </div>
               </div>
               )
             ) : ('')
           }
          </div>
    )
  }
}

ListMemberSubscriberWidget.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    loggeduser: state.authentication.user,
    l_id: ownProps.l_id,
    owner_id: ownProps.owner_id,
    lists: ownProps.listData ? ownProps.listData : [] ,
    type: ownProps.type != undefined ? ownProps.type : '',
  };
}
export default withRouter(connect(mapStateToProps)(ListMemberSubscriberWidget));

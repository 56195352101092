import React from "react";

export const charCounter = (text, max) => {

    let charsLeft = text ? text.toString() : '';
    charsLeft = charsLeft.replace(/&nbsp;/gm, ' ');
    const charCount = charsLeft.length;
    const charLeft = max - charCount;

    return (
        <div style={{marginBottom: '10px'}}><strong>total characters left:</strong> {charLeft}</div>
    );

};
import Pusher from "pusher-js";
import Echo from "laravel-echo";

import { getAuthHeaders } from '../api/helpers';

window.Pusher = Pusher;

export const Echoer =  (() => {
	let instance = null
	let socketId = null

	const getInstance = () => {
		if (instance === null){
			instance = new Echo({
				broadcaster: process.env.REACT_APP_ECHO_BROADCASTER,
				key: process.env.REACT_APP_ECHO_PUBLIC_KEY,
				authEndpoint: process.env.REACT_APP_ECHO_AUTH_ENDPOINT,
				auth: {
					headers: getAuthHeaders(),
				},
				wsHost: process.env.REACT_APP_ECHO_WS_HOST,
				wsPort: process.env.REACT_APP_ECHO_WS_PORT,
				wssPort: Number(process.env.REACT_APP_ECHO_WSS_PORT) || undefined,
				disableStats: true,
				encrypted: true,
				forceTLS: process.env.REACT_APP_ECHO_TLS === 'true'
			});


			instance.connector.pusher.connection.bind('connected', () => {
				socketId = instance && instance.socketId();
			});

			instance.connector.pusher.connection.bind('disconnected', () => {
				instance = null;
				socketId = null;
			});

			return instance;
		}

		return instance
	}

	 function getSocketId() {
		 console.log('socketId (getSocketId()): ', socketId);
		return socketId;
	}

	return {
		getInstance,
		getSocketId
	}
})()

import React, {useCallback, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import msgs from "../../messages/en/main";
import {Link} from "react-router-dom";
import {SimpleInput} from "../common/input";
import {useFormik} from "formik";
import {userService} from "../../services";
import s from "./forgotPassword.module.scss";
import cx from 'classnames';
import WelcomeLayout from "../welcomeLayout/WelcomeLayout";


function ForgotPassword() {
  const [isEmailSent, setIsEmailSent] = useState(null);
  const [email, setEmail] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: email || '',
    },
    onSubmit: (values, formikHelpers) => {
      const { email } = values;

      const errors = {
        email: validateEmail(email),
      };

      formikHelpers.setErrors(errors);
      if (hasError(errors)) return;

      onSubmit(email);
    },
  });

  const onSubmit = useCallback((email) => {
    userService.ForgetPassword(email).then(res => {
      if (res.status) {
        setIsEmailSent(true);
        setEmail(res.email);
      } else {
        toast.error(msgs.EMAIL_NOT_REGISTERED)
      }
    }).catch(err => {
      console.log("error", err);
    });
  }, []);

  return (
    <>
      <WelcomeLayout leftBarClassName={s.welcomeLayoutLeftBar}>
        <ToastContainer/>
        {!isEmailSent ? (
          <div className={cx(s.loginRightInn, 'email')}>
            <h4>RESET PASSWORD</h4>
            <form onSubmit={formik.handleSubmit}>
              <SimpleInput
                value={formik.values.email}
                type={'text'}
                name={'email'}
                id={'email'}
                placeholder={'Enter email'}
                onChange={formik.handleChange}
                errMessage={formik.errors.email}
                maxLength={254}
              />
              <button type="submit">
                <i className="fas fa-angle-right" />
              </button>
            </form>
          </div>
        ) : (
          <div className={cx(s.loginRightInn, 'otp')}>
            <h2><span>Please check your email inbox</span> {email}</h2>
          </div>
        )}
        <div className={s.dontHaveAccount}>
          <span>Join 2cents today.</span>
          <p>Don’t have an account? <Link to="/registration">SIGN UP</Link></p>
        </div>
      </WelcomeLayout>
    </>
  );
}

export default ForgotPassword;

function validateEmail(value) {
  let error;
  if (!value) {
    error = 'Email is required';
  } else {
    // const result = isStrongPassword(value);
    // if (Array.isArray(result) && result.length) {
    //   error = passwordValidationErrorsCodesToMessages[result[0]] || 'invalid';
    // }
  }

  return error;
}

function hasError(errors) {
  return Object.values(errors).find(i => !!i);
}

import React, {Component} from 'react';
import { connect } from 'react-redux';
import $ from "jquery";
import CreateSpark from '../sparks/CreateSpark';
import { userActions } from '../../../actions';
import {PasswordInput} from "../../../components/common/input";
import {userService} from "../../../services";
import {toast} from "react-toastify";
import { getCurrentLocation } from '../../../actions/helpers';
import { useHistory } from "react-router-dom";
import { userConstants } from '../../../constants';

class PopUp extends Component {
  constructor(props){
    super(props);

    this.state = {
      user: {
        user_id: this.props.user.id,
        username: this.props.user.username,
        email: this.props.user.email,
        phone_number: this.props.user.phone_number,
        gender: this.props.user.gender,
        action: 'account',
        plan_id:'',
        plan_name:'',
        plan_status:'',
        plan_amount:'',
        plan_expiry:''
      },
      update: false,
      passwordValue: '',
      passwordErrors: '',
    }
  }

  // componentDidMount(){
  //   $("[data-dismiss='modal'][data-toggle='modal']").click(function() {
  //     if($('body').css("padding-right") == '15px') {
  //         $('body').addClass('modal-open-fix');
  //         $('body').addClass('modal-open-fix-pad');
  //     } else {
  //         $('body').removeClass('modal-open-fix modal-open-fix-pad');
  //     }

  //     setTimeout(function() {

  //         if($('body').hasClass('modal-open-fix-pad')) {
  //             $('body').css('padding-right', '15px');
  //         } else {
  //           $('body').removeClass('modal-open-fix modal-open-fix-pad');
  //         }
  //     }, 1000);
  //   }.bind(this));
  // }

  closeAllModals = (event) => {
    $('.modal').hide('slow');
    $('.modal-backdrop').remove();
  }

  onSparkSendButton = () => {
    const { user} = this.props;

    $('button.close').trigger('click');
    // history.push(`/${user.username}`);
      // const currentLocation = getCurrentLocation();
    // setTimeout(function() {
    //   window.location.replace(currentLocation.pathname);
    // }.bind(this), 200);
    // window.location.replace(`/${user.username}`);
    // console.group(currentLocation)
  }

  deactivateAccount = () => {
    const { user, passwordValue } = this.state;
    const { dispatch } = this.props;
    const data = {
      user_id: user.user_id,
      password: passwordValue,
    };

    if(user.user_id) {
      dispatch(userActions.deactivateAccount(data));
    }
  }

  passwordHandleChange = (passwordInput) => {
    const { value } = passwordInput.target;
    this.setState({ passwordValue: value });
  };

  closeModal = () => {
    this.setState({ passwordValue: '' });
  };

  subsCancel = (user_id) => {
    const { dispatch, user } = this.props;
    dispatch(request());
    userService.SubCancel(user_id).then(res =>{
      if(res.status){
        toast.success(res.msg);
        this.setState({ plan_status: 'CANCEL' })
        dispatch(success());
      } else {
        dispatch(failure());
      }
    }).finally(() => dispatch(userActions.getHeaderResponse(user)));

    function request() { return { type: userConstants.PLAN_CANCEL_REQUEST } }
    function success() { return { type: userConstants.PLAN_CANCEL_SUCCESS } }
    function failure() { return { type: userConstants.PLAN_CANCEL_FAILURE } }
  }

  render(){
    const { isPasswordShow } = this.props;
    const { user, passwordValue, passwordErrors } = this.state;

    return (
      <div>
        {/* CreateSpark Modal */}
        <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog create-new" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Compose New Spark</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <CreateSpark onSend={this.onSparkSendButton} />
              </div>
            </div>
          </div>
        </div>
        {/* CreateSpark Modal */}

        {/* Deactivate Account Model */}
        <div className="modal fade" id="deactivate-acc" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog create-new" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Attention!</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body message">
                <h3>Your account will be deactivated and all user data will be removed!</h3>
                <p>Are you sure?</p>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#deactivate-info"
                  className="message"
                  data-dismiss="modal"
                >
                  YES
                </button>
                <button type="button" data-toggle="modal" data-target="#deactivate-acc" className="message" data-dismiss="modal">
                  NO
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Cancel plan */}
        <div className="modal fade" id="cancel-plan" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog create-new" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Attention!</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body message">
                <h3>Your current plan will be canceled!</h3>
                <p>Are you sure?</p>
                <button
                  type="button"
                  data-toggle="modal"
                  className="message"
                  data-dismiss="modal"
                  onClick={() => this.subsCancel(user.user_id)}
                >
                  YES
                </button>
                <button type="button" data-toggle="modal" data-target="#cancel-plan" className="message" data-dismiss="modal">
                  NO
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Cancel plan */}

        {/* Deactivate Information Model */}
        <div className="modal fade" id="deactivate-info" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog create-new" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Attention!</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeModal()}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body message">
                {/*<h3>You will have an ability to access your account within validity of your bought plan.</h3>*/}
                <p></p>
                <form>
                  <PasswordInput
                    value={passwordValue}
                    type={isPasswordShow ? 'text' : 'password'}
                    name={'password'}
                    id={'inputPassword'}
                    placeholder={'Password'}
                    onChange={this.passwordHandleChange}
                    errMessage={passwordErrors || ''}
                  />
                  <p></p>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.closeModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    data-toggle="modal"
                    data-target="#deactivate-info"
                    className="cancel-btn"
                    data-dismiss="modal"
                    style={{"float": "right"}}
                    onClick={() => this.deactivateAccount()}
                  >
                    Delete account
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { authentication: { user }, history } = state;
  return {
    user,
    isPasswordShow: false,
    history,
  };
}

export default connect(mapStateToProps)(PopUp);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';
import { sparksActions, suggestionActions, urlActions } from "../../../actions";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class UserTinyWidget extends Component {

  state = {
    muteUserByYou: this.props.profileInfo.muteUserByYou,
    blockByYou: this.props.blockByYou,
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.profileInfo !== this.props.profileInfo) {
      this.setState({ 
        muteUserByYou: this.props.profileInfo.muteUserByYou,
        blockByYou: this.props.blockByYou,
      })
    }
  }

  handleBlockUser(profileInfo){
    const { dispatch, loggeduser, history } = this.props;

    if(!loggeduser) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    }
    else {
      const blockData = {
        receiver_id: profileInfo.user_id,
        user_id: loggeduser.id,
      }

      const sendRaw = {
        follower_id : profileInfo.user_id,
        user_id: loggeduser.id,
      }

      if(profileInfo.followStatus == '1') {
        dispatch(suggestionActions.FollowUnfollowOther(sendRaw, profileInfo.username));
      }

      if(profileInfo.followings.some(user => user.user_id == loggeduser.id)) {
        dispatch(suggestionActions.FollowUnfollowOther({
          follower_id : loggeduser.id,
          user_id: profileInfo.user_id,
        },
        profileInfo.username,
        'isFollower'
        ));
      }

      dispatch(sparksActions.blockUser(blockData));
      this.setState(state => ({ blockByYou: !state.blockByYou}));
    }
  }

  handleMuteUser(e, user_id){
    const { dispatch, loggeduser, history } = this.props;
    e.preventDefault();
    if(!loggeduser) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    }
    else {
      const muteData = {
        muted_user_id: user_id,
        user_id: loggeduser.id,
      }
      dispatch(sparksActions.muteUnmuteAccount(muteData, ''));
      this.setState(state => ({ muteUserByYou: !state.muteUserByYou}));
    }
  }

  render(){
    let { loggeduser, full_name, username, isPrivate, followRequests, profileInfo, created, muteLoading, blockLoading } = this.props;
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    // const regDateStr = created ? loggeduser.created && new Date(created.split(' ').join('T')).toLocaleDateString('en-US', DATE_OPTIONS) : '';
    const regDateStr = loggeduser && loggeduser.created && new Date(loggeduser.created.split(' ').join('T')).toLocaleDateString('en-US', DATE_OPTIONS) || '';
    const created_at = loggeduser && created && new Date(created.split(' ').join('T')).toLocaleDateString('en-US', DATE_OPTIONS) || '';

    return (
      <div className="twiter-left1 list">
        <div className="user-name  text-overflow-ellipsis">
          <h3 title={full_name}>{full_name}</h3>
          { username ? (
            <h4>
              <Link to={"/"+username} title={'@'+username}>{'@'+username}</Link>
              { isPrivate == '1' && isPrivate != undefined ? (<i className="fas fa-lock"></i>) : ('') }
            </h4>
          ) : '' }
        </div>
        {
          loggeduser && loggeduser.username == username ? (
            <h5 className="follower-requests">
              <Link to={"/"+loggeduser.username+"/create-list"}> Create new list </Link>
            </h5>
          ) : ('')
        }
        {
          followRequests && followRequests.length > 0 ? (
            <h5 className="follower-requests">
              <Link to={"/follower-requests"}>( {followRequests.length} ) New Follow Requests </Link>
            </h5>
          ) : ('')
        }
        <h5><i className="far fa-calendar-alt"></i> Joined {loggeduser && loggeduser.username == username ? regDateStr : created_at}</h5>
        {
          loggeduser && loggeduser.username !== username && profileInfo ? (
            <div className="block-user-btn-container">
              <button
                onClick={ () => this.handleBlockUser(profileInfo) }
                className="block-user-btn"
                disabled={blockLoading}
              >
                { blockLoading && <img src="/frontend/images/bwloader.gif" alt="" /> }
                { !blockLoading && (!this.state.blockByYou ? "Block" : "Unblock") }
              </button>
              <button
                onClick={ (e) => this.handleMuteUser(e, profileInfo.user_id) }
                className={"block-user-btn"}
                disabled={muteLoading}
              >
                { muteLoading && <img src="/frontend/images/bwloader.gif" alt="" /> }
                { !muteLoading && (!this.state.muteUserByYou ? "Mute" : "Unmute") }
              </button>
            </div>
          ) : ('')
        }
      </div>
    )
  }
}

UserTinyWidget.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    loggeduser: state.authentication.user,
    muteLoading: state.users.muteLoading,
    muteData: state.users.muteData,
    blockLoading: state.sparks.loading,
    full_name: ownProps.full_name,
    username: ownProps.username,
    created: ownProps.created,
    isPrivate: ownProps.isPrivate,
    followRequests: state.profileinfo.data != undefined ? state.profileinfo.data.followRequest : [],
    blockByYou: state.profileinfo.data != undefined && state.profileinfo.data.blockByYou,
  };
}
export default withRouter(connect(mapStateToProps)(UserTinyWidget));

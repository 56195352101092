
import React, {Component} from 'react';
import { connect } from 'react-redux';


class VotePollView extends Component {
  constructor(props){
    super(props);
  }
  render(){
    let {singlePollData} =this.props;
    return (
      <ul className="poll-dis">
        {singlePollData.pollData.length > 0 ? (
            singlePollData.pollData.map((data,index) =>
              (
                <li key={index} className={data.vote_status != 0 ? ("li-bg") : ('')}>
                  <b>{data.count > 0 && singlePollData.pollTotalVotes != undefined ? (data.count*100)/(singlePollData.pollTotalVotes) : '0'}% </b>
                  <span>{data.choice_option}</span>
                  {data.vote_status != 0 ? (<i className="far fa-check-circle" />) : ('')}
                </li>
              )
            )
          ) : ('')
        }
      </ul>
    )
  }
}


function mapStateToProps(state,ownProps){
  return {
    alert: state.alert,
    user: state.authentication.user,
    singlePollData: ownProps.singlePollData
  };
}
export default connect(mapStateToProps)(VotePollView);

import { urlConstants } from '../constants';

export function url(state = {}, action) {
  switch (action.type) {
      /**shared **/
    case urlConstants.UPDATE_SHARED_LINK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case urlConstants.UPDATE_SHARED_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        sharedLink: action.url
      };
    case urlConstants.UPDATE_SHARED_LINK_FAILURE:
      return {
        ...state,
        loading: false
      };
    case urlConstants.UPDATE_SHARED_LINK_CLEAR:
      return {};

      /**prelogin **/
    case urlConstants.UPDATE_PRELOGIN_URL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case urlConstants.UPDATE_PRELOGIN_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        preloginUrl: action.url
      };
    case urlConstants.UPDATE_PRELOGIN_URL_FAILURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state
  }
}

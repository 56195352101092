import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import { momentsActions,alertActions } from '../../../actions';
import SparkLike from './SparkLike';
import SparkAccount from './SparkAccount';
import SparkTweet from './SparkTweet';
import { toast } from 'react-toastify';
import msgs from "../../../messages/en/main"
import $ from "jquery";
import AttachMomentSpark from '../sparks/attachMomentSpark';
import LoadingIcon from '../common/LoadingIcon';
import ManageSparkType from './ManageSparkType';
import ManageSelectedSpark from './ManageSelectedSpark';
import classNames from 'classnames';
import { fileSizeLimits } from '../../../misc/fileSizeLimits';
import { acceptableImageFormats, imageFormatChecker } from '../../../helpers';

// const st = {
//   width:'100%',
//   height:'auto',
// }

class EditMoment extends Component {
  constructor(props){
    super(props);

    this.state = {
      momentData: {
        category: '',
        title: '',
        description: '',
        userid: '',
      },
      listDataFromChild: this.props.momentsDetail.posts,
      type: "image",
      file: [],
      PreviewUrls: [],
      submitted: false,
      isCoverLoaded: true,
      fieldIsEmpty: {
        category: null,
        title: null,
        description: null,
        file: null,
        listDataFromChild: null,
      },
    };
    this.imgRef = createRef();
  }

  openCity = (evt, cityName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    if(document.getElementById(cityName) != null) {
      document.getElementById(cityName).style.display = "block";
    }
    evt.currentTarget.className += " active";
  }

  componentDidUpdate(prevProps){
    if (prevProps.momentsDetail.posts !== this.props.momentsDetail.posts) {
      this.setState({
        listDataFromChild: this.props.momentsDetail.posts,
        momentData: {
            category: this.props.momentsDetail.category_id,
            title: this.props.momentsDetail.title,
            description: this.props.momentsDetail.description,
            userid: this.props.user.id,
          },
        PreviewUrls: this.props.momentsDetail.PreviewUrls,
        file: this.props.momentsDetail.file,
       })
    }
  }

  handleChange = (event) => {
      const { name, value } = event.target;
      const { momentData, fieldIsEmpty } = this.state;

      this.setState({
          momentData: {
              ...momentData,
              [name]: value
          }
      });

      this.setState(prevState => ({
        fieldIsEmpty: {
            ...fieldIsEmpty,
            [name]: !prevState.momentData[name],
        }
      }));
  }

  handleInputTrigger = () => {
      this.fileInput.click();
  }

  readURL = (input) => {
    if (input) {
      var reader = new FileReader();
      reader.onload = function(e) {
        $('.UploadedImg').attr('src', e.target.result);
      }
      reader.readAsDataURL(input);
    }
  }

  handleImageChange = e => {
    e.preventDefault();
    let file = e.target.files[0];

    if(file) {
      if (file.size > fileSizeLimits.image) {
        return  toast.error(msgs.MAX_IMG_SIZE);
      }
      if (!imageFormatChecker(file)) {
        return toast.error(msgs.VALID_IMAGE_FORMATS);
      }

       let reader = new FileReader();
       let fType = file.type;
        if(fType.match('image.*')) {
            if(fType.match('image.*')){
              this.setState({ type: 'image' });
            }

            reader.onloadend = () => {
               this.setState(prevState => ({
                file: [file],
                PreviewUrls: [reader.result],
              }));
            }

            reader.readAsDataURL(file);

         this.setState(prevState => ({
          fieldIsEmpty: {
              ...prevState.fieldIsEmpty,
              file: false,
          }
        }));
        } else {
           toast.error(msgs.IMAGES_ARE_ALLOWED);
          }
    } else {
        return;
      }
  }

  createMomentProcess = (action, moment_id) => {
      var assignPost = [];
      const { 
        listDataFromChild, 
        file, 
        momentData, 
        type, 
      } = this.state;

      if(listDataFromChild.length > 0) {
        for (let i = 0; i < listDataFromChild.length; i++) {
          assignPost.push(listDataFromChild[i].post_id);
        }
      }

      this.setState({ submitted: true });
      const { dispatch } = this.props;

      if(momentData) {
        const sendRaw = {
          momentData,
          type,
          action,
          moment_id,
          file,
          assignPost,
        }

        if (!this.isMomentDataValid(sendRaw)) return;
        console.log(sendRaw)
        dispatch(momentsActions.editMoment(sendRaw));
      }
  }

  isMomentDataValid = ({
    momentData,
    action,
    type,
    file,
    assignPost,
    moment_id,
  }) => {

    const {
      category,
      title,
      description,
      userid,
    } = momentData;

    switch(action) {
      case 0:
        this.fieldIsEmptyCheck(category, 'category');
        this.fieldIsEmptyCheck(title, 'title', action);

        if (!category || !title) {
          toast.error(msgs.FILL_REQUIRED_INFORMATION);
          return false;
        }
      return true;
      case 1:
        this.fieldIsEmptyCheck(category, 'category');
        this.fieldIsEmptyCheck(title, 'title');
        this.fieldIsEmptyCheck(description, "description");
        this.fieldIsEmptyCheck(file, "file");
        this.fieldIsEmptyCheck(assignPost.length, 'listDataFromChild');
  
  
        if (!description || !file || assignPost.length === 0) {
          toast.error(msgs.FILL_REQUIRED_INFORMATION);
          return false;
        }

        if (!action || !userid || !moment_id) {
          toast.error(msgs.SOMETHING_WENT_WRONG);
          return false;
        }
        return true;
        default:
          break;
    }
  }

  fieldIsEmptyCheck = (field, name, action) => {
    if (!field) {
      this.setState(prevState => ({
        fieldIsEmpty: {
            ...prevState.fieldIsEmpty,
            [name]: true,
        }
      }));
    }

    if (action === 0) {
      this.setState(prevState => ({
        fieldIsEmpty: {
            ...prevState.fieldIsEmpty,
            description: false,
            file: false,
            listDataFromChild: false,
        }
      }));
    }
  }

  myCallback = (dataFromChild,index,type) => {
      const { dispatch } = this.props;
      const { user } = this.props;
      const newStateArray = this.state.listDataFromChild;
      var findindex = newStateArray.findIndex(x => x.post_id==dataFromChild.post_id);
      if (findindex === -1){
        newStateArray.push(dataFromChild);
        if(user)
        {
            const sendRaw = {
              // post_id: post_id,
              user_id: user.id,
              index: index,
              type: type,
            }
            dispatch(momentsActions.removeTweetMoment(sendRaw));
        }
      }
      else
      {
        toast.error(msgs.SPARK_IS_ALREADY_IN_MOMENT);
      }
      this.setState({ listDataFromChild: newStateArray });
  }

  myCallbackDelete = (dataArr) => {
      var array = this.state.listDataFromChild.filter(function(item) {
        return item.post_id !== dataArr.post_id
      });
      this.setState({
        listDataFromChild: array
      });
  }

  coverLoadErrorHandler = () => {
    this.setState({ file: '', isCoverLoaded: false });
  }

  render() {
    const {
      categoryData,
      momentsDetail,
      momentLoading,
      loading,
    } = this.props;
    const {
      listDataFromChild,
      momentData, 
      isCoverLoaded,
      fieldIsEmpty,
      file,
      PreviewUrls,
    } = this.state;
    // const newMomentData = momentData.replace(/\\/, '');

    return(
      <div>
        <div className="nav2">
          <div>
            {momentsDetail.isPublished == 0 ? (
              <div className="container">
                <button 
                  className="button"
                  style={{"position": "relative"}}
                  title="Publish"
                  onClick={() => this.createMomentProcess(1,momentsDetail.moment_id)}
                  disabled={loading}
                  >
                  Publish { loading && (<i className="fas fa-spin fa-spinner" />) }
                </button>
                <button
                  className="button-left-gap  button"
                  style={{"position": "relative"}}
                  title="Finish Later"
                  onClick={() => this.createMomentProcess(0,momentsDetail.moment_id)}
                  disabled={loading}
                  >
                  Finish Later { loading && (<i className="fas fa-spin fa-spinner" />) }
                </button>
              </div>
              ) : (
              <div className="container">
                <button
                  className="button"
                  style={{"position": "relative"}}
                  title="Publish"
                  onClick={() => this.createMomentProcess(1,momentsDetail.moment_id)}
                  disabled={loading}
                  >
                  Edit { loading && (<i className="fas fa-spin fa-spinner" />) }
                </button>
              </div>
              )}
          </div>
        </div>
        {
          <div className="create-moment">
            <div className="content-part">
              <div className="container">
                {
                  momentLoading ? <LoadingIcon /> : (
                    <div className="row">
                      <div className="col-sm-12 col-md-8 mx-auto">
                        <div className="edit-pro-inn">
                          <form>
                            <div className="form-group">
                              <select 
                                onChange={this.handleChange} 
                                name="category" 
                                value={momentData.category} 
                                className={classNames('form-control', {
                                  'error': fieldIsEmpty.category && fieldIsEmpty.category !== null,
                                })}
                                id="exampleInputEmail1" 
                                aria-describedby="emailHelp"
                              >
                                <option value="">
                                  Selecting Category *
                                </option>
                                {categoryData != undefined ?
                                  categoryData.map((category, index) => (
                                      <option 
                                        key={index} 
                                        value={category.category_id}
                                      >
                                        {category.title}
                                      </option>
                                  )) : ('')
                                }
                              </select>
                            </div>
                            <div className="form-group">
                              <input 
                                onChange={this.handleChange} 
                                name="title" 
                                type="text" 
                                className={classNames('form-control', {
                                  'error': fieldIsEmpty.title && fieldIsEmpty.title !== null,
                                })}
                                id="exampleInputEmail1" 
                                aria-describedby="emailHelp" 
                                placeholder="Title your Moment *"
                                value={momentData.title} 
                              />
                            </div>
                            <div className="form-group">
                              <textarea 
                                onChange={this.handleChange} 
                                name="description" 
                                className={classNames('form-control', {
                                  'error': fieldIsEmpty.description && fieldIsEmpty.description !== null,
                                })}
                                id="exampleFormControlTextarea1" 
                                rows={3} 
                                placeholder="Add a description *" 
                                value={momentData.description}>
                              </textarea>
                            </div>
                            <div className="use-photo-moment">
                              <p className={`${
                              fieldIsEmpty.file === false 
                              || fieldIsEmpty.file === null 
                              || file
                                  ? '' 
                                  : 'error'
                              }`}
                              >
                                Upload any image *
                              </p>
                              <div className="set-cover">
                                {/* <input 
                                  type="file" 
                                  id="file-add" 
                                  accept="image/*" 
                                /> */}
                                <input 
                                  ref={fileInput => this.fileInput = fileInput} 
                                  accept={acceptableImageFormats.join(', ')}
                                  name="file" 
                                  id="banner_image" 
                                  className="inputfile inputfile-3 hidefile" 
                                  data-multiple-caption="files selected" 
                                  type="file"
                                  onChange={this.handleImageChange} 
                                  style={{display: "none"}} 
                                />
                                <label onClick={() => this.handleInputTrigger()}>
                                  {file || PreviewUrls ? 'Change Cover' : 'Set Cover'}
                                </label>
                                  <div>
                                    <p>&nbsp;</p>
                                    {PreviewUrls 
                                      ?(PreviewUrls.map((raw,index) =>
                                          <img 
                                            src={raw} 
                                            key={index} 
                                            style={{maxWidth: '100%'}} 
                                          />
                                      ))
                                       : (<img 
                                            src={file} 
                                            key={momentsDetail.fileName} 
                                            style={{maxWidth: '100%'}} 
                                            onError={this.coverLoadErrorHandler}
                                            ref={this.imgRef}
                                          />)
                                    }
                                  </div>
                              </div>
                            </div>
                          </form>

                          <div id="sparks1 selectedData" className="selectedSpark">
                            {listDataFromChild && listDataFromChild.length > 0 ?
                              (listDataFromChild.map((like, index) => (
                                  <ManageSelectedSpark key={index} rawData={like} index = {index} callbackFromParentDelete={this.myCallbackDelete}/>
                              ))) : ('')
                            }
                          </div>
                          {/*<div className="moment-view-inn">
                            <AttachMomentSpark momentSparks={{ items: momentsDetail.posts }} type="moment" />
                          </div>*/}
                          <div className="add-spark">
                            <h3 className={`${
                              fieldIsEmpty.listDataFromChild === false 
                              || fieldIsEmpty.listDataFromChild === null 
                              || listDataFromChild.length !== 0
                                  ? '' 
                                  : 'error'
                              }`}
                            >
                              Add Sparks to your Instant *
                            </h3>
                            <div className="add-spark-inn">
                              <div className="tab2">
                                <button className="tablinks active" onClick={(e) => this.openCity(e, 'sparks1')}>Sparks I have liked</button>
                                <button className="tablinks" onClick={(e) => this.openCity(e, 'sparks2')}>Sparks by account</button>
                                <button className="tablinks" onClick={(e) => this.openCity(e, 'sparks3')}>Sparks search </button>
                              </div>
                              <SparkLike callbackFromMainParent={this.myCallback}/>
                              <SparkAccount callbackFromMainParent={this.myCallback}/>
                              <SparkTweet callbackFromMainParent={this.myCallback}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps(state)
{
  //console.log(state);
    return {
        categoryData: state.momentscategory.categoryData != undefined ? state.momentscategory.categoryData : [],
        //tweetMomentData: state.momentstweetpost.tweetMomentData != undefined ? state.momentstweetpost.tweetMomentData : [],
        user: state.authentication.user,
        alert: state.alert,
        momentsDetail: state.momentsingle.momentsDetail != undefined ? state.momentsingle.momentsDetail : [],
        momentLoading: state.momentsingle.loading,
        loading: state.momentsCreate.loading,
      };
}
export default connect(mapStateToProps)(EditMoment);

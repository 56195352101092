import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';

import { listsActions } from '../../../actions';
import BlockedContent from '../common/BlockedContent';

class UserListWidget extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      visible : 10
    };
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 10};
    });
  }

  deleteList = (list_id) => {
      this.setState({ submitted: true });
      const { dispatch,loggeduser } = this.props;

      if(loggeduser.id && list_id && dispatch)
      {
        const raw = {
          user_id: loggeduser.id,
          list_id: list_id
        };
        dispatch(listsActions.deleteList(raw));
      }
  }


  render(){

    let {lists,loggeduser,username, isHidden, isHiddenByYou } = this.props;
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    if((isHidden || isHiddenByYou ) && username) {
      return (
        <BlockedContent
          username={username}
          isHidden={isHidden} 
          isHiddenByYou={isHiddenByYou} 
        />
      );
    }

    if(lists.length === 0) {
      return (
        <div className="profile">
          <h3>N/A</h3>
        </div>
      );
    }

    return (
      <>
        <div className="list-users-section feed-container">
          <div className="noti-top">
            <ul className="list">
                <li className="link"><Link to={"/"+username+"/lists/"}>Subscribed to</Link></li>
                <li className="link"><Link to={"/"+username+"/lists/member"}>Member of </Link></li>
            </ul>
          </div>
        </div>
          <div className="twiter-left1">
            {lists.length > 0 ? (
              lists.slice(0,this.state.visible).filter((list) => list.isPrivate != 1 || loggeduser.username === list.username).map((list,index) =>
              <div key={index} className="twiter-left1 pro-moment profile-mid">
                <div className="row">
                  <div className="col-sm-12 padd-left">
                    <div className="profile-moment-right">
                      <Link to={'/'+loggeduser.username+'/list-detail/'+list.list_id} className="word-wrap" >{list.title}</Link>
                      <h4>{list.isPrivate == 1 ? 'Private' : 'Published'} {new Date(list.created).toLocaleDateString('en-US', DATE_OPTIONS)} <span>({list.totalMembers} Members)</span> </h4>
                      <p id="created-user">Created By
                        <span>
                          <Link to={"/"+list.username} title={list.at_username}> {list.at_username}</Link>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              )
            ) : (<p className="text-center">N/A</p>)

          }
          {lists != undefined && lists.length > 0 ?
          (this.state.visible < lists.length ? (
              <button onClick={this.loadMore} type="button" className="load-more btn btn-danger">Load more</button>
            ) : ('')) : ('')
          }

          </div>
      </>
    )
  }
}


function mapStateToProps(state,ownProps){
  return {
    loggeduser: state.authentication.user,
    username: ownProps.username,
    lists: ownProps.lists ? ownProps.lists : [] ,
    isHidden: state.profileinfo.data != undefined && state.profileinfo.data.blockByUser == 1 ? true : false,
    isHiddenByYou: state.profileinfo.data != undefined && state.profileinfo.data.blockByYou,
  };
}
export default connect(mapStateToProps)(UserListWidget);

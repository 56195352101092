import React, { useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import s from './chatModal.module.scss';
import debounce from 'lodash.debounce';
export default function CreateSingleChat(props) {
  const [searchValue, setSearchValue] = useState('');
  const [isUserRequested, setUserRequest] = useState(false);

  const { onClickOutside, userList, userId, searchUserChat, clearSearchResults, chatOpen, searchMsg } = props;
  const input = useRef(null);
  const debouncedSetSearchValue = debounce(value => {
    setSearchValue(value);
  }, 1000);

  const onUserSearch = (event) => {
    const { value } = event.target;

    debouncedSetSearchValue(value);
  };

  useEffect(() => {
      if (searchValue) {
        searchUserChat({ user_id: userId, search: searchValue });
      } else {
        clearSearchResults();
      }
    },[searchValue]
  );

  const onModalClose = () => {
    clearSearchResults();
    onClickOutside();
  }

  const onUserClick = (userId) => {
    setUserRequest(true);
    chatOpen(userId);
  }

  const clearSearchInput = () => {
    input.current.value = '';
    setSearchValue("");
    clearSearchResults();
  };
  const isSearchVal = searchValue !== '';

  return (
    <div className={s.overlay} onClick={onClickOutside}>
      <div className={s.modalContainer}>
        <div className={s.modalDialog} onClick={(event) => event.stopPropagation()}>
          <div className={s.modalContent}>
            <header className={s.modalChatHeader}>
              <i className="fas fa-arrow-left" onClick={onModalClose}></i>
              <span className={s.modalTitle}>
                New chat
              </span>
            </header>

            <div className={cs(s.singleChatBody, {[`${s.changePadding}`]: userList.length > 0 && searchValue })}>
              <label htmlFor="searchUserInput">
                Find Users
              </label>
              <div className={s.inputContainer}>
                <i className={cs("fas fa-search", {[`${s.searchIcon}`]: true })} />
                <input
                  ref={input}
                  // value={searchValue}
                  autoFocus={true}
                  onChange={onUserSearch}
                  className={s.searchUserInput}
                  id="searchUserInput"
                  maxLength="30"
                />
                <div onClick={clearSearchInput} className={`${s.closeButtonContainer}  ${isSearchVal && 'visible'}`}>
                  <div className={s.closeButtonStickOne}></div>
                  <div className={s.closeButtonStickTwo}></div>
                </div>
              </div>

              {userList.length > 0 && <div className={s.usersBlockTitle}>Users</div>}
              {searchMsg === 'data not found' && (
                <div className={s.usersBlockErrorTitle}>No User found</div>
              )}

              <div className={cs({[`${s.userListContainer}`]: userList.length > 0 && searchValue })}>
                {searchValue && userList.length > 0 ? [userList.map((friend,i) =>
                    (friend.status == 1 ? (
                        <div className={s.userContainer} key={friend.id} onClick={!isUserRequested && onUserClick.bind(null, friend.id)}>
                          <div className={s.userImage}>
                            <img src={friend.profile_image} alt="avatar" />
                          </div>
                          <div className={`${s.userNameContainer} text-overflow-ellipsis`}>
                            <h3 className="text-overflow-ellipsis">{friend.name}</h3>
                            <span>{friend.at_username}</span>
                          </div>
                        </div>
                      ) : ('')
                    )
                  )]
                  : ('')
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CreateSingleChat.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  searchUserChat: PropTypes.func.isRequired,
  userList: PropTypes.array,
  clearSearchResults: PropTypes.func,
};

CreateSingleChat.defaultProps = {
  userList: [],
  clearSearchResults: () => console.log("clearSearchResults"),
}

import { userConstants } from '../constants';

const initState = {
  lastTimeVerifyEmailSent: 0,
  userFireBaseAuth: null,
};

export function usersettings(state = initState, action) {
  switch (action.type) {
    case userConstants.GET_USER_DATA_SETTINGS_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_USER_DATA_SETTINGS_SUCCESS:
      return {
        loading: false,
        data: action.user,
      };
    case userConstants.GET_USER_DATA_SETTINGS_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case userConstants.SET_PRIVACY_REQUEST:
      return {
       ...state,
      };
    case userConstants.SET_PRIVACY_SUCCESS:
      state.data.privacyData = action.user.privacyData;
      return {
        ...state,
      };
    case userConstants.SET_PRIVACY_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case userConstants.USER_PRESSED_VERIFY_EMAIL_BUTTON:
      return {
        ...state,
        lastTimeVerifyEmailSent: Date.now()
      };
    case userConstants.USER_SAVE_FIREBASE_AUTH:
      return {
        ...state,
        userFireBaseAuth: action.user
      };

    default:
      return state
  }
}

import React, {Component} from 'react';
import { connect } from 'react-redux';
import BottomWidget from '../common/BottomWidget';
import TrendsForYou from '../sparks/TrendsForYou';
import ReplyPopUpText from '../common/ReplyPopUpText';
import ReSparkAction from '../common/ReSparkAction';
import LoadingIcon from '../common/LoadingIcon';
import { userActions,alertActions } from '../../../actions';
import PureList from '../sparks/PureList';
import UserMainWidget from '../userElements/UserMainWidget';
import ImageWidget from '../common/ImageWidget';

class SearchSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeTab: 'Top',
    }

  }

  componentDidUpdate(prevProps) {
    if(this.props.searchprocess.getsearchData && (prevProps.searchprocess.getsearchData !== this.props.searchprocess.getsearchData)) {
      const activeTab = [...Object.entries(this.props.searchprocess.getsearchData)]
        .find(result => result[1].length > 0);
      if(activeTab) {
        this.setState({ activeTab: activeTab[0].slice(0, -4) });
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(userActions.eraseSearchResults());
  }

  openCity = (e) => {
    const name = e.target.innerText.toLowerCase();
    this.setState(prevState => ({ ...prevState, activeTab: name}))
  }

  handleChange = (event) => {
      const { name, value } = event.target;
      const { user } = this.state;
      this.setState({
          user: {
              ...user,
              [name]: value
          }
      });
  }

  handleSubmit = (event) => {
      event.preventDefault();
      this.setState({ submitted: true });
      const { user } = this.state;
      const { dispatch } = this.props;
      if(user.username && user.phone_number)
      {
        dispatch(userActions.accountSettings(user));
      }
  }

  deactivateAccount = (event) => {
      event.preventDefault();
      this.setState({ submitted: true });
      const { user } = this.state;
      const { dispatch } = this.props;
      if(user.user_id)
      {
        dispatch(userActions.deactivateAccount(user));
      }
  }

  renderSearchResults = (category = [], categoryName) => {
    const { slug } = this.props;

    let searchResults = category.filter(post => {
      const values = Object.values(post);
      for (const value of values) {
        if(typeof value === 'string' && value.includes(slug)) {
          return post;
        }
      }
    });

    if(searchResults.length > 0 ) {

      return searchResults.map((post, index) =>
        <PureList key={post.post_id} rawData={post} index={index}/>
      )
    } else {
      return this.noMatchError(categoryName);
    }
  }

  noMatchError = (categoryName) => {
    return `Unfortunately, no matches in ${categoryName} Sparks in your query have been found`;
}

  render()
  {
    const { searchprocess,slug,user,alert } = this.props;
    const { activeTab } = this.state;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;
    const topData = (searchprocess.getsearchData && Array.isArray(searchprocess.getsearchData.topData)) 
      ? searchprocess.getsearchData.topData 
      : [];
    const latestData = (searchprocess.getsearchData && Array.isArray(searchprocess.getsearchData.latestData)) 
      ? searchprocess.getsearchData.latestData 
      : [];


    return (
     
        <div>
          <div className="nav2">
            <div className="search-top">
              <h4 className="container">{slug}</h4>
            </div>
            <div className="container">
              <div className="nav2-left">
                <ul>
                  <li>
                    <button 
                      className={`tablinks ${activeTab === "top" ? "active" : ""}`} 
                      onClick={(e) => this.openCity(e)}
                    >
                      Top
                    </button>
                  </li>
                  <li>
                    <button 
                      className={`tablinks ${activeTab === "latest" ? "active" : ""}`} 
                      onClick={(e) => this.openCity(e)}
                    >
                      Latest
                    </button>
                  </li>
                  <li>
                       
                    <button 
                      className={`tablinks ${activeTab === "people" ? "active" : ""}`} 
                      onClick={(e) => this.openCity(e)}
                    >
                      People
                    </button>
                  </li>
                  <li>
                    <button 
                      className={`tablinks ${activeTab === "photos" ? "active" : ""}`} 
                      onClick={(e) => this.openCity(e)}
                    >
                      Photos
                    </button>
                  </li>
                  <li>
                    <button 
                      className={`tablinks ${activeTab === "videos" ? "active" : ""}`} 
                      onClick={(e) => this.openCity(e)}
                    >
                      Videos
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="content-part search-pages">
            <div className="container">
              {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
              <div className="row">
                <div className="col-sm-3 left-sidebar">
                  <TrendsForYou/>
                </div>
                <div className="col-sm-6 search_section feed-container">
                  {activeTab === "top" && (
                    <div id="top" className="tabcontent">
                    {searchprocess.loading
                      ?  <LoadingIcon />
                      : this.renderSearchResults(topData, 'top')
                    }
                  </div>
                  )}

                  {activeTab === "latest" && (
                    <div id="latest" className="tabcontent">
                    {searchprocess.loading
                      ?  <LoadingIcon />
                      : this.renderSearchResults(latestData, 'latest')
                    }
                  </div>
                  )}


                  {activeTab === "people" && (
                    <div id="people" className="tabcontent">
                      {
                        searchprocess.loading ? (
                          <LoadingIcon />
                        ) : (
                          searchprocess.getsearchData != undefined && searchprocess.getsearchData.peopleData.length > 0 ? (
                            <UserMainWidget 
                              userslist={searchprocess.getsearchData.peopleData} 
                              username={searchprocess.getsearchData.peopleData.username} 
                              type="search-section" 
                              slug={slug} 
                            />
                          ) : ('Unfortunately, no matches among the users for your query have been found')
                        )
                      }
                    </div>
                  )}


                  {activeTab === "photos" && (
                    <div id="images" className="tabcontent">
                      {
                        searchprocess.loading ? (
                          <LoadingIcon />
                        ) : (
                          searchprocess.getsearchData != undefined && searchprocess.getsearchData.photosData.length > 0 ? (
                            <ImageWidget dataImage={searchprocess.getsearchData.photosData} />
                          ) : ('Unfortunately, no matches in Sparks with Images for your query have been found')
                        )
                      }
                    </div>
                  )}


                  {activeTab === "videos" && (
                    <div id="videos" className="tabcontent">
                      {
                        searchprocess.loading ? (
                          <LoadingIcon />
                        ) : (
                          searchprocess.getsearchData != undefined && searchprocess.getsearchData.videosData.length > 0 ? (
                            searchprocess.getsearchData.videosData.map((video, index) => (
                              <PureList key={video.post_id} rawData={video} index={index}/>
                            ))
                          ) : ('Unfortunately, no matches in Sparks with Videos for your query have been found')
                        )
                      }
                    </div>
                  )}
                  </div>
                <div className="col-sm-3 right-sidebar">
                  <BottomWidget/>
                </div>

                <ReplyPopUpText />
                <ReSparkAction />
              </div>
            </div>
          </div>
        </div>
    );
  }
};

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    searchprocess : state.searchprocess != undefined ? state.searchprocess : [],
    slug: ownProps.slug,
    alert: state.alert,
  };
}
export default connect(mapStateToProps)(SearchSection);

import React from 'react';
import Header from '../../../containers/frontend/common/Header';
import { Helmet } from "react-helmet";
import BottomWidget from '../../../containers/frontend/common/BottomWidget';

const ContactInformation = () => (
    <main className="ContactInformation">
      <Helmet>
        <title>Contact Information</title>
      </Helmet>
      <Header/>
      <div className="content-part">
        <div className="container">
          <div className="contacts-block">
            <h1 className="contacts-block_title">Contact Information</h1>
            <p className="contacts-block_text">
              If you have any questions or want to report inapproppriate activity,
              please feel free to contact us at the following email, telephone number or mailing address.
            </p>
            <p className="contacts-block_email">
              <strong>Email:</strong>
              <a href="mailto:info@2cents.com" type="email">info@2cents.com</a>
            </p>
            <p className="contacts-block_phone">
              <strong>Telephone Number:</strong>
              <a href="tel:214-379-7495" type="tel">214-379-7495</a>
            </p>
            <p className="contacts-block_address">
              <strong>Mailing Address:</strong>
              <a href="https://2cents.com/">2cents.com</a> 
              3723 Greenville Avenue
              <span>Dallas, Texas 75206</span>
            </p>
          </div>
          <BottomWidget />
        </div>
      </div>
    </main>
  )

export default ContactInformation;

import msgs from '../../../../messages/en/main';
import React, { useEffect, useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { cardBrandToPfClass } from "../../../../helpers/stripe-classes";

const cardNumberInput = ({ stripe, submitted, setStripe, hideTitile, hideIcon, changeColWidth }) => {
  const [error, setError] = useState(null);

    useEffect(() => {
        if (submitted && stripe) {

            stripe.createToken().then(async(payload) => {
                if (payload.error) {
                    toast.error(payload.error.message);
                    setError(payload.error.message);
                    return;
                }
                await setStripe(payload.token.id, true);
            });
        }
    }, [submitted]);

    const createOptions = () => {
        return {
            style: {
                base: {
                    textAlign: 'left',
                },
            },
        };

    };

    const cardNumberChange = ({ brand } ) => {
        const brandIconElement = document.getElementById('brand-icon');
        let pfClass = 'pf-credit-card';
        if(brandIconElement != null) {
            if (brand in cardBrandToPfClass) {
                pfClass = cardBrandToPfClass[brand];
            }
            for (let i = brandIconElement.classList.length - 1; i >= 0; i--) {
                brandIconElement.classList.remove(brandIconElement.classList[i]);
            }
            brandIconElement.classList.add('pf');
            brandIconElement.classList.add(pfClass);
        }
    }


    return (
        <div className={`info ${error ? "has-error": ""}`}>
            {hideTitile ? null : <h5 className="credit-card-info">credit card information</h5>}
            <div className="rewrite-card-number-class">
                {hideIcon ? null : <span className="brand my-credit-icon"><i className="pf pf-credit-card" id="brand-icon"></i></span>}
                <CardNumberElement
                    onChange={cardNumberChange}
                    placeholder="* Card Number"
                    className={changeColWidth ? "col-md-12 my-flex-grow-5": "col-md-12 my-flex-grow-3 fafa"}
                    {...createOptions()}
                />
                <CardExpiryElement
                    placeholder="* MM/YY"
                    className={changeColWidth ? "col-md-12 my-flex-grow-2" : "col-md-12 my-flex-grow-1"}
                    {...createOptions()}
                />
                <CardCVCElement
                    placeholder="* CVV"
                    lassName="col-md-12"
                    {...createOptions()}
                />
            </div>
            <div className="help-block">
              {error}
            </div>
        </div>
    )
}

export default injectStripe(cardNumberInput);


import React  from 'react';
import Header from '../../containers/frontend/common/Header';
import Chat from '../../containers/frontend/chatNotModal';

function ChatModule() {
  return (
    <div id="ChatModule" className="ChatModule">
      <Header/>
      <Chat/>
    </div>
  );
}

export default ChatModule;
import React, {Component} from 'react';
import { connect } from 'react-redux';
import PureList from './PureList';
import ReplyPopUpText from '../common/ReplyPopUpText';
import ReSparkAction from '../common/ReSparkAction';
import LoadingIcon from '../common/LoadingIcon';
import ReportSparkPopup from "../common/ReportSparkPopup";
import BlockedContent from '../common/BlockedContent';

class SparkList extends Component {
  constructor(props)
  {
    super(props);

    const { sparks } = this.props;

    this.state = {
      sparks : sparks,
      visible : 10
    };
      this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 10};
    });
  }

  shouldComponentUpdate(prevProps,nextProps){
    return true;
  }

  render()
  {
    const { sparks, user, noDataFoundText, isHidden, username, profileinfo, isHiddenByYou } = this.props;
    if (user && !!sparks.items && !!user.showObjectionableContent) {
      sparks.items = sparks.items.filter((spark) => {
        return !spark.isObjectionable;
      })
    }

    if(sparks.loading) {
      return <LoadingIcon />;
    }

    if((isHidden || isHiddenByYou ) && username) {
      return (
        <BlockedContent
          username={username}
          isHidden={isHidden} 
          isHiddenByYou={isHiddenByYou} 
        />
      );
    }

    return (
      <div className="home-feed-main">
        {sparks.items != undefined && sparks.items.length && !sparks.loading > 0 ? (
          sparks.items.slice(0,this.state.visible).map((raw,index) =>
            <PureList key={raw.post_id} rawData={raw} index={index}/>
          )
        ) : (
          profileinfo && profileinfo.user_id != user.id && (<h5 className="noDataFound">
            {noDataFoundText || (!sparks.loading && "User has no sparks yet.")}
            </h5>)
        )
        }
        {sparks.items != undefined ?
          (this.state.visible < sparks.items.length ? (
            <button onClick={this.loadMore} type="button" className="load-more btn btn-danger">Load more</button>
          ) : ('')) : ('')
        }
        <ReplyPopUpText />
        <ReportSparkPopup {...this.props}/>
        <ReSparkAction />
      </div>
    )
  }
}

function mapStateToProps(state,ownProps){
  return {
    sparks: state.sparks,
    reportReasons: state.reportreasons.items,
    user: state.authentication.user,
    isHidden: state.profileinfo.data != undefined && state.profileinfo.data.blockByUser == 1 ? true : false,
    isHiddenByYou: state.profileinfo.data != undefined && state.profileinfo.data.blockByYou,
    profileinfo: state.profileinfo.data,
  };
}
export default connect(mapStateToProps)(SparkList);

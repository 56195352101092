import React, {Component} from 'react';
import { connect } from 'react-redux';
import UserSideBarSection from '../common/UserSideBarSection';
import { userActions,alertActions } from '../../../actions';
import LoadingIcon from '../common/LoadingIcon';

class NotificationSettingSection extends Component {
  constructor(props){
    super(props);
  }

  handleEmailChange = (event) => {
      const { name, value } = event.target;
      const { user } = this.props;
      this.setState({ submitted: true });
      const { dispatch } = this.props;
      if(user.id && value && name)
      {
        let data = {
            user_id: user.id,
            [name]: value,
            type: 'email',
        };
        dispatch(userActions.setNotifySetting(data));
      }
  }

  handleNotifyChange = (event) => {
      const { name, value } = event.target;
      const { user } = this.props;
      this.setState({ submitted: true });
      const { dispatch } = this.props;
      if(user.id && value && name)
      {
        let data = {
            user_id: user.id,
            [name]: value,
            type: 'notify',
        };
        dispatch(userActions.setNotifySetting(data));
      }

  }

  shouldComponentUpdate(prevProps,nextProps){
    return true;
  }

  render(){

    const { notificationData,emailData,alert } = this.props;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;

    return (
      <div id="NotificationSettingSection" className="content-part">
        <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          <div className="row">
            <UserSideBarSection/>
            <div className="col-sm-6 feed-container">
              <div className="twiter-left1 access-mid sett-block">
                <h3>Notifications </h3>
                <p>Expand your experience, get closer, and stay current.</p>
                {notificationData != undefined && emailData != undefined ? (
                  <div>
                    <h6 className="h6">Push notifications from people:</h6>
                    <div className="add-your-phone">
                      <form>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="noti-right">
                              <div className="noti-right1">
                                <span>ReSpark</span>
                                <label className="switch">

                                  <input
                                  type="checkbox"
                                  value={notificationData != undefined && notificationData.retweet == 1 ? '0' : '1'}
                                  checked = {notificationData != undefined && notificationData.retweet == 1 ? true : false}
                                  name="retweet"
                                  id="retweet"
                                  onClick ={this.handleNotifyChange} />

                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>Like</span>
                                <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={notificationData != undefined && notificationData.post_like == 1 ? '0' : '1'}
                                  checked = {notificationData != undefined && notificationData.post_like == 1 ? true : false}
                                  name="post_like"
                                  id="post_like"
                                  onClick ={this.handleNotifyChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>Comment</span>
                                  <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={notificationData != undefined && notificationData.post_comment == 1 ? '0' : '1'}
                                  checked = {notificationData != undefined && notificationData.post_comment == 1 ? true : false}
                                  name="post_comment"
                                  id="post_comment"
                                  onClick ={this.handleNotifyChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>Message</span>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    value={notificationData != undefined && notificationData.send_message == 1 ? '0' : '1'}
                                    checked = {notificationData != undefined && notificationData.send_message == 1 ? true : false}
                                    name="send_message"
                                    id="send_message"
                                    onClick ={this.handleNotifyChange} 
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>New Follower</span>
                                <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={notificationData != undefined && notificationData.send_request == 1 ? '0' : '1'}
                                  checked = {notificationData != undefined && notificationData.send_request == 1 ? true : false}
                                  name="send_request"
                                  id="send_request"
                                  onClick ={this.handleNotifyChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>Accept Follower Request</span>
                                <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={notificationData != undefined && notificationData.accept_request == 1 ? '0' : '1'}
                                  checked = {notificationData != undefined && notificationData.accept_request == 1 ? true : false}
                                  name="accept_request"
                                  id="accept_request"
                                  onClick ={this.handleNotifyChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>Someone Add you in list</span>
                                <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={notificationData != undefined && notificationData.add_to_list == 1 ? '0' : '1'}
                                  checked = {notificationData != undefined && notificationData.add_to_list == 1 ? true : false}
                                  name="add_to_list"
                                  id="add_to_list"
                                  onClick ={this.handleNotifyChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>Someone Added your post in Instant</span>
                                <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={notificationData != undefined && notificationData.tweet_in_moment == 1 ? '0' : '1'}
                                  checked = {notificationData != undefined && notificationData.tweet_in_moment == 1 ? true : false}
                                  name="tweet_in_moment"
                                  id="tweet_in_moment"
                                  onClick ={this.handleNotifyChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <h6 className="h6">Email notifications from people:</h6>
                    <div className="add-your-phone">
                      <form>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="noti-right">
                              <div className="noti-right1">
                                <span>ReSpark</span>
                                <label className="switch">

                                  <input
                                  type="checkbox"
                                  value={emailData != undefined && emailData.retweet == 1 ? '0' : '1'}
                                  checked = {emailData != undefined && emailData.retweet == 1 ? true : false}
                                  name="retweet"
                                  id="retweet"
                                  onClick ={this.handleEmailChange} />

                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>Like</span>
                                <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={emailData != undefined && emailData.post_like == 1 ? '0' : '1'}
                                  checked = {emailData != undefined && emailData.post_like == 1 ? true : false}
                                  name="post_like"
                                  id="post_like"
                                  onClick ={this.handleEmailChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>Comment</span>
                                  <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={emailData != undefined && emailData.post_comment == 1 ? '0' : '1'}
                                  checked = {emailData != undefined && emailData.post_comment == 1 ? true : false}
                                  name="post_comment"
                                  id="post_comment"
                                  onClick ={this.handleEmailChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>New Follower</span>
                                <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={emailData != undefined && emailData.send_request == 1 ? '0' : '1'}
                                  checked = {emailData != undefined && emailData.send_request == 1 ? true : false}
                                  name="send_request"
                                  id="send_request"
                                  onClick ={this.handleEmailChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>Accept Follower Request</span>
                                <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={emailData != undefined && emailData.accept_request == 1 ? '0' : '1'}
                                  checked = {emailData != undefined && emailData.accept_request == 1 ? true : false}
                                  name="accept_request"
                                  id="accept_request"
                                  onClick ={this.handleEmailChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>Someone Add you in list</span>
                                <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={emailData != undefined && emailData.add_to_list == 1 ? '0' : '1'}
                                  checked = {emailData != undefined && emailData.add_to_list == 1 ? true : false}
                                  name="add_to_list"
                                  id="add_to_list"
                                  onClick ={this.handleEmailChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="noti-right1">
                                <span>Someone Added your post in Instant</span>
                                <label className="switch">
                                  <input
                                  type="checkbox"
                                  value={emailData != undefined && emailData.tweet_in_moment == 1 ? '0' : '1'}
                                  checked = {emailData != undefined && emailData.tweet_in_moment == 1 ? true : false}
                                  name="tweet_in_moment"
                                  id="tweet_in_moment"
                                  onClick ={this.handleEmailChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  ) : (
                    <LoadingIcon />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};


function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        alert: state.alert,
        notificationData: state.notificationdata.notificationData != undefined ? state.notificationdata.notificationData : undefined,
        emailData: state.notificationdata.emailData != undefined ? state.notificationdata.emailData : undefined,
    };
}

export default connect(mapStateToProps)(NotificationSettingSection);

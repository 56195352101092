import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Header from '../../containers/frontend/common/Header';

class NotFoundView extends Component{
  constructor(props){
    super(props);
  }

  render() {
    return (
      <>
        {this.props.userData.data && <Header />}
        <main className="notfound">
          <div className="content-part">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="text-center">Sorry, that page doesn’t exist!</h1>
                  <h3>You can search sparks using the search box above or return to the homepage.</h3>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}


const mapStateToProps = ({ userData }) => ({ userData });

export default connect(mapStateToProps)(NotFoundView);

NotFoundView.propTypes = {
  userData: PropTypes.object,
};
import { reportReasonsConstants } from '../constants';

export function reportreasons(state = {}, action) {
  switch (action.type) {

    case reportReasonsConstants.GETALLREPORT_REASONS_REQUEST:
      return {
        loading: true,
        items: state.items,
      };
    case reportReasonsConstants.GETALLREPORT_REASONS_SUCCESS:
      return {
        items: action.index
      };
    case reportReasonsConstants.GETALLREPORT_REASONS_FAILURE:
      return {
        error: action.error
      };

    default:
    return state
  }
}

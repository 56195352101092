export * from './alert.constants';
export * from './user.constants';
export * from './spark.constants';
export * from './reportreasons.constants';
export * from './hashtags.constants';
export * from './suggestion.constants';
export * from './profileinfo.constants';
export * from './moments.constants';
export * from './lists.constants';
export * from './url.constants';
export * from './userData';
export * from './reduxChat';

// chats
export const ONE_MESSAGES_PORTION = 15;

export const SINGLE_CHAT_TYPE = 'single';
export const GROUP_CHAT_TYPE = 'group';
export const EDIT_GROUP_CHAT_TYPE = 'EDIT_GROUP_CHAT_TYPE';

export const CHAT_CONTENT_ACTIVE_OR_NEW = 'CHAT_CONTENT_ACTIVE_OR_NEW';
export const CHAT_CONTENT_CHATS_LISTS = 'CHAT_CONTENT_CHATS_LISTS';
export const CHAT_CONTENT_CHAT_MODULE = 'CHAT_CONTENT_CHAT_MODULE';
export const CHAT_CONTENT_SEARCH_MODULE = 'CHAT_CONTENT_SEARCH_MODULE';
export const CHAT_CONTENT_CREATE_GROUP_CHAT = 'CHAT_CONTENT_CREATE_GROUP_CHAT';
export const CHAT_CONTENT_DELETE = 'CHAT_CONTENT_DELETE';


export const SEND_MEDIA_REQUEST = 'SEND_MEDIA_REQUEST';
export const SEND_MEDIA_SUCCESS = 'SEND_MEDIA_SUCCESS';
export const SEND_MEDIA_FAIL = 'SEND_MEDIA_FAIL';


export const UPDATE_CHATS = 'UDATE_CHATS';
export const UPDATE_SINGLE_CHATS = 'UDATE_SINGLE_CHATS';
export const UPDATE_GROUP_CHATS = 'UPDATE_GROUP_CHATS';

export const UPDATE_ACTIVE_CHAT_MEMBERS = 'UPDATE_ACTIVE_CHAT_MEMBERS';

export const PUSH_ACTIVE_CHAT_MESSAGE = 'PUSH_ACTIVE_CHAT_MESSAGE'

export const UNREAD_MESSAGES_AMOUNT_REQUEST = 'UNREAD_MESSAGES_AMOUNT_REQUEST';
export const UNREAD_MESSAGES_AMOUNT_SUCCESS = 'UNREAD_MESSAGES_AMOUNT_SUCCESS';
export const UNREAD_MESSAGES_AMOUNT_FAIL = 'UNREAD_MESSAGES_AMOUNT_FAIL';

export const UPDATE_UNREAD_MESSAGES_AMOUNT = 'UPDATE_UNREAD_MESSAGES_AMOUNT';
// new chats
export const CHAT_LIST_REQUEST = 'CHAT_LIST_REQUEST';
export const CHAT_LIST_SUCCESS = 'CHAT_LIST_SUCCESS';
export const CHAT_LIST_FAIL = 'CHAT_LIST_FAIL';
export const CLEAR_CHAT_LIST = 'CLEAR_CHAT_LIST';

export const CHAT_MESSAGES_REQUEST = 'CHAT_MESSAGES_REQUEST';
export const CHAT_MESSAGES_SUCCESS = 'CHAT_MESSAGES_SUCCESS';
export const CHAT_MESSAGES_FAIL = 'CHAT_MESSAGES_FAIL';

export const CREATE_SINGLE_CHAT_REQUEST = 'CREATE_SINGLE_CHAT_REQUEST';
export const CREATE_SINGLE_CHAT_SUCCESS = 'CREATE_SINGLE_CHAT_SUCCESS';
export const CREATE_SINGLE_CHAT_FAIL = 'CREATE_SINGLE_CHAT_FAIL';

export const CREATE_GROUP_CHAT_REQUEST = 'CREATE_GROUP_CHAT_REQUEST';
export const CREATE_GROUP_CHAT_SUCCESS = 'CREATE_GROUP_CHAT_SUCCESS';
export const CREATE_GROUP_CHAT_FAIL = 'CREATE_GROUP_CHAT_FAIL';

export const DELETE_CHAT_REQUEST = 'DELETE_CHAT_REQUEST';
export const DELETE_CHAT_SUCCESS = 'DELETE_CHAT_SUCCESS';
export const DELETE_CHAT_FAIL = 'DELETE_CHAT_FAIL';

export const EDIT_CHAT_REQUEST = 'EDIT_CHAT_REQUEST';
export const EDIT_CHAT_SUCCESS = 'EDIT_CHAT_SUCCESS';
export const EDIT_CHAT_FAIL = 'EDIT_CHAT_FAIL';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL';

export const MARK_MESSAGES_AS_READ_REQUEST = 'MARK_MESSAGES_AS_READ_REQUEST';
export const MARK_MESSAGES_AS_READ_SUCCESS = 'MARK_MESSAGES_AS_READ_SUCCESS';
export const MARK_MESSAGES_AS_READ_FAIL = 'MARK_MESSAGES_AS_READ_FAIL';

// single chats
export const ALL_UNREAD_SINGLE_CHATS_REQUEST = 'ALL_UNREAD_SINGLE_CHATS_REQUEST';
export const ALL_UNREAD_SINGLE_CHATS_SUCCESS = 'ALL_UNREAD_SINGLE_CHATS_SUCCESS';
export const ALL_UNREAD_SINGLE_CHATS_FAILURE = 'ALL_UNREAD_SINGLE_CHATS_FAILURE';

export const ADDED_UNREAD_SINGLE_CHAT = 'ADDED_UNREAD_SINGLE_CHAT';
export const REMOVED_UNREAD_SINGLE_CHAT = 'REMOVED_UNREAD_SINGLE_CHAT';
export const ADDED_UNREAD_SINGLE_CHAT_MESSAGE = 'ADDED_UNREAD_SINGLE_CHAT_MESSAGE';
export const READ_UNREAD_SINGLE_CHAT_MESSAGE = 'READ_UNREAD_SINGLE_CHAT_MESSAGE';

// group chats
export const ALL_UNREAD_GROUP_CHATS_REQUEST = 'ALL_UNREAD_GROUP_CHATS_REQUEST';
export const ALL_UNREAD_GROUP_CHATS_SUCCESS = 'ALL_UNREAD_GROUP_CHATS_SUCCESS';
export const ALL_UNREAD_GROUP_CHATS_FAILURE = 'ALL_UNREAD_GROUP_CHATS_FAILURE';

export const ADDED_UNREAD_GROUP_CHAT = 'ADDED_UNREAD_GROUP_CHAT';
export const REMOVED_UNREAD_GROUP_CHAT = 'REMOVED_UNREAD_GROUP_CHAT';
export const ADDED_UNREAD_GROUP_CHAT_MESSAGE = 'ADDED_UNREAD_GROUP_CHAT_MESSAGE';
export const CHANGE_UNREAD_GROUP_CHAT_DATA = 'CHANGE_UNREAD_GROUP_CHAT_DATA';

export const INVITE_USER_TO_CHAT_REQUEST = 'INVITE_USER_TO_CHAT_REQUEST';
export const INVITE_USER_TO_CHAT_SUCCESS = 'INVITE_USER_TO_CHAT_SUCCESS';
export const INVITE_USER_TO_CHAT_FAILURE = 'INVITE_USER_TO_CHAT_FAILURE';

export const REFERRAL_CONGRATS_VISABILITY = 'REFERRAL_CONGRATS_VISABILITY';
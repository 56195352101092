import { suggestionConstants } from '../constants';

export function suggestion(state = {}, action) {
  switch (action.type) {
    case suggestionConstants.SUGGESTION_REQUEST:
      if(state.items == undefined){
        return {
            loading: true
        };
      }
      else {
        return state
      }
    case suggestionConstants.SUGGESTION_SUCCESS:
      return {
        items: action.suggestion,
        loading: false
      };
    case suggestionConstants.SUGGESTION_FAILURE:
      return {
        error: action.error,
        loading: false
      };
    case suggestionConstants.FOLLOWUNFOLLOW_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.user_id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case suggestionConstants.FOLLOWUNFOLLOW_SUCCESS:
        return {
          items: state.items.filter(user => user.user_id !== action.id)
      };
    case suggestionConstants.FOLLOWUNFOLLOW_FAILURE:
        return {
          ...state,
          items: state.items.map(user => {
            if (user.user_id === action.id) {
              // make copy of user without 'deleting:true' property
              const { deleting, ...userCopy } = user;
              // return copy of user with 'deleteError:[error]' property
              return { ...userCopy, deleteError: action.error };
            }

            return user;
          })
      };
     case suggestionConstants.FOLLOWUNFOLLOWOTHER_REQUEST:
        return {
          loading: true,
          status: false
        };
     case suggestionConstants.FOLLOWUNFOLLOWOTHER_SUCCESS:
        return{
          loading: false,
          status: true
        };
     case suggestionConstants.FOLLOWUNFOLLOWOTHER_FAILURE:
        return {};
     case suggestionConstants.FOLLOWRESPONSE_REQUEST:
         return {
           loading: true,
           status: false
         };
     case suggestionConstants.FOLLOWRESPONSE_SUCCESS:
         return{
           loading: false,
           status: true
         };
     case suggestionConstants.FOLLOWRESPONSE_FAILURE:
         return {};
    default:
      return state
  }
}

import {
  SET_ACTIVE_CHAT_SUCCESS,
  UPDATE_ACTIVE_CHAT_SUCCESS,
  CHAT_CONTENT_DELETE,
  CHAT_MESSAGES_REQUEST,
  CHAT_MESSAGES_SUCCESS,
  CHAT_MESSAGES_FAIL,
  SEND_MEDIA_REQUEST,
  SEND_MEDIA_SUCCESS,
  SEND_MEDIA_FAIL,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAIL,
  UPDATE_ACTIVE_CHAT_MESSAGES,
  PUSH_ACTIVE_CHAT_MESSAGE,
  MARK_MESSAGES_AS_READ_REQUEST,
  MARK_MESSAGES_AS_READ_SUCCESS,
  MARK_MESSAGES_AS_READ_FAIL,
  UPDATE_ACTIVE_CHAT_MEMBERS,
} from '../../constants';
import get from 'lodash.get';

const defaultState = {
  loading: false,
  newMessageLoading: false,
  data: undefined,
  error: false,
  errorMsg: '',
  activeChatLastUnreadMessageId: null,
};

export default function activeChatDataReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_ACTIVE_CHAT_SUCCESS:
      let updatedActiveChat = action.data;
      if (state.data && (state.data.id === action.data.id) && state.data.messages) {
        updatedActiveChat = { ...action.data, messages: state.data.messages }
      }
      return {...state, activeChatLastUnreadMessageId: null, data: updatedActiveChat};
    case UPDATE_ACTIVE_CHAT_SUCCESS:
      const { data: { id: currentId } = {} } = state;

      if (currentId && currentId === get(action.data, 'id')) {
  
        return {...state, data: {...state.data, ...action.data}};
      } else {

        return state;
      }
    case PUSH_ACTIVE_CHAT_MESSAGE:
      const chatMessages = state.data.messages || [];
      return {
        ...state,
        data: {
          ...state.data,
          lastMessageTimestamp: action.data.timestamp,
          messages: [...chatMessages, action.data]
        }
      }
    case CHAT_CONTENT_DELETE:
      return {...defaultState};
    case CHAT_MESSAGES_REQUEST:

      return {...state, loading: true};
    case UPDATE_ACTIVE_CHAT_MEMBERS: {
      const { chat } = action;
      return {
        ...state,
        data: {
          ...state.data,
          ...chat,
        }
      }
    }
    case CHAT_MESSAGES_SUCCESS:
      const messages = (Array.isArray(action.messages) && action.messages.reverse()) || [];
      const oldMessages = state.data.messages || [];
      const mergedMessages = [...messages, ...oldMessages]
      const countOfUnreadMessages = state.data.countOfUnreadMessages;
      const activeChatLastUnreadMessageId = mergedMessages[mergedMessages.length - countOfUnreadMessages] &&
        mergedMessages[mergedMessages.length - countOfUnreadMessages]._id;
      return {
        ...state,
        loading: false,
        activeChatLastUnreadMessageId,
        data: {...state.data, messages: mergedMessages}
      };
    case CHAT_MESSAGES_FAIL:

      return {...state, loading: false, error: true};

    case SEND_MESSAGE_REQUEST:

      return {...state, newMessageLoading: true};
    case SEND_MESSAGE_SUCCESS:
      const message = action.message;
      const prevMessages = state.data.messages || [];
      return {
        ...state,
        newMessageLoading: false,
        data: {
          ...state.data,
          lastMessageTimestamp: message.timestamp,
          messages: [...prevMessages, message],
        }
      };
    case SEND_MESSAGE_FAIL:

      return {...state, newMessageLoading: false, error: true};

    case MARK_MESSAGES_AS_READ_REQUEST:
      return { ...state };
    case MARK_MESSAGES_AS_READ_SUCCESS: {
      const { messages = [] } = state.data;
      const { messages: messagesIds } = action.messages;
      const updatedMessages = messages.map((message) => {
        if (messagesIds.includes(message._id)) {
          return { ...message, seen: true };
        }

        return message;
      });

      return {
        ...state,
        data: {
          ...state.data,
          messages: updatedMessages,
        }
      };
    }
    case MARK_MESSAGES_AS_READ_FAIL:

      return {...state, loading: false, error: true};
    default:
      return state;
  }
}

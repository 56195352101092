import React, {Component, createRef} from 'react';
import { connect } from 'react-redux';
import MomentGrid from './MomentGrid';
import AttachMomentSpark from '../sparks/attachMomentSpark';
import $ from "jquery";
import LoadingIcon from '../common/LoadingIcon';
import { momentsActions,sparksActions } from '../../../actions';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';
import MomentSparkAction from '../common/MomentSparkAction';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotFoundView from '../../../components/frontend/NotFoundView';

class MomentSingleView extends Component
{
  constructor(props){
    super(props);
    this.state = {
      isCoverLoaded: true,
    }

    this.imgRef = createRef();
  }

  componentWillUnmount() {
    const { dispatch, history } = this.props;
    
    if (!history.location.pathname.includes('/status')) {
      dispatch(momentsActions.deleteSingleMomentData());
    }
  }

  LikeUnlikeSpark = (p_id) => {
    const { dispatch } = this.props;
    const { user,sparklike } = this.props;
    if(user && p_id){
          const sendRaw = {
            moment_id : p_id,
            user_id: user.id,
          }

          dispatch(momentsActions.LikeUnlikeSpark(sendRaw,''));
    }
  }

  UndoReSprak = (p_id) => {
    const { dispatch } = this.props;
    const { user } = this.props;
    if(user && p_id)
    {
        const sendRaw = {
          post_id : p_id,
          user_id: user.id,
          retweet_status: 'moment',
        }
        dispatch(sparksActions.ReSparkPost(sendRaw));

        this.setState({title : ''});
    }
    else {
       toast.error(msgs.SOMETHING_WENT_WRONG);
    }
  };

  PopUpData = (sendRaw) => {
    const { dispatch } = this.props;
    if(sendRaw !== undefined){
          dispatch(sparksActions.ReplyPopUpData(sendRaw,'moment'));
    }
  };

  componentDidUpdate(){
      var s = $(".mom-like-left");
      var pos = s.position();
      $(window).scroll(function() {
       var windowpos = $(window).scrollTop();

       if (windowpos >= 110 & windowpos <=3000) {
         s.addClass("stick");
       } else {
         s.removeClass("stick");
       }
      });
  }

  coverLoadErrorHandler = () => {
    this.setState({isCoverLoaded: false});
  }

  render()
  {
    const {momentsDetail,topMoments,error,loading} = this.props;
    const {isCoverLoaded} = this.state;

    if(error === "No Moments found with these details") {
      return <NotFoundView />;
    }

      return(
        <div className="content-part moment-view">
          <div className="container">
          {loading != undefined && loading == true ? (
              <div className="loading-tweet">
                <LoadingIcon />
              </div>
              ) : ('')
          }
          {momentsDetail != undefined && loading == false ? (
            <div className="row">
              <div className="col-sm-2">
                <div className="mom-like-left moments-side">
                  <ul>
                    <li>
                      <button title="Like" onClick={() => this.LikeUnlikeSpark(momentsDetail.moment_id)} className={momentsDetail.likeStatus == '1' ? "social-active" : ""}>
                        <i className={momentsDetail.likeStatus == '1' ? "fas fa-heart": "far fa-heart"} style={{ color: momentsDetail.likeStatus == '1' ? "#ef4639": "#989da1" }} />{momentsDetail.likeStatus == '1' ? 'Liked' : 'Like'}
                      </button>
                    </li>
                    <li>
                      {momentsDetail.isPublished == '0' ? ('') : (
                          <button title="ReSpark" onClick={() => this.PopUpData(momentsDetail)} data-toggle="modal" data-target={"#momentspark-modal"} data-id={momentsDetail.moment_id}>
                            <i className="fas fa-reply" />ReSpark
                          </button>
                        )
                      }
                      </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-7">
                <div className="moment-view-left mid">
                  <div className="moment-view-inn">
                    <h4>{momentsDetail.title}</h4>
                    <h5>{momentsDetail.category_title}</h5>
                    <p className="word-wrap">{momentsDetail.description}</p>
                    <div className="moment-like">
                      <button><strong>{momentsDetail.totalLikes}</strong> Likes</button>
                    </div>
                    {isCoverLoaded && (
                    <div className="moment-view-img">
                      <img ref={this.imgRef} src={momentsDetail.file} alt="" onError={this.coverLoadErrorHandler} />
                    </div>
                    )}
                  </div>

                  <div className="moment-view-inn">
                    <AttachMomentSpark momentSparks={{ items: momentsDetail.posts }} />
                  </div>
                </div>
              </div>
            </div>
            ) : ("Moments is no-longer active.")
          }

          <MomentSparkAction />
          </div>
        </div>
    )
  }
}

MomentSingleView.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  const momentsDetail = state.momentsingle.momentsDetail != undefined ? state.momentsingle.momentsDetail : '';
  const topMoments = state.momentsingle.topMoments != undefined ? state.momentsingle.topMoments : [];
  const error = state.momentsingle.error != undefined ? state.momentsingle.error : '';
  const loading = state.momentsingle.loading != undefined ? state.momentsingle.loading : false;
  const user = state.authentication.user;
  return {
    momentsDetail,topMoments,error,loading,user
  };
}
export default withRouter(connect(mapStateToProps)(MomentSingleView));

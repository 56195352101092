import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CreateGroupChat from './CreateGroupChat';

export default function EditGroupChat(props) {

  const { onSubmit, friends, groupData, userList, searchUserChat, onClickOutside, clearSearchResults, userId,searchMsg } = props;

  return (
    <CreateGroupChat
      userId={userId}
      userList={userList}
      friends={friends}
      searchUserChat={(rawUser) => searchUserChat(rawUser)}
      onClickOutside={onClickOutside}
      clearSearchResults={clearSearchResults}
      titles={{
        header: "Edit Group Chat",
        submitButton: "Save",
        findUsersTitle: "Find Users and add to the chat",
      }}
      updateGroupChat={onSubmit}
      createGroupChat={false}
      groupData={groupData}
      disableEditUserList={false}
      searchMsg={searchMsg}
    />
  )
}

EditGroupChat.propTypes = {
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  searchUserChat: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  groupData: PropTypes.shape({
    id: PropTypes.string,
    groupName: PropTypes.string,
    groupDescription: PropTypes.string,
    groupImage: PropTypes.string,
    members: PropTypes.object,
  }),
  clearSearchResults: PropTypes.func,
  userList: PropTypes.array,
  searchMsg: PropTypes.string,
};

EditGroupChat.defaultProps = {
  clearSearchResults: () => console.log("clearSearchResults"),
}

import React, { Component } from 'react';
import Header from '../../../containers/frontend/common/Header';
import PasswordSettingSection from '../../../containers/frontend/userProfile/PasswordSettingSection';

export default class SettingPassword extends Component {
  render() {
    return (
      <div id="SettingPassword" className="SettingPassword">
        <Header/>
        <PasswordSettingSection/>
     </div>
    );
  }
}

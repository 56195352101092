import { APP_URL, APP2_URL } from '../mainurl';
import { getAppHeaders, handleResponse } from "../helpers";

export const listsService = {
    createEditList,
    getAll,
    deleteList,
    listSubscribe,
    addDeleteMembers
};

function createEditList(data) {

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP_URL}createEditList`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function getAll(data) {

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP_URL}listDetails`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function deleteList(data) {

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP_URL}deleteList`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function listSubscribe(data) {

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP_URL}listSubscribe`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function addDeleteMembers(data) {

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP2_URL}addDeleteMembers`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

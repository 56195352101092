import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import PrivacySafetySettingSection from '../../../containers/frontend/userProfile/PrivacySafetySettingSection';

import { userActions,alertActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class PrivacySafetySetting extends Component {
  constructor(props){
      super(props);
      const { dispatch, history, alert } = this.props;

      history.listen((location, action) => {
          // clear alert on location change
          if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
      });

      const { user } = this.props;
      if (user) {
          dispatch(userActions.getNotifySetting({user_id: user.id}));
      }
    }
render() {
    return (
      <div id="PrivacySafetySetting" className="PrivacySafetySetting">
        <Header/>
        <PrivacySafetySettingSection/>
     </div>
    );
  }
}

PrivacySafetySetting.propTypes = {
    history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { alert,notificationdata } = state;
    return {
        user,alert,notificationdata
    };
}

export default withRouter(connect(mapStateToProps)(PrivacySafetySetting));

import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import s from './plan.module.scss';

const PlanCard = ({ interval_count = '1', profitMessage = '-', currency = 'USD', amount, interval, onChoose = () => {} }) => {

  const setPlanDuration = () => {
    switch(interval_count) {
      case('1'):
        return '1 month';
      case('6'):
        return `${interval_count} months`;
      case('12'):
        return '1 year';
      default:
        break;
    }
  };

  const setProfitMessage = () => {
    return (
      (
        <div className={cs(s.profitContainer, { [`${s.profitMsg}`]: profitMessage !== '-' })}>
          { profitMessage }
        </div>
      )
    );
  }

  return (
    <div className={s.planCardContainer}>
      <div className={s.durationContainer}>
        {setPlanDuration()}
      </div>

      {setProfitMessage()}

      <div className={s.totalPriceContainer}>
        <span className={s.totalPrice}>
          {currency === 'USD' ? `$${Number(amount).toFixed(2)}` : amount}
        </span>
        <span className={s.interval}>
          Per {interval}
        </span>
      </div>

      <div className={s.chooseContainer}>
        <button onClick={onChoose} className="float-left">
          <span>CHOOSE</span>
        </button>
      </div>
    </div>
  )
}

PlanCard.propTypes = {
  interval_count: PropTypes.string.isRequired,
  profitMessage: PropTypes.string,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  interval: PropTypes.string.isRequired,
  onChoose: PropTypes.func.isRequired,
};

export default PlanCard;

import React, { Component } from 'react';
//import '../App.css';
import Header from '../../../containers/frontend/common/Header';
import MomentsBar from '../../../containers/frontend/common/momentsBar';
import OthersContent from '../../../containers/frontend/moments/othersContent';
import {Helmet} from "react-helmet";

export default class Others extends Component {
  render() {
    return (
      <main className="Others InstantByCategory">
        <Helmet>
          <title>Others - 2cents Moments</title>
        </Helmet>
        <Header/>
        <MomentsBar/>
        <OthersContent/>
     </main>
    );
  }
}

import React, { Component } from 'react';
import Header from '../../../containers/frontend/common/Header';
import MomentsBar from '../../../containers/frontend/common/momentsBar';
import AllMomentsContent from '../../../containers/frontend/moments/AllMomentsContent';
import {Helmet} from "react-helmet";

export default class AllMoments extends Component {
  render() {
    return (
     <main className="AllMoments InstantByCategory">
         <Helmet>
           <title>All - 2cents Moments</title>
         </Helmet>
        <Header/>
        <MomentsBar/>
        <AllMomentsContent/>
     </main>
    );
  }
}

'use strict';

import React, {Component} from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ChatComponent from './ChatComponent';
import ChatDetails from './ChatDetails';
import PropTypes from 'prop-types';
import {
  user as userPropType,
  singleChatData as activeSingleChatDataPropType,
  groupChatData as activeGroupChatDataPropType, isUndefined,
} from '../../../../misc/propTypes';
import {
  urlActions,
  deleteActiveChat,
  getChatMessages,
  pushActiveChatMessage,
  editChat,
  inviteUserToChat,
  sendMessage,
  leaveChat,
  markMessagesAsRead,
} from '../../../../actions';
import { withRouter } from 'react-router-dom';

import {Echoer} from '../../../../services/echo'
import {ONE_MESSAGES_PORTION} from '../../../../constants/'
import {ChatChannel} from '../../../../constants/broadcasting/channels'
import {ChatNewMessageEvent} from '../../../../constants/broadcasting/events'


const sortMessages = messages => {
  messages.sort((a, b) => a.timestamp - b.timestamp);
  return messages;
};
  
const updateMessages = (update, targetMessages) => {
    const message = update;
    let messages = [...targetMessages];
    const foundIndex = targetMessages.findIndex(({_id}) => message._id === _id);
    if (foundIndex !== -1) {
      messages[foundIndex] = message;
    } else {
      messages.push(message);
      sortMessages(messages);
  }
  return messages;
};


class Chat extends Component {
  constructor(props) {
    super(props);

    const { history } = this.props;

    this.state = {
      messages: [],
      loadingMessages: true,
      showChatDetails: false,
      sendingTick: 0,
      currentUserIsBlocked: false,
      recipientIsBlocked: false,
      messageFiles: [],
      isPrevMessages: false,

      currentChatChannel: null
    };
  }

  componentDidMount() {
    const { dispatch, chatData } = this.props;
    if(chatData && chatData.id) {
      getChatMessages(chatData.id)(dispatch);
      this.subscribeOnChatChannel();
    }

  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props !== nextProps) return true;
    if (this.state.messages.length !== nextState.messages.length) return true;
    if (this.state.loadingMessages !== nextState.loadingMessages) return true;

    return false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevChatDataId  = prevProps.chatData != undefined ? prevProps.chatData.id : 0;

    if (this.props.chatData != undefined && this.props.chatData.id != prevChatDataId) {
      const { dispatch, chatData: { id } } = this.props;
      getChatMessages(id)(dispatch);

      this.subscribeOnChatChannel()
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    this.unSubscribeFromChatChannel();
    dispatch(deleteActiveChat());
  }

  subscribeOnChatChannel() {
    const newCurrentChatChannel = ChatChannel(this.props.chatData.id);

    this.unSubscribeFromChatChannel()
    this.setState({
      currentChatChannel: newCurrentChatChannel
    })

    Echoer.getInstance().private(newCurrentChatChannel)
      .listen(ChatNewMessageEvent, this.onChatNewMessage)
  }

  unSubscribeFromChatChannel(){
    if (this.state.currentChatChannel){
      Echoer.getInstance().leave(this.state.currentChatChannel)
    }
  }

  onChatNewMessage = ({ chatMessage }) => {
    const {dispatch} = this.props;
    const { chatData } = this.props;

    if (chatData && chatMessage && chatMessage.chat_id === chatData.id){
      pushActiveChatMessage(chatMessage)(dispatch)
    }
  }

  goToProfile = (username) => {
    const { history } = this.props;
    history.replace('/' + username);
  }

  removeSharedLink = () => {
    const {dispatch} = this.props;
    dispatch(urlActions.saveSharedLink(''));
  };

  showChatDetails = () => {
    this.setState({showChatDetails: true});
  };

  onChatDetailsBack = () => {
    this.setState({showChatDetails: false});
  };

  updateGroupChat = async ({ chat_img, description, members, title, groupId }) => {
    const { dispatch } = this.props;
    if (title) {
      const editChatReq = {
        title,
        description,
        chat_img,
      };

      editChat(editChatReq, groupId)(dispatch);
    }

    if (members.length) {
      const inviteChatReq = {
        users: members.map(userId => ({ id: userId })),
      };

      inviteUserToChat(inviteChatReq, groupId)(dispatch);
    }
  };

  getPrevMessages = () => {
    const { chatData, dispatch } = this.props;
    const chatMessages = chatData.messages || [];

    if (chatMessages.length >= ONE_MESSAGES_PORTION) {
      getChatMessages(chatData.id, chatMessages.length)(dispatch);;
    }
  }

  setMessagesAsSeen = (messages, chatId) => {
    const { chatData, dispatch } = this.props;

    chatData && markMessagesAsRead({ messages }, chatId)(dispatch)
  }

  leaveSelectedChat = (chatId) => {
    const { dispatch, chatData } = this.props;
    if(chatData.id === chatId) {
      dispatch(deleteActiveChat());
    }

    leaveChat(chatId)(dispatch);
  }

  renderChatDetails() {
    const { chatData } = this.props;
    const { recipientIsBlocked, currentUserIsBlocked } = this.state;
    return (
      <ChatDetails
        goToProfile={this.goToProfile}
        // leaveGroupChat={this.leaveGroupChat}
        leaveGroupChat={() => {}}
        // deleteSingleChat={this.deleteSingleChat}
        deleteSingleChat={() => {}}
        // updateGroupChatName={this.updateGroupChatName}
        updateGroupChatName={() => {}}
        data={chatData}
        sendStatus={!recipientIsBlocked && !currentUserIsBlocked}
        // updateGroupImage={this.updateGroupImage}
        updateGroupImage={() => {}}
        toastError={toast.error}
      />
    );
  }

  renderChatScreen() {
    const { sendingTick, recipientIsBlocked, currentUserIsBlocked, isPrevMessages } = this.state;
    const { chatData, user, sharedLink, loading, dispatch } = this.props;
    const chatMessages = chatData && chatData.messages && chatData.messages.length ?  chatData.messages : [];

    return (
      <ChatComponent
        user={user}
        chatData={chatData}
        goToProfile={this.goToProfile}
        removeSharedLink={this.removeSharedLink}
        // sendMessage={this.onAddMessage}
        sendMessage={sendMessage}
        messages={chatMessages}
        loadingMessages={loading}
        isPrevMessages={isPrevMessages}
        sendingDisabled={recipientIsBlocked || currentUserIsBlocked}
        // setReadStatus={setReadStatus}
        setReadStatus={() => {}}
        setMessagesAsSeen={this.setMessagesAsSeen}
        // setSingleMessageSeenStatus={setSingleMessageSeenStatus}
        sharedLink={sharedLink}
        sendingTick={sendingTick}
        // deleteSingleChat={this.deleteSingleChat}
        deleteSingleChat={() => {}}
        updateGroupChat={this.updateGroupChat}
        // leaveGroupChat={this.leaveGroupChat}
        // getPrevMessages={this.getPrevMessages}
        getPrevMessages={this.getPrevMessages}
      />
    );
  }

  render() {
    return this.renderChatScreen();
  }
}

Chat.propTypes = {
  user: userPropType.isRequired,
  chatData: PropTypes.oneOfType([activeSingleChatDataPropType, activeGroupChatDataPropType, isUndefined]),
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  const { chat: { activeChatData: { loading, data: chatData } } } = state;
  return {
    user: state.authentication.user,
    alert: state.alert,
    sharedLink: state.url.sharedLink,
    chatData,
    loading,
  };
}

export default withRouter(connect(mapStateToProps)(Chat));

import {APP_URL, APP2_URL} from '../mainurl';
import {getAppHeaders, handleResponse} from "../helpers";

export const sparksService = {
  CreateSpark,
  getAll,
  getProfileData,
  getTagAllData,
  LikeUnlikeSpark,
  GetSparkData,
  VotePoll,
  CommentPost,
  ReSparkPost,
  MomentSparkPost,
  deleteSpark,
  deleteComment,
  blockUser,
  markAsObjectionable,
  unmarkAsObjectionable,
  reportSpark,
  messagePushNotification,
};

function CreateSpark(data) {

  console.log('data: ', data);

  let formData = new FormData();
  if (data.type == 'image') {
    if (data.files.length > 1) {
      var fileList = data.files;
      for (var x = 0; x < fileList.length; x++) {
        formData.append('image[]', fileList[x], fileList[x].name);
      }
    } else {
      var fileList = data.files;
      for (var x = 0; x < fileList.length; x++) {
        formData.append('image', fileList[x], fileList[x].name);
      }
    }
  }

  if (data.type == 'video') {
    if (data.files.length > 1) {
      var fileList = data.files;
      for (var x = 0; x < fileList.length; x++) {
        formData.append('video[]', fileList[x]);
      }
    } else {
      var fileList = data.files;
      for (var x = 0; x < fileList.length; x++) {
        formData.append('video', fileList[x]);
      }
    }
  }

  formData.append('user_id', data.createspark.user_id);
  formData.append('sparktext', data.createspark.sparktext);
  formData.append('posttype', data.type);
  formData.append('location', data.createspark.location);
  formData.append('lat', data.createspark.lat);
  formData.append('long', data.createspark.long);

  if (data.type == 'poll') {
    formData.append('choice_option', JSON.stringify(data.choice_option));
    formData.append('validPoll', data.createspark.validPoll ? data.createspark.validPoll : '7');
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(false),
    body: formData,
  };

  return fetch(`${APP2_URL}createPost`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function getAll(user) {
  const data = {
    user_id: user,
  };

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}homePost`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function getProfileData(user) {
  let userLoggedIn = JSON.parse(localStorage.getItem('user'));
  const data = {
    username: user,
    user_id: userLoggedIn ? userLoggedIn.id : 0,
  };

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP_URL}getProfileData`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function getTagAllData(data) {
  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP_URL}filterPost`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function LikeUnlikeSpark(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}post_likedislike`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function GetSparkData(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}singlePost`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function VotePoll(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP_URL}votePoll`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}


function CommentPost(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}commentPost`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function ReSparkPost(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}retweetPost`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function MomentSparkPost(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify({'title': data.title})
  };

  return fetch(`${APP2_URL}resparkMoment/${data.moment_id}`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function deleteSpark(postdata) {
  const data = {
    post_id: postdata.post_id,
    user_id: postdata.user_id,
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}DeletePost`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function deleteComment(postdata) {
  const data = {
    comment_id: postdata.comment_id,
    user_id: postdata.user_id,
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}deleteComment`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function blockUser(blockData) {
  const data = {
    receiver_id: blockData.receiver_id,
    user_id: blockData.user_id,
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}block_user`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function markAsObjectionable(objectionableData) {
  const data = {
    user_id: objectionableData.user_id,
    spark_id: objectionableData.spark_id,
    spark_owner_id: objectionableData.spark_owner_id,
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}setAsObjectionable`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function unmarkAsObjectionable(objectionableData) {
  const data = {
    user_id: objectionableData.user_id,
    spark_id: objectionableData.spark_id,
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}unsetAsObjectionable`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function reportSpark(postdata) {

  const data = {
    post_id: postdata.post_id,
    user_id: postdata.user_id,
    type: 'post',
    reason_id: postdata.reason_id
  }

  return fetch(`${APP_URL}reportPost`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: getAppHeaders(true)
  })
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function messagePushNotification(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}messageNotification`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

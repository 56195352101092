import { toast } from 'react-toastify';
import {getAppHeaders} from "./auth-header";
import {APP2_URL} from "../mainurl";
import { removeAccessToken } from '../services/accessTokenStorage';
import { removePasswordAsync } from '../services/credentialStorage';
import { removeUserEmailBunchAsync } from '../services/userEmailBunchStorage';

export function handleResponse(response) {
  if (!response) {
    toast.error("No Internet Connection. Check your network and try again.")
    return Promise.reject("TypeError: Failed to fetch");
  }
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok || response.status == 401) {
      if (response.status === 401) {
        logout();
        window.location('/login');
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export function onLogoutDataCleanup() {
  removeAccessToken();
  removePasswordAsync()
    .catch(e => console.error('removePasswordAsync error',e));
  removeUserEmailBunchAsync()
    .catch(e => console.error('removeUserEmailBunchAsync error',e));
  localStorage.removeItem('user');
  localStorage.removeItem('chatOpen');
  localStorage.removeItem('notifyFilters');
}

export function logout() {
  const userStr = localStorage.getItem('user');

  onLogoutDataCleanup();

  if (userStr !== null) {
    const userObj = JSON.parse(userStr);
    const data = {'user_id': userObj.id, 'action': 'web', 'token': null};

    const requestOptions = {
      method: 'POST',
      headers: getAppHeaders(true),
      body: JSON.stringify(data)
    };

    return fetch(`${APP2_URL}logout`, requestOptions)
      .then(handleResponse)
      .then(raw => {
        let data = raw.data;
        return data;
      });
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import HelpCenterContent from '../../../containers/frontend/pages/HelpCenterContent';
import {Helmet} from "react-helmet";

import { alertActions,userActions } from '../../../actions';
class HelpCenter extends Component{

  constructor(props){
    super(props);
  }

  componentDidMount() {
    this.getPageData();
  }

  getPageData = () => {
    const { dispatch } = this.props;
    if (dispatch) {
          dispatch(userActions.getPagesData());
    }

  }
  render() {
    const { contentData, loading } = this.props;

    return (
      <main className="HelpCenter">
        <Helmet>
          <title>Help Center</title>
        </Helmet>
        <Header/>
        <HelpCenterContent contentData={contentData} loading= {loading} type="help" />
	   </main>
    )
  }
}

function mapStateToProps(state,ownProps){
  return {
    user: state.authentication.user,
    contentData: state.pageinfo.data != undefined ? state.pageinfo.data.help : [],
    loading: state.pageinfo.loading ? state.pageinfo.loading : false,
  };
}
export default connect(mapStateToProps)(HelpCenter);

import React, { Component } from 'react';
import $ from 'jquery';
import Header from '../../../containers/frontend/common/Header';
import HomeContent from '../../../containers/frontend/HomeContent';
import ReferralModal from '../../modal/referral/ReferralModal';
import Congratulations from '../../modal/referral/Congratulations';
import {Helmet} from "react-helmet";

class Home extends Component{
  constructor(props){
    super(props);
    $('html,body').css('overflow', '');
  }

  render() {
    return (
      <main className="Home">
        <Helmet>
          <title>2cents. Responsible Social Media</title>
        </Helmet>
        <Header/>
        <HomeContent/>
        <ReferralModal />
        <Congratulations />
	    </main>
    )
  }
}

export default Home;

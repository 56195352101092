import { userConstants } from '../constants';

export function pageinfo(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_PAGE_DATA_REQUEST:
      return {
        loading: true
      };
    case userConstants.GET_PAGE_DATA_SUCCESS:
      return {
        loading: false,
        data: action.pageData
      };
    case userConstants.GET_PAGE_DATA_FAILURE:
      return {
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}

import { asyncLocalStorage } from './localStorage';

export const PASSWORD = 'x_code';

let _password;

let isPasswordInit;

//primitive encryption
export const encrypt = (str) => window.btoa(str);
export const decrypt = (str) => window.atob(str);

const tryParseJSON = (v) => {
  try {
    return JSON.parse(v);
  } catch (e) {
    return v;
  }
}

export const setPassword = (password) => {
  _password = password;
  localStorage.setItem(PASSWORD, JSON.stringify(password));
  isPasswordInit = true;
};

export const getPassword = (useLocalStorage = false) => {
  if (useLocalStorage || !isPasswordInit) {
    const password = localStorage.getItem(PASSWORD);
    _password = tryParseJSON(password);
    isPasswordInit = true;
  }
  return _password;
};

export const removePassword = () => {
  _password = undefined;
  localStorage.removeItem(PASSWORD);
  isPasswordInit = true;
};

export const setPasswordAsync = (password) => {
  let prevPassword = _password;
  _password = password;
  return asyncLocalStorage.setItem(PASSWORD, JSON.stringify(password))
    .then(() => {
      isPasswordInit = true;
    })
    .catch(error => {
      _password = prevPassword;
      throw error;
    });
};

export const getPasswordAsync = () => {
  return asyncLocalStorage.getItem(PASSWORD)
    .then(() => {
      isPasswordInit = true;
    })
    .then(password => {
      _password = password;
      return password;
    });
};

export const removePasswordAsync = () => {
  let prevPassword = _password;
  return asyncLocalStorage.removeItem(PASSWORD)
    .then(() => {
      isPasswordInit = true;
    })
    .catch(error => {
      _password = prevPassword;
      throw error;
    });
};

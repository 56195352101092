import imageCompression from "browser-image-compression";
import msgs from "../messages/en/main";
import { toast } from "react-toastify";

export const getExistChat = (chats, userId) => {
  return chats.find(chat => chat.chatType === 1 && chat.members.some(user => user.userId === userId));
}

export async function processImages(files) {
  let processedFiles = [];

  const compressOptions = {
    maxSizeMB: 5,
    useWebWorker: false
  };

  for (const file of files) {
    if (file.size / 1024 / 1024 > 5 && file.type.match('image.*')) {
      try {
        const compressedFile = await imageCompression(file, compressOptions);
        processedFiles.push(compressedFile);
        toast.info(msgs.IMAGE_WAS_CONVERTED_AUTOMATICALLY);
      } catch (error) {
        console.log(error.message);
      }
    } else {
      processedFiles.push(file);
    }
  }

  return processedFiles;
}
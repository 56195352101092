import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { user as userPropType, userId as userIdPropType } from '../../../misc/propTypes';

function VotePollView(props) {
  const {
    data: { pollData = [], pollTotalVotes = 0, poll_expiry, expiry_days_left, user_id: creatorUserId } = {},
    user: { id: userId },
  } = props;

  const isOwner = creatorUserId == userId;

  return (
    <div className="full-width">
      <ul className="poll-dis">
        {pollData.length > 0 && (
          pollData.map(({ voted_user_id, count, vote_status, choice_option }, index) => {
            const markAsSelfVoted = !isOwner && (voted_user_id || []).find(voterId => voterId == userId);
            return (
              <li key={index} className={markAsSelfVoted ? 'li-bg' : ''}>
                <b>{count > 0 && typeof pollTotalVotes !== 'undefined' ? (count * 100 / pollTotalVotes).toFixed(2) : '0'}% </b>
                <span>{choice_option}</span>
                {markAsSelfVoted && <i className="far fa-check-circle" />}
              </li>
            );
          })
        )}
      </ul>
      <p className="poll-msg">
        {pollTotalVotes ? pollTotalVotes : '0'} Votes *
        {poll_expiry == 1 ? (expiry_days_left != 0 ? expiry_days_left + ' days left' : ' last day') : 'Poll ended'}
      </p>
    </div>
  );
}

const numberOrStringType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

VotePollView.propTypes = {
  data: PropTypes.shape({
    user_id: PropTypes.oneOfType([userIdPropType, PropTypes.string]).isRequired,
    pollData: PropTypes.arrayOf(PropTypes.shape({
      choice_option: PropTypes.string.isRequired,
      count: numberOrStringType.isRequired,
      percentage: numberOrStringType.isRequired,
      poll_id: numberOrStringType,
      post_id: numberOrStringType.isRequired,
      vote_status: PropTypes.string.isRequired,//"1"
      voted_user_id: PropTypes.arrayOf(numberOrStringType),
    })).isRequired,
    pollTotalVotes: numberOrStringType.isRequired,
    poll_expiry: PropTypes.string.isRequired,//"1"
    expiry_days_left: PropTypes.string.isRequired,//"1"
  }).isRequired,
  user: userPropType.isRequired,
};


function mapStateToProps(state){
  return {user: state.authentication.user,};
}
export default connect(mapStateToProps)(VotePollView);

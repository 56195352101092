import React, {Component} from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';

import { alertActions,sparksActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class VoteActionView extends Component {
  constructor(props){
    super(props);

    this.state = {
        user: '',
        poll_id: '',
        post_id: '',
        submitted: false
    };

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    const { user } = this.props;

  }

  handleChange = (event) => {
      const { name, value } = event.target;
      const post_id = event.target.dataset.post_id;

      this.setState({
        [name]: value,
        post_id: post_id
      });
  }

  VoteSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const {poll_id,post_id} = this.state;
    const {user} = this.props;
    if(poll_id && user.id != undefined){
      const { dispatch } = this.props;
      let data = {
        user_id : user.id,
        poll_id : poll_id,
        post_id : post_id,
      }
        dispatch(sparksActions.VotePoll(data));
    }
    else {
       toast.error(msgs.NOT_ALLOWED_TO_VOTE);
    }
  }

  render(){
    let {singlePollData} =this.props;
    return (
      <div className="vote">
        <ul>
          {singlePollData.pollData.length > 0 ? (
              singlePollData.pollData.map((data,index) =>
                (
                    <li key={index}>
                        <label className="radio">{data.choice_option}
                        <input onClick={this.handleChange} data-post_id={data.post_id} name="poll_id" type="radio" defaultValue={data.poll_id} defaultChecked="checked"/>
                        <span className="checkmark" />
                      </label>
                    </li>
                )
              )
            ) : ('')
          }
        </ul>
        <button onClick={this.VoteSubmit}>Vote</button>
      </div>

    )
  }
}

VoteActionView.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    alert: state.alert,
    user: state.authentication.user,
    singlePollData: ownProps.singlePollData,
    votespark: state.votespark
  };
}
export default withRouter(connect(mapStateToProps)(VoteActionView));

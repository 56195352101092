import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';
import VoteActionView from './VoteActionView';
import VotePollView from './VotePollView';
import SparkVideoSection from './SparkVideoSection';
import SparkImageSection from './SparkImageSection';
import {createDisplayedSpark, formatDateFromUTCtoLocal} from '../../../helpers';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { sparksActions,hashtagsActions,momentsActions } from '../../../actions';
import ResparkedInstantView from '../sparks/ResparkedInstantView';

class ManageSparkType extends Component {
    constructor(props){
      super(props);
    }

    LikeUnlikeSpark = (p_id) => {
      const { dispatch } = this.props;
      const { user,sparklike } = this.props;
      if(user && p_id){
            const sendRaw = {
              post_id : p_id,
              user_id: user.id,
            }
            dispatch(sparksActions.LikeUnlikeSpark(sendRaw,''));
      }
    }

    UndoReSprak = (p_id) => {
      const { dispatch } = this.props;
      const { user } = this.props;
      if(user && p_id){
            const sendRaw = {
              post_id : p_id,
              user_id: user.id,
              retweet_status: 'retweet',
            }
            dispatch(sparksActions.ReSparkPost(sendRaw));

            this.setState({title : ''});
      }
      else {
         toast.error(msgs.SOMETHING_WENT_WRONG);
      }
    }

    PopUpData = (sendRaw) => {
      const { dispatch } = this.props;

      if (sendRaw != undefined) {
            dispatch(sparksActions.ReplyPopUpData(sendRaw));
      }
    }

    goNext = (username,post_id,type,PostType) => {
      const { history } = this.props;
      if (PostType == 'retweet_title') {
        history.push('/'+username+'/status/'+post_id);
      } else if (type == 'moment') {
          history.push('/instants/view/'+post_id);
      } else {
        history.push('/'+username+'/status/'+post_id);
      }
    }

    selectedSpark(rawdata,index,type) {
      this.props.callbackFromParent(rawdata,index,type);
    }

    render() {
      const {raw,user,index,type,isSelected, history} = this.props;
      return (
          <div className="moment_sparktype">
            {raw.deleting ? <em> - Deleting...</em> : (
                <div className="tweet" data-id={raw.post_id}>
                  <div className="tweet-inn">
                    <div className="row">
                      <div className="col-sm-2">
                        <div className="tweet-img">
                          <div className="twit-mid-img">
                            <img src={raw.profile_image ? raw.profile_image : '/frontend/images/user-img.png'} alt="logo" />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-10 padd-left">
                        <div className="tweet-right">
                            <div>
                              <Link to={'/'+raw.username}>
                                {raw.name}
                                <p>{raw.at_username}</p>
                              </Link>
                              {raw.posttype != 'moment' ? (
                                  <div className="item-header" onClick={() => this.goNext(raw.username,raw.post_id,raw.posttype)} data-id={raw.post_id}  data-type={raw.posttype}>
                                    <div className="spark-text">
                                        { createDisplayedSpark(raw.post_title) }
                                    </div>
                                  </div>
                                ) : (
                                  <div style={{cursor: 'pointer'}} onClick={(e) => this.goNext(raw.username, raw.post_id, 'retweet_title')}>
                                    <ResparkedInstantView raw={raw} history={history} />
                                  </div>
                                  )
                              }
                              {raw.retweetData != undefined && raw.posttype != 'moment' && raw.retweetData.length > 0 ? (
                                raw.retweetData.map((resparkData,index) =>
                                    <div className="item-header pop-mid" key={index} onClick={() => this.goNext(resparkData.username,resparkData.post_id,resparkData.posttype)} data-id={resparkData.post_id} data-type={resparkData.posttype}>
                                      <Link to="/">{resparkData.at_username ? resparkData.at_username : resparkData.username}</Link>
                                        <div>
                                            { createDisplayedSpark(resparkData.post_title) }
                                            { resparkData.posttype === 'poll' && <VotePollView singlePollData={resparkData}/> }
                                            { resparkData.posttype === 'image' &&  <SparkImageSection sparkImageData={resparkData} /> }
                                            { resparkData.posttype === 'video' &&  <SparkVideoSection sparkVideoData={resparkData} /> }
                                        </div>
                                    </div>
                                )
                                ) : ('')
                              }
                              {raw.retweet_id != 0 && raw.posttype != 'moment' && (raw.retweetData == undefined || raw.retweetData.length == 0 )  ? (
                                  <div className="pop-mid">
                                    <div>This Spark is unavailable</div>
                                  </div>
                                ) : ('')
                              }
                            </div>
                            {raw.posttype == 'image' ?
                              <SparkImageSection sparkImageData={raw} />
                              : ('')
                            }
                            {raw.posttype == 'video' ?
                              <SparkVideoSection sparkVideoData={raw} />
                              : ('')
                            }
                            {raw.posttype == 'poll' ? (
                               raw.pollVoteStatus == 0 && raw.user_id != user.id ? (
                                 <VoteActionView singlePollData={raw}/>
                                ) : (
                                 <VotePollView singlePollData={raw}/>
                                )
                              ) : ('')
                            }
                          <div className="time list">{formatDateFromUTCtoLocal(raw.created)}</div>
                          <button className="selected_post select" onClick={() => this.selectedSpark(raw,index,type)} disabled={isSelected}>
                            <i className={`fas  ${isSelected ? 'fa-check-double': 'fa-check'}`}></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        );

    }
}

ManageSparkType.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    sparklike: state.sparklike,
    user: state.authentication.user,
    raw: ownProps.rawData,
    index: ownProps.index,
    type: ownProps.type
  };
}
export default withRouter(connect(mapStateToProps)(ManageSparkType));

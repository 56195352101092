import store from '../store';

export function getCurrentRouter() {
  const {router} = store.getState();
  return router;
}

export function getCurrentLocation() {
  const router = getCurrentRouter();
  return router.location;
}

import React, { Component } from 'react';
//import '../App.css';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import MomentsBar from '../../../containers/frontend/common/momentsBar';
import MomentSingleView from '../../../containers/frontend/moments/MomentSingleView';
import { alertActions,momentsActions } from '../../../actions';
import {Helmet} from "react-helmet";

class MomentView extends Component {
  constructor(props){
      super(props);
  }

  componentDidMount() {
      this.getPageData();
  }

  componentDidUpdate(prevProps)
  {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { user } = this.props;
    const { dispatch } = this.props;
    var momentId = this.props.match.params.slug;
    if(user){
        const data = {
          user_id: user.id,
          moment_id: momentId
        }
        dispatch(momentsActions.getMomentDetail(data));
    }
  }

  render() {
    return (
      <main className="MomentView">
        <Helmet>
          <title>Instant Single View on 2cents</title>
        </Helmet>
        <Header/>
        <MomentsBar/>
        <MomentSingleView/>
     </main>
    );
  }
}

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    alert: state.alert,
  };
}
export default connect(mapStateToProps)(MomentView);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserSmallDesign from './userElements/UserSmallDesign';
import TrendsForYou from './sparks/TrendsForYou';
import NoSparkSection from './sparks/NoSparkSection';
import CreateSpark from './sparks/CreateSpark';
import SparkList from './sparks/SparkList';
import WhoHaveToFollowWidget from './common/WhoHaveToFollowWidget';
import BottomWidget from './common/BottomWidget';
import Media from 'react-media';
import { userActions,alertActions,sparksActions, reportReasonsActions } from '../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class HomeContent extends Component {
  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
        if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });
  }

  componentDidMount() {
    const { user, holdflag, dispatch, history } = this.props;
    if (user && holdflag == false) {
      dispatch(sparksActions.getAll(user.id, history.location));
      dispatch(reportReasonsActions.getReportReasons());
    }

    if(!user) {
      dispatch(userActions.logout());
    }
  }

  render()
  {
    const { loggedIn,alert,sparks,user } = this.props;

    if (!user) {
      console.log('!user')
      return null;
    }

    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;

    return (
      <div className="content-part">
        <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          <div className="row">
            <div className="col-3 left-sidebar">
              <UserSmallDesign/>
              <TrendsForYou/>
            </div>

            <div className="col-6 feed-container">
              <CreateSpark />
              {sparks ? <SparkList /> : <NoSparkSection />}
            </div>
            <div className="col-3 right-sidebar">
              <WhoHaveToFollowWidget />
              <Media query="(max-width: 989px)" render={() => <TrendsForYou/>} />
              <BottomWidget />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

HomeContent.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    alert: state.alert,
    sparks: state.sparks,
    reportReasons: state.reportreasons.items,
    holdflag: ownProps.holdflag != undefined ? ownProps.holdflag : false,
  };
}
export default withRouter(connect(mapStateToProps)(HomeContent));

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link,} from 'react-router-dom';
import UserSideBarSection from '../common/UserSideBarSection';
import {toast} from 'react-toastify';
import {userActions, alertActions} from '../../../actions';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

class YourAccountDataSection extends Component {
  constructor(props) {
    super(props);

    const {dispatch, user, history, alert} = this.props;

    history.listen((location, action) => {
      // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    if (user) {
      dispatch(userActions.userSettings(user.id));
    }

  }

  render() {
    const {user, alert} = this.props;
    const DATE_OPTIONS = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;

    return (
      <div className="content-part sports-main">
        <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          <div className="row">
            <UserSideBarSection/>
            <div className="col-sm-6 feed-container">
              <div className="twiter-left1 access-mid cents-data">
                <h3>Your 2cents data</h3>
                <div className="cents-data-mid">
                  <p>Review and edit your profile information and data associated<br/>
                    with your account.</p>
                  <div className="cents-data-account">
                    <h4>Account</h4>
                    <div className="data-acco1">
                      <div className="row">
                        <div className="col-sm-5">
                          <h4>Username</h4>
                        </div>
                        <div className="col-sm-7">
                          <h5>
                            {user.at_username} 
                            <Link to={"/settings/account-setting"} className="edit-link">
                              Edit
                            </Link><br/>
                            <strong>User ID: {user.id}</strong>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="data-acco1">
                      <div className="row">
                        <div className="col-sm-5">
                          <h4>Account creation</h4>
                        </div>
                        <div className="col-sm-7">
                          <h5>{new Date(user.created).toLocaleDateString('en-US', DATE_OPTIONS)}<br/>
                            {/*<strong>{usersettings.sign_ip != undefined ? usersettings.sign_ip.ip : ''} (located in India)</strong>*/}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="data-acco1">
                      <div className="row">
                        <div className="col-sm-5">
                          <h4>Profile</h4>
                        </div>
                        <div className="col-sm-7">
                          <h5>
                            <Link to={"/" + (user.username != undefined ? user.username.replace("@", "") : '')}>
                              View your profile
                            </Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="data-acco1">
                      <div className="row">
                        <div className="col-sm-5">
                          <h4>Phone</h4>
                        </div>
                        <div className="col-sm-7">
                          <h5>{user.phone_number ? user.phone_number : '-'}</h5>
                        </div>
                      </div>
                    </div>
                    {/* <div className="data-acco1">
                      <div className="row">
                        <div className="col-sm-5">
                          <h4>Gender</h4>
                        </div>
                        <div className="col-sm-7">
                          <h5>{user.gender ? user.gender : '-'}</h5>
                        </div>
                      </div>
                    </div> */}
                    <div className="data-acco1">
                      <div className="row">
                        <div className="col-sm-5">
                          <h4>Birth date</h4>
                        </div>
                        <div className="col-sm-7">
                          <h5>{user.dob ? new Date(user.dob).toLocaleDateString('en-US', DATE_OPTIONS) : '-'}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cents-data-mid bor-botm">
                  <div className="cents-data-account">
                    <h4>Account history</h4>
                    <div className="data-acco1">
                      <div className="row">
                        <div className="col-sm-5">
                          <h4>Account access history</h4>
                        </div>
                        <div className="col-sm-7">
                          <h5>{user.ip_history != undefined ? user.ip_history.length : 0} logins
                            <Link to="/settings/login-ip-history"> See all</Link><br/>
                            <p>You can see all login history record activity with ip address</p>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

YourAccountDataSection.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    alert: state.alert,
  };
}

export default withRouter(connect(mapStateToProps)(YourAccountDataSection));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createDisplayedSpark, formatDateFromUTCtoLocal } from '../../../helpers';

class ListComments extends Component {
  constructor(props){
    super(props);
  }

  render(){
    const { CommentData, deleteComment, user, goToProfile } = this.props;

    return (
      <div className="comments">
        {CommentData.commentData.length > 0 ? (
          CommentData.commentData.map((data, index) =>
            (
              <div className="tweet-inn" key={data.comment_id}>
                <div className="row">
                  <div className="col-sm-2">
                    <div className="tweet-img">
                      <div className="twit-mid-img" onClick={() => goToProfile(data.at_username)}>
                        <img src={data.profile_image ? data.profile_image : '/frontend/images/user-img.png'} style={{ cursor: 'pointer' }} alt="logo" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-10 padd-left">
                    <div className="tweet-right">
                      <div>
                        <h4 title={data.name} style={{ cursor: 'pointer' }} onClick={() => goToProfile(data.at_username)} className="full-name  text-overflow-ellipsis  max-width-50">{data.name}</h4>
                        <div className="time">{formatDateFromUTCtoLocal(data.created)}</div>
                        {user && (CommentData.user_id == user.id || data.user_id == user.id ?
                          (
                            <div className="dropdown">
                              <button className="btn-arrow" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-chevron-down"></i>
                              </button>
                                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <span onClick={() => deleteComment(data.comment_id, index)} className="dropdown-item">Delete Comment</span>
                                  </div>
                            </div>
                          ) : (''))
                        }
                      </div>
                      <h5 title={data.at_username} style={{ cursor: 'pointer' }} onClick={() => goToProfile(data.at_username)} className="text-overflow-ellipsis  max-width-50">{data.at_username}</h5>
                        <div className="comment-text">
                          { createDisplayedSpark(data.comment_text) }
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )) : ('')
        }
      </div>
    )
  }
}


function mapStateToProps(state,ownProps){
  return {
    alert: state.alert,
    user: state.authentication.user,
    CommentData: ownProps.CommentData
  };
}
export default connect(mapStateToProps)(ListComments);

import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import s from './chatModal.module.scss';
import messages from "../../../../messages/en/main";
import {toast} from 'react-toastify';
import {database} from "../../../../firebase";
import debounce from 'lodash.debounce';
import UserAvatar from './UserAvatar';
import { processImages } from '../../../../helpers';
import LoadingIcon from '../../../../containers/frontend/common/LoadingIcon';
import { fileSizeLimits } from '../../../../misc/fileSizeLimits';

const formIds = {
  GROUP_DESCRIPTION: 'groupDescription',
  GROUP_NAME: 'groupName',
  GROUP_IMAGE: 'groupImage',
};

export default function CreateGroupChat(props) {
  const {
    createGroupChat,
    updateGroupChat,
    onClickOutside,
    userList,
    userId,
    friends = [],
    searchUserChat,
    clearSearchResults,
    searchMsg,
    titles = {
      header: "New Group Chat",
      submitButton: "Create",
      findUsersTitle: "Find Users*",
    },
    groupData = {
      groupName: "",
      groupDescription: "",
      groupImage: "",
      members: [],
    },
    disableEditUserList,
  } = props;

  const { GROUP_DESCRIPTION, GROUP_NAME, GROUP_IMAGE } = formIds;
  const [searchValue, setSearchValue] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [selectedUsers, updateSelectedUsers] = useState([]);
  const [groupDescription, updateGroupDescription] = useState(groupData.groupDescription);
  const [groupName, updateGroupName] = useState(groupData.groupName);
  const [groupImage, updateGroupImage] = useState(groupData.groupImage);
  const [groupImageError, setGroupImageError] = useState(false);
  const [imageAsBase64, setNewImagePreview] = useState(groupData.groupImage);
  const input = useRef(null);

  async function onFileSelect(e) {
    e.persist();
    if(e) {
      setGroupImageError(false);

      let files = Array.from(e.target.files);
      // updateGroupImageLoading(true);
      // let processedFiles = await processImages(files);
      // updateGroupImageLoading(false);
  
      // if (!processedFiles || !processedFiles.length) {
      //   // toast.error(messages.SOMETHING_WENT_WRONG);
      //   return;
      // }
  
      const file = files[0];
      if (!file.type.match('image.*')) {
        toast.error(messages.IMAGES_ARE_ALLOWED);
        return;
      }
  
      if (file.size > fileSizeLimits.image) {
        toast.error(messages.MAX_IMG_SIZE);
        setGroupImageError(true);
        return;
      }
  
      setNewImagePreview("");
  
      updateGroupImage(file);
  
      const reader = new FileReader();
  
      reader.addEventListener('load', () => {
        setNewImagePreview(reader.result);
      });
  
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (disableEditUserList) {
      getMembersData(userList || selectedUsers || {}, updateSelectedUsers);
    }
  }, []);

  const onUserClick = ({ id, profile_image, name }) => {
    if (!id || !name) {
      return;
    }

    const selectedUsersCopy = [...selectedUsers];

    if (selectedUsersCopy.some(user => user.userId === id)) {
      return updateSelectedUsers(selectedUsersCopy.filter(user => user.userId !== id));
    }

    selectedUsersCopy.push({ userId: id, profile_image, fullName: name });

    updateSelectedUsers(selectedUsersCopy);
  }

  const debouncedSetSearchValue = debounce(value => {
    setSearchValue(value);
  }, 1000);

  const onUserSearch = (event) => {
    const { value } = event.target;

    debouncedSetSearchValue(value);
  };

  useEffect(() => {
      if (searchValue) {
        searchUserChat({ user_id: userId, search: searchValue });
      } else {
        clearSearchResults();
      }
    },[searchValue]
  );

  const inputHandleChange = (event) => {
    switch(event.target.id) {
      case GROUP_DESCRIPTION:
        updateGroupDescription(event.target.value);
        break;
      case GROUP_NAME:
        updateGroupName(event.target.value);
        break;
      case GROUP_IMAGE:
      default:
        return;
    }
  };

  const onSubmit = (form) => {
    setSubmitted(true);

    if (!!createGroupChat && selectedUsers.length > 0 && groupName.trim()) {
      createGroupChat(form);
      onModalClose();
    } else if (!!updateGroupChat && friends.length > 0 && groupName.trim()) {
      updateGroupChat(form);
      onModalClose();
    } 
  };

  const clearSelectedUserList = () => {
    updateSelectedUsers([]);
  }

  const clearSearchInput = () => {
    setSearchValue("");
    clearSearchResults();
    input.current.value = '';
  };

  const getSelectedMemberCount = () => {
    const countMemeber = selectedUsers.length;

    if (countMemeber === 1) {
      return `${countMemeber} member selected`;
    }

    return `${countMemeber} members selected`;
  }

  const deleteUserFromSelected = (userId) => {
    const filtredArray = selectedUsers.filter(user => user.userId !== userId);
    updateSelectedUsers(filtredArray);
  };

  const onModalClose = () => {
    setSubmitted(false);
    setGroupImageError(false);
    updateSelectedUsers([]);
    clearSearchResults();
    onClickOutside();
  };
  const isSearchVal = searchValue !== '';
  const members = selectedUsers.map(user => parseInt(user.userId, 10));
  const currentMembers = friends.map(user => user.userId);

  return (
    <div className={s.overlay} /*onClick={closeModal}*/>
      <div className={s.modalContainer}>
        <div className={s.modalDialog} onClick={(event) => event.stopPropagation()}>
          <div className={s.modalContent}>
            <header className={s.modalChatHeader}>
              <i className="fas fa-arrow-left" onClick={onModalClose}></i>
              <span className={s.modalTitle}>
                {titles.header}
              </span>
              <button
                type={'submit'}
                className={s.createChat}
                onClick={() => onSubmit({
                  groupId: groupData.id,
                  title: groupName,
                  description: groupDescription,
                  chat_img: groupImage,
                  members,
                })}
              >
                {titles.submitButton}
              </button>
            </header>

            <div className={s.groupChatBody}>
              <div className={s.titleAndPhotoContainer}>
                <div className={s.titleContainer}>
                  <label htmlFor={GROUP_NAME}>
                    Title*
                  </label>
                  <input
                    type={'text'}
                    autoFocus={true}
                    value={groupName.trimLeft()}
                    className={s.searchUserInput}
                    id={GROUP_NAME}
                    maxLength={20}
                    onChange={inputHandleChange}
                    required={true}
                  />
                  {submitted && !groupName.trim() && (
                  <div className={s.usersBlockErrorTitle}>Title is a required field.</div>)}
                </div>

                <div className={s.uploadPhotoContainer}>
                  <label htmlFor={GROUP_IMAGE}>
                    Photo
                  </label>
                  <label className={s.uploadAvatarArea} htmlFor={GROUP_IMAGE}>
                    {imageAsBase64 ? (
                      <UserAvatar src={imageAsBase64} alt="Group Logo"/>
                    ) : (
                      <i className="fas fa-camera"></i>
                    )}
                  </label>
                  <input
                    type={'file'}
                    className={s.uploadPhotoInput}
                    onChange={onFileSelect}
                    name={'avatar'}
                    accept="image/jpeg, image/png, image/jpg"
                    id={GROUP_IMAGE}
                  />
                  { groupImageError && (
                  <div className={s.usersBlockErrorImg}>{messages.MAX_IMG_SIZE}</div>)}
              </div>
                </div>
              <div className={s.descriptionContainer}>
                <label htmlFor={GROUP_DESCRIPTION}>
                  Description
                </label>
                <input
                  type='text'
                  value={groupDescription}
                  className={s.searchUserInput}
                  id={GROUP_DESCRIPTION}
                  onChange={inputHandleChange}
                  maxLength={"50"}
                />
              </div>

              <div className={s.findUsersContainer}>
                <label htmlFor="searchUserInput">
                  {titles.findUsersTitle}
                </label>
                <div className={s.inputContainer}>
                  <i className={cs("fas fa-search", {[`${s.searchIcon}`]: true })} />
                  <input
                    onChange={onUserSearch}
                    className={`${s.searchUserInput} search-with-cross`}
                    // value={searchValue}
                    ref={input}
                    id="searchUserInput"
                    disabled={disableEditUserList ? true : false}
                    placeholder={disableEditUserList ? 'Editing of chat members is disabled at the moment' : ''}
                    maxLength="30"
                  />

                  <div onClick={clearSearchInput} className={`${s.closeButtonContainer}  ${isSearchVal && 'visible'}`}>
                    <div className={s.closeButtonStickOne}></div>
                    <div className={s.closeButtonStickTwo}></div>
                  </div>
                </div>
              </div>
              
              {[...friends, ...selectedUsers].length > 0 && (
                <div className={s.selectedMembersContainer}>
                  <div className={s.overflowUsers}>
                    {
                      [...friends, ...selectedUsers].filter(user => user.userId || user.id).map(user => {
                        const id = user.id || user.userId;
                        const name = user.name || user.fullName;
                        return (<div className={s.selectedUserContainer} key={id}>
                          <div className={s.userImageContainer}>
                            <UserAvatar src={user.profile_image} alt={"user avatar"} />
                          </div>
                          <span className={s.selectedUserName}>
                            {name}
                          </span>
                          {
                            !friends.find(({ userId }) => userId == user.userId) ? (
                              <button className={s.deleteFromSelectedButton} onClick={() => deleteUserFromSelected(user.userId)}>
                                {"×"}
                              </button>
                            ): ('')
                          }
                        </div>
                        )})
                    }
                  </div>
                  <p>
                    <span>
                      {selectedUsers.length > 0 && getSelectedMemberCount()}
                    </span>
                    {selectedUsers.length > 0 && (<button disabled={disableEditUserList} onClick={clearSelectedUserList}>
                      Clear list
                    </button>)}
                  </p>
                </div>
              )}

              {userList.length > 0 && <div className={s.usersBlockTitle}>Users</div>}
              {searchMsg === 'data not found' && (
                <div className={s.usersBlockErrorTitle}>No User found.</div>
              )}
              {submitted && members.length === 0 && currentMembers.length === 0 && (
                <div className={s.usersBlockErrorTitle}>Please select at least one user.</div>)}

              <div className={cs({[`${s.userListContainer}`]: userList.length > 0 && searchValue })}>
                {userList.length > 0 && [userList.filter(user => user.id && !currentMembers.includes(parseInt(user.id, 10)))
                  .map((friend,i) =>
                    (
                      <div className={s.userContainer} key={friend.id} onClick={() => {onUserClick(friend); setSubmitted(false)}}>
                        <div className={cs(s.userImage, {[`${s.selectedUser}`]: selectedUsers.some(user => user.userId == friend.id)})}>
                          <img src={friend.profile_image} alt="avatar" />
                          <i className={cs("fas fa-check", s.checkIcon)}></i>
                        </div>
                        <div className={`${s.userNameContainer} text-overflow-ellipsis`}>
                          <h3 className="text-overflow-ellipsis">{friend.name}</h3>
                          <span>{friend.at_username}</span>
                        </div>
                      </div>
                    )
                  )]
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CreateGroupChat.propTypes = {
  createGroupChat: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  updateGroupChat: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  onClickOutside: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  searchUserChat: PropTypes.func.isRequired,
  userList: PropTypes.array,
  clearSearchResults: PropTypes.func,
  titles: PropTypes.shape({
    header: PropTypes.string,
    submitButton: PropTypes.string,
    findUsersTitle: PropTypes.string,
  }),
  groupData: PropTypes.shape({
    id: PropTypes.string,
    groupName: PropTypes.string,
    groupDescription: PropTypes.string,
    groupImage: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.object),
  }),
};

function getMembersData(members, setMembersData) {
  Object.values(members).forEach(member => {
    if (!member || !member.status) return;

    database.ref('Users')
      .child(member.userId)
      .once('value')
      .then(snap => {
        const userData = snap.val();
        if (!userData) return;
        const {
          id = '',
          name = '',
          full_name = '',
          profile_image = '',
          username = '',
        } = userData;
        const user = {
          id,
          full_name: full_name,
          profile_image,
          username,
        };
        setMembersData((members) => {
          const newMembers = Object.keys(members).map(key => {
            return { ...members[key] };
          });
          newMembers.push(user);
          return newMembers;
        });
      })
      .catch(error => console.warn('getting member detailed info error: ', error));
  });
};

import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';

class SparkVideoSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      isVideoPlayed: true,
    };
  }

  isOnError = () => this.setState({isVideoPlayed: false});

  render(){
    let {sparkVideoData} = this.props;
    let {isVideoPlayed} = this.state;

    return (
      <div className="tweet-right-img">
        {sparkVideoData.postImgData.length > 0 ? (
            sparkVideoData.postImgData.map((postImg,index) =>
              isVideoPlayed ? (
                <ReactPlayer
                  key={index}
                  className='react-player'
                  width='100%'
                  height='100%'
                  url={postImg.file}
                  controls={true}
                  preload="none"
                  playsInline=""
                  poster={postImg.thumbimage}
                  onError={this.isOnError}
                />
              ) : (
                <a href={postImg.file} target="_blank">Download video</a>
              )
            )
          ) : ('')
        }
      </div>
    )
  }
}


function mapStateToProps(state,ownProps){
  return {
    alert: state.alert,
    user: state.authentication.user,
    sparkVideoData: ownProps.sparkVideoData
  };
}
export default connect(mapStateToProps)(SparkVideoSection);

import React, {Component} from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { sparksActions } from '../../../actions';


class CropPopUpModal extends Component {

  constructor(props){
    super(props);

    this.state = {
      comment_text : '',
    };
  }

  CommentPost = (p_id) => {
    const { dispatch } = this.props;
    const { user } = this.props;
    const {comment_text} = this.state;

    if(user && p_id && comment_text){
          const sendRaw = {
            post_id : p_id,
            user_id: user.id,
            comment_text: comment_text,
          }
          dispatch(sparksActions.CommentPost(sendRaw,'home'));

          this.setState({comment_text : ''});
          $('div#reply-modal button.close').click();
    }
    else {
       toast.error(msgs.ENTER_A_COMMENT_TO_REPLY);
    }
  }

  handleChange = (event) => {
      const { name, value } = event.target;
      this.setState({
              [name]: value
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render(){

    const { crop,src,croppedImageUrl } = this.props;

    return (
      <div className="modal fade" id="cropModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Modal title</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {src && (
                <ReactCrop
                  src={src}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              )}
              {croppedImageUrl && (
                <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
              )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state,ownProps){
  return {
    src : ownProps.src,
    croppedImageUrl : ownProps.croppedImageUrl,
    crop : ownProps.crop,
    user: state.authentication.user,
  };
}
export default connect(mapStateToProps)(CropPopUpModal);

import { profileinfoConstants,momentsConstants , sparkConstants} from '../constants';

export function profileinfo(state = {}, action) {
  switch (action.type) {
    case profileinfoConstants.PROFILE_GETALLINFO_REQUEST:
      if(state.data == undefined){
        return {
            loading: true,
            notfoundstatus: false,
        };
      }
      else {
        return state
      }
    case profileinfoConstants.PROFILE_GETALLINFO_SUCCESS:
      return {
        data: action.profileinfo,
        notfoundstatus: false,
      };
    case profileinfoConstants.PROFILE_GETALLINFO_FAILURE:
      return {
        error: action.error,
        notfoundstatus: true,
      };
      case momentsConstants.DELETE_MOMENT_REQUEST:
        return {
            ...state,
            loading: true,
        };
      case momentsConstants.DELETE_MOMENT_SUCCESS:
        return {
          ...state,
          data :{
            ...state.data, myMoments: state.data.myMoments.filter(moment => moment.moment_id !== action.momentdata.moment_id),
          }
        };
      case momentsConstants.DELETE_MOMENT_FAILURE:
        return {
          ...state,
          error: action.error
      };
      case sparkConstants.BLOCK_USER_REQUEST:
        return {
          ...state,
        };
      case sparkConstants.BLOCK_USER_SUCCESS:
        const blockByYou = action.blockData.block_userdata.status == 1 ? true : false;
        return {
          ...state,
          data: {...state.data, blockByYou }
        };
      case sparkConstants.BLOCK_USER_FAILURE:
        return {
          ...state,
          error: action.error,
        };
      case profileinfoConstants.DECREASE_SPARKS_COUNT:
        return {
          ...state,
          data: {
            ...state.data,
            totalSparks: state.data ? +state.data.totalSparks - 1: 0,
          },
        };
      case profileinfoConstants.CHANGE_TOTAL_LIKES_COUNT:
        const countChanger = action.likedata.like_status == 0 ? -1 : 1;
        return {
          ...state,
          data: {
            ...state.data,
            totalLikes: state.data ? +state.data.totalLikes + countChanger: 0,
          },
        };
    default:
    return state
  }
}

import { sparkConstants } from '../constants';

export function votespark(state = {}, action) {
  switch (action.type) {
    case sparkConstants.VOTESPARKS_REQUEST:
      return {
        loading: true,
        votestatus: false,
      };
    case sparkConstants.VOTESPARKS_SUCCESS:
      return {
        loading: false,
        votestatus: true,
      };
    case sparkConstants.VOTESPARKS_FAILURE:
      return {
        error: action.error,
        loading: false,
        votestatus: false,
      };
    default:
      return state
  }
}

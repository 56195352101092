import React, { useCallback, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import withProps from '../../../HOCs/withProps';
import {
  userData as userDataPropType,
  tickNum as tickNumPropType,
  sendingAnswersError as sendingAnswersErrorPropType,
  isSendingAnswersInProgress as isSendingAnswersInProgressPropType,
} from '../propTypes';
import { checkUsername } from '../../../api/registration';
import s from './personal-data.module.scss';
import {useFormik} from 'formik';
import { isStrongPassword, passwordValidatorErrorsCodes } from '../../../misc/validators';
import { PASSWORD_RESTRICTIONS } from '../../../config';
import debounce from 'lodash.debounce';

import { SimpleInput, PasswordInput } from '../../common/input';
import msgs from '../../../messages/en/main';

function PersonalData(props) {
  const {
    checkUsername, initAnswers, saveAnswers, setNextDisabled, showErrorPopup, onDone, sendingAnswersError,
    isSendingAnswersInProgress, onBack, nextDisabled, referralHash, userData,token,
  } = props;

  const [usernameAvailability] = useState(new Map());
  const [, setTick] = useState(0);
  const [isLoading, setIsLoading] = useState(false)

  const {
    username = '',
    firstname = '',
    lastname = '',
    password = '',
  } = initAnswers;
  const formik = useFormik({
    initialValues: {
      username,
      firstname,
      lastname,
      password,
      confirm_password: password || '',
      policy_accepted: false,
      token
    },
    onSubmit: (values, formikHelpers) => {
      const {
        username: _username, firstname: firstName, lastname: lastName,
        password, confirm_password, policy_accepted,token
      } = values;

      setIsLoading(true);

      const username = _username.trim();
      const firstname = firstName.trim();
      const lastname = lastName.trim();

      const errors = {
        username: validateUsername(username),
        firstname: validateFirstName(firstname),
        lastname: validateLastName(lastname),
        password: validatePassword(password),
        confirm_password: validateConfirmPassword(password, confirm_password),
        // confirm_password: password ? validateConfirmPassword(password, confirm_password) : '',
        policy_accepted: validateRequiredInput(policy_accepted),
      };

      formikHelpers.setErrors(errors);
      if (hasError(errors)) {
        setIsLoading(false);
        return;
      }

      const lowerCaseUsername = username.toLowerCase();

      if (usernameAvailability.get(lowerCaseUsername) === false) {
        setIsLoading(false);
        return;
      }

      let usernameAvailabilityPromise;
      if (usernameAvailability.has(lowerCaseUsername)) {
        usernameAvailabilityPromise = Promise.resolve({data: true});
      } else {
        usernameAvailabilityPromise = checkUsername(lowerCaseUsername);
      }

      usernameAvailabilityPromise
        .then(({data: status}) => {
          if (!status) {
            usernameAvailability.set(lowerCaseUsername, false);
            throw new Error(`username "${username}" is not available`);
          }
        })
        .then(() => {
          //TODO save data and go to the next step
          //throw new Error('not implemented')
          return saveAnswers({username, firstname, lastname, password, referralHash,token});
        })
        .then(onDone)
        .catch(error => {
          //TODO
          // or show sendingAnswersError
          if (error !== 'TypeError: Failed to fetch') {
            if(!navigator.onLine) {
              return showErrorPopup(msgs.NO_INTERNET_CONNECTION);
            }
            showErrorPopup('The username has already been taken. Please login.');
            console.error('registration PersonalData step saving data error: ', error);
          }
        })
        .finally(() => setIsLoading(false));
    },
  });

  const debouncedCheckUsername= useCallback(debounce((username) => {
    username = username.trim();
    if (validateUsername(username)) {
      return;
    }

    username = username.toLowerCase();
    if (usernameAvailability.has(username)) return;

    checkUsername(username)
      .then(({ data: status }) => {
        usernameAvailability.set(username, status);
        setTick((v) => (++v));
      })
      .catch(error => {});
  }, 1000), [checkUsername]);

  useEffect(() => () => {
    if (typeof debouncedCheckUsername.cancel === 'function') {
      debouncedCheckUsername.cancel();
    }
  }, [debouncedCheckUsername]);

  const onUsernameChange = useCallback((e) => {
    const { target: { value } = {} } = e;
    formik.setFieldValue('username', value);
    debouncedCheckUsername(value);
  } , [formik.setFieldValue]);

  return (
    <form onSubmit={formik.handleSubmit}>
        <div className={s.titleContainer}>
          <h4>ACCOUNT INFORMATION</h4>
        </div>
        {token && <input type='hidden' name='token' value={token || ''}/>}

        <div className={s.nameContainer}>
          <SimpleInput
            value={formik.values.firstname}
            type={'text'}
            name="firstname"
            id={'inputFirstname'}
            placeholder={'* First Name'}
            onChange={formik.handleChange}
            className={s.input}
            maxLength={namesMaxLength}
            errMessage={formik.errors.firstname}
          />
          <SimpleInput
            value={formik.values.lastname}
            type={'text'}
            name="lastname"
            id={'inputLastname'}
            placeholder={'* Last Name'}
            onChange={formik.handleChange}
            className={s.input}
            maxLength={namesMaxLength}
            errMessage={formik.errors.lastname}
          />
        </div>

        <div className={s.usernameContainer}>
          <SimpleInput
            value={formik.values.username.trim()}
            type="text"
            name="username"
            id={'inputUsername'}
            placeholder={'* Username'}
            onChange={onUsernameChange}
            className={s.input}
            errMessage={formik.errors.username}
            maxLength={usernameMaxLength}
            minLength={4}
            tooltip="Minimum four characters, only letters and numbers are available without spaces"
          />
          {usernameAvailability.get((formik.values.username.trim()).toLowerCase()) === true && (
            <p className={s.userAvailable}>@{formik.values.username} <br />is available.</p>
          )}
          {usernameAvailability.get((formik.values.username.trim()).toLowerCase()) === false && (
            <p className={s.userNotAvailable}>@{formik.values.username} <br />is not available.</p>
          )}
        </div>

        <div className={s.passwordContainer}>
          <PasswordInput
            value={formik.values.password}
            type="password"
            name="password"
            id={'__password'}
            placeholder={'* Password'}
            onChange={formik.handleChange}
            className={s.input}
            errMessage={formik.errors.password}
            showTooltipForPass={true}
            tooltip="Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character from ( @$!%*?&# )"
            tooltipPlace="bottom"
          />
          <PasswordInput
            value={formik.values.confirm_password}
            type="password"
            name="confirm_password"
            id="confirmPassword"
            placeholder={'* Confirm password'}
            onChange={formik.handleChange}
            className={s.input}
            errMessage={formik.errors.confirm_password}
          />
        </div>
        
        <div className={s.policyContainer}>
          <input
            type="checkbox"
            id={'policy-accept'}
            name="policy_accepted"
            onClick={formik.handleChange}
            value={formik.values.policy_accepted}
          />
          <label htmlFor={'policy-accept'}>
            I accept 
            <Link to="/pages/privacy-policy" target="_blank">Policy</Link> &
            <Link to="/pages/terms" target="_blank">Terms</Link>
          </label>
          {formik.errors.policy_accepted && <div className={s.errAcceptTerms}>Please accept the Policy & Terms to proceed</div>}
        </div>

        <div className={s.submitContainer}>
          <button className="float-left" type="submit" disabled={isLoading}>
            <span>Next </span>
            {!isLoading
              ? (<i className="fas fa-angle-right"/>)
              : (<i className="fas fa-spin fa-spinner"/>)
            }
          </button>
        </div>
    </form>
  );
}

PersonalData.propTypes = {
  userData: userDataPropType.isRequired,
  saveAnswers: PropTypes.func.isRequired,
  checkUsername: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  setNextDisabled: PropTypes.func.isRequired,
  initAnswers: PropTypes.object.isRequired,
  tickNum: tickNumPropType.isRequired,
  nextDisabled: PropTypes.bool.isRequired,
  sendingAnswersError: sendingAnswersErrorPropType.isRequired,
  isSendingAnswersInProgress: isSendingAnswersInProgressPropType.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default withProps({checkUsername})(PersonalData);

function hasError(errors) {
  return Object.values(errors).find(i => !!i);
}

function validateRequiredInput(value) {
  let error;
  if (!value) {
    error = 'Required';
  }
  return error;
}

const namesMaxLength = 30;

function validateFirstName(value) {
  if (validateRequiredInput(value)) {
    return 'First name is required';
  }
  if (value.length > namesMaxLength) {
    return `Maximum number of characters ${namesMaxLength}`;
  }
}

function validateLastName(value) {
  if (validateRequiredInput(value)) {
    return 'Last name is required';
  }
  if (value.length > namesMaxLength) {
    return `Maximum number of characters ${namesMaxLength}`;
  }
}

const usernameMinLength = 4;
const usernameMaxLength = 30;

function validateUsername(value) {
  if (validateRequiredInput(value)) {
    return 'Username is required';
  }

  const re = /^[a-zA-Z0-9]+$/;
  if (!re.test(String(value))) {
    return 'Username is invalid. Allowed only latin letters and numbers';
  }

  if (value.length < usernameMinLength) {
    return `Minimum number of characters ${usernameMinLength}`;
  }
  if (value.length > usernameMaxLength) {
    return `Maximum number of characters ${usernameMaxLength}`;
  }
}


export const passwordValidationErrorsCodesToMessages = {
  [passwordValidatorErrorsCodes.min]: `Has to be at least ${PASSWORD_RESTRICTIONS.MIN_LENGTH} characters`,
  [passwordValidatorErrorsCodes.max]: `Has to be no more than ${PASSWORD_RESTRICTIONS.MAX_LENGTH} characters`,
  [passwordValidatorErrorsCodes.lowercase]: 'Has to contain at least one lowercase letter',
  [passwordValidatorErrorsCodes.uppercase]: 'Has to contain at least one uppercase letter',
  [passwordValidatorErrorsCodes.symbols]: 'Has to contain at least one special character ( @$!%*?&# )',
  [passwordValidatorErrorsCodes.digits]: 'Has to contain at least one digit',
  [passwordValidatorErrorsCodes.spaces]: 'Must not contain spaces',
  [passwordValidatorErrorsCodes.has]: 'Can contain only latin and special characters',
};

function validatePassword(value) {
  let error;
  if (!value) {
    error = 'Password is required';
  } else {
    const result = isStrongPassword(value);
    if (Array.isArray(result) && result.length) {
      error = passwordValidationErrorsCodesToMessages[result[0]] || 'invalid';
    }
  }

  return error;
}


function validateConfirmPassword(password, confirmPassword) {
  let error;
  if (password && !confirmPassword) {
    error = 'Confirm password is required';
  }  else if (password === '') {
    error = 'Password does not match';
  } else if (password && password !== confirmPassword) {
    error = 'Password does not match';
  }
  return error;
}

import PropTypes from 'prop-types';
import { SINGLE_CHAT_TYPE, GROUP_CHAT_TYPE } from '../constants';

export const userId = PropTypes.number;

export const user = PropTypes.shape({
  email: PropTypes.string.isRequired,
  id: userId.isRequired,
  username: PropTypes.string.isRequired,
  at_username: PropTypes.string.isRequired,
  name: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  gender: PropTypes.string.isRequired,
  banner_image: PropTypes.string,
  status: PropTypes.number.isRequired,//"1"
  state: PropTypes.string,
  zipcode: PropTypes.string,
  address: PropTypes.string,
  address_2: PropTypes.string,
  city: PropTypes.string,
  created: PropTypes.string, //.isRequired,//"2020-04-02 13:37:30"
  dob: PropTypes.string,
  email_verify: PropTypes.number.isRequired,//"1"
  encode_email: PropTypes.string.isRequired,//"ZG1pdHJpeS5ib3lldkBpLnVh"
  encode_phone: PropTypes.string.isRequired,//"KzE1NDE3MDgzMjc1"
  expire_plan_sts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,//"1"
  isPrivate: PropTypes.string.isRequired,//"0"
  phone_number: PropTypes.string.isRequired,//"+15417083275"
  phone_verify: PropTypes.number.isRequired,//"1"
  plan_expiry: PropTypes.string.isRequired,//"2020-10-03 00:00:00"
  plan_id: PropTypes.number, //.isRequired,
  profile_image: PropTypes.string,//"http://..
  showObjectionableContent: PropTypes.bool, //.isRequired,
  totalFollowers: PropTypes.number, //.isRequired,
  totalFollowings: PropTypes.number, //.isRequired,
  totalSparks: PropTypes.number, //.isRequired,
  user_bio: PropTypes.string,
  website_url: PropTypes.string,
});

export const userData = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object,
});

export const singleChatData = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  chat_img: PropTypes.string.isRequired,
  chatType: PropTypes.oneOf([1]),
  lastMessage: PropTypes.string,
  notShow: PropTypes.bool,
});

/*
  id: <chat_id>,
  chatType: 1,
  name: "Rapan Mideevich",
  description: PropTypes.string,
  //groupId: PropTypes.string.isRequired,//"-M5vPGqwQf8uzSWc1F3w"
  chat_img: PropTypes.string.isRequired,//"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD"
  members: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      profile_image: PropTypes.string.isRequired,
    })
  ).isRequired, //members: [{ userId:1648,userName:'rapan',fullName:'Rapan Midieevich',profile_image:'...' }]
  notShow: PropTypes.bool,
 */

export const groupChatData = PropTypes.shape({
  id: PropTypes.number.isRequired,
  chatType: PropTypes.oneOf([0]),
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  chat_img: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      profile_image: PropTypes.string.isRequired,
    })
  ).isRequired, //members: [{ userId:1648,userName:'rapan',fullName:'Rapan Midieevich',profile_image:'...' }]
  notShow: PropTypes.bool,
});

/*
  id: PropTypes.number.isRequired,
  chatType: PropTypes.oneOf([0]),
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  chat_img: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      profile_image: PropTypes.string.isRequired,
    })
  ).isRequired, //members: [{ userId:1648,userName:'rapan',fullName:'Rapan Midieevich',profile_image:'...' }]
  notShow: PropTypes.bool,
}*/

export const message = PropTypes.shape({
  userId: PropTypes.number.isRequired,//author
  mediaUrl: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  text: PropTypes.string.isRequired,
  seen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  timestamp: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  }),
});

export const isUndefined = (props, propName) => {
  const { propName: data } = props;

  if (data === undefined) return;

  return new Error(`${propName} must be an on of user propTypes or undefined`);
};

import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import withProps from '../../../HOCs/withProps';
import {
  checkPhoneOccupied as checkPhoneOccupiedReq, setPhoneIsVerified,
  checkEmailOccupied as checkEmailOccupiedReq, setEmailIsVerified,
  deleteFirebaseAuthUser,
} from '../../../api/registration';
import { getUserData } from '../../../api/userData';
import useFirebaseVerification, { ERRORS_CODES } from '../useFirebaseVerification';
import { EmailPopup, PhonePopup } from '../../../containers/frontend/common/VerifyPopup';
import {
  userData as userDataPropType,
} from '../propTypes';
import PhoneInput from '../../../containers/frontend/common/PhoneInput';
import { SimpleInput } from '../../common/input';
import s from './identity-data.module.scss';
import { phoneValidate as validatePhone } from '../../../helpers';
import { validateEmail as validateEmailPattern } from '../../../misc/validators';
import { getPassword, setPassword, decrypt as decryptPassword, encrypt as encryptPassword } from '../../../services/credentialStorage';
import {
  getUserEmailBunch, decrypt as decryptUserEmailBunch, encrypt as encryptUserEmailBunch,
  stringifyValue as stringifyUserEmailBunch, parseValue as parseUserEmailBunch,
} from '../../../services/userEmailBunchStorage';
import get from 'lodash.get';
import { AFTER_EMAIL_VERIFICATION_REDIRECT_API_URL } from '../../../config';
import { APP2_URL } from '../../../mainurl';
import msgs from '../../../messages/en/main';

const {
  PHONE_NUMBER_IS_OCCUPIED,
  SENDING_SMS_ERROR,
  PHONE_NUMBER_IS_INVALID,
  EMAIL_IS_OCCUPIED,
  INVALID_PHONE_VERIFICATION_CODE,
  EMAIL_VERIFICATION_TOO_MANY_REQUESTS,
  PHONE_VERIFICATION_TOO_MANY_REQUESTS,
} = ERRORS_CODES;

const mapErrorsCodesToMessages = {
  [PHONE_NUMBER_IS_OCCUPIED]: 'Phone number is already taken',
  [SENDING_SMS_ERROR]: 'SMS sending error',
  [PHONE_NUMBER_IS_INVALID]: 'Phone number is invalid',
  [EMAIL_IS_OCCUPIED]: 'Email is already taken',
  [INVALID_PHONE_VERIFICATION_CODE]: 'SMS verification code is invalid',
  [EMAIL_VERIFICATION_TOO_MANY_REQUESTS]: 'Email has been sent. Too many attempts, please try later.',
  [PHONE_VERIFICATION_TOO_MANY_REQUESTS]: 'Too many attempts for this phone number , please try later.',
};

const PHONE_POPUP = 'phone';
const EMAIL_POPUP = 'email';

const SEND_CODE_MAX_ATTEMPTS_NUMBER = 3;

const EMAIL_VERIFIED_ON_SERVER_CHECKER_TIMEOUT_DELAY = 3000;
const EMAIL_VERIFIED_ON_FIREBASE_CHECKER_TIMEOUT_DELAY = 3000;


function validateEmail(email) {
  if (!email) {
    return 'Email is required';
  }
  const maxLength = 180;
  if (email.length > maxLength) {
    return `Max email length is ${maxLength} symbols`;
  }

  if (!validateEmailPattern(email)) {
    return 'Email is invalid';
  }
}

async function checkPhoneOccupied(phoneNumber) {
  const { data: status } = await checkPhoneOccupiedReq(phoneNumber);
  return status;
}

async function checkEmailOccupied(email) {
  const { data: status } = await checkEmailOccupiedReq(email);
  return status;
}

async function checkEmailVerificationData() {
  const { data: { email, email_verify, } = {} } = await getUserData();
  return {email, emailVerified: email_verify || false};
}

function getAfterEmailVerificationRedirectUrl(userId, email, uid) {
  const userEmailBunch = encryptUserEmailBunch(stringifyUserEmailBunch(userId, email, uid));
  return APP2_URL + AFTER_EMAIL_VERIFICATION_REDIRECT_API_URL + '/' + userEmailBunch;
}

const recaptchaContainerId = 'recaptchaContainerId';

function IdentityData(props) {
  const {
    checkPhoneOccupied, userData, setPhoneIsVerified, checkEmailOccupied, setEmailIsVerified,
    initAnswers, saveAnswers, showErrorPopup, onDone, answers, token, onAbortRegistration
  } = props;

  const { data: { id: userId } } = userData;

  const [isLoading, setIsLoading] = useState(false);
  const [isCloseRegistration, setIsCloseRegistration] = useState(false);

  const emailVerifiedOnFirebaseCheckerTimeoutRef = useRef(null);
  const emailVerifiedOnServerCheckerTimeoutRef = useRef(null);

  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState(null);
  const [prevInvalidPhoneNumber, setPrevInvalidPhoneNumber] = useState(null);
  const [verifyPhoneButtonDisable, setVerifyPhoneButtonDisable] = useState(true);
  const [sendCodeAttemptsNumber, setSendCodeAttemptsNumber] = useState(0);

  const [isPhoneVerificationInProgress, setIsPhoneVerificationInProgress] = useState(false);
  const [isSendingSmsCodeAgainDisabled, setIsSendingSmsCodeAgainDisabled] = useState(false);
  const [clearSmsCodeInputCounter, setClearSmsCodeInputCounter] = useState(0);

  const [isEmailVerificationInProgress, setIsEmailVerificationInProgress] = useState(false);
  const [emailVerificationInitializedForUid, setEmailVerificationInitializedForUid] = useState({});

  const [passwordFromStorage] = useState(() => {
    try {
      const password = getPassword(true)
      return password ? decryptPassword(password) : '';
    } catch (e) {
      console.error('decryption password from storage error: ', e);
      return '';
    }
  });

  const [userEmailBunchFromStorage] = useState(() => {
    const encryptedUserEmailBunch = getUserEmailBunch();
    if (!encryptedUserEmailBunch) {
      return {};
    }
    try {
      const userEmailBunch =  parseUserEmailBunch(decryptUserEmailBunch(encryptedUserEmailBunch));
      if (typeof get(userEmailBunch, 'userId') === 'undefined' || typeof get(userEmailBunch, 'email') === 'undefined') {
        throw new Error('userEmailBunch from localStorage has incorrect structure');
      }
      return userEmailBunch;
    } catch (e) {
      console.error('decryption of UserEmailBunch from storage error: ', e);
      return {};
    }
  });

  const emailFromStorage = (() => {
    const {userId: userIdFromStorage, email: emailFromStorage} = userEmailBunchFromStorage || {};
    return userId === userIdFromStorage ? emailFromStorage : '';
  })();

  const { initPhoneNumber, initPhoneVerified, initEmail, initEmailVerified, initCountryCode, password } = (() => {
    const { phone_number, phone_verify, email, email_verify, country_phone_code, } = initAnswers;
    const { password, } = answers;

    let initCountryCode = country_phone_code + '' || '1';
    if (initCountryCode.startsWith('+')) {
      initCountryCode = initCountryCode.slice(1);
    }

    let initPhoneNumber = phone_number || '';
    if (country_phone_code && initPhoneNumber) {
      if (initPhoneNumber.startsWith(initCountryCode)) {
        initPhoneNumber = initPhoneNumber.slice(initCountryCode.length);
      } else if (initPhoneNumber.startsWith('+' + initCountryCode)) {
        initPhoneNumber = initPhoneNumber.slice(('+' + initCountryCode).length);
      }
    }

    return {
      initPhoneNumber,
      initPhoneVerified: phone_verify || false,
      initEmail: email || emailFromStorage || '',
      initEmailVerified: email_verify || false,
      initCountryCode,
      password,
    };
  })();

  const {
    initFirebase,
    initPhoneVerification,
    getCurrentAuthUser: getCurrentFirebaseAuthUser,
    verifyEmail,
    signInWithEmailAndPassword,
  } = useFirebaseVerification({checkPhoneOccupied, recaptchaContainerId, checkEmailOccupied});

  useEffect(() => {
    if (password) {
      setPassword(encryptPassword(password));
    }
  }, []);

  const finalPassword = password || passwordFromStorage;

  useEffect(() => {
    if (!emailVerified) {
      startEmailVerifiedOnServerChecker();
    }
  }, []);

  const performSettingEmailIsVerified = useCallback(async (email, uid) => {
    try {
      setIsEmailVerificationInProgress(true);
      //TODO go pass through redux to update userData
      const {data: {email_verify}} = await setEmailIsVerified({email, uid});
      if (email_verify) {
        setEmailVerified(true);
        setEmail(email);
        setEmailValidationError('');
      }
    } catch (e) {
      console.error('performSettingEmailIsVerified setEmailIsVerified error', e);
    }
    setIsEmailVerificationInProgress(false);
  }, [setEmailIsVerified]);

  useEffect(() => {
    (async () => {
      try {
        const email = initEmail || emailFromStorage;
        //init firebase
        await initFirebase(email, finalPassword);

        if (email && finalPassword && !emailVerified) {
          const currentFirebaseAuthUser = getCurrentFirebaseAuthUser();
          if (!currentFirebaseAuthUser.emailVerified) return;

          await performSettingEmailIsVerified(currentFirebaseAuthUser.email, currentFirebaseAuthUser.uid);
        }
      } catch (e) {
        //TODO let user know
        console.error('init firebase error', e);
      }
    })();
  }, []);

  // phoneNumber without country code
  const [phoneNumber, setPhoneNumber] = useState(initPhoneNumber);
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [phoneVerified, setPhoneVerified] = useState(initPhoneVerified);
  const [country] = useState('us');
  // countryCode without "+" sign
  const [countryCode, setCountryCode] = useState(initCountryCode);
  const [smsCodeIsWrong, setSMSCodeIsWrong] = useState(false);

  const [email, setEmail] = useState(initEmail || emailFromStorage || '');
  const [emailVerified, setEmailVerified] = useState(initEmailVerified);
  const [emailValidationError, setEmailValidationError] = useState('');

  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const [sendingSms, setSendingSms] = useState(false);

  const clearEmailVerifiedOnFirebaseCheckerTimeout = useCallback(() => {
    clearTimeout(emailVerifiedOnFirebaseCheckerTimeoutRef.current);
  }, []);

  const clearEmailVerifiedOnServerCheckerTimeout = useCallback(() => {
    clearTimeout(emailVerifiedOnServerCheckerTimeoutRef.current);
  }, []);

  useEffect(() => {
    clearEmailVerifiedOnServerCheckerTimeout();
    clearEmailVerifiedOnFirebaseCheckerTimeout();
  }, []);

  useEffect(() => {
    if (emailVerified) {
      clearEmailVerifiedOnServerCheckerTimeout();
      clearEmailVerifiedOnFirebaseCheckerTimeout();
    }
  }, [emailVerified]);

  const startEmailVerifiedOnFirebaseChecker = useCallback(() => {
    clearEmailVerifiedOnFirebaseCheckerTimeout();

    if (emailVerified) return;

    console.log('startEmailVerifiedOnFirebaseChecker');

    emailVerifiedOnFirebaseCheckerTimeoutRef.current = setTimeout(async () => {
      const firebaseAuth = getCurrentFirebaseAuthUser();
      if (firebaseAuth && typeof firebaseAuth.reload === 'function') {
        try {
          await firebaseAuth.reload();
          const reloadedFirebaseAuth = getCurrentFirebaseAuthUser();

          if (reloadedFirebaseAuth.emailVerified) {
            await performSettingEmailIsVerified(reloadedFirebaseAuth.email, reloadedFirebaseAuth.uid);
          } else {
            startEmailVerifiedOnFirebaseChecker();
          }
        } catch (e) {
          console.error('startEmailVerifiedOnFirebaseChecker firebaseAuth.reload error or performSettingEmailIsVerified error: ', e);
        }
      } else {
        console.warn('startEmailVerifiedOnFirebaseChecker error: firebaseAuth.reload is not a function');
        startEmailVerifiedOnFirebaseChecker();
      }
    }, EMAIL_VERIFIED_ON_FIREBASE_CHECKER_TIMEOUT_DELAY);
  }, [getCurrentFirebaseAuthUser, clearEmailVerifiedOnFirebaseCheckerTimeout, performSettingEmailIsVerified, emailVerified]);

  const startEmailVerifiedOnServerChecker = useCallback(() => {
    console.log('startEmailVerifiedOnServerChecker')
    clearEmailVerifiedOnServerCheckerTimeout();

    emailVerifiedOnServerCheckerTimeoutRef.current = setTimeout(async () => {
      const { email, emailVerified } = await checkEmailVerificationData();
      if (emailVerified) {
        setEmail(email);
        setEmailVerified(true);
        setEmailValidationError('');

        const firebaseAuth = getCurrentFirebaseAuthUser();
        if (firebaseAuth && typeof firebaseAuth.reload === 'function') {
          try {
            await firebaseAuth.reload();
          } catch (e) {
            console.error('checkEmailVerificationData performed, email is verified but firebaseAuth.reload error: ', e);
          }
        } else if (finalPassword) {
          try {
            await signInWithEmailAndPassword(email, finalPassword);
          } catch (e) {
            console.error('checkEmailVerificationData performed, email is verified but signInWithEmailAndPassword error: ', e);
          }
        }
      } else {
        startEmailVerifiedOnServerChecker();
      }
    }, EMAIL_VERIFIED_ON_SERVER_CHECKER_TIMEOUT_DELAY);
  }, [getCurrentFirebaseAuthUser, clearEmailVerifiedOnServerCheckerTimeout, signInWithEmailAndPassword, finalPassword]);

  const onPhoneChange = useCallback((value, code = countryCode) => {
    setPhoneNumber(value);
    setCountryCode(code);
    setVerifyPhoneButtonDisable(!value);
    setPhoneNumberError('');
  }, []);

  const showPopup = useCallback((content) => {
    //setShowVerifyPopup(v => !v);
    setShowVerifyPopup(true);
    setPopupContent(content);
  }, []);

  const onCloseVerifyPopup = useCallback(() => {
    setShowVerifyPopup(false);
    setPopupContent('');
    if (PHONE_POPUP === popupContent) {
      setIsPhoneVerificationInProgress(false);
      setIsSendingSmsCodeAgainDisabled(false);
      setSMSCodeIsWrong(false);
    }
  }, [popupContent]);

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
    setEmailValidationError('');
  }, []);


  const initVerifyTelephone = useCallback(async () => {
    if(!navigator.onLine) {
      return showErrorPopup(msgs.NO_INTERNET_CONNECTION);
    }

    if (phoneVerified || isPhoneVerificationInProgress ) {
      return;
    }

    setClearSmsCodeInputCounter(v => (++v));
    setVerifyPhoneNumber(null);
    setSendCodeAttemptsNumber(0);

    try {
      if (!userId) {
        throw new Error('User is not exist. Go registration from the first step, please');
      }
      const code = typeof countryCode === "object" ? countryCode.dialCode : countryCode;
      const fullPhoneNumber = phoneNumber.startsWith(code) ? `+${phoneNumber}` : `+${code}${phoneNumber}`;
    
      if (!validatePhone(fullPhoneNumber)) {
        console.error('Please enter a valid mobile number fullPhoneNumber',fullPhoneNumber);
        setPhoneNumberError('Please enter a valid mobile number');
        setPrevInvalidPhoneNumber(fullPhoneNumber);
        setVerifyPhoneButtonDisable(true);
        return;
      }

      setIsSendingSmsCodeAgainDisabled(true);
      setIsPhoneVerificationInProgress(true);
      const { verifyPhoneNumber } = await initPhoneVerification(fullPhoneNumber);

      setVerifyPhoneNumber(() => verifyPhoneNumber);

      showPopup(PHONE_POPUP);
    } catch (e) {
      setIsPhoneVerificationInProgress(false);
      const message = e.code && mapErrorsCodesToMessages[e.code] ? mapErrorsCodesToMessages[e.code] : 'Something went wrong. Phone verification error';
      showErrorPopup(message);

      if (e.code === PHONE_NUMBER_IS_INVALID) {
        setPhoneNumberError('Please enter a valid mobile number');
        setVerifyPhoneButtonDisable(true);
      }

      if (e.code === PHONE_NUMBER_IS_OCCUPIED) {
        setPhoneNumberError(message);
        setVerifyPhoneButtonDisable(true);
      }
      console.error('initPhoneVerification error', e);
    }
    setIsSendingSmsCodeAgainDisabled(false);
  }, [phoneNumber, countryCode, initPhoneVerification, userId, showPopup, prevInvalidPhoneNumber]);

  const sendSmsCode = useCallback(async (smsCode) => {
    if(!navigator.onLine) {
      return showErrorPopup(msgs.NO_INTERNET_CONNECTION);
    }

    setSendingSms(true);
    if (typeof verifyPhoneNumber !== 'function') {
      console.error('verifyPhoneNumber is not function');
      setSendingSms(false);
      setIsPhoneVerificationInProgress(false);
      return;
    }
    if (sendCodeAttemptsNumber >= SEND_CODE_MAX_ATTEMPTS_NUMBER) {
      console.error('sendSmsCode error: exceeded maximum number of attempts to send a code');
      setSendingSms(false);
      return;
    }
    let result;
    try {
      const _verifyPhoneNumber = verifyPhoneNumber;
      if (sendCodeAttemptsNumber + 1 >= SEND_CODE_MAX_ATTEMPTS_NUMBER) {
        setVerifyPhoneNumber(null);
      }

      setSendCodeAttemptsNumber(v => (++v));
      result = await _verifyPhoneNumber(smsCode);
      setSendingSms(false);
    } catch (e) {
      const message = e.code && mapErrorsCodesToMessages[e.code] ? mapErrorsCodesToMessages[e.code] : 'Something went wrong. Phone verification error';
      showErrorPopup(message);
      setSMSCodeIsWrong(true);
      setIsPhoneVerificationInProgress(false);
      setIsSendingSmsCodeAgainDisabled(false);
      setSendingSms(false);
      return;
    }

    const user = result.user;
    const verifiedPhoneNumber = user.phoneNumber;
    const verifiedPhoneNumberWithoutPlus = verifiedPhoneNumber.startsWith('+') ? verifiedPhoneNumber.slice(1) : verifiedPhoneNumber;

    const data = {
      user_id: userId,
      uid: user.uid,
      phone_number: verifiedPhoneNumberWithoutPlus,
      country_phone_code: countryCode,
    };

    try {
      const result = await setPhoneIsVerified(data);
      const {data: {phone_verify} = {}} = result;
      setSendingSms(false);
      if (phone_verify) {
        setPhoneVerified(true);
        setShowVerifyPopup(false);
      } else {
        console.error('setPhoneIsVerified phone_verify === false, result=',result)
        throw new Error('setPhoneIsVerified error');
      }
    } catch (e) {
      //TODO
      console.error('setPhoneIsVerified error=',e);
      setIsSendingSmsCodeAgainDisabled(false);
      setSendingSms(false);
      showErrorPopup('Something went wrong. Phone verification error');
    }
    setIsPhoneVerificationInProgress(false);
    onCloseVerifyPopup();

    if (verifiedPhoneNumberWithoutPlus.startsWith(countryCode)) {
      const phoneNumber = verifiedPhoneNumberWithoutPlus.slice(countryCode.length)
      setPhoneNumber(phoneNumber);
    } else {
      console.error('verifiedPhoneNumber and entered phoneNumber mismatch, entered countryCode does not match that one of verifiedPhoneNumber');
    }
    setSendingSms(false);
  }, [countryCode, userId, showErrorPopup, verifyPhoneNumber, sendCodeAttemptsNumber]);

  const onEmailVerify = useCallback(async () => {
    if(!navigator.onLine) {
      return showErrorPopup(msgs.NO_INTERNET_CONNECTION);
    }

    if (!phoneVerified || emailVerified || isEmailVerificationInProgress) {
      return;
    }

    const emailValidationError = validateEmail(email);
    if (emailValidationError) {
      setEmailValidationError(emailValidationError);
      return;
    }

    try {
      setIsEmailVerificationInProgress(true);

      const getAfterVerificationUrl = (currentFirebaseAuthUser) => {
        const uid = currentFirebaseAuthUser.uid;
        return getAfterEmailVerificationRedirectUrl(userId, email, uid);
      };

      if (emailVerificationInitializedForUid.email && emailVerificationInitializedForUid.email !== email) {
        //in case when a user changes entered email after he has requested email verification (during this tab session)
        await deleteFirebaseAuthUser(emailVerificationInitializedForUid.uid);
      }

      await verifyEmail({email, password: finalPassword, getAfterVerificationUrl});
      const { uid } = getCurrentFirebaseAuthUser() || {};
      if (uid) {
        setEmailVerificationInitializedForUid({email, uid});
      } else {
        setEmailVerificationInitializedForUid({});
        console.warn('Email verifying was init but currentFirebaseAuthUser.uid is empty');
      }

      startEmailVerifiedOnServerChecker();
      startEmailVerifiedOnFirebaseChecker();

      showPopup(EMAIL_POPUP);
    } catch (e) {
      const message = e.code && mapErrorsCodesToMessages[e.code] ? mapErrorsCodesToMessages[e.code] : 'Something went wrong. Email verification error';
      showErrorPopup(message);
      setEmailValidationError(message);
      console.error('verifyEmail error', e);
    }
    setIsEmailVerificationInProgress(false);
  }, [
    emailVerificationInitializedForUid,
    phoneVerified,
    email,
    phoneVerified,
    isEmailVerificationInProgress,
    userId,
    finalPassword,
    verifyEmail,
    showPopup,
    getCurrentFirebaseAuthUser,
  ]);

  const onSubmit = useCallback(async() => {
    if(!navigator.onLine) {
      return showErrorPopup(msgs.NO_INTERNET_CONNECTION);
    }

    setIsLoading(true);
    try {
      const data = {
        phone_number: `${countryCode}${phoneNumber}`,
        phone_verify: phoneVerified,
        email,
        email_verify: emailVerified,
        country_phone_code: countryCode,
        token: token
      };
      await saveAnswers(data);
      onDone();
      setIsLoading(false);
    } catch (e) {
      console.error('IdentityData step, saveAnswers error: ', e);
      setIsCloseRegistration(true);
      setIsLoading(false);
      showErrorPopup(e);
    }
  }, [saveAnswers, onDone, showErrorPopup, email, phoneNumber, phoneVerified, emailVerified, countryCode]);

  const onPhoneCodeChange = useCallback((codeSymbols, finish) => {
    setSMSCodeIsWrong(false);
    if (!finish) return;

    let code = '';
    codeSymbols.forEach(s => {
      code += s;
    });

    if (code.length === 6 && /^[0-9]*$/.test(code)) {
      //code is ready to send
      sendSmsCode(code);
    } else {
      console.error('onPhoneCodeLastSymbol BAD code', code);
    }
  }, [sendSmsCode]);

  const renderBtn = () => {
    if (isCloseRegistration) {
      return (
        <button className="float-left" onClick={onAbortRegistration}>
          <span>Close</span>
        </button>
      );
    } else {
      return (
        <button className="float-left" disabled={!emailVerified || !phoneVerified || isLoading} onClick={onSubmit}>
          <span>Next </span>
          {!isLoading
            ? (<i className="fas fa-angle-right"/>)
            : (<i className="fas fa-spin fa-spinner"/>)
          }
        </button>
      );
    }
  }


  const verifyBtnDisabled = isPhoneVerificationInProgress || isEmailVerificationInProgress;

  const verifyPhoneBtnDisabled = !!phoneVerified || verifyBtnDisabled;
  const verifyEmailBtnDisabled = !!emailVerified || verifyBtnDisabled || !phoneVerified;
  return (
    <div className={s.container} >
      <div className={s.titleContainer}>
        <h4>CONTACTS</h4>
      </div>
      {token && <input type='hidden' name='token' value={token || ''}/>}

      <div id={recaptchaContainerId} />

      <div className={cs('telephoneInput', [`${s.phoneContainer}`])}>
        <PhoneInput
          countryCodeEditable={false}
          defaultCountry={country}
          countryCode={countryCode}
          onChangeCode={setCountryCode}
          value={phoneNumber}
          onChange={onPhoneChange}
          placeholder={"* Number"}
          enableSearchField={true}
          disableAreaCodes={true}
          inputExtraProps={{
            name: 'phone_number',
            required: true,
            maxLength: 30,
          }}
          disabled={phoneVerified || verifyPhoneBtnDisabled}
          errMessage={phoneNumberError}
        />
        <button
          onClick={initVerifyTelephone}
          className={cs('float-left', { [`${s.active}`]: !verifyPhoneBtnDisabled && !verifyPhoneButtonDisable, [`${s.verified}`]: phoneVerified })}
          disabled={verifyPhoneBtnDisabled || verifyPhoneButtonDisable}
        >
          <span>Verify Phone</span>
          <i className="fas fa-check" />
        </button>
      </div>
      {phoneNumberError && (<div className={s.phoneErr}>{phoneNumberError}</div>)}

      <div className={cs('', [`${s.emailContainer}`])}>
        <SimpleInput
          onChange={onChangeEmail}
          value={email}
          type={'email'}
          name={'email'}
          id={'emailInput'}
          placeholder={'* Email'}
          maxLength={254}
          disabled={emailVerified || isEmailVerificationInProgress || !phoneVerified}
          errMessage={emailValidationError}
        />
        <button
          onClick={onEmailVerify}
          className={cs('float-left', { [`${s.active}`]: !verifyEmailBtnDisabled, [`${s.verified}`]: emailVerified })}
          disabled={verifyEmailBtnDisabled}
        >
          <span>Verify Email</span>
          <i className="fas fa-check" />
        </button>
      </div>

      <div className={s.submitContainer}>
        {renderBtn()}
      </div>
      {showVerifyPopup && popupContent === PHONE_POPUP && (
        <PhonePopup
          onCloseClick={onCloseVerifyPopup}
          show={true}
          phoneNumber={`+${countryCode}${phoneNumber}`}
          popupBody={''}
          onSendPhoneCodeAgainClick={initVerifyTelephone}
          isCodeWrong={smsCodeIsWrong}
          onCodeChange={onPhoneCodeChange}
          sendPhoneCodeAgainDisabled={isSendingSmsCodeAgainDisabled}
          disabled={typeof verifyPhoneNumber !== 'function' || sendCodeAttemptsNumber >= SEND_CODE_MAX_ATTEMPTS_NUMBER}
          clearInputCounter={clearSmsCodeInputCounter}
          sendingSms={sendingSms}
        />
      )}
      {showVerifyPopup && popupContent === EMAIL_POPUP && (
        <EmailPopup
          onCloseClick={onCloseVerifyPopup}
          show={true}
        />
      )}
    </div>
  );
}

IdentityData.propTypes = {
  userData: userDataPropType.isRequired,
  initAnswers: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  checkPhoneOccupied: PropTypes.func.isRequired,
  setPhoneIsVerified: PropTypes.func.isRequired,
  checkEmailOccupied: PropTypes.func.isRequired,
  setEmailIsVerified: PropTypes.func.isRequired,
  saveAnswers: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onAbortRegistration: PropTypes.func.isRequired,
};


export default withProps({checkPhoneOccupied, setPhoneIsVerified, checkEmailOccupied, setEmailIsVerified})(IdentityData);

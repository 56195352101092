import { urlConstants } from '../constants';
import msgs from "../messages/en/main"

export const urlActions = {
  savePreloginUrl,
  saveSharedLink,
  clearSharedLink
};

function savePreloginUrl(prevUrl = false) {
  prevUrl = prevUrl ? prevUrl : window.location.pathname;

  return dispatch => {
    dispatch(request());
    if(prevUrl)
      dispatch(success(prevUrl));
    else
      dispatch(failure(msgs.SOMETHING_WENT_WRONG));
  };

  function request(url) { return { type: urlConstants.UPDATE_PRELOGIN_URL_REQUEST, url } }
  function success(url) { return { type: urlConstants.UPDATE_PRELOGIN_URL_SUCCESS, url } }
  function failure(error) { return { type: urlConstants.UPDATE_PRELOGIN_URL_FAILURE, error } }
}

function saveSharedLink(sharedLink) {

  return dispatch => {
    dispatch(request());
    if(sharedLink)
      dispatch(success(sharedLink));
    else
      dispatch(clear());
  };

  function request(url) { return { type: urlConstants.UPDATE_SHARED_LINK_REQUEST, url } }
  function success(url) { return { type: urlConstants.UPDATE_SHARED_LINK_SUCCESS, url } }
  function clear() { return { type: urlConstants.UPDATE_SHARED_LINK_CLEAR} }
}

function clearSharedLink() {
  return { type: urlConstants.UPDATE_SHARED_LINK_CLEAR};
}

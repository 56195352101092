export const urlConstants = {
    UPDATE_PRELOGIN_URL_REQUEST: 'UPDATE_PRELOGIN_URL_REQUEST',
    UPDATE_PRELOGIN_URL_SUCCESS: 'UPDATE_PRELOGIN_URL_SUCCESS',
    UPDATE_PRELOGIN_URL_FAILURE: 'UPDATE_PRELOGIN_URL_FAILURE',

    UPDATE_SHARED_LINK_REQUEST: 'UPDATE_SHARED_LINK_REQUEST',
    UPDATE_SHARED_LINK_SUCCESS: 'UPDATE_SHARED_LINK_SUCCESS',
    UPDATE_SHARED_LINK_FAILURE: 'UPDATE_SHARED_LINK_FAILURE',
    UPDATE_SHARED_LINK_CLEAR:   'UPDATE_SHARED_LINK_CLEAR',
    // BY PAVANDEEP END
};

import React, { Component } from 'react';
//import '../App.css';
import Header from '../../../containers/frontend/common/Header';
import MomentsBar from '../../../containers/frontend/common/momentsBar';
import TodayContent from '../../../containers/frontend/moments/todayContent';
import {Helmet} from "react-helmet";

export default class Today extends Component {

  render() {
    return (
      <main className="Today InstantByCategory">
        <Helmet>
          <title>Today - 2cents Instants</title>
        </Helmet>
        <Header/>
        <MomentsBar/>
        <TodayContent/>
     </main>
    );
  }
}

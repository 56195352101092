import React, { Component } from 'react';
import { connect, batch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import VoteActionView from './VoteActionView';
import VotePollView from './VotePollView';
import SparkVideoSection from './SparkVideoSection';
import SparkImageSection from './SparkImageSection';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';
import $ from 'jquery';
import { mainurl } from '../../../mainurl';
import { createDisplayedSpark, formatDateFromUTCtoLocal } from '../../../helpers';
import PropTypes from 'prop-types';
import {sparksActions, urlActions, showChatModal, setChatContentType, suggestionActions} from '../../../actions';
import { CHAT_CONTENT_ACTIVE_OR_NEW } from '../../../constants';
import ResparkedInstantView from './ResparkedInstantView';
import LoadingIcon from '../common/LoadingIcon';

class PureList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hideViewButton: [],
      isObjectionable: props.raw.isObjectionable,
    }
  }

  LikeUnlikeSpark = (p_id, user_id) => {
    const { dispatch, history } = this.props;
    const { user } = this.props;
    const myPost = user_id == user.id;

    if (!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    } else if (user && p_id) {
        const sendRaw = {
          post_id: p_id,
          user_id: user.id,
        };

        dispatch(sparksActions.LikeUnlikeSpark(sendRaw, '', myPost));
      }
  }

  UndoReSprak = (p_id) => {
    const { dispatch, history, user } = this.props;

    if (!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    } else {

      if (user && p_id) {
        const sendRaw = {
          post_id: p_id,
          user_id: user.id,
          retweet_status: 'retweet',
        };

        dispatch(sparksActions.ReSparkPost(sendRaw));

        this.setState({ title: '' });
      } else {
        toast.error(msgs.SOMETHING_WENT_WRONG);
      }
    }

  }

  PopUpData = (sendRaw) => {
    const { dispatch, user, history } = this.props;
    if (!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    } else if(sendRaw) {
      dispatch(sparksActions.ReplyPopUpData(sendRaw));
    }
    // history.push('/'+username+'/status/'+post_id); 
  }

  goNext = (username, post_id, type, PostType) => {
    const { history } = this.props;
    const rootPath = this.props.match.path === '/:profilename' ? '/' + username : '';
    if (PostType == 'retweet_title') {
      history.push(rootPath + '/status/' + post_id);
    } else {

      if (this.props.location.pathname.startsWith('/instants')) {
        history.push('/instants/view/' + this.props.match.params.slug + '/status/' + post_id);
      } else if (this.props.location.pathname.includes('/list-detail')) {
        history.push(`/${username}/list-detail/${this.props.match.params.l_id}/status/${post_id}`);
      } else {
        history.replace(rootPath + '/status/' + post_id);
      }
    }
  }

  handleBlockUser(profileInfo) {
    const { dispatch, user, history } = this.props;

    if (!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    } else {
      const blockData = {
        receiver_id: profileInfo.user_id,
        user_id: user.id,
      };

      const sendRaw = {
        follower_id : profileInfo.user_id,
        user_id: user.id,
      }

      dispatch(suggestionActions.FollowUnfollowOther(sendRaw));

      if(profileInfo.isFollower) {
        dispatch(suggestionActions.FollowUnfollowOther({
          follower_id : user.id,
          user_id: profileInfo.user_id,
        },
        false,
        'isFollower'
        ));
      }

      dispatch(sparksActions.blockUser(blockData));
    }
  }

  handleSetSparkAsObjectionable(spark_owner_id, spark_id, user_id) {
    const { dispatch } = this.props;
    const { isObjectionable } = this.state;
    const objectionableData = {
      user_id,
      spark_id,
      spark_owner_id,
    };

    if (!isObjectionable) {
      dispatch(sparksActions.markAsObjectionable(objectionableData));
    } else {
      dispatch(sparksActions.unmarkAsObjectionable(objectionableData));
    }

    this.setState((prevState) => ({ isObjectionable: !prevState.isObjectionable }));
  }

  handleMuteUser(user_id) {
    const { dispatch, user, history } = this.props;

    if (!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    } else {
      const muteData = {
        muted_user_id: user_id,
        user_id: user.id,
      };

      dispatch(sparksActions.muteUnmuteAccount(muteData, 'home'));
    }
  }

  handleDeleteSpark(post_id, index) {
    const { dispatch, user, history } = this.props;

    if (!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    } else {
      const postdata = {
        post_id: post_id,
        user_id: user.id,
        index: index,
      };

      dispatch(sparksActions.deleteSpark(postdata));
    }
  }

  copyToClipboard = (e) => {
    var copyText = e.target.getAttribute('data-item');
    var link = mainurl + copyText;
    $('<input>').val(link).appendTo('body').select()
    document.execCommand('copy');
    toast.success(msgs.LINK_COPIED);
  };

  shouldComponentUpdate(prevProps, nextProps) {
    return true;
  }

  chatOpen = (otherId, displayName, displayImage, fullName) => {

    const { dispatch, user, history } = this.props;

    if (!user) {
      dispatch(urlActions.savePreloginUrl());
      history.push('/login');
    } else {

      if (otherId && user.id) {
        let rawUser = {
          user_id: user.id,
          otherId: otherId,
          displayName: displayName,
          displayImage: displayImage,
          displayFullName: fullName,
          type: "single",
          status: true,
          msg: "fetching info in process",
        };

        localStorage.setItem('chatOpen', JSON.stringify(rawUser));

        setTimeout(function () {
          $('#friend-list-action button#close-hidden').trigger('click');
        }.bind(this), 500);

      } else {
        alert("unable to open data");
      }
    }

  }

  componentDidMount() {
    setTimeout(function () {
      $('a.hashtag-link').click(function (e) {
        e.stopPropagation();
      });
    }.bind(this), 500);
  }

  innerFunction = (e) => {}

  setViewButtonVisibility = (key) => {
    const hideView = key;
    const { hideViewButton } = this.state;
    hideViewButton[hideView] = !hideViewButton[hideView];
    this.setState({ hideViewButton: hideViewButton });
  }

  // shareViaDirectMessage = (e) => {
  //   const { dispatch, user, history, openChatModal } = this.props;

  //   if (!user) {
  //     dispatch(urlActions.savePreloginUrl());
  //     history.push('/login');
  //   } else {
  //     const location = window.location;

  //     const sharedLink = location.protocol + '//' + location.hostname + (location.port ? `:${location.port}` : '') + '/' + e.target.dataset.item;

  //     dispatch(urlActions.saveSharedLink(sharedLink));
  //     openChatModal();
  //   }
  // }

  clearSharedLink = (e) => {
    const { dispatch } = this.props;
    dispatch(urlActions.clearSharedLink());
  }

  render() {
    const { raw, user, index, type, profileInfo, history, sparklike } = this.props;
    const { pathname } = history.location;
    const { hideViewButton, isObjectionable } = this.state;
    let objectionableMenu = (!isObjectionable ? 'Mark ' : 'Unmark') + ' this spark as objectionable';
    return (
      <div>
        {raw.deleting ? <em> - Deleting...</em> : (
          <div className="tweet" data-id={raw.post_id}>
            <div className="tweet-inn">
              {raw.isReported && raw.reportedUserId == user.id && !hideViewButton[raw.post_id] ? (
                <div className="card">
                  <div className="card-body align-items-center d-flex" id={'spark-view-' + raw.post_id}>
                    <span className="d-flex flex-fill">You reported this Spark</span>
                    <button className="btn btn-danger load-more float-right btn-view" style={{ margin: '0px' }} onClick={() => this.setViewButtonVisibility(raw.post_id)}> VIEW </button>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-2">
                    <div className="tweet-img">
                      <div className="twit-mid-img">
                        <Link to={'/' + raw.username}>
                          <img src={raw.profile_image ? raw.profile_image : '/frontend/images/user-img.png'} alt="logo" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-10 padd-left">
                    <div className="tweet-right">
                      <div>
                        <Link to={'/' + raw.username} className="d-flex flex-column w-75  text-overflow-ellipsis">
                          <span title={raw.name}>{raw.name}</span>
                          <h4 className="mt-2" title={raw.at_username}>{raw.at_username}</h4>
                        </Link>

                        <div >
                          {raw.posttype != 'moment' ? (
                              <div className="item-header" onClick={() => this.goNext(raw.username, raw.post_id, raw.posttype)} data-id={raw.post_id} data-type={raw.posttype}>
                                <div className="spark-text">
                                  {createDisplayedSpark(raw.post_title)}
                                </div>
                              </div>
                            ) : (
                            <div style={{cursor: 'pointer'}} onClick={() => this.goNext(raw.username, raw.post_id, 'retweet_title')}>
                              <ResparkedInstantView raw={raw} history={history} />
                            </div>
                            )
                          }
                          {raw.retweetData != undefined && raw.posttype != 'moment' && raw.retweetData.length > 0 ? (
                            raw.retweetData.map((resparkData, index) =>
                              <div className="item-header pop-mid" key={index} onClick={() => this.goNext(resparkData.username, resparkData.post_id, resparkData.posttype, raw.posttype)} data-id={resparkData.post_id} data-type={resparkData.posttype}>
                                <Link className="text-overflow-ellipsis" to="/">{resparkData.at_username ? resparkData.at_username : resparkData.username}</Link>
                                <div className="retweet-wrapper">
                                  {createDisplayedSpark(resparkData.post_title)}
                                  {resparkData.posttype === 'poll' && <VotePollView data={resparkData} />}
                                  {resparkData.posttype === 'image' && <SparkImageSection sparkImageData={resparkData} />}
                                  {resparkData.posttype === 'video' && <SparkVideoSection sparkVideoData={resparkData} />}
                                </div>
                              </div>
                            )
                          ) : ('')
                          }
                          {raw.retweet_id != 0 && raw.posttype != 'moment' && (raw.retweetData == undefined || raw.retweetData.length == 0) ? (
                            <div className="pop-mid">
                              <div>This Spark is unavailable</div>
                            </div>
                          ) : ('')
                          }
                          {raw.posttype == 'image' ?
                            <SparkImageSection sparkImageData={raw} />
                            : ('')
                          }
                          {raw.posttype == 'video' ?
                            <SparkVideoSection sparkVideoData={raw} />
                            : ('')
                          }
                          {raw.posttype == 'poll' ? (
                            raw.pollVoteStatus == 0 && (!user || raw.user_id != user.id) && raw.poll_expiry == 1 ? (
                              <VoteActionView singlePollData={raw} />
                            ) : (
                                <VotePollView data={raw} />
                              )
                          ) : ('')
                          }
                        </div>
                      </div>
                      {type != 'moment' ? (
                        <div className="tweet-right-btm-icon">
                          <ul>
                            <li>
                              <button title="Comment" onClick={() => this.goNext(raw.username, raw.post_id, raw.posttype)} data-id={raw.post_id} data-type={raw.posttype} className={raw.commentStatus > '0' ? "social-active" : ""}>
                                <i className="far fa-comment" />{raw.totalComment}
                              </button>
                            </li>
                            <li>
                              {raw.retweetStatus == '0' ? (
                                <button 
                                  title="ReSpark" 
                                  onClick={() => this.PopUpData(raw)} 
                                  data-toggle={user ? "modal" : ""} 
                                  data-target={"#respark-modal"} 
                                  data-id={raw.post_id} 
                                  className={raw.retweetStatus == '1' ? "social-active" : ""
                                }>
                                  <i className="fas fa-reply" />{raw.totalRetweet}
                                </button>
                              ) : (
                                  <button title="Undo Spark" onClick={() => this.UndoReSprak(raw.post_id)} data-id={raw.post_id} className={raw.retweetStatus == '1' ? "social-active" : ""}>
                                    <i className="fas fa-reply" />{raw.totalRetweet}
                                  </button>
                                )
                              }
                            </li>
                            <li>
                              <button title="Like" onClick={() => this.LikeUnlikeSpark(raw.post_id, raw.user_id)} className={raw.likeStatus == '1' ? "social-active" : ""}>
                                <i className={raw.likeStatus == '1' ? "fas fa-heart" : "far fa-heart"} /> 
                                { sparklike.loading && sparklike.data.post_id === raw.post_id 
                                  ? <img src="/frontend/images/bwloader.gif" alt="" /> 
                                  : raw.totallike}
                              </button>
                            </li>
                          </ul>
                        </div>
                      ) : ('')}

                      {type != 'moment' ? (
                        <>
                          <div className="time list">{formatDateFromUTCtoLocal(raw.created)}</div>
                          <div className="dropdown">
                            <button className="btn-arrow" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="fas fa-chevron-down"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              {user && user.id != raw.user_id && raw.at_username !== '@2cents' && raw.username !== '2cents' ?
                                (<div>
                                  <button onClick={() => this.handleBlockUser(raw)} className="dropdown-item">{profileInfo && profileInfo.blockByYou == "1" ? "Unblock" : "Block"} {raw.at_username}</button>
                                  {pathname != undefined && pathname == '/' ?
                                    <button onClick={(e) => this.handleMuteUser(raw.user_id)} className="dropdown-item mute-account-btn" data-item={raw.username + '/status/' + raw.post_id}>Mute Account</button>
                                    : ''
                                  }
                                </div>
                                ) : ('')}
                              {user && user.id == raw.user_id ?
                                (<button onClick={() => this.handleDeleteSpark(raw.post_id, index)} className="dropdown-item">Delete Spark</button>) : ('')
                              }
                              {/* <button
                                data-item={raw.username + '/status/' + raw.post_id}
                                onClick={this.shareViaDirectMessage}
                                className="dropdown-item"
                              >
                                Share via Direct Message
                              </button> */}
                              <button data-item={raw.username + '/status/' + raw.post_id} onClick={(e) => this.copyToClipboard(e)} className="dropdown-item">Copy link to Clipboard</button>
                              {user && user.id != raw.user_id && raw.at_username !== '@2cents' && raw.username !== '2cents' && (
                                <button 
                                  data-item=""
                                  onClick={ () => this.handleSetSparkAsObjectionable(raw.user_id, raw.post_id, user.id) }
                                  className="dropdown-item"
                                >
                                  { objectionableMenu }
                                </button>
                              )}
                              {user && user.id != raw.user_id && raw.at_username !== '@2cents' && raw.username !== '2cents' && <button title="Report" onClick={(e) => { return this.PopUpData(raw) }} data-key={'#spark-' + raw.post_id} data-toggle="modal" data-target={"#reportThisSpark"} data-id={raw.post_id} className="dropdown-item">Report This Spark</button>}
                              <input type="hidden" value="" className="copyText" />
                            </div>
                          </div>
                        </>
                      ) : (
                          <button className="selected_post delete" onClick={() => this.handleDeleteSpark(raw.post_id, index)}><i className="fa fa-trash" aria-hidden="true"></i></button>
                        )
                      }
                    </div>
                  </div>
                </div>)}
            </div>
          </div>
        )
        }
      </div>
    );
  }
}

PureList.propTypes = {
  history: PropTypes.object.isRequired,
  openChatModal: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    sparklike: state.sparklike,
    type: ownProps.type,
    user: state.authentication.user,
    raw: ownProps.rawData,
    profileInfo: state.profileinfo ? state.profileinfo.data : {}
  };
}

function openChatModal() {
  return (dispatch) => {
    batch(() => {
      showChatModal()(dispatch);
      setChatContentType(CHAT_CONTENT_ACTIVE_OR_NEW)(dispatch);
    });
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators({openChatModal}, dispatch),
  };
};

const PureListWithRouter = withRouter(PureList);
export default connect(mapStateToProps, mapDispatchToProps)(PureListWithRouter);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Registration from '../components/registration';
import {batch} from 'react-redux';
import {userActions, urlActions, registerUser, finishUserRegistration, alertActions, setActiveChat} from '../actions';

const logout = () => (dispatch) => {
  batch(() => {
    dispatch(alertActions.clear());
    dispatch(urlActions.savePreloginUrl('/'));
    dispatch(userActions.logout());
    dispatch(userActions.filterNotification(null));
    dispatch(setActiveChat({}));
  });
};

function RegistrationContainer(props) {
  const { userData: { loading } } = props;
  const [showRegistration, setShowRegistration] = useState(!loading);
  useEffect(() => {
    //To avoid Registration unmounting always show after userData loading was finished
    setShowRegistration((show) => {
      return show || !loading;
    });
  }, [loading]);

  return showRegistration && (<Registration {...props} />);
}

const mapStateToProps = ({ userData }) => {
  return {userData};
}

export default connect(mapStateToProps, {registerUser, finishUserRegistration, logout})(RegistrationContainer);

import React from 'react'
import s from "./question.module.scss";

function Question(props) {
    const { onBack, goToTrialRegistration, goToPayedRegistration } = props;

    return (
        <div>
        <div className={s.container}>
          <div className={s.titleContainer}>
            <div className={s.title}>
              <h4>What would you like?</h4>
            </div>
          </div>

          <div className={s.chooseContainer}>
              <div className={s.questionBlocksContainer}>
                  <div className={s.questionBlockRed} onClick={goToTrialRegistration}>
                      <div className={s.questionTitle}>
                          Try It!
                      </div>
                      <div className={s.questionDescription}>
                          14-day free trial period
                      </div>
                  </div>
                  <div className={s.questionBlockWhite} onClick={goToPayedRegistration}>
                      <div className={s.questionTitle}>
                          Buy it!
                      </div>
                      <div className={s.questionDescription}>
                          Select plan
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    )
}



export default Question
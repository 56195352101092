import React, { Component } from 'react';
import Header from '../../../containers/frontend/common/Header';
import NotificationsContent from '../../../containers/frontend/notifications/notificationsContent';
import { alertActions,userActions } from '../../../actions';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";

class Notifications extends Component {
  constructor(props){
    super(props);
    //this.getPageData();
  }

  componentDidMount() {
    this.getPageData();
  }

  getPageData = () => {
    const { user } = this.props;
    const { dispatch } = this.props;
    if(user){
      dispatch(userActions.getAllNotification(user.id));
    }
  }

  render()
  {
    return (
      <div id="Notifications" className="Notifications">
        <Helmet>
          <title>2cents / Notifications</title>
        </Helmet>
        <Header/>
        <NotificationsContent/>
     </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        alert: state.alert,
    };
}
export default connect(mapStateToProps)(Notifications);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import TrendsForYou from '../sparks/TrendsForYou';
import NoSparkSection from '../sparks/NoSparkSection';
import CreateSpark from '../sparks/CreateSpark';
import SparkList from '../sparks/SparkList';
import WhoHaveToFollowWidget from '../common/WhoHaveToFollowWidget';
import BottomWidget from '../common/BottomWidget';

import { userActions,alertActions,sparksActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class GetHashtagAllData extends Component {

  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });
  }

  render()
  {
    const { loggedIn,alert,sparks,user } = this.props;

    return (
      <div className="content-part">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 left-sidebar">
              <TrendsForYou/>
            </div>
            <div className="col-sm-6 feed-container">
              {alert.type == 'alert-danger' &&
                <div className={`alert ${alert.type}`}>{alert.message}</div>
              }

              {sparks ? (
                   <SparkList noDataFoundText={"Unfortunately, no matches for your query have been found."} />
                ) : (
                  <NoSparkSection />
                )
              }
            </div>
            <div className="col-sm-3 right-sidebar">
              <WhoHaveToFollowWidget />
              <BottomWidget />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

GetHashtagAllData.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    alert: state.alert,
    sparks: state.sparks,
  };
}
export default withRouter(connect(mapStateToProps)(GetHashtagAllData));

import { momentsConstants } from '../constants';

export function momentsCreate(state = {}, action){
  switch (action.type) {
    case momentsConstants.CREATE_MOMENT_REQUEST:
      return {
        // momentCreating: true,
        // momentUploadComplete: false,
        loading: true,
      };
    case momentsConstants.CREATE_MOMENT_SUCCESS:
      return {
        // momentCreating: false,
        // momentUploadComplete: true,
        loading: false,
      };
    case momentsConstants.CREATE_MOMENT_FAILURE:
      return {
        // momentCreating: false,
        // momentUploadComplete: false,
        loading: false,
      };
    default:
      return state
  }
}

import { sparkConstants } from '../constants';

export function sparks(state = {}, action) {
  switch (action.type) {
    case sparkConstants.GETALLSPARKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case sparkConstants.GETALLSPARKS_SUCCESS:
      return {
        items: action.sparks
      };
    case sparkConstants.GETALLSPARKS_FAILURE:
      return {
        error: action.error
      };
    case sparkConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case sparkConstants.DELETESPARKS_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(spark =>
          spark.post_id === action.id
            ? { ...spark, deleting: true }
            : spark
        )
      };

    case sparkConstants.CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        uploadcomplete: false,
        loading: false,
      };

    case sparkConstants.DELETESPARKS_SUCCESS:
      state.items.splice(action.index, 1)
      return {
        ...state
      };
    case sparkConstants.DELETESPARKS_FAILURE:
      return {
        ...state,
        items: state.items.map(spark => {
          if (spark.post_id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...sparkCopy } = spark;
            // return copy of user with 'deleteError:[error]' property
            return { ...sparkCopy, deleteError: action.error };
          }

          return spark;
        })
      };
    case sparkConstants.UPDATESPARKS_REQUEST:
      // add 'updating:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(spark =>
          spark.post_id == action.id
            ? { ...spark, updating: true }
            : spark
        )
      };
    case sparkConstants.UPDATESPARKS_SUCCESS:
      let items = state.items;
      const newItem = action.id.new_data && action.id.new_data.count > 0 ? action.id.new_data[0] : action.id.updated_post[0];
      
      if (items[0].post_id >= newItem.post_id) {
        items = items.map(spark => {
          if (spark.post_id == newItem.post_id) {
            return { ...spark, ...newItem }
          } else {
            return spark;
          }
        })
      } else {
        const retweet_post = newItem.retweetData[0];
        const updated_posts = items.map(spark => {
          if (spark.post_id == retweet_post.post_id) {
            return { ...spark, ...retweet_post }
          } else {
            return spark;
          }
        })
        items = [newItem, ...updated_posts]
      }

      return {
        ...state,
        items: items
      };
    case sparkConstants.CLEAR_SPARK_LIST:
      return {
        ...state,
        items: [],
      }
    case sparkConstants.UPDATESPARKS_FAILURE:
      return {
        ...state,
        items: state.items.map(spark => {
          if (spark.post_id === action.id.updated_post[0].post_id) {
            // make copy of user without 'updating:true' property
            const { updating, ...sparkCopy } = spark;
            // return copy of user with 'updateError:[error]' property
            return { ...sparkCopy, updateError: action.error };
          }

          return spark;
        })
      };

    case sparkConstants.BLOCK_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case sparkConstants.BLOCK_USER_SUCCESS:
      console.log(state)
      return {
        ...state,
        loading: false
      };
    case sparkConstants.BLOCK_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case sparkConstants.GETALL_LIST_SPARKS_REQUEST:
      return {
        loading: true,
        items: [],
      };
    case sparkConstants.GETALL_LIST_SPARKS_SUCCESS:
      return {
        items: action.sparks
      };
    case sparkConstants.GETALL_LIST_SPARKS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

import {HIDE_CHAT_MODAL, SHOW_CHAT_MODAL, TOGGLE_CHAT_MODAL} from '../../constants'

export default function showModal(state = false, action) {
  switch (action.type) {
    case SHOW_CHAT_MODAL:
      return true;
    case HIDE_CHAT_MODAL:
      return false;
    case TOGGLE_CHAT_MODAL:
      return !state;
    default:
      return state;
  }
}

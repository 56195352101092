import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import ProgressBar from "./ProgressBar";
import PersonalDataStep from "./PersonalData";
import IdentityDataStep from "./IdentityData";
import Congratulations from "./congratulations";
import { toast } from "react-toastify";
import useRegistrationForms from "./useRegistrationForms";
import withProps from "../../HOCs/withProps";
import { saveAnswers, getSubscriptionPlans, checkTokenLife } from "../../api/registration";
import { useHistory } from "react-router";

const stepsNames = {
  PERSONAL_DATA: "personalData",
  IDENTITY_DATA: "identityData",
};

const {
  PERSONAL_DATA,
  IDENTITY_DATA,
} = stepsNames;

const config = [
  {
    name: PERSONAL_DATA,
    component: PersonalDataStep,
    requiredFields: ["username"]
  },
  {
    name: IDENTITY_DATA,
    component: IdentityDataStep,
    requiredFields: [
      "phone_number",
      "country_phone_code",
      "phone_verify",
      "email",
      "email_verify"
    ],
    //shouldBeTrue: ['is_user_registered'],
    shouldBeFalse: ["is_temp_user"]
  },
];

const showErrorPopup = error => {
  toast.error(error || "Something went wrong");
};

function TrialRegistration({
  registerUser: sendAnswers,
  finishUserRegistration,
  userData,
  getSubscriptionPlans,
  logout,
  onBackToQuestion,
  referralHash,
  token,
  onAbortRegistration,
}) {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if(token !== ''){
      checkTokenLife({token : token}).then((e) => {
        if (e.status !== 'success') {
          showErrorPopup("Getting subscription plans error");
          token = null;
          history.push('/');
        }

        if (e && e.status === 'error' && typeof(e.msg) === 'string') {
          toast.error(e.msg);
        }
      })
      .catch(e => {
        history.push('/');
        toast.error(e);
      });
    }
  
    getSubscriptionPlans()
      .then(({ data: subscriptionPlans } = {}) => {
        setSubscriptionPlans(subscriptionPlans);
      })
      .catch(e => {
        console.error("Getting subscription plans error: ", e);
        showErrorPopup("Getting subscription plans error");
      });
  }, []);


  const isLoading = false; //TODO

  const navigateBack = useCallback(() => {
    onBackToQuestion()
  }, []);

  const {
    Step,
    onStepDone,
    saveAnswers,
    tickNum,
    stepNum,
    nextDisabled,
    setNextDisabled,
    isSendingAnswersInProgress,
    stepInitAnswers,
    onNext,
    onBack,
    answers,
    stepSendingAnswersError
  } = useRegistrationForms({
    sendAnswers,
    finishUserRegistration,
    config,
    token: token,
    initAnswers: userData.data,
    onFirstStepBack: navigateBack
  });

  useEffect(() => {
    if (!Step) {
      logout();
    }
  }, [Step]);

  const toLogin = useCallback(() => {
    history.push("/login");
  }, []);

  const backToLogin = useCallback(e => {
    e.preventDefault();
    logout();
    history.push("/login");
  }, []);

  return (
    <>
        {Step ? (
          <>
            <ProgressBar step={stepNum} isTrial={true}/>
            <Step
              userData={userData}
              initAnswers={stepInitAnswers}
              tickNum={tickNum}
              token={token || answers.lifetime_token || ''}
              saveAnswers={saveAnswers}
              onDone={onStepDone}
              showErrorPopup={showErrorPopup}
              setNextDisabled={setNextDisabled}
              nextDisabled={nextDisabled}
              subscriptionPlans={subscriptionPlans}
              sendingAnswersError={stepSendingAnswersError}
              isSendingAnswersInProgress={isSendingAnswersInProgress}
              onBack={onBack}
              onAbortRegistration={onAbortRegistration}
              answers={answers}
              referralHash={referralHash}
            />
          </>
        ) : (
            <Congratulations token={token || answers.lifetime_token} onToLogin={toLogin} isTrial={true} />
        )}
    </>
  );
}

TrialRegistration.propTypes = {
  userData: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object
  }).isRequired,
  registerUser: PropTypes.func.isRequired,
  getSubscriptionPlans: PropTypes.func.isRequired,
  checkTokenLife: PropTypes.func,
  logout: PropTypes.func.isRequired,
  finishUserRegistration: PropTypes.func.isRequired,
  welcomeLayoutLeftBar: PropTypes.string,
  onAbortRegistration: PropTypes.func.isRequired,
};

export default withProps({ saveAnswers, getSubscriptionPlans })(TrialRegistration);

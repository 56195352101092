import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import FollowingUserSection from '../../../containers/frontend/userProfile/FollowingUserSection';
import { alertActions,sparksActions } from '../../../actions';
import NotFoundView from '../NotFoundView';

class FollowingUsers extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.profilename !== this.props.match.params.profilename) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { dispatch } = this.props;

    const { username } = this.props;
    if (username) {
        dispatch(sparksActions.getProfileData(username));
    }
  }
  render() {
    const { notfoundstatus, alert } = this.props;
    if (notfoundstatus && alert && alert.message !== 'TypeError: Failed to fetch') {
      return (
        <>
          <Header/>
          <NotFoundView />
        </>
      )
    }
    
    return (
      <div id="UserProfile" className="FollowingUsers">
        <Header/>
        <FollowingUserSection username={this.props.match.params.profilename}/>
     </div>
    );
  }
}
function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    notfoundstatus: state.profileinfo.notfoundstatus != undefined ? state.profileinfo.notfoundstatus : false,
    username: ownProps.match.params.profilename,
    alert: state.alert,
  };
}
export default connect(mapStateToProps)(FollowingUsers);

import React, {Component} from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import {createDisplayedSpark, charCounter} from '../../../helpers';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';
import Params from '../../../config/Params';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { sparksActions } from '../../../actions';

class MomentSparkAction extends Component {

  static defaultProps = {
    maxCharCount: Params.textareaMaxCharCount
  }

  constructor(props){
    super(props);

    this.state = {
      title : '',
    };
  }


  ReSparkPost = (p_id) => {
    const { dispatch } = this.props;
    const { user,raw } = this.props;
    const {title} = this.state;

    if(user && p_id){
          const sendRaw = {
            moment_id : p_id,
            user_id: user.id,
            title: title != '' ? title : raw.title,
          }
          dispatch(sparksActions.MomentSparkPost(sendRaw));
          $('div#momentspark-modal button.close').click();
          this.setState({title : ''});
          // toast.success("Instant share as spark successfully");
    }
    else {
       toast.error(msgs.SOMETHING_WENT_WRONG);
    }
  }

  handleChange = (event) => {
      const { name, value } = event.target;
      this.setState({
              [name]: value
      });
  }

  goToProfile = (username) => {
    const { history } = this.props;
    history.replace('/' + username);
    $('button.close').trigger('click');
  }

  render(){

    const { raw, maxCharCount } = this.props;
    const {title} = this.state;
    const username = raw && raw.username[0] === '@' ? raw.username.substr(1) : '';

    return (
      <div className="modal fade retweet-modal" id="momentspark-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog create-new" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Share this as Spark</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {raw != undefined ? (
              <div className="modal-body">
                  <div className="reply-btm">
                    <textarea placeholder="Instant as Spark" name="title" onChange={this.handleChange} value={title} maxLength={maxCharCount} />
                    { charCounter(title, maxCharCount) }
                  </div>
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="tweet-img">
                        <div className="twit-mid-img">
                          <img src={raw.profile_image ? raw.profile_image : '/frontend/images/user-img.png'} alt="logo" style={{ cursor: 'pointer' }} onClick={() => this.goToProfile(username)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-10 padd-left">
                      <div className="tweet-right">
                        <h5 style={{ cursor: 'pointer' }} onClick={() => this.goToProfile(username)} className="word-break">{raw.name}</h5>
                        <h4>{raw.at_username}</h4>
                        {/*<div dangerouslySetInnerHTML={{__html: raw.title}} />*/}
                          <div className="spark-text">
                              { raw.title && createDisplayedSpark(raw.title) }
                          </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <button className="retweet" onClick={() => this.ReSparkPost(raw.moment_id)}>Instant As Spark</button>
                    </div>
                  </div>
                </div>
              ) : ('')
            }
          </div>
        </div>
      </div>
    );
  }
}

MomentSparkAction.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  //console.log(state.momentsingle.momentsDetail);
  return {
    raw: state.momentsingle.momentsDetail ? state.momentsingle.momentsDetail : '',
    user: state.authentication.user,
  };
}
export default withRouter(connect(mapStateToProps)(MomentSparkAction));

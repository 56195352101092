import { reportReasonsConstants} from '../constants';
import { reportReasonsService} from '../services';
import { toast } from 'react-toastify';

export const reportReasonsActions = {
    getReportReasons
};


function getReportReasons() {
  return dispatch => {
    dispatch(request(1));
    reportReasonsService.getReportReasons()
        .then(
            data => {
              if(data) {
                const status = data.status;
                const msg = data.msg;
                if (status) {
                  dispatch(success(data.reportReason));
                }
                else {
                  dispatch(failure(msg.toString()));
                  toast.error(msg.toString());
                }
              }
            },
            error => {
              dispatch(failure(error.toString()))
            }

        );
  };

  function request(postdata) { return { type: reportReasonsConstants.GETALLREPORT_REASONS_REQUEST, postdata } }
  function success(index) { return { type: reportReasonsConstants.GETALLREPORT_REASONS_SUCCESS, index } }
  function failure(error) { return { type: reportReasonsConstants.GETALLREPORT_REASONS_FAILURE, error } }
}
import React, {Component} from 'react';
import {connect} from 'react-redux';
import { toast } from 'react-toastify';
import UserSideBarSection from '../common/UserSideBarSection';
import {userActions, alertActions} from '../../../actions';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { mainurl } from '../../../mainurl';

class ReferralProgramSection extends Component {
  constructor(props) {
    super(props);

    const {dispatch, user, history, alert} = this.props;
    this.referralLinkInput = React.createRef();
    history.listen((location, action) => {
      // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    if (user) {
      dispatch(userActions.userSettings(user.id));
    }
  }

  renderRemainingUsers = (invitedUsers = []) => {
    const countOfInvitedUsers = invitedUsers.length >= 10 ? 10 : invitedUsers.length;
    const totalUsers = [...invitedUsers, ...Array(10 - countOfInvitedUsers)];
    
    return totalUsers.map((user, index) => (
      <div className="user-container">
        <div className="user-avatar">
          <span className="user-order">{++index}</span>
          {user && <img src={user.profile_image} />}
        </div>
        <span className="user-name">{user && user.fullname}</span>
      </div>
    ));
  }

  onCopyReferralClick = () => {
    this.referralLinkInput.current.select();
    document.execCommand('copy');
    toast.success("Referral link was copied");
  };

  render() {
    const { referralData: { referral_link, users: invitedUsers } } = this.props;
    const invitedUsersToBenefit = invitedUsers.length >= 10 ? 10 : invitedUsers.length;

    return (
      <div className="content-part sports-main">
        <div className="container">
          {/* {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>} */}
          <div className="row">
            <UserSideBarSection/>
            <div className="col-sm-6 feed-screen">
              <div className="twiter-left1 access-mid">
                <h3>Referral program</h3>
                <div className="referral-mid">
                  {invitedUsers.length >= 10 ? (
                    <p>You got <b>a lifetime free account</b> for 2Cents. Go to your subscribtion and cancel it.</p>
                  ) : (
                    <p>Invite 10 people to register using the referral link and get <b>a lifetime free account</b> for 2Cents</p>
                  )}
                  <input type="text" className="referral-text-input" ref={this.referralLinkInput} value={referral_link} />
                  <button className="copy-link" onClick={this.onCopyReferralClick}>
                    <i className="fas fa-link"></i>
                    Copy link
                  </button>
                </div>
                <div className="referral-users">
                  <div className="referral-remaining">
                    <span>List</span>
                    {invitedUsersToBenefit < 10 && <span className="users-left">{`Only ${10 - invitedUsersToBenefit} left`}</span>}
                  </div>
                  <div className="users-container">
                    {this.renderRemainingUsers(invitedUsers)}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
};

ReferralProgramSection.defaultProps = {
  referralData: {
    referral_link: mainurl + "registration",
    users: [],
  }
}

ReferralProgramSection.propTypes = {
  history: PropTypes.object.isRequired,
  referralData: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    alert: state.alert,
    referralData: state.usersettings.data != undefined && state.usersettings.data.lifetime_vip === 0 ? state.usersettings.data.referral_program : undefined,
  };
}

export default withRouter(connect(mapStateToProps)(ReferralProgramSection));

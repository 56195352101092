import { hashtagsConstants } from '../constants';

export function hashtags(state = {}, action) {
  switch (action.type) {
    case hashtagsConstants.HASHTAG_TRENDS_REQUEST:
      if(state.items == undefined){
        return {
            loading: true
        };
      }
      else {
        return state
      }
    case hashtagsConstants.HASHTAG_TRENDS_SUCCESS:
      return {
        items: action.hashtags
      };
    case hashtagsConstants.HASHTAG_TRENDS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

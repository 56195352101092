import React from "react";
import moment from 'moment';

export function createDisplayedSpark(sparkText) {

    if (!sparkText) {
        return "";
    }

    let sparkTextArr = sparkText.replace(/\n/g, "\n<br/>\n").split( "\n" ).join( " " ).split(' ');
    let result = sparkTextArr.map((item) => {
        let regExpr = /(https?:\/\/)?([\w\.-]+)\.([a-zA-Z]{2,6}\.?)(\/[\w\.\?=&-\[\]\(\)\{\}%\|]*)*\/?/gmi;

        return item.search(regExpr) === -1 ? { text: item + ' ' } : { link: item.replace('<br/>', '') };
    });

    return result.map((item, i) => {
        if('text' in item) {
            return SparkTextDisplay(item.text, i);
        }

        if(!('link' in item)) {
            return '';
        }

        let isHttp = item.link.search(/^(https?:\/\/)/gmi) !== -1;

        return (
            <a 
                key={i} 
                target="_blank" 
                className="spark-link" 
                href={ isHttp ? item.link : ('http://' + item.link) }
                title={ isHttp ? item.link : ('http://' + item.link)  }
            >
                    { item.link.length > 26 ? item.link.slice(0,26) + '... ' : item.link + ' ' }
            </a>
        );
    });
};

export function SparkTextDisplay(str, index = 0) {
    const reg = new RegExp(/^#[a-z 0-9 A-Z]+/gi);
    let hashTagText = str.replace('#','').replace('<br/>', '');

    let hashTagUrl = '/hashtag/' + hashTagText;

    if (str.search(reg) === -1) {
        return str.replace('<br/>', '\n');
    }

    return (
        <a key={`spark-text-${index}`} className='hashtag-link' id='hashtag-link' data-tag={hashTagText} href={hashTagUrl}>#{hashTagText}</a>
    );
};

// export function convertToLocalDate(utcCreatedDate) {
//     let now = new Date();
//     let utcDate = new Date(utcCreatedDate);
//     let createdDate = new Date(utcDate.getTime() - now.getTimezoneOffset() * 60000);
//
//     let year = createdDate.getFullYear();
//     let month = (createdDate.getMonth() + 1) > 9 ? createdDate.getMonth() + 1 : '0' + (createdDate.getMonth() + 1);
//     let date = createdDate.getDate() > 9 ? createdDate.getDate() : '0' + createdDate.getDate();
//     let hours = createdDate.getHours() > 9 ? createdDate.getHours() : '0' + createdDate.getHours();
//     let minutes = createdDate.getMinutes() > 9 ? createdDate.getMinutes() : '0' + createdDate.getMinutes() ;
//
//     return year + '-' + month + '-' + date + ' ' + hours + ':' + minutes;
// };

export function formatDateFromUTCtoLocal(utcDateStr) {
    const date = new moment.utc(utcDateStr);
    return date.local().format("lll");
};

export function getDateOptions(timestamp) {
    const currentDate = new Date();
    const timestampDate = timestamp ? new Date(timestamp) : currentDate;

    const dateOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        // timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    if (currentDate.getMonth() === timestampDate.getMonth()) {
        dateOptions.month = undefined;
    }

    return dateOptions;
}

export function makeUserName(username, needToAddAtSign = true) {
    if (!username) {
        return;
    }

    if (username.charAt(0) === '@') {
        return username;
    }

    return needToAddAtSign ? '@' + username : username;
}

export function timestampToDate(ts) {
    const d = new Date();
    d.setTime(ts * 1000);

    return d;
}

export const acceptableImageFormats = ["image/jpeg", "image/png", "image/jpg", "image/gif"];

export function imageFormatChecker(file) {
    const fileType = file.type;

    for (const format of acceptableImageFormats) {
        if(fileType === format) {
            return true;
        }
    }

    return false;
}
'use strict';

import React, {useCallback, useState, useEffect, useLayoutEffect, useRef, useMemo} from 'react';
import { connect } from "react-redux";
import $ from 'jquery';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import {
  singleChatData as activeSingleChatDataPropType,
  groupChatData as activeGroupChatDataPropType,
  user as userPropType,
  message as messagePropType, isUndefined, message
} from '../../../../misc/propTypes';
import {leaveChat, userActions, setChatContentType, deleteActiveChat} from '../../../../actions';
import { SINGLE_CHAT_TYPE, GROUP_CHAT_TYPE, EDIT_GROUP_CHAT_TYPE, CHAT_CONTENT_ACTIVE_OR_NEW } from '../../../../constants';
import cx from 'classnames';
import OptionMenu from '../../common/optionMenu/OptionMenu';

import s from './chatComponent.module.scss';

import {
  EditGroupChat as EditGroupChatModal
} from '../chatModals';
import { Link } from 'react-router-dom';
import cs from "classnames";
import ChatMessagesList from './ChatMessagesList';
import { MessageForm } from './MessageForm';
import UserAvatar from '../chatModals/UserAvatar';

function ChatComponent(props) {
  const chatContainer = useRef(null);
  const [isChatMenuOpen, setMenuVisability] = useState(false);
  const [isImgAdded, setIsImgAdded] = useState(false);
  const [openModalType, setModalType] = useState(null);
  const {
    user, sharedLink, chatData, goToProfile, removeSharedLink, dispatch, updateGroupChat, activeChatId,
    sendMessage, messages, sendingDisabled, sendingTick, leaveGroupChat, setReadStatus, newMessageLoading,
    user: { id: userId }, setMessagesAsSeen, getPrevMessages, loadingMessages, isPrevMessages, friends, searchMsg, activeChatLastUnreadMessageId
  } = props;
  const [messagesList, updateMessagesList] = useState([]);
  const [loadingPrevMessages, updateLoadingPrevMessages] = useState(false);
  const [showScrollBottomButton, setShowScrollBottomButton] = useState(false);
  const messagesWindow = document.getElementById('userChatContainerBlock');
  messagesWindow && messagesWindow.addEventListener('scroll', () => setTimeout(onScrollOnTop, 500));

  useEffect( () => {
    updateMessagesList(messages);
  }, [messages]);

  function onScrollOnTop() {
    const messagesWindow = document.getElementById('userChatContainerBlock');
    if(messagesWindow && messagesWindow.scrollTop === 0) {
      updateLoadingPrevMessages(true);
    }
    if (messagesWindow && (messagesWindow.scrollHeight - messagesWindow.offsetHeight - messagesWindow.scrollTop > 40)) {
      setShowScrollBottomButton(true);
    } else {
      setShowScrollBottomButton(false);
    }
  }

  function loadPrevMessages() {
    if (!loadingPrevMessages) return;
    getPrevMessages();

    setTimeout(() => {
      updateLoadingPrevMessages(false);
    }, 1000);
  }

  useEffect(loadPrevMessages, [loadingPrevMessages]);

  const setChatMessagesAsRead = () => {
    const unreadMessages = messages.filter(message => !message.seen)
    if (!unreadMessages.length) {
      return;
    }
    setTimeout(() => {
      const messagesIds = unreadMessages.map(message => message._id);
      if (userId) {
        setMessagesAsSeen(messagesIds, chatData.id);
        // setReadStatus(userId, chatData.fiendListKey);
        // updateMessagesList([...messagesList]);
      }
    }, 2000);
  };

  useEffect(setChatMessagesAsRead, [messages]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if(chatContainer && chatContainer.current && chatContainer.current.lastChild) {
        chatContainer.current.lastChild.scrollIntoView(true);
        setShowScrollBottomButton(false);
      }
    }, 1500);
  };

  useEffect(scrollToBottom, [activeChatId]);

  const updateMessageStatus = () => {
    chatData
    && userId
    && messages > 0
    && chatData.fiendListKey
    && messagesList.length > 0
    && messages[messages.length - 1].user._id != userId
    && setReadStatus(userId, chatData.fiendListKey);
  };

  useEffect(updateMessageStatus, [messages, messagesList]);

const setChatImg = (chatData) => {
  const isSingleChatType = chatData.chatType === 1;
  let chatImage = chatData.chat_img;
  if(isSingleChatType && chatData.members.length === 2) {
    const friendUserName = getUserName(chatData).slice(1);
    chatImage = chatData.members.find(member => member.userId !== userId).profile_image || chatImage;

    return (
      <Link to={'/' + friendUserName}>
        <UserAvatar src={chatImage} alt={friendUserName} />
      </Link>
    ) 
  } 

  return (
    <UserAvatar src={chatImage} alt={chatData.name}/>
  )
}

  const onMenuClick = () => {
    setMenuVisability(!isChatMenuOpen);
    $(document).on("click", event => {
      setMenuVisability(false);
      $(document).off(event);
    });
  };

  const onLeaveChat = () => {
    dispatch(deleteActiveChat());
    leaveChat(chatData.id)(dispatch);
    setMenuVisability(false);
  }

  const getMenuOptions = () => {
  const isSingleChatType = chatData.chatType === 1;

    if (isSingleChatType) {
      return [{
        title: "Delete chat",
        onClick: onLeaveChat,
      }];
    } else {
      return [{
        title: "Edit group",
        onClick: () => {
          setModalType(EDIT_GROUP_CHAT_TYPE);
        },
      }, {
        title: "Leave chat",
        style: s.leaveChatButton,
        onClick: onLeaveChat,
      }];
    }
  };

  const closeChatModal = () => {
    setModalType(null);
  };

  const closeResponsiveChat = () => {
    dispatch(setChatContentType(CHAT_CONTENT_ACTIVE_OR_NEW));
  };

  const sendMessageRequest = (body) => {
    sendMessage(body)(dispatch);
  }

  const renderModal = () => {

    switch(openModalType) {
      case EDIT_GROUP_CHAT_TYPE: {
        return (
          <EditGroupChatModal
            userId={userId}
            onSubmit={updateGroupChat}
            friends={chatData.members}
            userList={friends}
            onClickOutside={closeChatModal}
            clearSearchResults={() => dispatch(userActions.clearSearchResults())}
            searchMsg={searchMsg}
            searchUserChat={(rawUser) => dispatch(userActions.searchUserChat(rawUser)) }
            groupData={{
              id: chatData.id,
              groupName: chatData.name,
              groupDescription: chatData.description,
              groupImage: chatData.chat_img,
            }}
          />
        )
      }
      default: {
        return null;
      }
    }
  };

  const getUserName = (chatData) => {
    let userName = chatData.chatType === 1 && chatData.members.length === 2 ?
      chatData.members.find(member => member.userId !== userId).userName : '';
    // let user = chatData.chatType === 1 && chatData.members.length === 2 ?
    //   chatData.members.find(member => member.userId !== userId) : '';

    // if(!user.userId) return 'User is deleted.';
    if(!userName) return '';

    return (!userName || userName.charAt(0)) === '@' ? userName : `@${userName}`;
  }


  if(!chatData ) {
    return <h4>Please choose any chat to start a conversation</h4>;
  }

  return (
    <>
      {chatData && (
      <div className={cx("chat-screen", s.chatScreen)} id="chat-screen" >
        <div className={s.chatInfoContainer}>
          <div className={s.backToChatList} onClick={closeResponsiveChat}>
            <i className="fas fa-arrow-left"></i>
          </div>
          <div className={s.avatarNameContainer}>
            {setChatImg(chatData)
            // : (
            //   <div className={s.chatAvatarContainer}>
            //     <i className="far fa-image icon-2x"></i>
            //   </div>
            // )
            }
            <div className={s.nameContainer}>
              <h5 className={s.chatName}>
                {chatData.name}
              </h5>
              <span className={s.chatMember}>
                {chatData && (chatData.chatType === 1 ? getUserName(chatData) : `${chatData.members && chatData.members.length} members`)}
              </span>
            </div>
          </div>

          <div className={s.chatMenu} onClick={onMenuClick}>
            <i className="fas fa-ellipsis-v"></i>
            <OptionMenu
              active={isChatMenuOpen}
              styles={s.openChatMenu}
              options={getMenuOptions()}
            />
          </div>
        </div>
        <div
          className={cs(s.userChatContainer, { [s.loadedImg]: isImgAdded })}
          id='userChatContainerBlock'
          ref={chatContainer}
        >
          {messagesList && (
            <ChatMessagesList 
              user={user} 
              chatData={chatData}
              messages={messagesList} 
              loadingMessages={loadingMessages}
              loadingPrevMessages={loadingPrevMessages}
              chatContainerRef={chatContainer}
              showScrollBottomButton={showScrollBottomButton}
              activeChatLastUnreadMessageId={activeChatLastUnreadMessageId}
            />
          )}
        </div>
        <div className={s.sendMessagePanel}>
          {sendingDisabled ? (
            <p className="text-center">You are no longer available to send message here.</p>
          ) : (
            <MessageForm
              removeSharedLink={removeSharedLink}
              disabled={sendingDisabled}
              sendMessage={sendMessageRequest}
              sharedLink={sharedLink}
              goToProfile={goToProfile}
              sendingTick={sendingTick}
              hostUser={user}
              chatId={chatData.id}
              newMessageLoading={newMessageLoading}
              loadingMessages={loadingMessages}
              setIsImgAdded={setIsImgAdded}
            />
          )}
        </div>
        <div id="hiddenBlock" className={s.hiddenBlock}></div>
      </div>
      )}
      {renderModal()}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    friends: state.chatusersearch.data != undefined ? state.chatusersearch.data : [],
    activeChatId: state.chat.activeChatData.data ? state.chat.activeChatData.data.id : null,
    newMessageLoading: state.chat.activeChatData.newMessageLoading,
    searchMsg: state.chatusersearch.error,
    activeChatLastUnreadMessageId: state.chat.activeChatData.activeChatLastUnreadMessageId,
  };
};

export default connect(mapStateToProps)(ChatComponent);

ChatComponent.propTypes = {
  user: userPropType.isRequired,
  chatData: PropTypes.oneOfType([activeSingleChatDataPropType, activeGroupChatDataPropType, isUndefined]),
  goToProfile: PropTypes.func.isRequired,
  removeSharedLink: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(messagePropType).isRequired,
  sendingDisabled: PropTypes.bool.isRequired,
  sendingTick: PropTypes.number.isRequired,
  updateGroupChat: PropTypes.func.isRequired,
  leaveGroupChat: PropTypes.func.isRequired,
  loadingMessages: PropTypes.bool.isRequired,
};

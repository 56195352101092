import React, {Component} from 'react';
import { connect } from 'react-redux';

class ProtectedMessage extends Component {
  constructor(props){
    super(props);
  }
  render(){
    let {username,msgvisible} = this.props;

    return (
      <div>
        {msgvisible == true ? (
            <div className="protected-msg">
              <h3>This account Sparks are protected.</h3>
              <p>Only confirmed followers have access to @{username} Sparks and complete profile. Click the "Follow" button to send a follow request.</p>
            </div>
          ) : ('')
        }
      </div>
    )
  }
}


function mapStateToProps(state,ownProps){
  return {
    user: state.authentication.user,
    username: ownProps.username,
    msgvisible: state.profileinfo.data != undefined ? true : false,
  };
}
export default connect(mapStateToProps)(ProtectedMessage);

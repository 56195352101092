import React, {Component} from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { alertActions,hashtagsActions } from '../../../actions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';


class TrendsForYou extends Component {
  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    const { user } = this.props;
    if (user) {
        dispatch(hashtagsActions.getAll(user.id));
    }
  }

  RefreshList = (event) => {
    const { dispatch, userId, user } = this.props;
    if (user || userId) {
      dispatch(hashtagsActions.getAll(userId ? userId : user.id));
    }
  }

  render(){
    let {hashtags} =this.props;
    return (
      <div className="twiter-left1 pic-profile trend mar-top padd">
        <h4>Trends For You</h4>
        <button className="action-suggestion" onClick={this.RefreshList}>
          <i className="fas fa-sync-alt"></i>
        </button>
        <div>
          {hashtags.items != undefined ? (
              hashtags.items.map((raw,index) =>
                <NavLink key={index} exact to={'/hashtag/' + raw.replace('#', '')}>
                  {raw.length > 26 ? raw.slice(0,26) + '... ' : raw + ' '}
                </NavLink>
              )
            ) : (<h4>N/A</h4>)
          }
        </div>
      </div>
    )
  }
}

TrendsForYou.propTypes = {
  history: PropTypes.object.isRequired,
};


function mapStateToProps(state){
  return {
    alert: state.alert,
    user: state.authentication.user,
    hashtags: state.hashtags
  };
}
export default withRouter(connect(mapStateToProps)(TrendsForYou));

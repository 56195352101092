import { momentsConstants } from '../constants';

export function momentscategory(state = {}, action) {
  switch (action.type) {
    case momentsConstants.CATEGORY_REQUEST:
      return {
        categoryData: state.items,
      };
    case momentsConstants.CATEGORY_SUCCESS:
      return {
        categoryData: action.categoryData
      };
    case momentsConstants.CATEGORY_FAILURE:
      return {
        error: action.error
      };
    default:
    return state
  }
}

import React, { Component } from 'react';
import {Elements} from "react-stripe-elements";
import InjectedRenewPlan from '../../../containers/frontend/common/RenewPlan';

class RenewPlanComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { plan_id, id } = this.props.match.params;
    return (
      // <div className="RenewPlanComponent">
      //   <RenewPlan plan_id={atob(plan_id)}  user_id={atob(id)} />

      <Elements>
        <InjectedRenewPlan plan_id={atob(plan_id)} user_id={atob(id)} />
      </Elements>
      //  </div>
    );
  }
}

export default RenewPlanComponent;

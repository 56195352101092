import { userConstants } from '../constants';

export function chatusersearch(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_SEARCH_USER_REQUEST:
      return {
          loading: true
      };
    case userConstants.GET_SEARCH_USER_SUCCESS:
      return {
        loading: false,
        data: action.searchData
      };
    case userConstants.GET_SEARCH_USER_FAILURE:
      return {
        loading: false,
        error: action.error
      };
    case userConstants.CLEAR_SEARCH_RESULTS:
      return {
        data: [],
      }
    default:
      return state
  }
}

export const suggestionConstants = {

  SUGGESTION_REQUEST: 'SUGGESTION_REQUEST',
  SUGGESTION_SUCCESS: 'SUGGESTION_SUCCESS',
  SUGGESTION_FAILURE: 'SUGGESTION_FAILURE',

  FOLLOWUNFOLLOW_REQUEST: 'FOLLOWUNFOLLOW_REQUEST',
  FOLLOWUNFOLLOW_SUCCESS: 'FOLLOWUNFOLLOW_SUCCESS',
  FOLLOWUNFOLLOW_FAILURE: 'FOLLOWUNFOLLOW_FAILURE',

  FOLLOWUNFOLLOWOTHER_REQUEST: 'FOLLOWUNFOLLOWOTHER_REQUEST',
  FOLLOWUNFOLLOWOTHER_SUCCESS: 'FOLLOWUNFOLLOWOTHER_SUCCESS',
  FOLLOWUNFOLLOWOTHER_FAILURE: 'FOLLOWUNFOLLOWOTHER_FAILURE',

  FOLLOWRESPONSE_REQUEST: 'FOLLOWRESPONSE_REQUEST',
  FOLLOWRESPONSE_SUCCESS: 'FOLLOWRESPONSE_SUCCESS',
  FOLLOWRESPONSE_FAILURE: 'FOLLOWRESPONSE_FAILURE',
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { toast } from 'react-toastify';
import cs from 'classnames';

import { userActions,alertActions,listsActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class CreateListForm extends Component {
  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    const { user } = this.props;

    this.state = {
        list: {
            user_id: user.id ? user.id : '',
            username: user.username ? user.username : '',
            listname: '',
            description: '',
            isPrivate : 0,
            action_status: '1',
        },
        submitted: false,
        chars_left: 100,
    };

    this.goBack = this.goBack.bind(this);
  }

  goBack(){
    const { history } = this.props;
      $('html, body').css('overflow', '');
      history.goBack();
  }

  inSideClick = (event) => {
    event.stopPropagation();
  }

  outSideClick = (event) => {
    this.goBack();
  }

  handleChange = (event) => {
      const { name, value } = event.target;
      const { list } = this.state;
      this.setState({
          list: {
              ...list,
              [name]: value
          }
      });
      if(name == 'description'){
          this.handleWordCount(event);
      }
  }

  handleWordCount = event => {
      const charCount = event.target.value.length;
      const charLeft = 100 - charCount;
      this.setState({ chars_left: charLeft});
  }

  handleSubmit = (event) => {
      event.preventDefault();
      const { list } = this.state;
      const { dispatch } = this.props;

      this.setState(prevState => ({ 
        submitted: true,
        list: { 
        ...prevState.list,
        listname: list.listname.trim(),
        },
      }));
      if(list.user_id && dispatch && list.listname) {
        dispatch(listsActions.createEditList(list));
      }
  }

  render() {

    const { list, chars_left, submitted } = this.state;
    const errMessage = submitted && !list.listname ? 'List name is required' : '';

    return (
      <div className="overlay" onClick={this.outSideClick}>
        <div className="modal tweet-modal" id="edit-list-modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog create-new edit-pop" role="document" onClick={this.inSideClick}>
            <div className="modal-content">
              <button type="button" className="close" onClick={this.goBack} title="Close">
                <span aria-hidden="true">×</span>
              </button>
              <div className="pop-top-left">
                <h4>Create list details</h4>
              </div>
              <div className="edit-pop1">
                <div className="row">
                  <div className="col-sm-3">
                    <label>List name</label>
                  </div>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="listname"
                      id="listname"
                      className={cs("input-list", {"has-error": errMessage})}
                      value={list.listname}
                      onChange={this.handleChange} 
                      maxLength="100"
                    />
                    {errMessage && (
                    <div className="help-block eror-help-block"> 
                      {errMessage}
                    </div>)}
                  </div>
                </div>
              </div>
              <div className="edit-pop1">
                <div className="row">
                  <div className="col-sm-3">
                    <label>Description</label>
                  </div>
                  <div className="col-sm-9">
                    <textarea
                      className="input-list"
                      name="description" id="description"
                      value={list.description}
                      maxLength="100"
                      onChange={this.handleChange}
                    >
                    </textarea>
                    <p>Only {chars_left} characters left, optional</p>
                  </div>
                </div>
              </div>
              <div className="edit-privacy">
                <h4>Privacy</h4>
                <div className="edit-privacy1">

                  <input type="radio" name="isPrivate" id="isPrivate" defaultValue='0' checked={list.isPrivate == 0} onChange={this.handleChange} />

                  <label>Public · Anyone can follow this list </label>
                </div>
                <div className="edit-privacy1">

                  <input type="radio" name="isPrivate" id="isPrivate" defaultValue='1' checked={list.isPrivate == 1} onChange={this.handleChange} />

                  <label>Private · Only you can access this list </label>
                </div>
                <input type="submit" value="Save list" className="save-list" onClick={this.handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CreateListForm.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    alert: state.alert,
    user: state.authentication.user,
    listDetail: ownProps.listDetail != undefined && ownProps.listDetail ? ownProps.listDetail : '',
  };
}
export default withRouter(connect(mapStateToProps)(CreateListForm));

import React, {Component} from 'react';
import { connect } from 'react-redux';


class NoSparkSection extends Component {
  constructor(props){
    super(props);
  }
  render(){
    return (
      <div className="twiter-left1 what-spark padd mar-top">
        <h3>What? No Spark yet?</h3>
        <p>This empty timeline won’t be around for long. Start following people and you’ll see Sparks show up here.</p>
        <button>FIND PEOPLE TO FOLLOW</button>
      </div>
    )
  }
}


function mapStateToProps(state){
  return {
    trendPost: state.counterReducer,
  };
}
export default connect(mapStateToProps)(NoSparkSection);

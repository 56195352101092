import React, { useEffect, useState, useCallback } from "react";
import WelcomeLayout from "../welcomeLayout";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../containers/frontend/common/LoadingIcon";
import withProps from "../../HOCs/withProps";
import { saveAnswers, getSubscriptionPlans, deleteUser , checkTokenLife } from "../../api/registration";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import s from "./style.module.scss";
import Question from "./Question";
import PayedRegistration from "./PayedRegistration";
import TrialRegistration from "./TrialRegistration";
import msgs from "../../messages/en/main";

const registrationPaths = {
  NO_CHOOSEN_PATH: 0,
  TRIAL_REGISTARTON: 1,
  PAYED_REGISTRATION: 2
};

const {
  NO_CHOOSEN_PATH,
  TRIAL_REGISTARTON,
  PAYED_REGISTRATION
} = registrationPaths;

function Registration(props) {
  const {
    logout,
    registerUser,
    finishUserRegistration,
    userData,
    getSubscriptionPlans,
    checkTokenLife,
    deleteUser,
    referral,
  } = props;
  const [questionAnswer, setQuestionAnswer] = useState(0);
  const [token, setToken] = useState(false);

  const history = useHistory();

  const isLoading = false; //TODO

  const backToLogin = useCallback(e => {
    e.preventDefault();
    logout();
    history.push("/login");
  }, []);

  const abortConfirmation = () => window.confirm("Are you sure you want to abort registration?");

  const abortRegistration = useCallback(e => {
    e.preventDefault();
    if (abortConfirmation()) {
      userData && userData.data && userData.data.id && deleteUser(userData.data.id);
      logout();
      history.push("/login");
    }
  }, [userData]);

  


  const goToPayedRegistration = () => {
    setQuestionAnswer(PAYED_REGISTRATION);
  };

  const goToTrialRegistration = () => {
    setQuestionAnswer(TRIAL_REGISTARTON);
  };

  const goBackToQuestion = () => {
    setQuestionAnswer(NO_CHOOSEN_PATH);
  };

  useEffect(() => {
    if(history.location.search.indexOf('?') !== -1 ){
      let rt = history.location.search.replace('?','')
      .split('&')
      .reduce(
          function(p,e){
              var a = e.split('=');
              p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
              return p;
          },
          {}
      );
     if(rt['token']){
       
        setQuestionAnswer(TRIAL_REGISTARTON);
        setToken(rt['token'])
     }
    }
    if(userData.data && userData.data.plan_id != null) {
      goToPayedRegistration();
    }

    if(userData.data && userData.data.is_trial_reg == true) {
      goToTrialRegistration();
    }
  })

  return (
    <>
      <WelcomeLayout
        onRightBarLogoClick={abortRegistration}
        leftBarClassName={s.welcomeLayoutLeftBar}
        onAbortRegistration={abortRegistration}
      >
        <ToastContainer />
        {questionAnswer == TRIAL_REGISTARTON ? (
          <TrialRegistration
            registerUser={registerUser}
            finishUserRegistration={finishUserRegistration}
            userData={userData}
            getSubscriptionPlans={getSubscriptionPlans}
            checkTokenLife={checkTokenLife}
            logout={logout}
            onBackToQuestion={goBackToQuestion}
            onAbortRegistration={abortRegistration}
            referralHash={referral}
            token={token || ''}
          />
        ) : questionAnswer == PAYED_REGISTRATION ? (
          <PayedRegistration
            registerUser={registerUser}
            finishUserRegistration={finishUserRegistration}
            userData={userData}
            getSubscriptionPlans={getSubscriptionPlans}
            logout={logout}
            onBackToQuestion={goBackToQuestion}
            referralHash={referral}
          />
        ) : (
          <Question
            onBack={backToLogin}
            goToPayedRegistration={goToPayedRegistration}
            goToTrialRegistration={goToTrialRegistration}
          />
        )}
      </WelcomeLayout>
      {isLoading && <Loading />}
    </>
  );
}

Registration.propTypes = {
  userData: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object
  }).isRequired,
  registerUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  finishUserRegistration: PropTypes.func.isRequired
};


export default withProps({ saveAnswers, getSubscriptionPlans, deleteUser })(Registration);

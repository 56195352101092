import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';
import $ from 'jquery';
import Geocode from "react-geocode";
import ContentEditable from "react-contenteditable";
import { userActions, alertActions, sparksActions, hashtagsActions } from '../../../actions';
import { charCounter } from '../../../helpers';
import { thumbnail } from '../../../thumbnail';
import Params from '../../../config/Params';
import imageCompression from 'browser-image-compression';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getConfirmation } from 'history/DOMUtils';
import { fileSizeLimits } from '../../../misc/fileSizeLimits';
class CreateSpark extends Component {

  static defaultProps = {
    maxCharCount: Params.textareaMaxCharCount
  }

  constructor(props) {
    super(props);

    const { user } = this.props;

    this.state = {
      pollCheck: false,
      files: [],
      PreviewUrls: [],
      buttonIsActive: false,
      choiceCount: 2,
      isThirdAvailable: false,
      isForthAvailable: false,
      createspark: {
        user_id: user.id ? user.id : '',
        sparktext: '',
        location: 'N/A',
        lat: '',
        long: '',
        validPoll: '1',
      },
      type: 'text',
      locEnable: false,
      choice_option: {
        choice1: '',
        choice2: '',
        choice3: '',
        choice4: '',
      },
      submitted: false,
      totalVideoUpload: 0,
    };

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
      // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    this.allowLocationSet = this.allowLocationSet.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);

  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { createspark } = this.state;
    let sparkValue = value;

    sparkValue = sparkValue.replace(/&nbsp;/gm, ' ');

    this.setState({
      createspark: {
        ...createspark,
        [name]: sparkValue,
      }
    });

    this.ButtonActiveOrNot();
  }

  async allowLocationSet() {
    await navigator.geolocation.getCurrentPosition(
      (position) => {

        let lat = position.coords.latitude;
        let long = position.coords.longitude;

        Geocode.setApiKey("AIzaSyBtbsnGwwuGSXXeNsAQ8bkMt31pSNlnFNA");

        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
          response => {
            const address = response.results[0].address_components[0].long_name;
            const { createspark } = this.state;
            this.setState({
              createspark: {
                ...createspark,
                location: address,
                lat: position.coords.latitude,
                long: position.coords.longitude,
              },
              locEnable: true
            });
          },
          error => {
            console.error(error);
            const { createspark } = this.state;
            this.setState({
              createspark: {
                ...createspark,
                lat: position.coords.latitude,
                long: position.coords.longitude,
              },
              locEnable: true
            });
          }
        );

      },
      // (error) => console.log(error)
    );
  }

  ButtonActiveOrNot = () => {
    const {
      createspark,
      choice_option,
      pollCheck,
      type
    } = this.state;

    if (pollCheck) {
      this.setState({ buttonIsActive: createspark.sparktext.trim() && choice_option.choice1.trim() && choice_option.choice2.trim() });
    }
    else {
      this.setState({ buttonIsActive: createspark.sparktext.trim() && type != 'poll' });
    }
  }

  handleOptionValChange = (e) => {
    const { name, value } = e.target;
    const { choice_option } = this.state;
    this.setState({
      choice_option: {
        ...choice_option,
        [name]: value,
      }
    });
  }

  onPollClick = () => {
    const { pollCheck, createspark:{ sparktext }, files, choice_option:{ choice1, choice2, choice3, choice4 } } = this.state;
    const { user } = this.props;

    if (pollCheck) {

      if(choice1 || choice2|| choice3 || choice4) {

        if (!window.confirm(msgs.POLL_DATA_WILL_BE_DELETED)) return;
      }

      this.setState({
        pollCheck: false,
        choice_option:{ 
          choice1: '', 
          choice2: '', 
          choice3: '', 
          choice4: '',
         },
      });
    }

    if(files.length > 0) {
      if (!window.confirm(msgs.ONLY_ONE_TYPE_OF_ATTACHMENT)) return;
      this.fileInput.value = '';
    }


    if (!pollCheck) {
      this.setState({
        ...this.state,
        pollCheck: true,
        createspark: {
          user_id: user.id ? user.id : '',
          sparktext,
          location: 'N/A',
          validPoll: '1'
        },
        type: 'poll',
        files: [],
        PreviewUrls: [],
        totalVideoUpload: 0,
      });
    }

    setTimeout(function () {
      this.ButtonActiveOrNot();
    }.bind(this), 100);

  }

  ChoiceOptionCountDown = (optAct) => {
    const { choiceCount, choice_option, isThirdAvailable, isForthAvailable } = this.state;
    choice_option[`choice${optAct}`] = ''; //delete value from the object of current option

    this.setState({
      choice_option,
      choiceCount: (choiceCount - 1),
    });
    this.ButtonActiveOrNot();

    switch(optAct) {
      case 3:
        return this.setState({isThirdAvailable: false})
      case 4:
        return this.setState({isForthAvailable: false})
      default:
        return;
    };
  }

  ChoiceOptionCountUp = () => {
    const { choiceCount, isForthAvailable } = this.state;
    const newChoiceCount = choiceCount + 1;
    this.setState({
      choiceCount: newChoiceCount,
    });
    this.ButtonActiveOrNot();

    switch(newChoiceCount) {
      case 3:
        return this.setState({isThirdAvailable: true})
      case 4:
        if(isForthAvailable) return this.setState({isThirdAvailable: true})
        return this.setState({isForthAvailable: true})
      default:
        return;
    };
  }

  handleInputTrigger = () => {
    const { choice_option:{ choice1, choice2, choice3, choice4 } } = this.state;

    if(choice1 || choice2|| choice3 || choice4) {
      if (!window.confirm(msgs.POLL_DATA_WILL_BE_DELETED)) return;
      this.fileInput.value = '';
    }

    this.fileInput.click();
  }

  // async rocessImages(files) {
  //   let processedFiles = [];

  //   const compressOptions = {
  //     maxSizeMB: 5,
  //     useWebWorker: false
  //   };

  //   for (const file of files) {
  //     if (file.size / 1024 / 1024 > 5 && file.type.match('image.*')) {
  //       try {
  //         const compressedFile = await imageCompression(file, compressOptions);
  //         processedFiles.push(compressedFile);
  //         toast.info(msgs.IMAGE_WAS_CONVERTED_AUTOMATICALLY);
  //       } catch (error) {
  //         // console.log(error.message);
  //       }
  //     } else {
  //       processedFiles.push(file);
  //     }
  //   }

  //   return processedFiles;
  // }

  async handleImageChange(e) {
    e.preventDefault();
    // FileList to Array
    let files = Array.from(e.target.files);
    if ((this.state.totalVideoUpload > 0 && files[0].type.match('video.*')) || 
    (files.every(file => file.type.match('video.*')) && files.length > 1) ) {
      this.fileInput.value = '';
      return toast.error(msgs.MAX_VIDEO_QUANTITY);
    }

    if (files.some(file => file.type.match('video.*')) && files.some(file => file.type.match('image.*'))) {
      this.fileInput.value = '';
      return toast.error(msgs.ONLY_IMAGE_OR_VIDEO);
    }

    if ((this.state.files.length + e.target.files.length > 4) && files[0].type.match('image.*')) {
      return toast.error(msgs.SPARK_IMAGES_COUNT_ALLOWED);
    }

    // let processedFiles = await this.rocessImages(files);

    // File Reader for Each file and and update state arrays
    files.forEach((file, i) => {
      let reader = new FileReader();
      let fType = file.type;

      if (file.size > fileSizeLimits.image && fType.match('image.*')) {
        this.fileInput.value = '';
        return toast.error(msgs.MAX_IMG_SIZE);
      }

      if (file.size > fileSizeLimits.video && fType.match('video.*')) {
        this.fileInput.value = '';
        return toast.error(msgs.MAX_VIDEO_SIZE);
      }

      this.setState({
        pollCheck: false,
        choice_option: {
          choice1: '',
          choice2: '',
          choice3: '',
          choice4: '',
        },
        totalVideoUpload: 0,
      });

      if (fType.match('video.*') || fType.match('image.*')) {
        reader.onloadend = () => {

          // const totalSizeMb = parseInt((reader.result).replace(/=/g,"").length * 0.75) / Math.pow(1024, 2);
            if (fType.match('image.*')) {
              if (this.state.type === 'video') {
                if (!window.confirm(msgs.ONLY_ONE_TYPE_OF_ATTACHMENT)) return;

                this.setState({
                  files: [],
                  PreviewUrls: [],
                });
              }
              this.setState({ type: 'image' });
            }
    
            if (fType.match('video.*')) {
              if (this.state.type === 'image') {
                if (!window.confirm(msgs.ONLY_ONE_TYPE_OF_ATTACHMENT)) return;

                this.setState({
                  files: [],
                  PreviewUrls: [],
                  totalVideoUpload: 0,
                });
              }
              this.setState({ type: 'video', totalVideoUpload: this.state.totalVideoUpload + 1 });
            }
          
            
          this.setState(prevState => ({
            files: [...prevState.files, file],
            PreviewUrls: [...prevState.PreviewUrls, reader.result],
          }));
        }
        reader.readAsDataURL(file);

      } else {
        toast.error(msgs.IMAGES_AND_VIDEOS_ARE_ALLOWED);
      }
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { createspark, pollCheck, choice_option, files, type } = this.state;
    const { dispatch, username, profileinfo, actionfrom, user, onSend, history } = this.props;

    let pageType = actionfrom;

    if (!actionfrom && profileinfo && profileinfo.at_username != username)
      pageType = 'profile';

    createspark.sparktext = createspark.sparktext ? createspark.sparktext.trim() : "";

    if (pollCheck && type == 'poll') {
      if (createspark.user_id && createspark.sparktext && createspark.location && type && choice_option.choice1 && choice_option.choice2) {
        const sendRaw = {
          createspark: createspark,
          choice_option: {
            choice1: choice_option.choice1.trim(),
            choice2: choice_option.choice2.trim(),
            choice3: choice_option.choice3.trim(),
            choice4: choice_option.choice4.trim(),
          },
          type: type,
          username: username,
        }
        dispatch(sparksActions.CreateSpark(sendRaw, pageType));
        this.clearState();
        if (onSend) {
          onSend();
          history.push(`/`);
        }
      }
    }
    else {
      if (createspark.user_id && createspark.sparktext && createspark.location && type) {
        const sendRaw = {
          createspark: createspark,
          files: files,
          type: type,
          username: username,
        }
        dispatch(sparksActions.CreateSpark(sendRaw, pageType));
        this.clearState();
        if (onSend) {
          onSend();
          history.push(`/`);
        }
      }
    }
  }

  clearState = () => {
    const { user } = this.props;
    this.setState({
      pollCheck: false,
      buttonIsActive: false,
      isForthAvailable: false,
      isThirdAvailable: false,
      choiceCount: 2,
      createspark: {
        user_id: user.id ? user.id : '',
        sparktext: '',
        location: 'N/A',
        validPoll: '1',
      },
      type: 'text',
      choice_option: {
        choice1: '',
        choice2: '',
        choice3: '',
        choice4: '',
      },
      submitted: false,
      files: [],
      PreviewUrls: [],
      totalVideoUpload: 0
    });
    this.fileInput.value = '';
  };

  removeImg = (index) => {
    this.fileInput.value = '';
    const { files, PreviewUrls, totalVideoUpload } = this.state;
    if (files.length > 0) {
      if (PreviewUrls[index].startsWith('data:video')) {
        this.setState(prevState =>  ({ totalVideoUpload: prevState.totalVideoUpload - 1 }))
        this.fileInput.value = '';
      }

      files.splice(index, 1);
      PreviewUrls.splice(index, 1);

      this.setState({
        files: files,
        PreviewUrls: PreviewUrls,
      });
      
      if (files.length === 0) {
        this.setState({
          type: 'text',
        });
      }
    } else {
      this.setState({
        files: [],
        PreviewUrls: [],
        type: 'text',
      });
    }

  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    const { creating, alert, user, maxCharCount } = this.props;
    const { createspark, submitted, pollCheck, choiceCount, buttonIsActive, type, files, locEnable, PreviewUrls, isThirdAvailable, isForthAvailable, choice_option } = this.state;
    const optionCharLimit = 100;
    return (
      <div className="twiter-left1 twit-mid padd">
        <div className="twit-mid-img">
          <img src={user.profile_image != undefined ? user.profile_image : "/frontend/images/user-img.png"} alt="logo" />
        </div>
        <div className="twi-mid-right">
          <form onSubmit={this.handleSubmit}>
            <textarea 
              maxLength={maxCharCount} 
              className={(submitted && !createspark.sparktext ? 'has-error' : '')} 
              placeholder="What’s going on?" 
              name="sparktext" 
              onKeyUp={this.ButtonActiveOrNot} 
              onChange={this.handleChange} 
              autoComplete="off" 
              value={createspark.sparktext.trimLeft()} 
            />

            {charCounter(createspark.sparktext.trimLeft(), maxCharCount)}
            <div className="selected-images-sparks">
              {PreviewUrls.length > 0 && !pollCheck ?
                (PreviewUrls.map((raw, index) => 
                  <div key={index} className="img-grid-spark">
                    {raw && raw.indexOf("video", 4) !== -1 ? <img src={thumbnail} /> : <img src={raw} />}
                    <i className="fas fa-times-circle" onClick={() => this.removeImg(index)} ></i>
                  </div>
                )) : ('')
              }
            </div>
            {submitted && !createspark.sparktext &&
              <div className="eror-help-block">text is required</div>
            }
            {pollCheck ? (
              <div>
                <div className="poll-main">
                  <ul>
                    <li>
                      <input 
                        type="text"
                        name="choice1"
                        placeholder="choice 1"
                        className="poll-input"
                        onKeyUp={this.ButtonActiveOrNot}
                        onChange={this.handleOptionValChange}
                        maxLength={optionCharLimit}
                        value={choice_option.choice1.trimLeft()}
                      />
                    </li>
                    <li>
                      <input 
                        type="text" 
                        name="choice2" 
                        placeholder="choice 2" 
                        className="poll-input" 
                        onKeyUp={this.ButtonActiveOrNot} 
                        onChange={this.handleOptionValChange} 
                        maxLength={optionCharLimit}
                        value={choice_option.choice2.trimLeft()}
                        />
                      </li>
                    {isThirdAvailable && (
                      <li className="add-m-more">
                        <input 
                          type="text"
                          name="choice3"
                          placeholder="choice 3 (optional)"
                          className="more-inp"
                          onKeyUp={this.ButtonActiveOrNot}
                          onChange={this.handleOptionValChange}
                          maxLength={optionCharLimit}
                        value={choice_option.choice3.trimLeft()}
                        />
                        <span onClick={() => this.ChoiceOptionCountDown(3)} className="close-op"><i className="fas fa-times"></i></span>
                      </li>
                    )
                    }
                    {isForthAvailable && (
                      <li className="add-m-more">
                        <input 
                          type="text"
                          name="choice4"
                          placeholder="choice 4 (optional)"
                          className="more-inp"
                          onKeyUp={this.ButtonActiveOrNot}
                          onChange={this.handleOptionValChange}
                          value={choice_option.choice4.trimLeft()}
                          maxLength={optionCharLimit}
                        />
                        <span onClick={() => this.ChoiceOptionCountDown(4)} className="close-op"><i className="fas fa-times"></i></span>
                      </li>
                    )
                    }
                    {choiceCount < 4 && (
                      <span className="add-more" onClick={() => this.ChoiceOptionCountUp()}>+ Add a Choice</span>
                    ) 
                    }
                  </ul>
                  <span className="remove-poll" onClick={this.onPollClick}>Remove Poll</span>
                </div>
                <div className="row poll-duration" >
                  <div className="col-md-12">
                    <label>Poll Duration: </label>
                    <select name="validPoll" defaultValue="1" onChange={this.handleChange}>
                      <option value="1">1 day</option>
                      <option value="2">2 days</option>
                      <option value="3">3 days</option>
                      <option value="4">4 days</option>
                      <option value="5">5 days</option>
                      <option value="6">6 days</option>
                      <option value="7">7 days</option>
                    </select>
                  </div>
                </div>
              </div>
            )
              : ('')
            }
            <div className="mid-icon">
              <i className={"far fa-images" + (files.length > 0 ? ' active' : '')} onClick={this.handleInputTrigger} />
              <i className={"fas fa-poll" + (pollCheck ? ' active' : '')} onClick={this.onPollClick}></i>
              <input 
                type="file" 
                accept="image/jpg, image/jpeg, image/png, image/gif, video/*" 
                ref={fileInput => this.fileInput = fileInput} 
                onChange={this.handleImageChange} 
                id="files" 
                name="files[]" 
                multiple 
              />
              {/* <i className={"fas fa-map-marker-alt" + (locEnable ? ' active' : '')} onClick={this.allowLocationSet} /> */}
              <button disabled={!buttonIsActive}>SEND</button>
            </div>
          </form>
        </div>

      </div>
    )
  }
}

CreateSpark.propTypes = {
  history: PropTypes.object.isRequired,
};


function mapStateToProps(state, ownProps) {
  return {
    user: state.authentication.user,
    sparks: state.sparks,
    actionfrom: ownProps.type,
    alert: state.alert,
    profileinfo: state.profileinfo ? state.profileinfo.data : {},
    username: state.authentication.loggedIn && state.authentication.user.at_username != undefined ? state.authentication.user.at_username : '',
  };
}
export default withRouter(connect(mapStateToProps)(CreateSpark));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import CreateListSection from '../../../containers/frontend/userProfile/CreateListSection';
import NotFoundView from '../NotFoundView';
import { alertActions,listsActions } from '../../../actions';
import $ from 'jquery';

class CreateList extends Component {
  constructor(props){
    super(props);
    $('html, body').css('overflow', 'hidden');
  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.l_id !== this.props.match.params.l_id || prevProps.match.params.profilename !== this.props.match.params.profilename) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { dispatch } = this.props;

    const { user, username, l_id } = this.props;
    if (username && l_id && user) {
        let rawData = {
          username: username,
          user_id: user.id,
          list_id: l_id
        }
        dispatch(listsActions.getAll(rawData));
    }
  }
  render() {

    if (this.props.notfoundstatus) {
      return (
        <>
          <Header/>
          <NotFoundView />
        </>
      )
    }
    
    return (
      <div id="UserProfile" className="CreateList">
        <Header/>
        <CreateListSection username={this.props.match.params.profilename}/>
     </div>
    );
  }
}
function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    username: ownProps.match.params.profilename,
    l_id: ownProps.match.params.l_id,
    notfoundstatus: state.profileinfo.notfoundstatus != undefined ? state.profileinfo.notfoundstatus : false,
  };
}
export default connect(mapStateToProps)(CreateList);

import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cs from "classnames";

import Modal from "../index";
import { userActions } from "../../../actions";
import s from "./referral.module.scss";

const Congratulations = ({ planStatus, userId, paymentSystem, showCongratsReferralModal, dispatch }) => {
  const [showModal, setShowModal] = React.useState(showCongratsReferralModal && planStatus === 'free');
  const isIos = (paymentSystem && paymentSystem.toLowerCase()) === 'itunes';
  const closeModal = () => {
    setShowModal(false);

    dispatch(userActions.setCongratsReferralModalVisability(userId));
  };

  return (
    showModal ? (
      <Modal>
        <div className={s.modal}>
          <div className={s.container}>
            <button onClick={closeModal} className={s.closeReferral}>
              <i className="fas fa-times"></i>
            </button>
            <div className={s.referralLogo}>
              <img src="/frontend/images/referral-congrats.png" alt=""/>
            </div>
            <div className={s.referralInfo}>
              <p>Congratulations!</p>
              <p>You have invited <b className={s.count}>10 people,</b> now you have</p>
              <p><b className={s.referralBenefit}>a lifetime free account</b></p>
              {isIos && <p className={s.iTunesCancelationTip}><b>Important:</b> You have a free lifetime account, so please go to your App Store settings and cancel your paid subscriptions.</p>}
            </div>
            <div className={s.referralModalActions}>
              <div className={s.closeDetatilsBlock}>
                <button onClick={closeModal} className={cs(s.referralButton, s.close)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    ) : null
  );
}

Congratulations.defaultProps = {
  show_congrats_referral_modal: 0,
  showReferralNow: false,
};

Congratulations.propTypes = {
  planStatus: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  paymentSystem: PropTypes.string,
  showCongratsReferralModal: PropTypes.oneOf([0, 1]),
};

const mapStateToProps = ({ authentication: { user } }) => ({
  userId: user ? user.id : '',
  planStatus: user ? user.plan_status : '',
  showCongratsReferralModal: user ? user.show_congrats_referral_modal : '',
  paymentSystem: user ? user.payment_system : '',
});

export default connect(mapStateToProps, null)(Congratulations);
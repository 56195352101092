import { sparkConstants } from '../constants';

export function sparksingle(state = {}, action) {
  switch (action.type) {
    case sparkConstants.SINGLESPARKS_REQUEST:
      return {
        loading: true,
        msg: null,
      };
    case sparkConstants.SINGLESPARKS_SUCCESS:
      return {
        items: action.sparksingle,
        loading: false,
        msg: action.msg,
      };
    case sparkConstants.LIKESPARKS_SUCCESS: 
      return {
        items: action.likedata.updated_post,
        loading: false,
      };
    case sparkConstants.SINGLESPARKS_FAILURE:
      return {
        error: action.error,
        loading: false,
        msg: null,
      };
    case sparkConstants.UPDATESINGLESPARKS_REQUEST:
      // add 'updating:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(spark =>
          spark.post_id === action.id
            ? { ...spark, updating: true }
            : spark
        )
      };
    case sparkConstants.UPDATESINGLESPARKS_SUCCESS:
      return {
          ...state,
          items: state.items.map(spark =>
            spark.post_id == action.id.updated_post[0].post_id
              ? { ...spark, ...action.id.updated_post[0] }
              : spark
          )
        };
    case sparkConstants.UPDATESINGLESPARKS_FAILURE:
      return {
        ...state,
        items: state.items.map(spark => {
          if (spark.post_id === action.id.updated_post[0].post_id) {
            // make copy of user without 'updating:true' property
            const { updating, ...sparkCopy } = spark;
            // return copy of user with 'updateError:[error]' property
            return { ...sparkCopy, updateError: action.error };
          }

          return spark;
        })
    };
    default:
      return state
  }
}

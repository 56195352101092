import React, {Component} from 'react';
import { connect } from 'react-redux';
import TrendsForYou from '../sparks/TrendsForYou';
import BottomWidget from '../common/BottomWidget';
import CreateListForm from '../common/CreateListForm';

import { alertActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class CreateListSection extends Component {
  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });
  }
  render(){
    const { loggedIn,alert,lists,username,user,profileinfo } = this.props;
    return (
      <div>
      <div className="edit-profile">
        <div className="content-part sports-main">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 left-sidebar">
                <TrendsForYou/>
              </div>
              <div className="col-sm-6 feed-container">

              </div>
              <div className="col-sm-3 right-sidebar">
                <BottomWidget/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateListForm listDetail={lists.listDetail} />
      </div>
    );
  }
};

CreateListSection.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    profileinfo: state.profileinfo.data != undefined ? state.profileinfo.data : [],
    lists: state.lists != undefined ? state.lists : [],
    username: ownProps.username,
  };
}
export default withRouter(connect(mapStateToProps)(CreateListSection));

'use strict';

import React, {useState, useCallback, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { makeUserName } from '../../../../helpers';
import PropTypes from 'prop-types';
import { SINGLE_CHAT_TYPE, GROUP_CHAT_TYPE } from '../../../../constants';
import messages from '../../../../messages/en/main';
import {
  singleChatData as singleChatDataPropType,
  groupChatData as groupChatDataPropType,
} from '../../../../misc/propTypes';
import get from 'lodash.get';
import { database } from '../../../../firebase';

const cursorPointerStyle = {cursor: 'pointer'};

export default function ChatDetails(props) {
  const {
    onBack, goToProfile, leaveGroupChat, deleteSingleChat,
    updateGroupChatName, data, sendStatus, updateGroupImage, toastError,
  } = props;

  const [imageSrc, setImageSrc] = useState(undefined);

  const onUpdateGroupChatName = useCallback((groupId, chatName) => {
    updateGroupChatName(groupId, chatName)
  }, [updateGroupChatName]);

  const onFileSelect = useCallback(e => {
    if (!e.target.files || !e.target.files.length) {
      toastError(messages.SOMETHING_WENT_WRONG);
      return;
    }

    const file = e.target.files[0];
    if (!file.type.match('image.*')) {
      toastError(messages.IMAGES_ARE_ALLOWED);
      return;
    }

    const reader = new FileReader();

    reader.addEventListener('load', () => {
      updateGroupImage(reader.result);
    });

    reader.readAsDataURL(e.target.files[0]);
  }, [updateGroupImage, toastError]);

  const chatType = get(data, 'type');

  return (
    <div className="chat-detail" id="chat-detail" >
      <div className="modal-header">
          <span className="back-icon" onClick={onBack}>
            <i className="fas fa-chevron-left"/>
          </span>
        <h5 className="modal-title" id="exampleModalLabel">
          {chatType === SINGLE_CHAT_TYPE ? 'Chat info' : 'Group info'}
        </h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className="modal-body message send-mess">
        {chatType === SINGLE_CHAT_TYPE && (
          <SingleChatDetails
            data={data}
            goToProfile={goToProfile}
            imageSrc={imageSrc}
          />
        )}
        {chatType === GROUP_CHAT_TYPE && (
          <GroupChatDetails
            data={data}
            onFileSelect={onFileSelect}
            imageSrc={imageSrc}
            updateGroupChatName={onUpdateGroupChatName}
          />
        )}
      </div>

      <div className="group-botm">
        {chatType === GROUP_CHAT_TYPE ? (
          sendStatus && (<button onClick={leaveGroupChat}>Leave conversation</button>)
        ) : (
          <button onClick={deleteSingleChat}>Delete conversation</button>
        )}
      </div>
    </div>
  );
}

ChatDetails.propTypes = {
  onBack: PropTypes.func.isRequired,
  goToProfile: PropTypes.func.isRequired,
  leaveGroupChat: PropTypes.func.isRequired,
  deleteSingleChat: PropTypes.func.isRequired,
  updateGroupChatName: PropTypes.func.isRequired,
  updateGroupImage: PropTypes.func.isRequired,
  toastError: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([singleChatDataPropType, groupChatDataPropType]),
  sendStatus: PropTypes.bool.isRequired,
};

function SingleChatDetails({ goToProfile, data, imageSrc }) {
  return(
    <div className="group-info">
      <div className="group-info-img">
        <img
          src={(imageSrc ? imageSrc : data.profile_image) || ''}
          alt=""
          style={cursorPointerStyle}
          onClick={() => goToProfile(data.username)}
        />
      </div>
      <h3>
        <span>{data.name}</span><br/>{data.atUsername}
      </h3>
    </div>
  );
}

SingleChatDetails.propTypes = {
  goToProfile: PropTypes.func.isRequired,
  data: singleChatDataPropType.isRequired,
  imageSrc: PropTypes.string,
};

function GroupChatDetails({ onFileSelect, data, imageSrc, updateGroupChatName, }) {
  const [editedChatName, setEditedChatName] = useState(undefined);
  const groupImage = (imageSrc ? imageSrc : data.groupImage) || '';
  const members = Object.values(data.members || {});
  const chatName = (typeof editedChatName === 'undefined' ? data.groupName : editedChatName) || '';

  const [membersData, setMembersData] = useState({});

  useEffect(() => {
    getMembersData(data.members || {}, setMembersData);
  }, []);

  const handleChatNameChange = useCallback((e) => {
    const { target: { value } = {} } = e;
    setEditedChatName(value);
  }, []);

  const saveChatName = useCallback((e) => {
    updateGroupChatName(data.id, editedChatName);
  }, [updateGroupChatName, data.groupId, editedChatName]);

  return (
    <div>
      <div className="group-info group-check-info-css">
        <div className="group-info-img">
          <input id="file-upload-2" type="file" accept="image/*" onChange={onFileSelect}/>
          {groupImage && <img src={groupImage} alt="group image"/>}
          <label htmlFor="file-upload-2"> <i className="fa fa-camera" aria-hidden="true"/></label>
        </div>
        <h3 className="edit-group-name-check">
          <span>{chatName}</span>
          <input
            title="click here to edit group name"
            type="text"
            name="gName"
            value={chatName}
            onChange={handleChatNameChange}
            maxLength={20}
          />
          <i className="fas fa-check" onClick={saveChatName} title="update group name"/>
        </h3>
      </div>
      <div className="group-member">
        <p>Members ({members.length}) </p>
      </div>
      <div>
        {members.map(({userId, status}, index) => {
          const member = membersData[userId];
          if (!member) return null;

          return (
            <div className="block-acc-btm1 group-list" key={index}>
              <div className="row">
                <div className="col-sm-2">
                  <span>
                    <img src={member.profile_image ? member.profile_image : ''} alt="logo"/>
                  </span>
                </div>
                <div className="col-sm-6 padd-left">
                  <div className="block-acc-info">
                    <h4 className="word-break">
                      <strong>{member.name ? member.name : 'N/A'}</strong> ({member.username ? makeUserName(member.username) : 'N/A'})
                    </h4>
                  </div>
                </div>
                <div className="col-sm-4">
                  {member.username && (
                    <Link target="_blank" to={"/" + member.username.replace("@", "")}>View Profile</Link>
                  )}
                </div>
              </div>
            </div>
          )
        })}
        {!members.length && ('N/A')}
      </div>
    </div>
  );
}

GroupChatDetails.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  data: groupChatDataPropType.isRequired,
  imageSrc: PropTypes.string,
  updateGroupChatName: PropTypes.func.isRequired,
};

function getMembersData(members, setMembersData) {
  Object.values(members).forEach(member => {
    if (!member || !member.status) return;

    database.ref('Users')
      .child(member.userId)
      .once('value')
      .then(snap => {
        const userData = snap.val();
        if (!userData) return;
        const {
          id = '',
          name = '',
          full_name = '',
          profile_image = '',
          username = '',
        } = userData;
        const user = {
          id,
          full_name: full_name,
          profile_image,
          username,
        };
        setMembersData((members) => {
          const newMembers = {...members};
          newMembers[id] = user;
          return newMembers;
        });
      })
      .catch(error => console.warn('getting member detailed info error: ', error));
  });
};

import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';
import UserSmallDesign from '../userElements/UserSmallDesign';
import BottomWidget from './BottomWidget';

// export default class UserSideBarSection extends Component {
class UserSideBarSection extends Component {
    constructor(props) {
        super(props);
    }

  render(){
    const { lifetime_vip } = this.props.user;

    return (
          <div className="col-sm-3 left-sidebar">
            <UserSmallDesign />
            <div className="twiter-left1 sett-left mar-top">
              <ul>
                <li><Link to="/settings/account-setting">Account<i className="fas fa-chevron-right" /></Link></li>
                <li><Link to="/settings/password-setting">Password<i className="fas fa-chevron-right" /></Link></li>
                <li><Link to="/settings/privacy-safety-setting">Privacy & Safety<i className="fas fa-chevron-right" /></Link></li>
                <li><Link to="/settings/notifications-setting">Notifications<i className="fas fa-chevron-right" /></Link></li>
                <li><Link to="/settings/block-account-setting">Blocked Accounts<i className="fas fa-chevron-right" /></Link></li>
                <li><Link to="/settings/your-2cents-data">Your 2cents data<i className="fas fa-chevron-right" /></Link></li>
                { !lifetime_vip ? (<li><Link to="/settings/referral">Referral program<i className="fas fa-chevron-right" /></Link></li>) : '' }
              </ul>
            </div>
            <BottomWidget />
          </div>
    );
  }
};

function mapStateToProps(state){
    return {
        user: state.authentication.user,
    };
}

export default connect(mapStateToProps)(UserSideBarSection);
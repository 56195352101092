import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import msgs from "../../../../messages/en/main";
import { ToastContainer } from "react-toastify";
import WelcomeLayout from "../../../../components/welcomeLayout";
import CreditCardInput from "react-credit-card-input";
import LoginLeftContentBar from "../LoginLeftContentBar";
import LogoWithTextSectiom from "../LogoWithTextSectiom";
import LoadingIcon from "../LoadingIcon";
import CardNumberInput from "../cardNumberInput";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import PlanRenewComponent from "../PlanRenewComponent";
import SubscriptionDropdown from '../SubcribtionDropdown'

import $ from "jquery";
import { userActions, alertActions } from "../../../../actions";
import { getCurrentYear } from "../../../../helpers";
import s from "./renewPlan.module.scss";

class RenewPlan extends Component {
  constructor(props) {
    super(props);

    let data = $.getJSON("https://jsonip.com?callback=?");

    // reset login status
    // this.props.dispatch(userActions.logout());
    this.props.dispatch(userActions.getPlan());

    this.state = {
      user: {
        plan_id: this.props.plan_id,
        user_id: this.props.user_id,
        stripe_token: ""
      },
      submitted: false,
      formVisible: false,
      isLoading: false,
      successfullRenew: false,
    };

    const { dispatch, history } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }
  //pass plan_id?
  showForm = plan_id => {
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        plan_id: plan_id
      },
      formVisible: true
    });
  };

  hideForm = () => {
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        plan_id: this.props.plan_id
      },
      formVisible: false
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        [name]: value
      },
      vaild: true
    });
  };

  errorCheck = event => {
    this.setState({
      vaild: false,
      submitted: false
    });
  };

  handleSubmit = event => {
    const { user_id, history } = this.props;

    if(!user_id) {
      history.push("/login");
    }
    event.preventDefault();
    const { successfullRenew } = this.state;
    this.setState({ submitted: true, isLoading: true, });

    setTimeout(
      (async () => {
        if (successfullRenew) {
          await this.dataApiHit();
          this.setState({ successfullRenew: false });
        } else {
          this.setState({ submitted: false, isLoading: false, });
    }
      }),
      2000
    );
  };

  dataApiHit = () => {
    const { user } = this.state;

    const { dispatch, renewplan } = this.props;
    if (dispatch) {
      dispatch(userActions.PlanRenew(user));

      if (!renewplan.loading) {
        this.setState({ submitted: false });
      }
    } else {
      this.setState({ submitted: false, isLoading: false, successfullRenew: false});
      toast.error(msgs.SOMETHING_WENT_WRONG);
    }
  };

  setStripe = (stripe, successfullRenew) => {
    const { user } = this.state;
  
    this.setState({
      user: {
        ...user,
        stripe_token: stripe
      },
      successfullRenew,
    });
    this.dataApiHit();
  };

  mama = () => {
    const { dispatch } = this.props;
    dispatch(userActions.logout());
  }

  render() {
    const { Getplan, plan_id, user_id, renewplan } = this.props;
    const { formVisible, user, submitted, vaild, isLoading, isCreditCardEmpty } = this.state;

    return (
      <>
        <WelcomeLayout
          onRightBarLogoClick={this.mama}
          onAbortRegistration={this.abortRegistration}
        >
          <ToastContainer />
          {formVisible ? (
            <div>
              <div className={s.titleContainer}>
                <div className={s.title}>
                  <h4>Credit card information</h4>
                </div>
              </div>
              <SubscriptionDropdown 
                getPlan={Getplan} 
                chosenPlan={this.state.user.plan_id} 
                showForm={this.showForm}
                disabled={true}
              />
              <div className={s.renewCardContainer}>
                <CardNumberInput
                  changeColWidth={true}
                  hideTitile={true}
                  hideIcon={true}
                  submitted={submitted}
                  setStripe={this.setStripe}
                />
                <button onClick={this.handleSubmit} disabled={renewplan.loading || isLoading} className={s.renewButton}>
                  <div className={s.renewButtonText}>
                    <span>Pay</span>
                    {!renewplan.loading
                      ? (<i className="fas fa-angle-right" />)
                      : (<i className="fas fa-spin fa-spinner" />)
                    }
                  </div>
                </button>
              </div>
            </div>
          ) : (
            <PlanRenewComponent getPlan={Getplan} showForm={this.showForm} plan_id={plan_id}/>
          )}
        </WelcomeLayout>
      </>
    );
  }
}

RenewPlan.propTypes = {
  history: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  const { alert, renewplan, Getplan, authentication } = state;
  const { plan_id, user_id } = ownProps;
  return {
    alert,
    renewplan,
    Getplan,
    plan_id,
    user_id,
    authentication
  };
}
export default withRouter(connect(mapStateToProps)(RenewPlan));

import { APP2_URL } from '../mainurl';
import { getAppHeaders, handleResponse } from "../helpers";

export const hashtagsService = {
    getAll
};

/*Get all trends tags*/
function getAll(user) {
    const data = {
      user_id: user
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP2_URL}trendPost`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    USER_GET_LOGIN_REQUEST: 'USER_GET_LOGIN_REQUEST',
    USER_GET_LOGIN_SUCCESS: 'USER_GET_LOGIN_SUCCESS',
    USER_GET_LOGIN_FAILURE: 'USER_GET_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GETALL_BLOCKED_REQUEST: 'USERS_GETALL_BLOCKED_REQUEST',
    GETALL_BLOCKED_SUCCESS: 'USERS_GETALL_BLOCKED_SUCCESS',
    GETALL_BLOCKED_FAILURE: 'USERS_GETALL_BLOCKED_FAILURE',


    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    EDIT_PROFILE_REQUEST: 'USERS_EDIT_PROFILE_REQUEST',
    EDIT_PROFILE_SUCCESS: 'USERS_EDIT_PROFILE_SUCCESS',
    EDIT_PROFILE_FAILURE: 'USERS_EDIT_PROFILE_FAILURE',

    EDIT_IMAGE_REQUEST: 'USERS_EDIT_IMAGE_REQUEST',
    EDIT_IMAGE_SUCCESS: 'USERS_EDIT_IMAGE_SUCCESS',
    EDIT_IMAGE_FAILURE: 'USERS_EDIT_IMAGE_FAILURE',

    UNBLOCK_REQUEST: 'USERS_UNBLOCK_REQUEST',
    UNBLOCK_SUCCESS: 'USERS_UNBLOCK_SUCCESS',
    UNBLOCK_FAILURE: 'USERS_UNBLOCK_FAILURE',

    UNMUTE_REQUEST: 'USERS_UNMUTE_REQUEST',
    UNMUTE_SUCCESS: 'USERS_UNMUTE_SUCCESS',
    UNMUTE_FAILURE: 'USERS_UNMUTE_FAILURE',

    DIRECT_UNMUTE_REQUEST: 'USERS_DIRECT_UNMUTE_REQUEST',
    DIRECT_UNMUTE_SUCCESS: 'USERS_DIRECT_UNMUTE_SUCCESS',
    DIRECT_UNMUTE_FAILURE: 'USERS_DIRECT_UNMUTE_FAILURE',

    EDIT_SETTINGS_REQUEST: 'USERS_EDIT_SETTINGS_REQUEST',
    EDIT_SETTINGS_SUCCESS: 'USERS_EDIT_SETTINGS_SUCCESS',
    EDIT_SETTINGS_FAILURE: 'USERS_EDIT_SETTINGS_FAILURE',

    DEACTIVATE_USER_REQUEST: 'USERS_DEACTIVATE_REQUEST',
    DEACTIVATE_USER_SUCCESS: 'USERS_DEACTIVATE_SUCCESS',
    DEACTIVATE_USER_FAILURE: 'USERS_DEACTIVATE_FAILURE',

    PLAN_CANCEL_REQUEST: 'PLAN_CANCEL_REQUEST',
    PLAN_CANCEL_SUCCESS: 'PLAN_CANCEL_SUCCESS',
    PLAN_CANCEL_FAILURE: 'PLAN_CANCEL_FAILURE',

    GET_SETTINGS_REQUEST: 'USERS_GET_SETTINGS_REQUEST',
    GET_SETTINGS_SUCCESS: 'USERS_GET_SETTINGS_SUCCESS',
    GET_SETTINGS_FAILURE: 'USERS_GET_SETTINGS_FAILURE',

    SET_SETTINGS_REQUEST: 'USERS_SET_SETTINGS_REQUEST',
    SET_SETTINGS_SUCCESS: 'USERS_SET_SETTINGS_SUCCESS',
    SET_SETTINGS_FAILURE: 'USERS_SET_SETTINGS_FAILURE',

    SET_PRIVACY_REQUEST: 'USERS_SET_PRIVACY_REQUEST',
    SET_PRIVACY_SUCCESS: 'USERS_SET_PRIVACY_SUCCESS',
    SET_PRIVACY_FAILURE: 'USERS_SET_PRIVACY_FAILURE',

    GET_USER_DATA_SETTINGS_REQUEST: 'USERS_GET_DATA_SETTINGS_REQUEST',
    GET_USER_DATA_SETTINGS_SUCCESS: 'USERS_GET_DATA_SETTINGS_SUCCESS',
    GET_USER_DATA_SETTINGS_FAILURE: 'USERS_GET_DATA_SETTINGS_FAILURE',

    GETALL_NOTIFICATION_REQUEST: 'USERS_GETALL_NOTIFICATION_REQUEST',
    GETALL_NOTIFICATION_SUCCESS: 'USERS_GETALL_NOTIFICATION_SUCCESS',
    GETALL_NOTIFICATION_FAILURE: 'USERS_GETALL_NOTIFICATION_FAILURE',

    FILTER_NOTIFICATION: 'USERS_FILTER_NOTIFICATION',

    GET_SEARCH_REQUEST: 'USERS_GET_SEARCH_REQUEST',
    GET_SEARCH_SUCCESS: 'USERS_GET_SEARCH_SUCCESS',
    GET_SEARCH_FAILURE: 'USERS_GET_SEARCH_FAILURE',

    CLEAR_SEARCH_RESULTS: 'CLEAR_SEARCH_RESULTS',

    GET_SEARCH_USER_REQUEST: 'USERS_GET_SEARCH_USER_REQUEST',
    GET_SEARCH_USER_SUCCESS: 'USERS_GET_SEARCH_USER_SUCCESS',
    GET_SEARCH_USER_FAILURE: 'USERS_GET_SEARCH_USER_FAILURE',

    SEARCH_REQUEST: 'USER_SEARCH_REQUEST',
    SEARCH_SUCCESS: 'USER_SEARCH_SUCCESS',
    SEARCH_FAILURE: 'USER_SEARCH_FAILURE',

    EMPTY_SEARCH_SUCCESS: 'USER_EMPTY_SEARCH_SUCCESS',

    DELETE_SEARCH_RESULTS: 'DELETE_SEARCH_RESULTS',

    GET_PAGE_DATA_REQUEST: 'GET_PAGE_DATA_REQUEST',
    GET_PAGE_DATA_SUCCESS: 'GET_PAGE_DATA_SUCCESS',
    GET_PAGE_DATA_FAILURE: 'GET_PAGE_DATA_FAILURE',

    // BY PAVANDEEP START

    GET_PLAN_REQUEST:'GET_PLAN_REQUEST',
    GET_PLAN_SUCCESS:'GET_PLAN_SUCCESS',
    GET_PLAN_FAILURE:'GET_PLAN_FAILURE',

    CHECKUSER_REQUEST:'CHECKUSER_REQUEST',
    CHECKUSER_SUCCESS:'CHECKUSER_SUCCESS',
    CHECKUSER_FAILURE:'CHECKUSER_FAILURE',

    SET_REFERRAL_VISABILITY_CLOSE:'SET_REFERRAL_VISABILITY_CLOSE',
    SET_REFERRAL_VISABILITY_REQUEST:'SET_REFERRAL_VISABILITY_REQUEST',
    SET_REFERRAL_VISABILITY_SUCCESS:'SET_REFERRAL_VISABILITY_SUCCESS',
    SET_REFERRAL_VISABILITY_FAIL:'SET_REFERRAL_VISABILITY_FAIL',

    SET_CONGRATS_REFERRAL_VISABILITY_REQUEST:'SET_CONGRATS_REFERRAL_VISABILITY_REQUEST',
    SET_CONGRATS_REFERRAL_VISABILITY_SUCCESS:'SET_CONGRATS_REFERRAL_VISABILITY_SUCCESS',
    SET_CONGRATS_REFERRAL_VISABILITY_FAIL:'SET_CONGRATS_REFERRAL_VISABILITY_FAIL',

    PLAN_RENEW_REQUEST:'PLAN_RENEW_REQUEST',
    PLAN_RENEW_SUCCESS:'PLAN_RENEW_SUCCESS',
    PLAN_RENEW_FAILURE:'PLAN_RENEW_FAILURE',

    // BY PAVANDEEP END

    USER_PRESSED_VERIFY_EMAIL_BUTTON: 'USER_PRESSED_VERIFY_EMAIL_BUTTON',
    USER_SAVE_FIREBASE_AUTH: 'USER_SAVE_FIREBASE_AUTH',
    SET_USER_CONNETION: 'SET_USER_CONNETION',
};

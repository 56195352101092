import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { sparkcreate } from './sparkcreate.reducer';
import { sparks } from './sparks.reducer';
import { reportreasons } from './reportreasons.reducer';
import { hashtags } from './hashtags.reducer';
import { suggestion } from './suggestion.reducer';
import { sparklike } from './sparklike.reducer';
import { sparksingle } from './sparksingle.reducer';
import { commentspark } from './commentspark.reducer';
import { replydata } from './replydata.reducer';
import { votespark } from './votespark.reducer';
import { profileinfo } from './profileinfo.reducer';
import { moments } from './moments.reducer';
import { momentscategory } from './momentscategory.reducer';
import { momentstweetpost } from './momentstweetpost.reducer';
import { momentsingle } from './momentsingle.reducer';
import { momentsCreate } from './momentsCreate.reducer';
import { notificationdata } from './notificationdata.reducer';
import { usersettings } from './usersettings.reducer';
import { lists } from './lists.reducer';
import { searchprocess } from './searchprocess.reducer';
import { chatusersearch } from './chatusersearch.reducer';
import { pageinfo } from './pageinfo.reducer';
import { Getplan } from './Getplan.reducer';
import { Checkuser } from './Checkuser.reducer';
import { renewplan } from './renewplan.reducer';
import { url } from './url.reducer';
import userData from './userData';
import chat from './chat.reducer';
import { connectRouter } from 'connected-react-router';
import chatReducer from "../reducers/chatReducer";

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  authentication,
  users,
  alert,
  sparkcreate,
  sparks,
  hashtags,
  suggestion,
  sparklike,
  sparksingle,
  commentspark,
  replydata,
  votespark,
  profileinfo,
  moments,
  momentstweetpost,
  momentscategory,
  momentsingle,
  momentsCreate,
  notificationdata,
  usersettings,
  lists,
  searchprocess,
  chatusersearch,
  pageinfo,
  Getplan,
  Checkuser,
  renewplan,
  reportreasons,
  url,
  userData,
  chat,
  chats: chatReducer,
});

export default rootReducer;

import React, {Component} from 'react';
import {connect} from 'react-redux';
import MomentGrid from './MomentGrid';
import {momentsActions, alertActions} from '../../../actions';

class AllMomentsContent extends Component {
  constructor(props) {
    super(props);
    this.getPageData();
  }

  componentDidMount() {
    this.getPageData();
  }

  getPageData = () => {
    const {user} = this.props;
    const {dispatch} = this.props;
    if (user) {
      dispatch(momentsActions.getAllMoments(user.id));
    }
  }

  sortPosts = (posts) => {
    if (posts) {

      return posts
      .sort((a,b) => {
        const firstPostDate = new Date(a.created);
        const secondPostDate = new Date(b.created);
        return secondPostDate.getTime() - firstPostDate.getTime();
      })
    }
  };

  render() {
    const {user, momentData, alert} = this.props;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;
    const allMoments = Object.values([momentData.entertainment,
       momentData.fun, 
       momentData.news, 
       momentData.others,
       momentData.sports]).flat();
    return (
      <div className="content-part">
          <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          { momentData
            ? <MomentGrid momentData={this.sortPosts(allMoments)} type='all'/>
            : <p className="text  text--centered">There are no instants</p>}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    momentData: state.moments.items != undefined ? state.moments.items : {},
    alert: state.alert,
  };
}

export default connect(mapStateToProps)(AllMomentsContent);

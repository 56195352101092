import PropTypes from 'prop-types';


export const userData = PropTypes.shape({

});

export const tickNum = PropTypes.number;

export const subscriptionPlans = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  interval_count: PropTypes.string.isRequired,
  intervals: PropTypes.string.isRequired,
}));

export const sendingAnswersError = PropTypes.string;

export const isSendingAnswersInProgress = PropTypes.bool;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import UserRequestSection from '../../../containers/frontend/userProfile/UserRequestSection';
import { alertActions,sparksActions } from '../../../actions';

class UserRequests extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.username !== this.props.username) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { dispatch } = this.props;
    const { username } = this.props;
    if (username) {
        dispatch(sparksActions.getProfileData(username));
    }
  }
  render() {
    return (
      <div id="UserProfile" className="UserRequests">
        <Header/>
        <UserRequestSection username={this.props.match.params.profilename}/>
     </div>
    );
  }
}
function mapStateToProps(state){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    username: state.authentication.user.username,
  };
}
export default connect(mapStateToProps)(UserRequests);

import { suggestionConstants } from '../constants';
import { suggestionService } from '../services';
import { alertActions,sparksActions,listsActions,userActions } from './';
import { toast } from 'react-toastify';

export const suggestionActions = {
    getAll,
    FollowUnfollow,
    FollowUnfollowOther,
    FollowResponse
};

function getAll(user) {
    return dispatch => {
        dispatch(request(user));
        suggestionService.getAll(user)
            .then(
                suggestion => {
                  const status = suggestion.status;
                  const msg = suggestion.msg;
                  if(status){
                    dispatch(success(suggestion.suggestion))
                  }
                  else {
                    dispatch(failure(msg.toString()));
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                }
            );
    };

    function request(user) { return { type: suggestionConstants.SUGGESTION_REQUEST, user } }
    function success(suggestion) { return { type: suggestionConstants.SUGGESTION_SUCCESS, suggestion } }
    function failure(error) { return { type: suggestionConstants.SUGGESTION_FAILURE, error } }
}

function FollowUnfollow(user) {
    return dispatch => {
        dispatch(request(user.follower_id));
        suggestionService.FollowUnfollow(user)
            .then(
                suggestion => {
                  const status = suggestion.status;
                  const msg = suggestion.msg;
                  if(status){
                    dispatch(success(user.follower_id));
                    dispatch(getAll(user.user_id));
                    toast.success(suggestion.msg);
                  }
                  else {
                    dispatch(failure(msg.toString()));
                    toast.error(msg.toString()+' in FollowUnfollow process');
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                }
            );
    };

    function request(id) { return { type: suggestionConstants.FOLLOWUNFOLLOW_REQUEST, id } }
    function success(id) { return { type: suggestionConstants.FOLLOWUNFOLLOW_SUCCESS, id } }
    function failure(error) { return { type: suggestionConstants.FOLLOWUNFOLLOW_FAILURE, error } }
}

function FollowUnfollowOther(user,username,type,slug) {
    return dispatch => {
        dispatch(request(user.follower_id));
        suggestionService.FollowUnfollow(user)
            .then(
                suggestion => {
                  const status = suggestion.status;
                  const msg = suggestion.msg;
                  if(status){
                    dispatch(success(user.follower_id));
                    if(username){
                        dispatch(sparksActions.getProfileData(username));
                    }
                    if(type == 'listsection')
                    {
                      dispatch(listsActions.getAll(user));
                    }
                    if(type == 'search-section')
                    {
                        const searchData = {
                          user_id : user.user_id,
                          search : slug
                        }
                        dispatch(userActions.getSearchData(searchData));
                    }
                    if(type !== 'isFollower') {
                      toast.success(msg.toString());
                    }
                  }
                  else {
                    dispatch(failure(msg.toString()));
                    toast.error(msg.toString()+' in FollowUnfollow process');
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                }
            );
    };

    function request(id) { return { type: suggestionConstants.FOLLOWUNFOLLOWOTHER_REQUEST, id } }
    function success(id) { return { type: suggestionConstants.FOLLOWUNFOLLOWOTHER_SUCCESS, id } }
    function failure(error) { return { type: suggestionConstants.FOLLOWUNFOLLOWOTHER_FAILURE, error } }
}

function FollowResponse(user,username,type,slug) {
    return dispatch => {
        dispatch(request(user.follower_id));
        suggestionService.FollowResponse(user)
            .then(
                suggestion => {
                  const status = suggestion.status;
                  const msg = suggestion.msg;
                  if(status){

                    dispatch(success(user.other_id));
                    dispatch(getAll(user.user_id));

                    if(username && type == 'profile_main'){
                        dispatch(sparksActions.getProfileData(username));
                    }

                    if(type == 'listsection')
                    {
                      dispatch(listsActions.getAll(user));
                    }
                    else if(type == 'search-section')
                    {
                        const searchData = {
                          user_id : user.user_id,
                          search : slug
                        }
                        dispatch(userActions.getSearchData(searchData));
                    }

                    toast.success(msg.toString());
                  }
                  else {
                    dispatch(failure(msg.toString()));
                    toast.error(msg.toString()+' in FollowUnfollow process');
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                }
            );
    };

    function request(id) { return { type: suggestionConstants.FOLLOWRESPONSE_REQUEST, id } }
    function success(id) { return { type: suggestionConstants.FOLLOWRESPONSE_SUCCESS, id } }
    function failure(error) { return { type: suggestionConstants.FOLLOWRESPONSE_FAILURE, error } }
}

import React, {Component} from 'react';
import { connect } from 'react-redux';
import TrendsForYou from '../sparks/TrendsForYou';
import BottomWidget from '../common/BottomWidget';
import UserTinyWidget from '../userElements/UserTinyWidget';
import UserProfileTopSection from '../userElements/UserProfileTopSection';
import UserMainWidget from '../userElements/UserMainWidget';
import ProtectedMessage from '../common/ProtectedMessage';

import { alertActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingIcon from "../common/LoadingIcon";

class FollowerUserSection extends Component {
  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });
  }
  render(){
    const { 
      followers,
      username,
      profileinfo,
      alert,
    } = this.props;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;
    return (
      <div className="edit-profile">
        <div className="content-part sports-main">
          <UserProfileTopSection username={username}/>
          <div className="container">
            {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}

            <div className="row">
              <div className="col-sm-3 left-sidebar">
              <UserTinyWidget 
                profileInfo={profileinfo}
                isPrivate={profileinfo.isPrivate} 
                full_name={profileinfo.full_name} 
                username={profileinfo.username} 
                created={profileinfo.created} 
              />
              </div>
              <div className="col-sm-6 feed-container">
                {profileinfo.visible != '1' ?
                    (
                    <ProtectedMessage username={username}/>
                    ) :  (
                         <UserMainWidget userslist={followers} username={username} />
                      ) 
                }
              </div>
              <div className="col-sm-3 right-sidebar">
                <TrendsForYou/>
                <BottomWidget/>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
};

FollowerUserSection.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    alert: state.alert,
    profileinfo: state.profileinfo.data != undefined ? state.profileinfo.data : [],
    followers: state.profileinfo.data != undefined ? state.profileinfo.data.followers : [],
    username: ownProps.username,
  };
}
export default withRouter(connect(mapStateToProps)(FollowerUserSection));

import React, { Component } from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import s from "./chatEmptyMessage.module.scss";
import {connect} from "react-redux";
import {
    SINGLE_CHAT_TYPE,
    GROUP_CHAT_TYPE,
    CHAT_CONTENT_ACTIVE_OR_NEW,
} from './../../../constants';
import {
    CreateSingleChat as CreateSingleModal,
    CreateGroupChat as CreateGroupModal
} from './chatModals';
import {
    userActions,
    setActiveChat,
    chatActions,
} from './../../../actions';
import { makeUserName } from './../../../helpers';
import firebase from "firebase";
import { database } from './../../../firebase';
import { toast } from "react-toastify";
import msgs from "./../../../messages/en/main";


class ChatEmptyMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: ""
    };
  }

  closeChatModal = () => {
    this.setState({ showModal: "" });
  };

  

  createGroupChat = (form) => {
    this.setState({submitted: true});

    const { user: { id: userId }, onChatClick, dispatch } = this.props;
    const { selectedUsers, groupData: {groupName, groupDescription, groupImage} } = form;
    const members = [];

    if (userId && groupName && groupDescription && selectedUsers.length > 0) {
      selectedUsers.forEach((value) => {
        members.push({
          userId: value.id,
          status: true,
        });
      });

      members.push({
        userId: userId,
        status: true,
      });

      const rawData = {
        createdBy: userId,
        members: members,
        groupName: groupName,
        groupDescription: groupDescription,
        groupImage: groupImage || '',
        createdAt: firebase.database.ServerValue.TIMESTAMP,
      };

      database.ref('/Groups/').push(rawData)
        .then(async (snap) => {
          const key = snap.key;
          const { members, createdBy, groupDescription, groupName, groupImage, } = rawData;

          await Promise.all(members.map((value) => {
            database.ref('/Users/' + value.userId + '/group_list/').push(key)
          }));

          toast.success(msgs.GROUP_CREATE_SUCCESS);

          const chatData = {
            id: key,
            createdBy,
            type: GROUP_CHAT_TYPE,
            groupName,
            groupDescription,
            groupImage,
            members,
          };

          setActiveChat(chatData)(dispatch);
          onChatClick();
          this.closeChatModal();
        })
        .catch(error => {
          console.error('Creating group chat error', error);
          toast.error(msgs.GROUP_FILL_REQUIRED);
        });
    } else {
      toast.error(msgs.GROUP_FILL_REQUIRED);
    }
  };


  renderChatModal = () => {
    const { showModal } = this.state;
    const { dispatch, user: { id: userId }, friends } = this.props;
    switch(showModal) {
      case SINGLE_CHAT_TYPE: {
        return (
          <CreateSingleModal
            userId={userId}
            userList={friends}
            searchUserChat={(rawUser) => dispatch(userActions.searchUserChat(rawUser)) }
            onClickOutside={this.closeChatModal}
            clearSearchResults={() => dispatch(userActions.clearSearchResults())}
            chatOpen={this.chatOpen}
          />
        );
      }

      case GROUP_CHAT_TYPE: {
        return (
          <CreateGroupModal
            userId={userId}
            userList={friends}
            createGroupChat={this.createGroupChat}
            updateGroupChat={undefined}
            searchUserChat={(rawUser) => dispatch(userActions.searchUserChat(rawUser)) }
            onClickOutside={this.closeChatModal}
            clearSearchResults={() => dispatch(userActions.clearSearchResults())}
          />
        )
      }

      default: {
        return null;
      }
    }
  };

  chatOpen = async (recipientId) => {
    const { user: { id: userId }, dispatch, friends, onChatClick } = this.props;

    const recipient = friends.find(friend => friend.id === recipientId);

    if (!recipient || !userId) return;

    // const { username, name, profile_image, at_username } = recipient;

    // const chatData = {
    //   id: `${userId}-${recipientId}`,
    //   type: SINGLE_CHAT_TYPE,
    //   atUsername: at_username || makeUserName(username),
    //   username,
    //   name,
    //   profile_image,
    //   lastMessage: '',
    //   timestamp: firebase.database.ServerValue.TIMESTAMP,
    //   recipientId,
    //   fiendListKey: Number(recipientId),
    // };
    const data = { user_id: recipientId, type: 1 };
    const chatData = await dispatch(chatActions.createSingleChat(data));
    // setActiveChat(chatData)(dispatch);
    onChatClick();
    this.closeChatModal();
  };

  render() {
    return (
    <>
      <div className={s.chatEmptyContainer}>
        <div className={s.chatEmptyTitle}>You don't have any chats yet.</div>
        <div className={s.chatButtonsContainer}>
          <div onClick={() => {this.setState({ showModal: SINGLE_CHAT_TYPE })}} className={s.chatEmptyCreateButton}>Create Single Chat</div>
          <span className={s.chatEmptyOr}>or</span>
          <div onClick={() => {this.setState({ showModal: GROUP_CHAT_TYPE }) }} className={s.chatEmptyCreateButton}>Create Group Chat</div>
        </div>
      </div>
       {this.renderChatModal()}
    </>
    );
  }
}


const mapStateToProps = (state) => {
    return {
      user: state.authentication.user,
      alert: state.alert,
      activeChatData: state.chat.activeChatData,
      unreadChats: state.chat.unreadChats,
      friends: state.chatusersearch.data != undefined ? state.chatusersearch.data : [],
      state: state
    };
  };


export default withRouter(connect(mapStateToProps)(ChatEmptyMessage))
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import UserAvatar from '../chatNotModal/chatModals/UserAvatar';
import ImgPreview from '../moments/ImgPreview';

class ResparkedInstantView extends Component {

  constructor(props){
    super(props);
  }

  goToProfile = (username) => {
    const { history, parent } = this.props;
    parent && parent.close();
    window.scrollTo(0, 0);
    history.push('/' + username);
  }

  render() {
    const { raw } = this.props;
    if(!raw.momentData || raw.momentData.length === 0) {
      return (
        <div className="pop-mid">
           <p>This Instant is currently unavailable.</p>
        </div>
      )
    }
    const { title, name, description, profile_image, at_username, file } = raw.momentData;

    return (
      <div className="resparked-instant container" >
        <div className="pop-mid" data-id={raw.retweet_id} data-type={raw.posttype}>
          <div className="row">
            <div className="col-md-12">
              <div className="instant-user-data" onClick={() => this.goToProfile(at_username.slice(1))}>
                <div className="pop-top-left-img">
                  <UserAvatar
                    src={profile_image}
                    alt={at_username}/>
                </div>
                <h6>
                  <strong>{name}</strong><br/>
                  {at_username}
                </h6>
              </div>
              <div className="spark-text">
                <h4 className="default-display">
                  {title}
                </h4>
                <p>{description}</p>
              </div>
              <div className="respaked-instant-img-container">
                <ImgPreview src={file}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResparkedInstantView.propTypes = {
  history: PropTypes.object.isRequired,
};

// const SingleViewWithRouter = withRouter(SingleView);
export default ResparkedInstantView;

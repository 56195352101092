//TODO remove with nested components
import React, { Component } from 'react';
import VerificationContainer from '../../../containers/frontend/common/VerificationContainer';
// import { connect } from "react-redux";

class VerificationComponent extends Component{

    constructor(props){
        super(props);
    }

    render(){
        const { mobile, email, id } = this.props.match.params;
        return (
          <div className="VerificationComponent">
           <VerificationContainer curMobile={atob(mobile)} curEmail={atob(email)} user_id={atob(id)}/>
          </div>
        );
    }
}

export default VerificationComponent;

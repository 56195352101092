import React, { Component } from 'react';
import Header from '../../../containers/frontend/common/Header';
import YourAccountDataSection from '../../../containers/frontend/userProfile/YourAccountDataSection';

export default class YourAccountData extends Component {
  render() {
    return (
      <div id="AccountSetting" className="YourAccountData">
        <Header/>
        <YourAccountDataSection/>
     </div>
    );
  }
}

import React, {Component} from "react";
import {ToastContainer} from 'react-toastify';
import {Link} from "react-router-dom";
import FirebaseVerification from "./FirebaseVerification";
import LoadingIcon from '../common/LoadingIcon';
import {userService} from "../../../services";
import msgs from "../../../messages/en/main"
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class VerificationContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email_verify: '',
      phone_verify: '',
      loading: true,
    }
    this.checkUserIsVerify();
  }

  checkUserIsVerify() {
    const {user_id} = this.props;
    this.setState({});
    userService.checkIsverifyAccount(user_id).then(res => {
      res.text().then(res => {
        const resText = res && JSON.parse(res);
        this.setState({
          email_verify: resText.data.response.email_verify,
          phone_verify: resText.data.response.phone_verify,
          loading: false,
        });
      });
    });
  }

  continue() {
    this.redirect();
    // this.sendWelcomeEmail();
  }

  redirect() {
    const { history } = this.props;
    history.push("/login");
  }

  // sendWelcomeEmail() {
  //
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: getAppHeaders(true),
  //     body: JSON.stringify({userId: this.props.user_id})
  //   };
  //
  //   fetch(`${APP_URL}sendWelcomeEmail/`, requestOptions);
  // }

  render() {
    const {curMobile, curEmail, user_id} = this.props;
    const {email_verify, phone_verify, loading} = this.state;
    return (
      <div className="login-main">
        <div className="bg">
          <img src="/frontend/images/bg2.png" alt="bg"/>
        </div>
        <ToastContainer/>
        <div className="container">
          <div className="login-inn">
            <div className="row">

              <div className="col-sm-7 padd-right">
                <div className="login-left">
                  <h3>WELCOME</h3>
                  <p>
                    Your privacy is protected.<br/>
                    No selling of your personal data.<br/>
                    All voices given equal respect.<br/>
                    No advertisements.<br/>
                    No robots or fake accounts.<br/>
                    No political or social biases pushed by 2cents.<br/>
                    No shadow banning.
                  </p>
                  <h4>Responsible Social Media</h4>
                  <Link to="/">
                    <img src="/frontend/images/logo.png" alt="logo"/>
                  </Link>
                </div>
              </div>

              <div className="col-sm-5 padd-left">
                <div className="login-right verify">
                  <div className="logo">
                    <Link to="/">
                      <img src="/frontend/images/login-logo.png" alt="logo"/>
                    </Link>
                  </div>
                  {
                    loading ?
                      (
                        <div className="loading-tweet">
                          <LoadingIcon/>
                        </div>
                      ) :
                      (
                        <div className="login-right-inn">
                          {phone_verify === '1' ?
                            (<h4>{msgs.MOBILE_HAS_BEEN_VERIFIED} <i className="fas fa-check-circle text-success"></i>
                            </h4>) :
                            (<FirebaseVerification phoneNum={curMobile} user_id={user_id} icon="fa-phone"
                                                   loginType="PHONE" verifyCheck={() => this.checkUserIsVerify()}/>)
                          }
                          {email_verify === '1' ?
                            (<h4>{msgs.EMAIL_HAS_BEEN_VERIFIED} <i className="fas fa-check-circle text-success"></i>
                            </h4>) :
                            (<FirebaseVerification email={curEmail} user_id={user_id} icon="fa-envelope"
                                                   loginType="EMAIL" verifyCheck={() => this.checkUserIsVerify()}/>)
                          }
                          {email_verify === '1' && phone_verify === '1' ?
                            (<button onClick={() => this.continue()} className="float-right"><i
                              className="fas fa-angle-right"></i></button>) : ''
                          }
                        </div>
                      )
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

VerificationContainer.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(VerificationContainer);

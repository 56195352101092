import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  setUserEmailBunch,
  parseValue as parseUserEmailBunch,
  decrypt as decryptUserEmailBunch,
} from '../../services/userEmailBunchStorage';
import { userData as userDataPropType } from '../../misc/propTypes';
import {connect} from 'react-redux';
import { toast } from 'react-toastify';
import WelcomeLayout from '../../components/welcomeLayout';
import s from './style.module.css';

const isUserRegistrationFinished = (userData) => !!userData && userData.is_user_registered;
const isAuthenticatedUser = (userData) => !!userData;

const comeBackMessage = 'Email has been verified. Please come back to the initial browser and continue registration there.';

function AfterEmailConfirmation({ match, userData: { loading: userDataLoading, data: userData } }) {
  const { params: { status, userEmailBunch } } = match;

  const onWillUnmountToastMessageRef = useRef('');

  useEffect(() => {
    if (userEmailBunch) {
      setUserEmailBunch(userEmailBunch);
    }
  }, []);

  useEffect(() => () => {
    if (onWillUnmountToastMessageRef.current) {
      toast.info(onWillUnmountToastMessageRef.current);
    }
  }, []);

  const firstLoading = userDataLoading && !userData;

  if (firstLoading) return null;

  let redirectPath;

  if (isAuthenticatedUser(userData)) {
    const isRegistrationFinished = isUserRegistrationFinished(userData);

    redirectPath = isRegistrationFinished ? '/' : '/registration';

    let usedId;
    try {
      ({ usedId } = parseUserEmailBunch(decryptUserEmailBunch(userEmailBunch)) || {});
    } catch (e) {
      console.error('parseUserEmailBunch error', e);
    }

    if (status === '1' && usedId && usedId === userData.id && !isRegistrationFinished) {
      onWillUnmountToastMessageRef.current = 'Email has been verified.';
    }
  } else if (status === '1') {
    onWillUnmountToastMessageRef.current = comeBackMessage;

    return <EmailVerified/>;
  } else {
    onWillUnmountToastMessageRef.current = 'Something went wrong';
    redirectPath = '/login';
  }

  return <Redirect to={redirectPath} />;
}

AfterEmailConfirmation.propTypes = {
  userData: userDataPropType.isRequired,
};

const mapStateToProps = ({ userData }) => {
  return {userData};
};

export default connect(mapStateToProps)(AfterEmailConfirmation);

function EmailVerified() {
  return (
    <WelcomeLayout>
      <h4 className={s.title} >Email has been verified.</h4>
      <div className={s.text}>Please come back to the initial browser and continue registration there.</div>
    </WelcomeLayout>
  );
}


import { APP_URL, APP2_URL } from '../mainurl';
import { getAppHeaders, handleResponse } from "../helpers";

export const momentsService = {
    getAllMoments,
    getAllCategory,
    getTweetToMoment,
    getMomentDetail,
    createMoment,
    editMoment,
    searchUserByAccount,
    searchUserByTweet,
    getUserByAccount,
    getTweetBySearch,
    getEditMoment,
    removeTweetMoment,
    deleteMoment,
    LikeUnlikeSpark
};

function getAllMoments(user) {
    const data = {
      user_id: user
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP2_URL}getmomentsList`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function getAll(user) {
    const data = {
      user_id: user
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP2_URL}homePost`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function getAllCategory(user) {
    const data = {
      user_id: user.id
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP_URL}momentsCategory`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function getTweetToMoment(user) {

    const data = {
      user_id: user.id
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP_URL}addTweetToMoment`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      //console.log(raw.data);
        let data = raw.data;
        return data;
    });
}

function getMomentDetail(momentdata) {
    const data = {
      user_id: momentdata.user_id,
      moment_id: momentdata.moment_id,
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP_URL}getMomentsDetail`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function createMoment(sendRaw) {
    let formData = new FormData();
    if(sendRaw.type == 'image'){
        var fileList = sendRaw.file;
        for(var x=0;x<fileList.length;x++) {
          formData.append('image', fileList[x]);
        }
    }

    if(sendRaw.type == 'video'){
        var fileList = sendRaw.file;
        for(var x=0;x<fileList.length;x++) {
          formData.append('video', fileList[x]);
        }
    }

    formData.append('user_id', sendRaw.momentData.userid);
    formData.append('category_id', sendRaw.momentData.category);
    formData.append('title', sendRaw.momentData.title);
    formData.append('description', sendRaw.momentData.description);
    formData.append('assignPost', sendRaw.assignPost);
    formData.append('action', sendRaw.action);

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(false),
        body: formData,
    };

    return fetch(`${APP2_URL}createMoments`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function editMoment(sendRaw) {
    let formData = new FormData();

    if(sendRaw.type == 'image'){
        var fileList = sendRaw.file;
        for(var x=0;x<fileList.length;x++) {
          formData.append('image', fileList[x]);
        }
    }

    if(sendRaw.type == 'video'){
        var fileList = sendRaw.file;
        for(var x=0;x<fileList.length;x++) {
          formData.append('video', fileList[x]);
        }
    }

    formData.append('user_id', sendRaw.momentData.userid);
    formData.append('category_id', sendRaw.momentData.category);
    formData.append('title', sendRaw.momentData.title);
    formData.append('description', sendRaw.momentData.description);
    formData.append('assignPost', sendRaw.assignPost);
    formData.append('action', sendRaw.action);
    formData.append('moment_id', sendRaw.moment_id);

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(false),
        body: formData,
    };

    return fetch(`${APP_URL}editMoments`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function searchUserByAccount(data) {
    const userdata = {
      search: data.search,
      user_id: data.user_id,
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(userdata)
    };

    return fetch(`${APP_URL}getSearchSuggestion`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function searchUserByTweet(data) {
    const userdata = {
      search: data.search,
      user_id: data.user_id,
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(userdata)
    };

    return fetch(`${APP_URL}getSearchSuggestion`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function getUserByAccount(data) {
    const userdata = {
      username: data.username,
      user_id: data.user_id,
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(userdata)
    };

    return fetch(`${APP2_URL}searchByAccount`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function getTweetBySearch(data) {
    const userdata = {
      keyword: data.keyword,
      user_id: data.user_id,
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(userdata)
    };

    return fetch(`${APP_URL}searchByTweet`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function getEditMoment(momentdata) {

    const data = {
      user_id: momentdata.user_id,
      moment_id: momentdata.moment_id
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP_URL}getMomentsDetail`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function removeTweetMoment(postdata) {
  if(postdata.index)
  {
    return 1;
  }
  else {
    return 0;
  }
}

function deleteMoment(momentdata) {
    const data = {
      user_id: momentdata.user_id,
      moment_id: momentdata.moment_id,
    };

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP_URL}deleteMoment`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

function LikeUnlikeSpark(data) {

    const requestOptions = {
        method: 'POST',
        headers: getAppHeaders(true),
        body: JSON.stringify(data)
    };

    return fetch(`${APP_URL}moment_likedislike`, requestOptions)
    .then(handleResponse)
    .then(raw => {
        let data = raw.data;
        return data;
    });
}

import { momentsConstants } from '../constants';
//const initialState = {loading: true,momentsDetail: [],topMoments: [],error: false}

export function momentsingle(state = {}, action) {
  switch (action.type) {
    case momentsConstants.SINGLE_DETAIL_REQUEST:
      return {
        loading: true,
      };
    case momentsConstants.SINGLE_DETAIL_SUCCESS:
      return {
        loading: false,
        momentsDetail: action.payload.momentsDetail,
        topMoments: action.payload.topMoments
      };
    case momentsConstants.SINGLE_DETAIL_FAILURE:
      return {
        loading: false,
        error: action.error
      };
    case momentsConstants.UPDATE_ATTACHED_SPARK:
      const updatedSparkIndex = state.momentsDetail.posts.findIndex(spark => spark.post_id == action.data.post_id);
      const updatedPosts = state.momentsDetail.posts;
      updatedPosts[updatedSparkIndex] = {...updatedPosts[updatedSparkIndex], ...action.data};
      return {
        ...state,
        momentsDetail: {
          ...state.momentsDetail,
          posts: updatedPosts
        }
      };
    case momentsConstants.SET_REPORT_ATTACHED_SPARK: {
      const reportSparkIndex = state.momentsDetail.posts.findIndex(post => post.post_id == action.post_id);
      const updatedPosts = state.momentsDetail.posts;
      updatedPosts[reportSparkIndex].isReported = true;
      return {
        ...state,
        momentsDetail: {
          ...state.momentsDetail,
          posts: updatedPosts
        }
      };
    }
    case momentsConstants.DELETE_ATTACHED_SPARK:
      const deletedSparkArray = state.momentsDetail.posts.filter(spark => spark.post_id != action.data.post_id);
      return {
        ...state,
        momentsDetail: {
          ...state.momentsDetail,
          posts: deletedSparkArray
        }
      };
    case momentsConstants.LIKEMOMENTS_REQUEST:
      return {
        ...state
      };
    case momentsConstants.LIKEMOMENTS_SUCCESS:
      return {
        ...state,
        momentsDetail :{
          ...state.momentsDetail,
          totalLikes:action.likedata.moment_status.totalLikes,
          likeStatus:action.likedata.moment_status.likeStatus
        }
      };
    case momentsConstants.MARK_ATTACHED_SPARK_AS_OBJECTIONABLE: {
      const { spark_id } = action.objectionableData;
      const updatedInstantSparks = state.momentsDetail.posts.map(spark => {
        if (spark.post_id == spark_id) {
          spark.isObjectionable = true;
          return spark;
        }
        return spark;
      })
      return {
        ...state,
        momentsDetail: {
          ...state.momentsDetail,
          posts: updatedInstantSparks,
        }
      }
    }
    case momentsConstants.UNMARK_ATTACHED_SPARK_AS_OBJECTIONABLE: {
      const { spark_id } = action.objectionableData;
      const updatedInstantSparks = state.momentsDetail.posts.map(spark => {
        if (spark.post_id == spark_id) {
          spark.isObjectionable = false;
          return spark;
        }
        return spark;
      })
      return {
        ...state,
        momentsDetail: {
          ...state.momentsDetail,
          posts: updatedInstantSparks,
        }
      }
    }
    case momentsConstants.DELETE_SINGLE_MOMENT_DATE:
      return {};
    case momentsConstants.LIKEMOMENTS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
    return state
  }
}

import React, { Component } from 'react';
import { batch, connect } from 'react-redux';
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import RegistrationRoute from './RegistrationRoute';
import VerificationComponent from '../components/frontend/verification/VerificationComponent';
import Home from '../components/frontend/home/Home';
import HashTag from '../components/frontend/hashtag/HashTag';
import SparkSingleView from '../components/frontend/sparks/SparkSingleView';
import Registration from '../pages/Registration';
import AfterEmailConfirmation from './afterEmailConfirmation';
import AccountExpiredComponent from '../components/frontend/accountVerify/AccountExpiredComponent';
import RenewPlanComponent from '../components/frontend/renewplan/RenewPlanComponent';

// import Login from '../components/frontend/login/Login';
import Login from '../components/Login';
import News from '../components/frontend/moments/News';
import MomentView from '../components/frontend/moments/MomentView'
import Today from '../components/frontend/moments/Today';
import Entertainment from '../components/frontend/moments/Entertainment';
import Fun from '../components/frontend/moments/Fun';
import AllMoments from '../components/frontend/moments/AllMoments';
import Sports from '../components/frontend/moments/Sports';
import Others from '../components/frontend/moments/Others';
import Notifications from '../components/frontend/notifications/Notifications';
import UserProfile from '../components/frontend/users/UserProfile';
import FollowingUsers from '../components/frontend/users/FollowingUsers';
import FollowerUsers from '../components/frontend/users/FollowerUsers';
import UserMomentList from '../components/frontend/users/UserMomentList';
import UserLists from '../components/frontend/users/UserLists';
import UserMemberLists from '../components/frontend/users/UserMemberLists';
import ListDetailView from '../components/frontend/users/ListDetailView';
import MembersListDetail from '../components/frontend/users/MembersListDetail';
import SubscribersListDetail from '../components/frontend/users/SubscribersListDetail';
import EditList from '../components/frontend/users/EditList';
import CreateList from '../components/frontend/users/CreateList';

import UserLikes from '../components/frontend/users/UserLikes';
import UserRequests from '../components/frontend/users/UserRequests';

import EditProfile from '../components/frontend/users/EditProfile';
import AccountSetting from '../components/frontend/users/AccountSetting';
import SettingPassword from '../components/frontend/users/SettingPassword';
import MobileSetting from '../components/frontend/users/MobileSetting';
import NotificationSetting from '../components/frontend/users/NotificationSetting';
import PrivacySafetySetting from '../components/frontend/users/PrivacySafetySetting';
import BlockAccountSetting from '../components/frontend/users/BlockAccountSetting';
import YourAccountData from '../components/frontend/users/YourAccountData';
import ReferralProgram from '../components/frontend/users/ReferralProgram';
import LoginIpHistory from '../components/frontend/users/LoginIpHistory';
import CreateMoments from '../components/frontend/moments/CreateMoments';
import NotFoundView from '../components/frontend/NotFoundView';
import EditMoments from '../components/frontend/moments/EditMoments';
import Search from '../components/frontend/users/Search';
import EditProfileNew1 from '../components/frontend/users/EditProfileNew1';

import About from '../components/frontend/pages/About';
import HelpCenter from '../components/frontend/pages/HelpCenter';
import ContactInformation from '../components/frontend/pages/ContactInformation';
import Terms from '../components/frontend/pages/Terms';
import PrivacyPolicy from '../components/frontend/pages/PrivacyPolicy';
import Cookies from '../components/frontend/pages/Cookies';

import ForgotPassword from '../containers/ForgotPassword';
import ResetPassword from '../containers/ResetPassword';

import EmailVerified from '../components/frontend/accountVerify/EmailVerified';

import { gaTracking } from '../helpers';

import {StripeProvider} from 'react-stripe-elements';
import Soundfile from '../audio_notification/notification.mp3';
import StripeKeys from '../config/StripeKeys';
import {
  getUserData,
  resetUserDataLoadingFlag,
  clearUserData,
  getChatUnreadMessages,
  updateUnreadCounter,
  setUserConnection,
} from '../actions';
import { getAccessToken, onAccessTokenChange, offAccessTokenChange } from '../services/accessTokenStorage';
import ChatModule from "../components/chat";

import {
  subscribeOnUnreadSingleChatMessages,
  unsubscribeOnUnreadSingleChatMessages,
  subscribeOnUnreadGroupChatMessages,
  unsubscribeOnUnreadGroupChatMessages,
  getUnreadSingleChatMessages,
  getUnreadGroupChatMessages
} from "../actions/chatAction";

import { Echoer } from '../services/echo'
import { UserChannel } from '../constants/broadcasting/channels'
import { ChatMessageCounterUpdatedEvent } from '../constants/broadcasting/events'

const stripePublicKey = StripeKeys.stripePublicKey;
const NotificationSound = new Audio(Soundfile);

class App extends Component{

  componentDidMount() {
    this.addNetworkConnectionChangeListener();
    gaTracking.pageview(window.location.pathname + window.location.search);
    const accessToken = getAccessToken(true);
    const { getUserData, resetUserDataLoadingFlag, user, getUnreadSingleChatMessages, getUnreadGroupChatMessages, getChatUnreadMessages, dispatch } = this.props;
    if (accessToken) {
      getUserData();

      if (user && user.id) {
        getChatUnreadMessages();
        this.subscribeOnUnreadMessages(user.id);
      }
    } else {
      resetUserDataLoadingFlag();
    }

    onAccessTokenChange(this.onAccessTokenChange);
  }

  componentWillUnmount() {
    const { user: id } = this.props;
    offAccessTokenChange(this.onAccessTokenChange);
    this.unsubscribeMessageCounter(id);
    this.removeNetworkConnectionChangeListener();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user && (!prevProps.user || prevProps.user.id !== user.id)) {
      console.log('Get counts of unread messages');
    }

    if (!prevProps.user && user) {
      this.props.getChatUnreadMessages();
      this.subscribeOnUnreadMessages(user.id);
    }

    if (prevProps.user && !user) {
      this.unsubscribeMessageCounter(prevProps.user.id);
    }
  }

  addNetworkConnectionChangeListener = () => {
    window.addEventListener('offline', this.onNetworkOffline);
    window.addEventListener('online', this.onNetworkOnline);
  }

  removeNetworkConnectionChangeListener = () => {
    window.removeEventListener('offline', this.onNetworkOffline);
  }

  onNetworkOffline = () => {
    this.props.setUserConnection(false);
    toast.error("No Internet Connection. Check your network and try again.");
  }

  onNetworkOnline = () => {
    this.props.setUserConnection(true);
  }

  unsubscribeMessageCounter = (userId) => {
    Echoer.getInstance().leave(UserChannel(userId));
    Echoer.getInstance().disconnect();
  }

  onUpdateUnreadCounter = ({ countUnreadMessages: {countUnreadMessages} }) => {
    const { countUnreadMessages: prevUnreadCounter, updateUnreadCounter } = this.props;
    if (countUnreadMessages > prevUnreadCounter) {
      NotificationSound.play();
    }
    updateUnreadCounter(countUnreadMessages);
  }

  subscribeOnUnreadMessages = (userId) => {
    Echoer.getInstance()
      .private(UserChannel(userId))
      .listen(ChatMessageCounterUpdatedEvent, this.onUpdateUnreadCounter)
  }

  // addChatListeners = ({ chats: chatPrevProps }) => {
  //   const {
  //     unreadSingleChatMessages: {isFetching: prevSingleChatFetchingState},
  //     unreadGroupChatMessages: {isFetching: prevGroupChatFetchingState},
  //   } = chatPrevProps;
  //   const {
  //     user,
  //     subscribeOnUnreadSingleChatMessages,
  //     subscribeOnUnreadGroupChatMessages,
  //     chats: {
  //       unreadSingleChatMessages: {isFetching: singleChatFetchingState},
  //       unreadGroupChatMessages: {isFetching: groupChatFetchingState},
  //     },
  //   } = this.props;

  //   // Set listener for single chat
  //   if (!singleChatFetchingState) {
  //     subscribeOnUnreadSingleChatMessages(user.id);
  //   }

  //   // Set listener for group chat
  //   if (!groupChatFetchingState) {
  //     subscribeOnUnreadGroupChatMessages(user.id);
  //   }
  // }

  onAccessTokenChange = (newAccessToken) => {
    const { getUserData, clearUserData } = this.props;

    if (newAccessToken) {
      //getUserData({useLocalStorageAccessToken: true});
      window.location.reload();
    } else {
      clearUserData();
      window.location.reload();
    }
  }

  render() {
    return (
      <StripeProvider apiKey={stripePublicKey}>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <Redirect from="/home" to="/" />
          <PublicRoute path="/login" component={Login} />

          {/*START BY PAVANDEEP */}
          <RegistrationRoute exact path="/registration/:ref?" component={Registration} />
          <Route exact path="/afterEmailVerification/:status/:userEmailBunch" component={AfterEmailConfirmation} />

          <Route exact path="/verification/:mobile/:email/:id" component={VerificationComponent} />
          <Route exact path="/renew-plan/:id/:plan_id" component={RenewPlanComponent} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <PublicRoute exact path="/reset-password/:otp/:email" component={ResetPassword} />
          {/*END BY PAVANDEEP */}

          <Route exact path="/emailVerified" component={EmailVerified} />

          <Route exact path="/plan-expired" component={AccountExpiredComponent} />

          <PrivateRoute exact path="/hashtag/:slug" component={HashTag}/>
          <Route exact path="/:profilename/status/:post_id" component={SparkSingleView}/>
          <Route exact path="/status/:post_id" component={SparkSingleView}/>
          <Route exact path="/instants/view/:slug/status/:post_id" component={SparkSingleView}/>

          <PrivateRoute exact path="/chat" component={ChatModule}/>

          <PrivateRoute exact path="/:profilename/following" component={FollowingUsers}/>
          <PrivateRoute exact path="/:profilename/followers" component={FollowerUsers}/>
          <PrivateRoute exact path="/:profilename/like" component={UserLikes}/>
          <PrivateRoute exact path="/:profilename/instants" component={UserMomentList}/>
          <PrivateRoute exact path="/:profilename/lists" component={UserLists}/>
          <PrivateRoute exact path="/:profilename/lists/member" component={UserMemberLists}/>
          <PrivateRoute exact path="/:profilename/list-detail/:l_id" component={ListDetailView}/>
          <PrivateRoute exact path="/:profilename/list-detail/:l_id/status/:post_id" component={SparkSingleView}/>
          <PrivateRoute exact path="/:profilename/list-detail/:l_id/members" component={MembersListDetail}/>
          <PrivateRoute exact path="/:profilename/list-detail/:l_id/subscribers" component={SubscribersListDetail}/>

          <PrivateRoute exact path="/:profilename/edit-list/:l_id" component={EditList}/>
          <PrivateRoute exact path="/:profilename/create-list" component={CreateList}/>
          <PrivateRoute exact path="/follower-requests" component={UserRequests}/>
          <PrivateRoute exact path="/create-instants" component={CreateMoments}/>
          <PrivateRoute exact path="/instants/news" component={News}/>
          <PrivateRoute exact path="/instants/all" component={AllMoments}/>
          <PrivateRoute exact path="/instants/today" component={Today}/>
          <PrivateRoute exact path="/instants/entertainment" component={Entertainment}/>
          <PrivateRoute exact path="/instants/fun" component={Fun}/>
          <PrivateRoute exact path="/instants/sports" component={Sports}/>
          <PrivateRoute exact path="/instants/others" component={Others}/>
          <PrivateRoute exact path="/instants/view/:slug" component={MomentView}/>
          <PrivateRoute exact path="/instants/edit/:slug" component={EditMoments}/>
          <PrivateRoute path="/notifications" component={Notifications}/>
          <PrivateRoute exact path="/:profilename" component={UserProfile}/>
          <PrivateRoute path="/settings/edit-profile" component={EditProfile}/>
          <PrivateRoute path="/settings/account-setting" component={AccountSetting}/>
          <PrivateRoute path="/settings/password-setting" component={SettingPassword}/>
          <PrivateRoute path="/settings/mobile-setting" component={MobileSetting}/>
          <PrivateRoute path="/settings/notifications-setting" component={NotificationSetting}/>
          <PrivateRoute path="/settings/privacy-safety-setting" component={PrivacySafetySetting}/>
          <PrivateRoute path="/settings/block-account-setting" component={BlockAccountSetting}/>
          <PrivateRoute path="/settings/your-2cents-data" component={YourAccountData}/>
          <PrivateRoute path="/settings/referral" component={ReferralProgram}/>
          <PrivateRoute path="/settings/login-ip-history" component={LoginIpHistory}/>
          <PrivateRoute exact path="/search/:slug" component={Search}/>
          <PrivateRoute exact path="/setting/edit-profile-new1" component={EditProfileNew1}/>

          <Route exact path="/pages/about-us" component={About}/>
          <Route exact path="/pages/help-centers" component={HelpCenter}/>
          <Route exact path="/pages/contact-information" component={ContactInformation}/>
          <Route exact path="/pages/terms" component={Terms}/>
          <Route exact path="/pages/privacy-policy" component={PrivacyPolicy}/>
          <Route exact path="/pages/cookies" component={Cookies}/>
          <Route exact path="/pages/cookies" component={Cookies}/>

          <Route component={NotFoundView} />
        </Switch>
      </StripeProvider>
    );
  }
}

App.propTypes = {
  getUserData: PropTypes.func.isRequired,
  resetUserDataLoadingFlag: PropTypes.func.isRequired,
  clearUserData: PropTypes.func.isRequired,
  subscribeOnUnreadSingleChatMessages: PropTypes.func.isRequired,
  subscribeOnUnreadGroupChatMessages: PropTypes.func.isRequired,
  getUnreadSingleChatMessages: PropTypes.func.isRequired,
  getUnreadGroupChatMessages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { chatUnreadMessages } = state.chat;

  return {
    user: state.authentication.user,
    countUnreadMessages: chatUnreadMessages.countUnreadMessages,
  };
};

export default connect(mapStateToProps, {
  getUserData,
  resetUserDataLoadingFlag,
  clearUserData,
  getUnreadSingleChatMessages,
  getUnreadGroupChatMessages,
  subscribeOnUnreadSingleChatMessages,
  subscribeOnUnreadGroupChatMessages,
  getChatUnreadMessages,
  updateUnreadCounter,
  setUserConnection,
})(App);

import React, { Component } from "react";
import LoginLeftContentBar from "../../../containers/frontend/common/LoginLeftContentBar";
import LogoWithTextSectiom from "../../../containers/frontend/common/LogoWithTextSectiom";

class EmailVerified extends Component{

  render(){
    return (
      <div className="login-main">
        <div className="bg">
          <img src="/frontend/images/bg2.png" alt="bg" />
        </div>
        <div className="container">
          <div className="login-inn">
            <div className="row">
              <LoginLeftContentBar />
              <div className="col-sm-5 padd-left">
                <div className="login-right">
                  <LogoWithTextSectiom />
                    <div className="login-right-inn email" id="email-input-section">
                        <h4>Your email address verified</h4>
                        <div style={{'text-align': 'center'}}>
                          Please return to the mobile application and complete the registration process.
                        </div>
                    </div>
                  <div className="dont-have">
                    <span className="login-spl-text">Join 2cents today.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default EmailVerified;
/*
http://api2.2cents.lan/emailVerified?verify=2e57f3a541ec6ec7c372368c649212d277f1f2c4b8df5e0966f95cb273659a48?userId=1424
*/
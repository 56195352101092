export const userData = {
  USER_DATA_REQUEST: 'USER_DATA_REQUEST',
  USER_DATA_SUCCESS: 'USER_DATA_SUCCESS',
  USER_DATA_FAILURE: 'USER_DATA_FAILURE',
  USER_DATA_RESET_LOADING: 'USER_DATA_RESET_LOADING',
  USER_DATA_CLEAR_DATA: 'USER_DATA_CLEAR_DATA',
  USER_PLAN_DETAIL_REQUEST: 'USER_PLAN_DETAIL_REQUEST',
  USER_PLAN_DETAIL_SUCCESS: 'USER_PLAN_DETAIL_SUCCESS',
  USER_PLAN_DETAIL_FAILURE: 'USER_PLAN_DETAIL_FAILURE',
  SET_USER_CONNETION: 'SET_USER_CONNETION', 
};

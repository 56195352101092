import * as constants from '../../constants';

const defaultState = {
    loading: false,
    chats: [],
    error: false,
    errorMsg: '',
};

export default function chatListDataReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.UPDATE_CHATS: {
            return {
                ...state, chats: action.data
            };
        }
        // Get chats
        case constants.CHAT_LIST_REQUEST:{
            return {
                ...state, loading: true,
            };
        }
        case constants.CHAT_LIST_SUCCESS:{
            const chats = action.chats || [];

            if (state.chats.length) {
                const isGotChatsExist = chats.some(chat => chat.id === state.chats[0].id);

                if (isGotChatsExist) return {...state, chats: [ ...chats ], loading: false};
            }
            return {...state, chats: [...state.chats, ...chats], loading: false};
        }
        case constants.CHAT_LIST_FAIL:{
            return {
                ...state, error: true, errorMsg: action.errMsg, loading: false
            };
        }
        case constants.CLEAR_CHAT_LIST:{
            return {
                ...defaultState,
            };
        }
        // Delete chat
        case constants.DELETE_CHAT_REQUEST:{
            return {
                ...state, loading: true,
            };
        }
        case constants.DELETE_CHAT_SUCCESS:{
            const { chats } = state;
            const { chatId } = action;

            return {
                ...state,
                chats: chats.filter(({id}) => id !== chatId),
                loading: false
            };
        }
        case constants.DELETE_CHAT_FAIL:{
            return {
                ...state, error: true, errorMsg: action.errMsg, loading: false
            };
        }
        // Create chats
        case constants.CREATE_SINGLE_CHAT_REQUEST:{
            return {
                ...state, createSingleChatLoading: true,
            };
        }
        case constants.CREATE_SINGLE_CHAT_SUCCESS:{
            const chats = JSON.parse(JSON.stringify(state.chats));
            const isGotChatsExist = chats.some(chat => chat.id === action.data.chatData.id);

            if (!isGotChatsExist) {
                chats.unshift(action.data.chatData);
            }
            return {
                ...state, chats,
            };
        }
        case constants.CREATE_SINGLE_CHAT_FAIL:{
            return {
                ...state, error: true, errorMsg: action.errMsg, createSingleChatLoading: false
            };
        }
        case constants.CREATE_GROUP_CHAT_REQUEST:{
            return {
                ...state, loading: true,
            };
        }
        case constants.CREATE_GROUP_CHAT_SUCCESS:{
            const chats = JSON.parse(JSON.stringify(state.chats));
            chats.unshift(action.chatData);
            return {
                ...state, chats, loading: false
            };
        }
        case constants.CREATE_GROUP_CHAT_FAIL:{
            return {
                ...state, error: true, errorMsg: action.errMsg, loading: false
            };
        }
        // Invite user to chat
        case constants.INVITE_USER_TO_CHAT_REQUEST:{
            return {
                ...state, loading: true,
            };
        }
        case constants.INVITE_USER_TO_CHAT_SUCCESS:{
            const { chats } = state;
            const { chatData: { id: chat_id, members } } = action;
            const updatedGroup = chats.map(chat => chat.id === chat_id ? { ...chat, members } : chat);

            return {
                ...state,
                chats: updatedGroup,
                loading: false
            };
        }
        case constants.INVITE_USER_TO_CHAT_FAILURE:{
            return {
                ...state, error: true, errorMsg: action.errMsg, loading: false
            };
        }
        case constants.EDIT_CHAT_REQUEST:{
            return {
                ...state, loading: true,
            };
        }
        case constants.EDIT_CHAT_SUCCESS:{
            const { id, name, chat_img, description } = action.chatData;
            const chats = state.chats.map(chat => chat.id === id ? { ...chat, name, chat_img, description } : chat);
            return {
                ...state, chats, loading: false,
            };
        }
        case constants.EDIT_CHAT_FAIL:{
            return {
                ...state, error: true, errorMsg: action.errMsg, loading: false
            };
        }
        case constants.SEND_MESSAGE_SUCCESS: {
            const { message, chatId } = action;
            const { chats } = state;
            const updatedChats = chats.map(chat => {
                // if user send picture
                if (chat.id === chatId && message.mediaUrl.length) {
                    return { ...chat, isLastMessageMedia: true, lastMessage: message.text, lastMessageTimestamp: message.timestamp };
                }
                if (chat.id === chatId) {
                    return { ...chat, lastMessage: message.text, lastMessageTimestamp: message.timestamp };
                }

                return { ...chat };
            });

            return { ...state, chats: updatedChats };
        }
        default:
            return state;
    }
}
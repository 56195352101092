import React, {Component} from 'react';
import { connect } from 'react-redux';
import { sparksActions, reportReasonsActions, suggestionActions, userActions } from '../../../actions';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';

class ReportSparkPopup extends Component {
  constructor(props){
    super(props);

    const { sparks, user, dispatch} = this.props;

    this.state = {
      reasonId: 0,
      step: 10,
      parentId: 0,
      reportReasons: []
    };

  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.reportReasons!==prevState.reportReasons){
      const reasonId = nextProps.reportReasons && nextProps.reportReasons.length > 0  ? nextProps.reportReasons[0].id : 0;

      return { ...prevState, reportReasons: nextProps.reportReasons, reasonId: reasonId};
    }
    else return null;
  }

  shouldComponentUpdate(prevProps,nextProps){
    return true;
  }

  handleSecondStep = () => {

    const {user, dispatch, raw, pagetype} = this.props;
    const {reasonId, step, reportReasons} = this.state;

    const childrenEl = reportReasons.find(el => el.parent_id == reasonId);

    if(!childrenEl) {

      this.setState({step: 2, parentId: 0});

      const reportData = {
        user_id: user.id,
        post_id: raw.post_id,
        type: 'post',
        reason_id: reasonId,
        username: raw.username,
        pagetype: pagetype
      }

      dispatch(sparksActions.reportSpark(reportData));
    }else {
      this.setState({
        step: (step + 1),
        parentId: reasonId,
        reasonId: childrenEl.id
      });
    }

  }

  handlePrevStep = () => {
    // const {reportReasons} = this.props;
    const {step, parentId, reasonId, reportReasons} = this.state;

    const parentEl = reportReasons.find(el => el.id == parentId);
    const firstEl = reportReasons.filter(el => el.parent_id == parentEl.parent_id).shift();
    this.setState({step: (step -1), parentId: parentEl.parent_id, reasonId: firstEl.id});

  }

  handleReasonChange = (e) => {
    this.setState({reasonId: e.target.value});
  }

  getFirstStep = () => {
  
    // const {reportReasons} = this.props;
    const {step, parentId, reportReasons } = this.state;

    return (reportReasons?
        <div className="web-reporting-flow">
            <h3>Help us understand the problem. What is going on with this Spark?</h3>
            <form style={{paddingTop: "20px"}} action="/i/safety/report_story" method="GET">
              <fieldset className="form-group">
                <div className="overflow-list user-list">
                  {reportReasons.filter(el=> el.parent_id == parentId).map((el, index) => (
                      <div className="form-group" key={'form-group-' + el.id}>
                        <div className="form-check" >
                          <input
                                key={'radio' + el.id}
                                className="form-check-input"
                                id={'radio-' + el.id}
                                type="radio"
                                name="radioReason"
                                defaultValue={el.id}
                                defaultChecked={index === 0}
                                onChange={this.handleReasonChange}
                                />
                            <label className="form-check-label" htmlFor={'radio-' + el.id}>
                              {el.text}
                            </label>
                        </div>
                    </div>
                  ))}
                </div>
              </fieldset>

            </form>
            <div className="page-bottom">
              <p className="small-text">
                <a href="/pages/help-centers" target="_blank" className="report-link">Learn more </a>
                about reporting violations of our rules.
              </p>
            </div>
            <div id="new-report-flow-control" className="modal-body submit-section">

              <div className="mid-icon">
                <button type="button" onClick={this.handleSecondStep}>Next</button>
              </div>
              {(step > 10) && <div className="mid-icon">
                <button type="button" onClick={this.handlePrevStep}>Back</button>
              </div>}
            </div>
        </div>
    :'')
  }

  handleMuteUser = (user_id) => {
    const { dispatch,user, pagetype } = this.props;
    const muteData = {
      muted_user_id: user_id,
      user_id: user.id,
    }
    dispatch(sparksActions.muteUnmuteAccount(muteData, pagetype));
    this.handleClose();
  }

  handleBlockUser = (user_id) => {
    const { dispatch,user } = this.props;
    const blockData = {
      receiver_id: user_id,
      user_id: user.id,
    }
    dispatch(sparksActions.blockUser(blockData));
    this.handleClose();
  }

  FollowUnfollowUser = (user_id) => {
    const { dispatch, user, raw } = this.props;

    if(user && user_id){
      const sendRaw = {
        follower_id : user_id,
        user_id: user.id,
      }
      dispatch(suggestionActions.FollowUnfollowOther(sendRaw));
    }
    else {
      toast.error(msgs.SOMETHING_WENT_WRONG);
    }

    this.handleClose();
  }

  getSecondStep = () => {
    const {user, raw, followers} = this.props;
    const me = followers.find(el => el.user_id == user.id) || null;

    return (
        <div className="web-reporting-flow">
            <h3>Thanks for letting us know.</h3>
            <div className="col-sm-12">
              {(me && me.status == 0 || raw.isFollower) &&
              <div className = "row pt-sm-3">
                   <div className = "col-sm-6">
                      <button id = "unfollow-btn" className = "btn btn-outline-primary"
                              onClick = {() => this.FollowUnfollowUser(raw.user_id)} name = "action"
                              data-dismiss = "modal" value = "unfollow">Unfollow</button>
                   </div>
                   <div className = "col-sm-6">
                     <p>You will no longer see Sparks and receive Direct Messages from {raw.at_username}. If {raw.at_username}
                       mentions you in a Spark, you will still receive a notification.</p>
                   </div>
               </div>
              }
               <div className="row pt-sm-3">
                   <div className="col-sm-6">
                      <button id="mute-btn" onClick={() => this.handleMuteUser(raw.user_id)} className="btn btn-outline-primary" name="action" data-dismiss="modal" value="mute">Mute</button>
                   </div>
                   <div className="col-sm-6">
                     <p>Since you are following {raw.at_username}, you will no longer see {raw.at_username}'s Sparks. If {raw.at_username} mentions you or sends you a Direct Message, you will still receive a notification.</p>
                   </div>
               </div>
               <div className="row pt-sm-3">
                   <div className="col-sm-6">
                      <button id="mute-btn" onClick={() => this.handleBlockUser(raw.user_id)} className="btn btn-outline-danger" name="action" data-dismiss="modal" value="mute">Block</button>
                   </div>
                   <div className="col-sm-6">
                     <p>{raw.at_username} will not be able to follow you or view
                       your Sparks, and you will not see Sparks or notifications from {raw.at_username}.
                       <a href="/pages/help-centers" target="_blank"> Learn more </a>
                       about what it means to block an account.
                     </p>
                   </div>
               </div>
            </div>
            <div id="new-report-flow-control" className="modal-body submit-section">
              <div className="mid-icon">
                <button className="btn btn-primary" data-dismiss="modal" aria-label="Close" type="button" onClick={this.handleClose}>Done</button>
              </div>
            </div>
        </div>
    )
  }

  handleClose = () => {
    setTimeout(()=>{
      this.setState({step: 10});
    }, 1000);
  }

  render() {
    const {step} = this.state;

    return (
        <div className="modal fade" id="reportThisSpark" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered create-new" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Report This Spark</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {step >= 10 && <this.getFirstStep/>}
                {step == 2 && <this.getSecondStep/>}
              </div>
            </div>
          </div>
        </div>
    )
  }
}

function mapStateToProps(state){
  return {
    raw: state.replydata ? state.replydata.data : '',
    user: state.authentication.user,
    followers: state.profileinfo.data != undefined ? state.profileinfo.data.followers : [],
  };
}
export default connect(mapStateToProps)(ReportSparkPopup);

'use strict';

import React, {useCallback, useState, useEffect, useRef} from 'react';
import { createDisplayedSpark, getDateOptions, makeUserName, processImages } from '../../../../helpers';
import PropTypes from 'prop-types';
import {
  user as userPropType,
} from '../../../../misc/propTypes';
import cx from 'classnames';
import Picker from 'emoji-picker-react';
import s from './chatComponent.module.scss';
import {toast} from "react-toastify";
import msgs from "../../../../messages/en/main";
import { fileSizeLimits } from '../../../../misc/fileSizeLimits';

const cursorPointerStyle = {cursor: 'pointer'};

export function MessageForm({ removeSharedLink, disabled, sendMessage, newMessageLoading,
  sharedLink, goToProfile, sendingTick, hostUser, messageLoading, loadingMessages, chatId, setIsImgAdded,
}) {
  const [message, setMessage] = useState('');
  const [showCharLimitError, setShowCharLimitError] = useState(false);
  const [showEmojiModal, setVisabilityEmojiModal] = useState(false);
  const messageInput = useRef(null);
  const emojiModal = useRef(null);

  const [messageFiles, setMessageFiles] = useState([]);
  const [imagePreviewUrls, setImagePreviewUrls] = useState([]);

  useEffect(() => {
    if (sendingTick) {
      setMessage('');
    }
    
    function handleClickOutside(event) {
      if (emojiModal.current && !emojiModal.current.contains(event.target)) {
        setVisabilityEmojiModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    setIsImgAdded(imagePreviewUrls.length > 0);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sendingTick, emojiModal, imagePreviewUrls]);

  useEffect(() => {
    setMessage('');
    messageInput.current.focus();
  }, [chatId]);

  const onSendMessage = useCallback((e) => {
    e.preventDefault();

    if (!loadingMessages && (message.match(/\S/) || messageFiles.length > 0)) {
      sendMessage({
        data: messageFiles,
        message,
        chatId
      });
      setMessage('');
      setImagePreviewUrls([]);
      setMessageFiles([]);
    }
    messageInput.current.focus();
  }, [sendMessage, message, setMessage, messageFiles, imagePreviewUrls]);

  async function handleImageChange(e) {
    e.preventDefault();
    if (messageFiles.length + e.target.files.length > 5) {
      toast.error(msgs.IMAGES_COUNT_ALLOWED);
      return;
    }

    let files = Array.from(e.target.files);
    // let processedFiles = await processImages(files);
    let BreakException = {};

    if (files.some(file => file.size > fileSizeLimits.image)) {
      toast.error(msgs.MAX_IMG_SIZE);
      return;
    }

    try {
      files.forEach((file, i) => {
        let reader = new FileReader();
        let fType = file.type;

        if (fType.match('image.*')) {
          reader.onloadend = () => {
            setMessageFiles(prevState => [...prevState, file]);
            setImagePreviewUrls(prevState => [...prevState, reader.result]);
          };
          reader.readAsDataURL(file);
        } else {
          throw BreakException;
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;

      toast.error(msgs.IMAGES_ARE_ALLOWED);
      setImagePreviewUrls([]);
      setMessageFiles([]);
    }
  }



  const onEmojiClick = useCallback((event, emojiObject) => {
    const currentMessage = messageInput.current.value;
    if(currentMessage.length >= 499) return setShowCharLimitError(true);
    setMessage(currentMessage + emojiObject.emoji);
  }, []);

  const onKeyPress = useCallback((e) => {
    if (e.keyCode !== 13) return;

    if (e.altKey === true || e.ctrlKey === true) {
      setMessage((v) => (v + '\n'));
    } else if (!newMessageLoading && !e.shiftKey && !loadingMessages && (message.match(/\S/) || messageFiles.length > 0)) {
      e.preventDefault();
      sendMessage({
        data: messageFiles,
        message,
        chatId
      });
      setMessage('');
      setImagePreviewUrls([]);
      setMessageFiles([]);
    }
    e.preventDefault();
  }, [sendMessage, message, messageFiles, imagePreviewUrls, newMessageLoading]);

  const onTextChange = (e) => {
    const value = e.target.value;

    if (message.length === 500 && value.length >= 500) {
      return setShowCharLimitError(true);
    }

    if(value.length > 500) {
      setMessage(value.slice(0, 500));

      return setShowCharLimitError(true);
    }

    setMessage(value);
  };

  useEffect(() => {
    setShowCharLimitError(message.length > 500);
  }, [message, chatId])

  const toggleEmojiModal = useCallback(() => {
    setVisabilityEmojiModal(!showEmojiModal);
  }, [showEmojiModal]);

  const removeImg = (index) => {
    imagePreviewUrls.splice(index, 1);
    messageFiles.splice(index, 1);
    setImagePreviewUrls([...imagePreviewUrls]);
    setMessageFiles([...messageFiles]);
  };

  const [isTextTwoLined, setTextTwoLined] = useState(false);

  return (
    <div>
      <div className={s.selectedImagesSparks}>
        {imagePreviewUrls.length > 0 &&
          (imagePreviewUrls.map((imagePreviewUrl, index) => (
            <div key={index} className="img-grid-spark">
              <img src={imagePreviewUrl} />
              <i className="fas fa-times-circle" onClick={() => removeImg(index)}></i>
            </div>
          ))) 
        }
      </div>
      <form className={s.sendMessMain}>
        <div className={s.textAreaContainer}>
          {sharedLink && (
            <div className="chat shared-link">
              <img
                style={cursorPointerStyle}
                src={hostUser.profile_image}
                alt={'@' + hostUser.username}
                title={'@' + hostUser.username}
                onClick={() => goToProfile(hostUser.username)}
              />
              <div className="msg-inner-area">
                <span style={cursorPointerStyle} onClick={() => goToProfile(hostUser.username)}>
                  {makeUserName(hostUser.username)}
                </span>
                <p style={cursorPointerStyle} onClick={() => goToProfile(hostUser.username)}>
                  {createDisplayedSpark(sharedLink)}
                </p>
                <span className="time-left">{new Date().toLocaleString("en-US", getDateOptions())}</span>
              </div>
              <button type="button" style={{color: '#c1c1c1'}} onClick={removeSharedLink} className="close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          )}
          <textarea
            ref={messageInput}
            className={`${s.sendMessInput} ${isTextTwoLined ? s.twoLinedMessInput : ''} ${showCharLimitError ? s.erroredInput : ''}`}
            placeholder="Type your Message"
            value={message}
            onKeyDown={onKeyPress}
            onChange={(e) => {
              setTextTwoLined(e.target.scrollHeight >= 56);
              onTextChange(e);
            }}
          />
        </div>

        <div className={s.submitContainer}>
          <div className={cx(s.addPhoto, { [`${s.disabled}`]: messageFiles.length === 5 })}>
            <label htmlFor="uploadedFiles">
              <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0259 1.80005C12.7261 -0.499756 8.98098 -0.499756 6.68362 1.80005L0.311546 8.16724C0.270042 8.20874 0.248069 8.26489 0.248069 8.32349C0.248069 8.38208 0.270042 8.43823 0.311546 8.47974L1.21242 9.38062C1.2536 9.42162 1.30935 9.44463 1.36745 9.44463C1.42556 9.44463 1.4813 9.42162 1.52248 9.38062L7.89455 3.01343C8.68557 2.22241 9.73782 1.78784 10.856 1.78784C11.9741 1.78784 13.0264 2.22241 13.815 3.01343C14.606 3.80444 15.0406 4.85669 15.0406 5.97241C15.0406 7.09058 14.606 8.14038 13.815 8.9314L7.32082 15.4231L6.26858 16.4753C5.28469 17.4592 3.68557 17.4592 2.70168 16.4753C2.22561 15.9993 1.96438 15.3669 1.96438 14.6931C1.96438 14.0193 2.22561 13.387 2.70168 12.9109L9.14456 6.47046C9.30813 6.30933 9.52297 6.219 9.75246 6.219H9.75491C9.9844 6.219 10.1968 6.30933 10.3579 6.47046C10.5215 6.63403 10.6094 6.84888 10.6094 7.07837C10.6094 7.30542 10.5191 7.52026 10.3579 7.6814L5.09182 12.9426C5.05032 12.9841 5.02834 13.0403 5.02834 13.0989C5.02834 13.1575 5.05032 13.2136 5.09182 13.2551L5.9927 14.156C6.03388 14.197 6.08962 14.22 6.14773 14.22C6.20584 14.22 6.26158 14.197 6.30276 14.156L11.5664 8.89233C12.0523 8.4065 12.3184 7.76196 12.3184 7.07593C12.3184 6.38989 12.0498 5.74292 11.5664 5.25952C10.563 4.2561 8.93215 4.25854 7.92873 5.25952L7.30373 5.88696L1.4883 11.7C1.0936 12.0923 0.780732 12.5592 0.567833 13.0734C0.354935 13.5876 0.246246 14.139 0.248069 14.6956C0.248069 15.8259 0.689964 16.8879 1.4883 17.6863C2.31594 18.5115 3.39993 18.9241 4.48391 18.9241C5.56789 18.9241 6.65188 18.5115 7.47707 17.6863L15.0259 10.1423C16.1367 9.02905 16.752 7.54712 16.752 5.97241C16.7544 4.39526 16.1392 2.91333 15.0259 1.80005Z" fill="#EF4639"/>
              </svg>
            </label>
            <input
              type={"file"}
              accept={"image/jpeg, image/png, image/jpg, image/gif"}
              className={s.uploadPhotoInput}
              onChange={handleImageChange}
              id={"uploadedFiles"}
              name={"uploadedFiles[]"}
              multiple={"multiple"}
              value={''}
              disabled={messageFiles.length === 5}
            />
          </div>
          <div className={s.emojiContainer}>
            {
              showEmojiModal && (
                <div ref={emojiModal} className={s.emojiModalContainer}>
                  <Picker
                    disableSkinTonePicker={true}
                    onEmojiClick={onEmojiClick}
                  />
                </div>
              )
            }
            <div className={cx(s.emojiButton, { [`${s.active}`]: showEmojiModal })} onClick={toggleEmojiModal}>
              <i className="far fa-smile-wink"></i>
            </div>
          </div>
          
          <button
            className={s.messSend}
            disabled={disabled || (!message.trim() && messageFiles.length === 0) || loadingMessages}
            onClick={onSendMessage}
          >
          { newMessageLoading ?  <i className="fas fa-spinner fa-pulse"></i> : 'Send' }
          </button>
        </div>
        {showCharLimitError && (
          <div className={s.charLimitError}>{msgs.MESSAGE_LENGTH_LIMIT}</div>
        )}
      </form>
    </div>
  );
}

MessageForm.propTypes = {
  removeSharedLink: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  sendMessage: PropTypes.func.isRequired,
  sharedLink: PropTypes.string,
  goToProfile: PropTypes.func.isRequired,
  sendingTick: PropTypes.number.isRequired,
  hostUser: userPropType.isRequired,
  loadingMessages: PropTypes.bool.isRequired,
};
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import WelcomeLayout from '../welcomeLayout';
import { ToastContainer, toast } from 'react-toastify';
import { PasswordInput, SimpleInput } from "../common/input";
import images from '../../config/images';
import { Link } from "react-router-dom";
import s from './login.module.scss';
import { useFormik } from "formik";
import { userActions } from "../../actions";


function Login(props) {

  const { loading, dispatch, alert, isPasswordShow, isConnectionAvailable } = props;

  const onSignUpClick = (e) => {
    if (!isConnectionAvailable){
      e.preventDefault();
      toast.error("No Internet Connection. Check your network and try again.");
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values, formikHelpers) => {
      const { email: email, password: password } = values;

      const errors = {
        email: validateEmail(email),
        password: validatePassword(password),
      };

      formikHelpers.setErrors(errors);
      if (hasError(errors)) return;

      dispatch(userActions.login(values));
    }
  });

  return (
    <>
      <WelcomeLayout rightBarLogoClassName={s.loginRightBarLogo}>
        <ToastContainer/>
          <div className={s.loginContainer}>
            <div className={s.loginChooseBlock}>
              <h4>LOGIN HERE </h4> or <Link onClick={onSignUpClick} to="/registration"> SIGN UP</Link>
            </div>
            <form name="form" className={s.formSignin} onSubmit={formik.handleSubmit}>
              {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
              <SimpleInput
                value={formik.values.email}
                type={'text'}
                name={'email'}
                id={'inputEmail'}
                placeholder={'Email or username'}
                onChange={formik.handleChange}
                errMessage={formik.errors.email}
                maxLength={254}
              />
              <PasswordInput
                value={formik.values.password}
                type={isPasswordShow ? 'text' : 'password'}
                name={'password'}
                id={'inputPassword'}
                placeholder={'Password'}
                onChange={formik.handleChange}
                errMessage={formik.errors.password}
              />
              <div className={s.singinForgotpassBlock}>
                <button className="float-left" type="submit">
                  <span>SIGN IN </span>
                  <i className="fas fa-angle-right" />
                </button>
                { loading && <img src={images.loading} />}
                <p className={s.forgotPass}>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </p>
              </div>
            </form>
            <div className={s.marketBlock}>
              <a href="https://apps.apple.com/ua/app/2cents-social-media/id1479051728">
                <img src="/frontend/images/app-download-btn1.png" alt="logo" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.twocent">
                <img src="/frontend/images/app-download-btn2.png" alt="logo" />
              </a>
            </div>
          </div>
      </WelcomeLayout>
    </>
  );
}

function mapStateToProps(state) {
  const { loading, user } = state.authentication;
  const { isConnectionAvailable } = state.userData;
  const { alert } = state;

  return {
    loading,
    alert,
    user,
    prevUrl: state.url.preloginUrl,
    isPasswordShow: false,
    isConnectionAvailable,
  };
}

export default connect(mapStateToProps)(Login);


function hasError(errors) {
  return Object.values(errors).find(i => !!i);
}

function validateEmail(value) {
  if (validateRequiredInput(value)) {
    return 'Email or username is required';
  }
}

function validatePassword(value) {
  if (validateRequiredInput(value)) {
    return 'Password is required';
  }
}

function validateRequiredInput(value) {
  let error;
  if (!value) {
    error = 'Required';
  }
  return error;
}

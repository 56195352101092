import {getAppHeaders, handleResponse, logout} from '../helpers';
import {APP_URL, APP2_URL} from '../mainurl';
import { setAccessToken } from './accessTokenStorage';
import { setPassword, removePasswordAsync, encrypt } from '../services/credentialStorage';
import { removeUserEmailBunchAsync } from '../services/userEmailBunchStorage';

export const userService = {
  login,
  logout,
  getAll,
  editProfile,
  editImage,
  editImageNew,
  getBlockedUser,
  unblock,
  muteUnmuteAccount,
  accountSettings,
  deactivateAccount,
  getNotifySetting,
  setNotifySetting,
  setPrivacy,
  userSettings,
  getAllNotification,
  searchProcess,
  getSearchData,
  searchUserChat,
  getPagesData,
  getPlan,
  checkUser,
  acountVerify,
  checkIsverifyAccount,
  PlanRenew,
  ForgetPassword,
  confirmOtp,
  resetPassword,
  getHeaderResponse,
  SubCancel,
  setReferralModalVisability,
  setCongratsReferralModalVisability,
};

function login(user) {
  const password = user ? user.password : '';
  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(user)
  };

  return fetch(`${APP2_URL}login`, requestOptions)
  // return fetch(`${APP2_URL}sign_in`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let user = raw.data;
      // login successful if there's a jwt token in the respons
      if (user.status) {
        setAccessToken(raw.data.access_token);
        const { response: { is_user_registered } = {} } = user;

        if (is_user_registered) {
          removePasswordAsync()
            .catch(e => console.error('removePasswordAsync error',e));
          removeUserEmailBunchAsync()
            .catch(e => console.error('removeUserEmailBunchAsync error',e));
        } else {
          if (password) {
            setPassword(encrypt(password));
          }
        }
      }
      return user;
    });
}

function getHeaderResponse(user) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify({ user_id: user.id })
  };

  return fetch(`${APP2_URL}getHeaderResponse`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let user = raw.data;
      return user;
    });
}

function getAll(user) {
  const data = {
    user_id: user
  };

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}homePost`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function editProfile(data) {
  let formData = new FormData();
  formData.append('user_id', data.user_id);
  formData.append('fullname', data.firstname + ' ' + data.lastname);
  formData.append('firstname', data.firstname);
  formData.append('lastname', data.lastname);
  // formData.append('user_bio', data.user_bio);
  // formData.append('website_url', data.website_url);

  if (data.year && data.month && data.day) {
    let year = data.year;
    let day = data.day;
    let month = ++data.month;

    let dob = year + '/' + (month.toString().length > 1 ? month : '0' + month) + '/' + (day.toString().length > 1 ? day : '0' + day);

    formData.append('dob', dob);
  }

  if (data.dob) {
    formData.append('dob', data.dob);
  }

  formData.append('address', data.location);
  // formData.append('address_2', data.address_2);
  // formData.append('city', data.city);
  // formData.append('state', data.state);
  // formData.append('zipcode', data.zipcode);

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(false),
    body: formData
  };

  return fetch(`${APP2_URL}editProfile`, requestOptions)
    .then(handleResponse)
    .then(function (raw) {
      let data = raw.data;
      return data;
    });
}

function editImage(data, type) {
  let formData = new FormData();
  var file = data.file;

  formData.append(type, file);
  formData.append('user_id', data.user_id);

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(false),
    body: formData
  };

  return fetch(`${APP_URL}editProfile`, requestOptions)
    .then(handleResponse)
    .then(function (raw) {
      let data = raw.data;
      return data;
    });
}

function editImageNew(data, type) {

  const user = {
    user_id: data.user_id,
    file: data.file,
    type: type,
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(user)
  };

  return fetch(`${APP2_URL}editProfileImage`, requestOptions)
    .then(handleResponse)
    .then(function (raw) {
      // console.log(raw);
      let data = raw.data;
      return data;
    });
}

function getBlockedUser(user) {
  const data = {
    user_id: user
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP_URL}getBlockedList`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function unblock(blockData) {
  const data = {
    user_id: blockData.user_id,
    receiver_id: blockData.receiver_id,
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP_URL}block_user`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function muteUnmuteAccount(unmuteData) {
  const data = {
    user_id: unmuteData.user_id,
    muted_user_id: unmuteData.muted_user_id,
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP_URL}muteUnmuteAccount`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function accountSettings(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}accountSettings`, requestOptions)
    .then(handleResponse)
    .then(function (raw) {
      let data = raw.data;
      return data;
    });
}

function deactivateAccount(data) {

  const requestOptions = {
    method: 'DELETE',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}delete`, requestOptions)
    .then(handleResponse)
    .then(function (raw) {
      let data = raw.data;
      return data;
    });
}

function getNotifySetting(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}getNotifySetting`, requestOptions)
    .then(handleResponse)
    .then(function (raw) {
      let data = raw.data;
      return data;
    });
}

function setNotifySetting(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP_URL}setNotifySetting`, requestOptions)
    .then(handleResponse)
    .then(function (raw) {
      let data = raw.data;
      return data;
    });
}

function setPrivacy(data) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}setPrivacy`, requestOptions)
    .then(handleResponse)
    .then(function (raw) {
      let data = raw.data;
      return data;
    });
}

function userSettings(rawData) {
  const data = {
    user_id: rawData
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}userSettings`, requestOptions)
    .then(handleResponse)
    .then(function (raw) {
      let data = raw.data;
      return data;
    });
}

function getAllNotification(user) {
  const data = {
    user_id: user
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}getNotificationList`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function searchProcess(data) {
  const userdata = {
    search: data.search,
    user_id: data.user_id,
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(userdata)
  };

  return fetch(`${APP_URL}getSearchSuggestion`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function getSearchData(user) {
  const data = {
    user_id: user.user_id,
    search: user.search
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP_URL}getSearchData`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function searchUserChat(user) {
  const data = {
    user_id: user.user_id,
    search: user.search
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP_URL}chatUserSearchSuggestion`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function getPagesData() {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true)
  };

  return fetch(`${APP_URL}getPages`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}


function getPlan() {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
  };

  return fetch(`${APP_URL}getPlan`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function checkUser(user) {
  const data = {
    email: user.email,
    username: user.username,
    phone_number: user.phone_number,
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}userCheckBeforSignUp`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function setReferralModalVisability() {
  const requestOptions = {
    method: 'PUT',
    headers: getAppHeaders(true),
  };

  return fetch(`${APP2_URL}toggleShowReferralModal`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
    });
}

function setCongratsReferralModalVisability() {
  const requestOptions = {
    method: 'PUT',
    headers: getAppHeaders(true),
  };

  return fetch(`${APP2_URL}toggleShowCongratsReferralModal`, requestOptions)
      .then(handleResponse)
      .then(raw => {
        let data = raw.data;
        return data;
      });
}

function acountVerify(data) {
  const sendData = {
    type: data.type,
    email: data.email ? data.email : '',
    phone: data.phone ? data.phone : ''
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(sendData)
  };

  return fetch(`${APP_URL}accountVerify`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      return raw.data;
    });
}

function checkIsverifyAccount(userId) {
  const sendData = {
    user_id: userId
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(sendData)
  };

  return fetch(`${APP_URL}checkIsverifyAccount`, requestOptions)
    .then(res => {
      return res
    });

}

function PlanRenew(user) {

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(user)
  };

  return fetch(`${APP2_URL}planRenew`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      let user = raw.data;

      if (user.status) {
        setAccessToken(raw.data.access_token);
      }

      return raw.data;
    });
}


// BY PAVANDEEP 1 APRIL 2019
function ForgetPassword(email) {
  const sendData = {
    email: email
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(sendData)
  };

  return fetch(`${APP2_URL}forgotPassword`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      return raw.data;
    });

}


function confirmOtp(user) {
  const sendData = {
    otp: user.otp,
    email: user.email
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(sendData)
  };

  return fetch(`${APP2_URL}confirmOtp`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      return raw.data
    })
}

function resetPassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(data)
  };

  return fetch(`${APP2_URL}resetPassword`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      return raw.data
    })
}

function SubCancel(user_id) {
  const sendData = {
    user_id: user_id
  }

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: JSON.stringify(sendData)
  };

  return fetch(`${APP2_URL}StripeSubscriptionCancel`, requestOptions)
    .then(handleResponse)
    .then(raw => {
      const data = raw.data;
      return data;
    }).catch(err => {
      console.error(err);
    });
}

import React, {Component} from 'react';
import { connect } from 'react-redux';

class LoadingIcon extends Component {
  constructor(props){
    super(props);
  }
  render(){
    const { height, width, margin } = this.props;
    return (
      <img className={this.props.pTop}
        style={this.props.type !== "input" 
        ? {display: 'block',margin: margin ? margin :'50px auto',height: height ? height : '150px',width: width ? width : '150px'}
        : {margin: '4px auto',
          height: '40px',
          width: '40px',
          position: 'absolute',
          right: '0'}
        }
        src='/frontend/images/loader.gif'
        alt=""
      />
    )
  }
}


function mapStateToProps(state){
  return {
    user: state.authentication.user,
  };
}
export default connect(mapStateToProps)(LoadingIcon);

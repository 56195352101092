import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import s from './congratulations.module.scss';


function Congratulations(props) {
  const { onToLogin, isTrial, token } = props;


  const onClick = useCallback((e) => {
    typeof onToLogin === 'function' && onToLogin(e);
  }, [onToLogin]);

  return (
    <div className={s.congratsContainer}>
      <div className={s.congratsTitle}>
        Congratulations! You’re IN.
      </div>
      { isTrial ? 
        token && token.length > 6 ? 
        (<div className={s.congratsTrialInfo}>
          Now you have free lifetime account
         </div>)
        :
        (<div className={s.congratsTrialInfo}>
        You have chosen the 14-day trial version. After it ends, you will not be able to use our service until
        you purchase a paid subscription. You can buy a subscription in your profile.
      </div>): ""}
      <button className={s.toLogin} onClick={onClick}>
        <span>Continue to login </span>
        <i className="fas fa-angle-right" />
      </button>
    </div>
  );
}

Congratulations.propTypes = {
  onToLogin: PropTypes.func,
  isTrial: PropTypes.bool,
};

export default Congratulations

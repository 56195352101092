import "@babel/polyfill";
import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './containers/App';
import history from './history';
// import ReactGA from 'react-ga';
import { gaTracking } from './helpers';
import Params from './config/Params';
import { ConnectedRouter } from 'connected-react-router';
import store from './store';

gaTracking.init(history, Params.gaTrackingId);

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
	     <App />
    </ConnectedRouter>
  </Provider>, document.getElementById('root')
);

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import debounce from 'lodash.debounce';
import ReactTooltip from 'react-tooltip';
import { groupChatData as groupChatDataPropType, } from '../../../../misc/propTypes';
import OptionMenu from '../../common/optionMenu/OptionMenu';
import s from './chat.module.scss';
import setSubmenuPosition from '../../../../helpers/setSubmenuPosition';

export default function GroupChatLists({ loading, list, onChatClick, activeChatDataId, containerStyle, leaveGroupChat, setChatImg }) {
  const [showPending, setShowChatPending] = useState(loading);
  const groupChatList = useRef(null);

  const hideSkeletDebounce = debounce(() => setShowChatPending(false), 1000);
  const showSkeletDebounce = debounce(() => setShowChatPending(true), 1000);

  if (!loading) {
    hideSkeletDebounce();
  } else {
    showSkeletDebounce();
  }

  useEffect(() => {

    if (groupChatList.current) {
      groupChatList.current.addEventListener('click', (event) => setPosition(event, 'dropdown-menu-parent', 'dropdown-menu-wrapper'));
    }

    return () => {
      groupChatList.current.removeEventListener('click', setPosition);
    };
  }, []);

  const setPosition = (event, parentClass, submenuClass) => setSubmenuPosition(event, parentClass, submenuClass);

  const setActiveChat = (groupId) => {
    if (showPending) {
      return;
    }

    onChatClick(groupId);
  };

  const getCountOfMembers = (members) => {
    let membersCount = 0;
    let memberPrefix = "participants";
    if (typeof members === "object") {
      membersCount = members.length;
    }
    if (membersCount === 0 || membersCount === 1) {
      memberPrefix = "participant";
    }

    return `${membersCount} ${memberPrefix}`;
  };

  return (
    <div ref={groupChatList} className={cs('overflow-list', 'group-list', containerStyle)}>
      {list.length > 0 ? (
        list.filter((chat => !chat.notShow)).map((group, i) =>
          <div
            className={cs({
              [`${s.chatContainer}`]: true,
              [`${s.activeChat}`]: activeChatDataId === group.id && !showPending,
              [`${s.pendingChat}`]: showPending,
              [`${s.unread}`]: group.countOfUnreadMessages > 0
            })}
            key={group.id}
            onClick={() => setActiveChat(group.id)}
          >
            <div className={s.redDot} />
            <div className={s.chatAvatarContainer}>
              {setChatImg(group)}
            </div>
            <div className={`${s.chatNameMessageInfo}  text-overflow-ellipsis`}>
              <h3 title={group.name}>{group.name}</h3>
              <p>{getCountOfMembers(group.members)}</p>
            </div>

            <div className={cs("dropdown-menu-parent", s.chatMenuContainer)}>
              <i className="fas fa-ellipsis-v" />
              <OptionMenu
                active={false}
                styles={s.chatOptionMenu}
                options={
                  [{
                    title: "Leave Chat",
                    onClick: (e) => leaveGroupChat(group.id, e),
                  }]
                }
              />
            </div>  
          </div>
        )
      ) : (
        null
      )}
    </div>
  );
}

GroupChatLists.propTypes = {
  loading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(groupChatDataPropType).isRequired,
  onChatClick: PropTypes.func.isRequired,
  activeChatDataId: PropTypes.string,
};

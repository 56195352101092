import React, {Component} from 'react';
import { connect } from 'react-redux';
import BottomWidget from '../common/BottomWidget';
import UserListActionWidget from '../userElements/UserListActionWidget';
import UserProfileTopSection from '../userElements/UserProfileTopSection';
import UserListWidget from '../userElements/UserListWidget';
import EditListForm from '../common/EditListForm';
import ListSparksData from './ListSparksData';

import { userActions,alertActions,sparksActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class EditListSection extends Component {
  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });
  }
  render(){
    const { lists, profileinfo } = this.props;
    return (
      <div>
      <div className="edit-profile">
        <div className="content-part sports-main">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 left-sidebar">
              <UserListActionWidget isPrivate={profileinfo.isPrivate} name={profileinfo.name} username={profileinfo.username} created={profileinfo.created} />
              </div>
              <div className="col-sm-6 feed-container">
              <ListSparksData />
              </div>
              <div className="col-sm-3 right-sidebar">
                <BottomWidget/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditListForm listDetail={lists.listDetail} />
      </div>
    );
  }
};

EditListSection.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    profileinfo: state.profileinfo.data != undefined ? state.profileinfo.data : [],
    lists: state.lists != undefined ? state.lists : [],
    username: ownProps.username,
  };
}
export default withRouter(connect(mapStateToProps)(EditListSection));

import React, {Component} from 'react';
import { connect } from 'react-redux';
import PureList from './PureList';
import ReplyPopUpText from '../common/ReplyPopUpText';
import ReSparkAction from '../common/ReSparkAction';
import ReportSparkPopup from "../common/ReportSparkPopup";

import { reportReasonsActions } from '../../../actions';


class attachMomentSpark extends Component {
  constructor(props){
    super(props);
  }

  shouldComponentUpdate(prevProps,nextProps){
    return true;
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reportReasonsActions.getReportReasons());
  }

  render()
  {
    const { momentSparks,type,user } = this.props;
    let filtredMomentSparks = momentSparks.items;
    if (filtredMomentSparks && user) {
      filtredMomentSparks = filtredMomentSparks.filter(spark => {
        return (!user.showObjectionableContent || spark.isObjectionable === false) && (!spark.isReported || spark.isReported === "0")
      });
    }
    return (
      <div className="home-feed-main">
      {momentSparks && momentSparks.items != undefined ? (
        filtredMomentSparks.map((raw,index) =>
            <PureList key={raw.post_id} rawData={raw} type={type} index={index}/>
            )
          ) : ('')
       }
       <ReplyPopUpText />
       <ReportSparkPopup {...this.props}/>
       <ReSparkAction />
      </div>
    )
  }
}

function mapStateToProps(state,ownProps){

  return {
    type: ownProps.type,
    user: state.authentication.user,
    sparks: state.sparks,
    reportReasons: state.reportreasons.items,
  };
}
export default connect(mapStateToProps)(attachMomentSpark);

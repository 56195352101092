import { APP2_URL } from '../mainurl';
import {
  getAppHeaders,
  getAppHeadersWithSockets,
  handleResponse,
  getChatListsData,
  getChatMessagesData,
  getChatUnreadMessagesData,
  deleteChatData,
  createSingleChatData,
  createGroupChatData,
  inviteUserToChatData,
  sendMediaData,
  editChatData,
} from "../helpers";

export async function sendMessage(fileList, message, chatId) {
  let formData = new FormData();

  if (fileList && fileList.length > 0) {
    for (var x = 0; x < fileList.length; x++) {
      formData.append('media[]', fileList[x], fileList[x].name);
    }
  }

  formData.append('message', message);

  const requestOptions = {
    method: 'POST',
    headers: getAppHeadersWithSockets(false),
    body: formData,
  };

  return await fetch(`${APP2_URL}chat/${chatId}/message`, requestOptions)
    .then(handleResponse)
    .then(raw => raw.data);
}

export function getChatLists() {
  const requestOptions = {
    method: 'GET',
    headers: getAppHeaders(true),
  };

  return fetch(`${APP2_URL}chats`, requestOptions)
    .then(handleResponse)
    .then(raw => raw.data);
  // return Promise.resolve(getChatListsData(true))
  //   // .then(handleResponse)
  //   .then(raw => raw.data);
}

export function getChatMessages(chatId, countSkipMessages) {
  const requestOptions = {
    method: 'GET',
    headers: getAppHeaders(true),
  };

  return fetch(`${APP2_URL}chat/${chatId}/messages/${countSkipMessages}`, requestOptions)
    .then(handleResponse)
    .then(raw => raw.data);
  // return Promise.resolve(getChatMessagesData(true))
  //   // .then(handleResponse)
  //   .then(raw => raw.data);
}

export function getUnreadMessages() {
  const requestOptions = {
    method: 'GET',
    headers: getAppHeaders(true),
  };

  return fetch(`${APP2_URL}chats/unreadMessages`, requestOptions)
    .then(handleResponse)
  // return Promise.resolve(getChatUnreadMessagesData(true))
    // .then(raw => raw.data);
}

export function leaveChat(chatId) {
  const requestOptions = {
    method: 'DELETE',
    headers: getAppHeaders(true),
  };

  return fetch(`${APP2_URL}chat/${chatId}`, requestOptions)
    .then(handleResponse)
    .then(raw => raw.data);
  // return Promise.resolve(deleteChatData(true))
  //   // .then(handleResponse)
  //   .then(raw => raw.data);
}

export async function createSingleChat(data) {
  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: data,
  };

  // return Promise.resolve(createSingleChatData(true, data))
  return await fetch(`${APP2_URL}chat`, requestOptions)
    .then(handleResponse)
    .then(raw => raw.data);
}

export function createGroupChat(data) {
  const formData = new FormData();

  for (const key in data) {
    if (Array.isArray(data[key])) {
      data[key].forEach(member => formData.append('members[]', member))
    } else {
      formData.append(key, data[key]);
    }
}
  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(false),
    body: formData,
  };

  return fetch(`${APP2_URL}chat`, requestOptions)
    .then(handleResponse)
    .then(raw => raw.data);
  // return Promise.resolve(createGroupChatData(true, data))
  //   // .then(handleResponse)
}

export function inviteUserToChat(data, chatId) {
  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: data,
  };

  return fetch(`${APP2_URL}chat/${chatId}/invite`, requestOptions)
    .then(handleResponse)
    .then(raw => raw.data);
  // return Promise.resolve(inviteUserToChatData(true, data))
  //   // .then(handleResponse)
  //   .then(raw => raw.data);
}

export function sendMedia(data) {
  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(true),
    body: data,
  };
    // return fetch(`${APP2_URL}sendMedia`, requestOptions)
    return Promise.resolve(sendMediaData(true, data))
       // .then(handleResponse)
       .then(raw => raw.data);
}

export function editChat(data, chatId) {
  const formData = new FormData();

  for (const key in data) {
    if (Array.isArray(data[key])) {
      data[key].forEach(member => formData.append('members[]', member))
    } else {
      formData.append(key, data[key]);
    }
}

  const requestOptions = {
    method: 'POST',
    headers: getAppHeaders(false),
    body: formData,
  };

  return fetch(`${APP2_URL}chat/${chatId}`, requestOptions)
    .then(handleResponse)
    .then(raw => raw.data);
  // return Promise.resolve(editChatData(true, data))
  //   // .then(handleResponse)
  //   .then(raw => raw.data);
}

export function markAsRead(data, chatId) {
  const requestOptions = {
    method: 'PUT',
    headers: getAppHeaders(true),
    body: data,
  };

  return fetch(`${APP2_URL}chat/${chatId}/markMessagesAsRead`, requestOptions)
    .then(handleResponse)
    .then(raw => raw.data);
  // return Promise.resolve(editChatData(true, data))
  //   // .then(handleResponse)
  //   .then(raw => raw.data);
}
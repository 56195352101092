import React, {Component} from 'react';
import {connect} from 'react-redux';
import UserSideBarSection from '../common/UserSideBarSection';
import {userActions } from '../../../actions';
import { toast } from 'react-toastify';
import msgs from "../../../messages/en/main";
import ReactTooltip from 'react-tooltip';
import LoadingIcon from '../common/LoadingIcon';
import { PasswordInput } from '../../../components/common/input';

class PasswordSettingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        user_id: this.props.user.id,
        oldpassword: '',
        newpassword: '',
        confirmpassword: '',
        action: 'change-password',
      },
      submitted: false,
      passwordnotvalid: false,
      oldPasswordNotCorrect: false,
      passwordsNotMatch: false,
      showTooltip: false,
    };
  }

  handleChange = (event) => {
    const {name, value} = event.target;
    const {user} = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      },
      showTooltip: false,
    });
  };

  clearFields = () => {
    const {user} = this.state;
    this.setState({
      submitted: false,
      passwordnotvalid: false,
      oldPasswordNotCorrect: false,
      passwordsNotMatch: false,
      user: {
        ...user,
        oldpassword: '',
        newpassword: '',
        confirmpassword: '',
      }
    });
  };

  errorsToDefault = () => {
    this.setState({
      passwordnotvalid: false,
      oldPasswordNotCorrect: false,
      passwordsNotMatch: false,
    });
  };

  oldPasswordCheck = () => {
    this.setState({oldPasswordNotCorrect: true});
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({submitted: true});
    const {user} = this.state;
    const {dispatch} = this.props;

    this.errorsToDefault();

    if (user.oldpassword && user.newpassword && user.confirmpassword) {

      if (!user.newpassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[()@$!%*?&#])[A-Za-z\d()@$!%*?&#]{8,}$/)) {
        this.setState({
          passwordnotvalid: true,
          showTooltip: true,
        });

        return;
      } 

      if (user.newpassword !== user.confirmpassword) {
        this.setState({
          passwordsNotMatch: true,
        });

        return;
      }
      dispatch(userActions.accountSettings(user, this.clearFields, this.oldPasswordCheck));
    }
  };

  renderHelpBlock = (inputName) => {
    //todo - delete helpblock and insert error msg in PasswordInput
    const {
      user, 
      submitted, 
      passwordnotvalid,
      passwordsNotMatch,
      oldPasswordNotCorrect,
    } = this.state;

    if(submitted) {
      switch(inputName) {
        case 'current password':
          if(!user.oldpassword || oldPasswordNotCorrect) {
            return (
            <div className="help-block">
              {!user.oldpassword && msgs.CURRENT_PASSWORD_IS_REQUIRED}
              {oldPasswordNotCorrect && msgs.CURRENT_PASSWORD_NOT_CORRECT}
            </div>
            )
          }
        break;
        case 'new password':
          if(!user.newpassword || passwordnotvalid) {
            return (
            <div className="help-block">
              {!user.newpassword && msgs.PASSWORD_IS_REQUIRED}
              {passwordnotvalid && msgs.PASSWORD_NOT_VALID}
            </div>
            )
          }
          break;
        case 'verify password':
          if(!user.confirmpassword || passwordsNotMatch) {
            return (
            <div className="help-block">
              {!user.confirmpassword && msgs.CONFIRM_PASSWORD_IS_REQUIRED}
              {passwordsNotMatch && msgs.PASSWORDS_DONT_MATCH}
            </div>
            )
          }
          break;
          default:
          break;
      }
    }

    return;
  }

  render() {
    const {user, submitted, passwordnotvalid, oldPasswordNotCorrect, passwordsNotMatch} = this.state;
    const { alert, loading } = this.props;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;
    const tooltipMsg = "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character ( @$!%*?&# )";
    const newPasswordhasError = !user.newpassword || passwordnotvalid;
    const oldPasswordHasError =  oldPasswordNotCorrect || !user.oldpassword;
    const verifyPasswordhasError = !user.confirmpassword || passwordsNotMatch;

    return (
      <div className="content-part sports-main">
        <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          <div className="row">
            <UserSideBarSection/>
            <div className="col-sm-6 feed-container">
              <div className="twiter-left1 access-mid sett-block">
                <h3>Password </h3>
                <p>Change your password.</p>
                <div className={`password-change-info info-block ${passwordnotvalid ? "error" : ""}`} data-for="changePassword" data-tip={tooltipMsg}>
                  <i className="fas fa-info-circle"/>
                </div>
                <ReactTooltip
                  id="changePassword"
                  type="dark"
                  place="right"
                  effect="solid"
                  multiline={true}
                  className="password-change-tooltip tooltip"
                />
                <div className="add-your-phone">
                  <form>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="country-label">
                          <label>Current password</label>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div
                          className={'country-inpu forgot' + (submitted && oldPasswordHasError ? ' has-error' : '')}>
                          <PasswordInput 
                            type="password" 
                            name="oldpassword" 
                            id="oldpassword"
                            onFocus={() => this.setState({ oldPasswordNotCorrect: false })}
                            value={user.oldpassword}
                            onChange={this.handleChange}
                            placeholder={""}
                          />
                          {loading && <LoadingIcon type="input" />}
                         {this.renderHelpBlock('current password')}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="country-label">
                          <label>New password</label>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div
                          className={'country-inpu' + (submitted && newPasswordhasError ? ' has-error' : '')}  data-toggle="tooltip">
                          <PasswordInput
                            type="password"
                            name="newpassword"
                            id="newpassword"
                            value={user.newpassword}
                            onChange={this.handleChange}
                            onFocus={() => this.setState({ showTooltip: true })}
                            onBlur={() => this.setState({ showTooltip: false })}
                            placeholder={""}
                          />
                          {this.state.showTooltip &&
                            <span className="tooltiptext">{tooltipMsg}</span>
                          }
                         {this.renderHelpBlock('new password')}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="country-label">
                          <label>Verify password</label>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className={'country-inpu' + (submitted && verifyPasswordhasError ? ' has-error' : '')}>
                          <PasswordInput
                            type="password"
                            name="confirmpassword"
                            id="confirmpassword"
                            value={user.confirmpassword}
                            onChange={this.handleChange}
                            placeholder={""}
                          />
                         {this.renderHelpBlock('verify password')}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-5">
                      </div>
                      <div className="col-sm-7">
                        <div className="access-mid-btm-right">
                          <input type="button" defaultValue="SAVE CHANGES"
                                 onClick={this.handleSubmit}/>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, loading } = state.authentication;
  const { alert } = state;
  return {
    user,
    alert,
    loading,
  };
}

export default connect(mapStateToProps)(PasswordSettingSection);

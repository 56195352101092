import React, { Component } from 'react';
import { connect } from 'react-redux';
import BottomWidget from '../common/BottomWidget';
import LoadingIcon from '../common/LoadingIcon';

import { alertActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class PageContent extends Component {
  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    const { user,holdflag } = this.props;
    if (user && holdflag == false) {
        //dispatch(sparksActions.getAll(user.id));
    }
  }

  render()
  {
    const { contentData, loading, type, rawInfo, alert } = this.props;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;

    return (
      <div className="content-part">
        <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          <div className="row">
            <div className="col-sm-12">
              <div className="privacy blog">
                {loading ? (
                  <div className="loading-tweet">
                    <LoadingIcon />
                  </div>
                  ) : ('')
                }
                {type != 'about' ? (
                  <div dangerouslySetInnerHTML={{__html: contentData}} />
                  ) : (
                    <div>
                  		<div className="abt_bidding">
                        {rawInfo.feature_img != undefined ?
                          <div className="bid_img"><img src="/frontend/images/about.png" alt=""/></div>
                          : ('')
                        }
                        <div className="bid_detail">
                          <div dangerouslySetInnerHTML={{__html: rawInfo.feature_text}} />
                        </div>
                      </div>

                      <div className="vision_detail">
                          <div dangerouslySetInnerHTML={{__html: rawInfo.content}} />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="col-sm-12 text-center">
              <BottomWidget />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PageContent.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    alert: state.alert,
    contentData: ownProps.contentData != undefined ? ownProps.contentData : 'N/A',
    loading: ownProps.loading ? ownProps.loading : false,
    type: ownProps.type,
    rawInfo : ownProps.rawInfo,
  };
}
export default withRouter(connect(mapStateToProps)(PageContent));

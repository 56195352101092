import React, {Component} from 'react';
import TrendsForYou from '../sparks/TrendsForYou';
import WhoHaveToFollowWidget from '../common/WhoHaveToFollowWidget';
import BottomWidget from '../common/BottomWidget';
import $ from 'jquery';
import { Link, } from 'react-router-dom';
import {connect} from 'react-redux';
import LoadingIcon from '../common/LoadingIcon';
import { userActions } from '../../../actions';
import {formatDateFromUTCtoLocal} from '../../../helpers';

// const st={float:"left",padding:"0px 10px 0px 0px"}
// const rt={float:"right",marginTop: "20px"}
const sty={
  float:"left",
  width: "100%",
  display: "block",
  padding:"0px 10px 0px 0px"
}

const styl={
  width: "100%",
  float: "left",
  margin: "10px",
  marginTop: "20px",
}

class notificationsContent extends Component {
  constructor(props){
    super(props);

    this.state = {
      visible : 10,
    };
    this.loadMore = this.loadMore.bind(this);
    this.filterOptions = this.props.filterOptions;
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 10};
    });
  }

  componentDidMount() {
    $(document).on('click', '.allow-focus', (e) => {
      $('.noti-top .dropdown-menu').addClass('show');
      $('.noti-top .dropdown').addClass('show');
    });
  }

  componentWillUnmount() {
    $(document).off('click', '.allow-focus');
  }

  shouldComponentUpdate(prevProps,nextProps){
    return true;
  }

  handleChangeFilter = (event) => {
    const { name } = event.target;
    const { dispatch } = this.props;
    this.filterOptions[name] = !this.filterOptions[name];
    dispatch(userActions.filterNotification(this.filterOptions));
  }

  render()
  {
    const {notificationdata,filterOptions,alert} = this.props;
    const { retweet,
      post_like,
      post_comment,
      send_request,
      follow,
      send_message,
      add_to_list,
      tweet_in_moment
    } = filterOptions;
    // const DATE_OPTIONS = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minutes: 'numeric' };

    const filtredNotification = notificationdata.userNotification && notificationdata.userNotification.filter(notification => filterOptions[notification.notification_type]);
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;
    return(
      <div id="notificationsContent">
        <div className="content-part sports-main">
          <div className="container">
            {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
            <div className="row">
              <div className="col-sm-3">
                <TrendsForYou/>
              </div>
              <div className="col-sm-6 feed-container">
              <div className="twiter-left1 what-spark">
                 <div className="noti-top">
                  <div className="dropdown">
                    <button className="btn1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <span>
                        Filter
                      </span>
                    </button>
                    <div className="dropdown-menu checkbox-menu allow-focus" aria-labelledby="dropdownMenuButton" style={{ minWidth: '240px' }}>
                      <ul>
                        <li>
                          <span>Follow requests</span>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={follow}
                              name="follow"
                              id="follow"
                              onClick={this.handleChangeFilter}
                            />
                            <span className="slider round"></span>
                          </label>
                        </li>
                        <li>
                          <span>Send request</span>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={send_request}
                              name="send_request"
                              id="send_request"
                              onClick={this.handleChangeFilter}
                            />
                            <span className="slider round"></span>
                          </label>
                        </li>
                        <li>
                          <span>Likes</span>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={post_like}
                              name="post_like"
                              id="post_like"
                              onClick={this.handleChangeFilter}
                            />
                            <span className="slider round"></span>
                          </label>
                        </li>
                        <li>
                          <span>Resparks</span>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={retweet}
                              name="retweet"
                              id="retweet"
                              onClick={this.handleChangeFilter}
                            />
                            <span className="slider round"></span>
                          </label>
                        </li>
                        <li>
                          <span>Comments</span>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={post_comment}
                              name="post_comment"
                              id="post_comment"
                              onClick={this.handleChangeFilter}
                            />
                            <span className="slider round"></span>
                          </label>
                        </li>
                        {/*<li>*/}
                        {/*  <span>Messages</span>*/}
                        {/*  <label className="switch">*/}
                        {/*    <input*/}
                        {/*      type="checkbox"*/}
                        {/*      checked={send_message}*/}
                        {/*      name="send_message"*/}
                        {/*      id="send_message"*/}
                        {/*      onClick={this.handleChangeFilter}*/}
                        {/*    />*/}
                        {/*    <span className="slider round"></span>*/}
                        {/*  </label>*/}
                        {/*</li>*/}
                        <li>
                          <span>Lists</span>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={add_to_list}
                              name="add_to_list"
                              id="add_to_list"
                              onClick={this.handleChangeFilter}
                            />
                            <span className="slider round"></span>
                          </label>
                        </li>
                        <li>
                          <span>Instants</span>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={tweet_in_moment}
                              name="tweet_in_moment"
                              id="tweet_in_moment"
                              onClick={this.handleChangeFilter}
                            />
                            <span className="slider round"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                   <button><Link to="settings/notifications-setting">Settings</Link></button>
                 </div>
                <div className="noti-inn">
                 <h4>See when members follow, like, Respark or comment on your post.</h4>
                </div>

                {filtredNotification != undefined ? (
                  <div  style={styl} className="notify_main_wrap">
                    {filtredNotification.slice(0,this.state.visible).map((notify, index) => (
                        <ol style={sty} className="notifylist" key={index} status={notify.status}>
                           <div className="col-sm-12">
                             <div className="row">
                             <div className="left col-sm-2">
                               <div className="twit-mid-img">
                                 <Link to={"/" + notify.username.slice(1)}>
                                   <img src={notify.sender_profile_image} alt="logo"/>
                                 </Link>
                               </div>
                             </div>
                             <div className="tweet-right col-sm-8">
                               <p className="notify-message">{notify.message}</p>
                               <span>{formatDateFromUTCtoLocal(notify.created)}</span>
                             </div>
                             {
                               notify.status == 1 &&
                               (<div  className="col-sm-2">
                                 <Link to={notify.url != undefined ? notify.url : ''}
                                       className="btn btn-danger">View</Link>
                               </div>)
                             }
                             {  notify.status == 0 &&
                                 (<div  className="col-sm-2">
                                   <div title={notify.status_msg || ''} className="btn btn-danger disabled">View</div>
                                 </div>)
                             }
                             { notify.status == -1 && '' }
                             </div>
                           </div>
                       </ol>
                      ))
                    }
                    {filtredNotification != undefined ?
                      (this.state.visible < filtredNotification.length ? (
                         <button onClick={this.loadMore} type="button" className="load-more btn btn-danger">Load more</button>
                       ) : ('')) : ('')
                     }
                   </div>
                  ) : (
                      <LoadingIcon />
                  )
                }
                </div>
              </div>
              <div className="col-sm-3">
                <WhoHaveToFollowWidget />
                <BottomWidget />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  //console.log(state);
    return {
        filterOptions: state.notificationdata.filterOptions,
        notificationdata: state.notificationdata != undefined ? state.notificationdata : [],
        user: state.authentication.user,
        alert: state.alert,
    };
}
export default connect(mapStateToProps)(notificationsContent);

import React, {Component} from 'react';
import { connect } from 'react-redux';
import UserSideBarSection from '../common/UserSideBarSection';
import { toast } from 'react-toastify';
import msgs from "../../../messages/en/main";
import { userActions } from '../../../actions';
import ReactTooltip from 'react-tooltip';

class AccountSettingSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      user: {
        user_id: this.props.user.id,
        username: this.props.user.username,
        email: this.props.user.email,
        phone_number: this.props.user.phone_number,
        gender: this.props.user.gender,
        payment_system: this.props.user.payment_system,
        action: 'account',
        plan_status: this.props.planDetail.plan_status,
      },

      usernamenotvaild: false,
      submitted: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { planDetail: { plan_status }} = this.props;

    if(prevProps.planDetail.plan_status !== plan_status) {
      this.setState({user:{plan_status: plan_status}})
    }

  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });

    if (name == 'username') {
      this.usernameValidate(event);
    }
  }

  usernameValidate = event => {
    const { value } = event.target;
    if (typeof value !== "undefined") {
      if(!value.match(/^([a-zA-Z0-9]{4,})+$/)){
        this.setState({ usernamenotvaild: true, });
      } else {
        this.setState({ usernamenotvaild: false, });
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { user, usernamenotvaild } = this.state;
    const { dispatch } = this.props;

    if(user.username && user.phone_number && usernamenotvaild != true) {
      dispatch(userActions.accountSettings(user));
    } else {
      toast.error(msgs.FILL_REQUIRED_INFORMATION);
    }
  }

  checkUser = (event) => {
    const { dispatch } = this.props;
    const { name, value } = event.target;

    if(name == 'username') {
      const rawData = {
        email: '',
        username: value,
        phone_number: '',
      };

      dispatch(userActions.checkUser(rawData));
    }

  }

  render() {
    const { alert, planDetail:{ plan_expiry, plan_id, plan_cost }, user: { payment_system, lifetime_vip } , planCancelLoading} = this.props;
    const { user, submitted, usernamenotvaild , user:{ plan_status }} = this.state;
    const blockButton = (this.state.user.username === this.props.user.username) && (this.state.user.gender === this.props.user.gender);
    const userNameFieldMaxLength = 30;
    const isAllowCancelPlan = payment_system === 'Stripe';
    let tooltipMsg = '';

    if (!isAllowCancelPlan) {
      tooltipMsg = payment_system === 'iTunes'
        ? msgs.ITUNES_SUBSCRIPTION_TOOLTIP
        : msgs.TRIAL_SUBSCRIPTION_TOOLTIP;
    }

    if (plan_status === 'cancel') {
      tooltipMsg = msgs.SUBSCRIPTION_PLAN_CANCELED;
    }

    if (plan_status === 'free') {
      tooltipMsg = msgs.FREE_SUBSCRIPTION_TOOLTIP;
    }

    const freeAccountMsg = msgs.FREE_SUBSCRIPTION_EXPIRATION;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;

    return (
      <div className="content-part sports-main">
        <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          <div className="row">
            <UserSideBarSection/>
            <div className="col-sm-6 feed-container">
              <div className="twiter-left1 access-mid sett-cents">
                <h3>Account</h3>
                <div className="mobile-app cents setting">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="country-label">
                        <label>Username</label>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className={'country-inpu forgot' + (submitted && usernamenotvaild ? ' has-error' : '')}>
                        <input
                          type="text"
                          defaultValue={user.username}
                          name="username"
                          id="username"
                          maxLength={userNameFieldMaxLength}
                          onChange ={this.handleChange}
                        />
                        {/* <h5>https://2cents.com/{user.username}</h5> */}
                        {submitted && (user.username && usernamenotvaild) &&
                        <div className="help-block">Username minimum length is 4 and also special char not allowed</div>
                        }
                        {submitted && !user.username &&
                        <div className="help-block">Username is required</div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="country-label">
                        <label>Email</label>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="country-inpu forgot">
                        <input type="text" defaultValue={user.email} name="email" id="email" readOnly/>
                        <h5>Email will not be publicly displayed.</h5>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="country-label">
                        <label>Phone Number</label>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className={'country-inpu forgot'}>
                        <input type="text" defaultValue={user.phone_number} name="phone_number" id="phone_number" readOnly/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="setting-content">
                  {/* <h3>Content</h3>
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="access-mid-btm-label">
                        <label>Gender</label>
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div className="country-inpu forgot">
                        <select defaultValue={user.gender ? user.gender : ''} className="langu-select" name="gender" id="gender" onChange ={this.handleChange}>
                          <option value="">select your gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-5">
                    </div>
                    <div className="col-sm-7">
                      <div className="access-mid-btm-right">
                        <input 
                          type="button"
                          defaultValue="SAVE CHANGES"
                          onClick={this.handleSubmit}
                          disabled={blockButton}
                          style={{ background: blockButton ? '#343a40' : '#ef4639', cursor: blockButton ? "default" : "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="setting-content">
                  <h3>Plan information</h3>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="access-mid-btm-label">
                        <table className="table">
                          <thead>
                          <tr>
                            <th>
                              <label>PLAN TYPE : </label>
                            </th>
                            <th>
                              <label>{plan_cost}</label>
                            </th>
                          </tr>
                          <tr>
                            <th>
                              <label>PLAN EXPIRY : </label>
                            </th>
                            <th>
                              <label>{plan_status === 'free' ? freeAccountMsg : plan_expiry}</label>
                            </th>
                          </tr>
                          </thead>
                        </table>
                        { !!plan_status && (
                          <>
                            { plan_status == 'cancel' || plan_status == 'free' || lifetime_vip ? (
                              <div className="row">
                                <div className="col-sm-5"></div>
                                <div className="col-sm-7">
                                  <div className="cancel-plan-info info-block" data-for="cancelPlan" data-tip={tooltipMsg}>
                                    <i className="fas fa-info-circle"/>
                                  </div>
                                  <ReactTooltip
                                    id="cancelPlan"
                                    type="dark"
                                    place="left"
                                    effect="solid"
                                    multiline={true}
                                    className="tooltip"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="row">
                                <div className="col-sm-5"></div>
                                { isAllowCancelPlan ? (
                                  <div className="col-sm-7">
                                    <div className="access-mid-btm-right">
                                      <input
                                        type="button"
                                        defaultValue="Cancel Plan"
                                        onClick={() => this.setState({ submitted: true })}
                                        data-toggle="modal"
                                        data-target="#cancel-plan"
                                        disabled={planCancelLoading}
                                      />
                                    </div>
                                  </div>
                                ) 
                                // : (
                                //   <div className="col-sm-7">
                                //     <div className="cancel-plan-info info-block" data-for="cancelPlan" data-tip={tooltipMsg}>
                                //       <i className="fas fa-info-circle"/>
                                //     </div>
                                //     <ReactTooltip
                                //       id="cancelPlan"
                                //       type="dark"
                                //       place="left"
                                //       effect="solid"
                                //       multiline={true}
                                //       className="cancel-plan-tooltip"
                                //     />
                                //   </div>
                                // )}
                                : ('') }
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                { payment_system !== 'iTunes' ? (
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#deactivate-acc"
                    className={`deactivate ${!isAllowCancelPlan ? 'deactivate_no-cancel' : ''}`}
                    onClick={() => this.setState({ submitted: true })}
                  >
                    Deactivate your account
                  </button>
                ) : ('')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

function mapStateToProps(state) {
  const { user, planCancelLoading } = state.authentication;
  const { alert, userData } = state;
  return {
    user,
    alert,
    planCancelLoading,
    planDetail: {
      plan_expiry: user.plan_expiry || "",
      plan_status: user.plan_status,
      plan_id: user.plan_id,
      plan_cost: user.plan_cost || user.plan_status || "",
    },
  };
}

export default connect(mapStateToProps)(AccountSettingSection);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PureList from './PureList';
import ReplyPopUpText from '../common/ReplyPopUpText';
import ReSparkAction from '../common/ReSparkAction';
import LoadingIcon from '../common/LoadingIcon';
import ReportSparkPopup from "../common/ReportSparkPopup";
import BlockedContent from '../common/BlockedContent';

class SparkLikeList extends Component {
  constructor(props)
  {
    super(props);

    this.state = {
      visible : 10
    };
      this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 10};
    });
  }

  shouldComponentUpdate(prevProps,nextProps){
    return true;
  }

  renderPosts() {
    const { postLikes } = this.props;

    if( postLikes.length > 0 ) {
      return (
        postLikes.slice(0,this.state.visible).map((raw,index) =>
          <PureList key={raw.post_id} rawData={raw} index={index}/>
        )
      )
    } else {
      return (<h4>No posts</h4>);
    }
  }

  render()
  {
    const { postLikes, isHidden, isHiddenByYou, username } = this.props;

    if((isHidden || isHiddenByYou ) && username) {
      return (
        <BlockedContent
          username={username}
          isHidden={isHidden} 
          isHiddenByYou={isHiddenByYou} 
        />
      );
    }

    if(!postLikes) {
      return (
        <div className="loading-tweet">
          <LoadingIcon />
        </div>);
    }

    return (
      <div className="home-feed-main">
        {this.renderPosts()}
        {this.state.visible < postLikes.length ? (
            <button 
              onClick={this.loadMore} 
              type="button" 
              className="load-more btn btn-danger">
              Load more
            </button>
          ) : ('')
        }
        <ReplyPopUpText />
        <ReportSparkPopup {...this.props}/>
        <ReSparkAction />
      </div>
    )
  }
}

function mapStateToProps(state,ownProps){
  return {
    postLikes: ownProps.postLikes,
    reportReasons: state.reportreasons.items,
    user: state.authentication.user,
    isHidden: state.profileinfo.data != undefined && state.profileinfo.data.blockByUser == 1 ? true : false,
    isHiddenByYou: state.profileinfo.data != undefined && state.profileinfo.data.blockByYou,
  };
}
export default connect(mapStateToProps)(SparkLikeList);

import passwordValidator from 'password-validator';
import { PASSWORD_RESTRICTIONS } from '../config';

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const passwordValidatorSchema = new passwordValidator();
passwordValidatorSchema
  .is().min(PASSWORD_RESTRICTIONS.MIN_LENGTH)     // Minimum length 8
  .is().max(PASSWORD_RESTRICTIONS.MAX_LENGTH)     // Maximum length 255
  .has().uppercase()                              // Must have uppercase letters
  .has().lowercase()                              // Must have lowercase letters
  .has().symbols()                                // Must have at least one symbol
  .has().digits()                                 // Must have digits
  .has().not().spaces()                           // Should not have spaces
  .has(/^[a-z\d@$!%*?&#]+$/i)// @$!%*?&#

export const passwordValidatorErrorsCodes = {
  min: 'min',
  max: 'max',
  lowercase: 'lowercase',
  uppercase: 'uppercase',
  symbols: 'symbols',
  digits: 'digits',
  spaces: 'spaces',
  has: 'has',
};


export const isStrongPassword = (value) => passwordValidatorSchema.validate(value, {list: true});

import {userConstants, userData as userDataConstants} from '../constants';

const {
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAILURE,
  USER_DATA_RESET_LOADING,
  USER_DATA_CLEAR_DATA,
  USER_PLAN_DETAIL_REQUEST,
  USER_PLAN_DETAIL_SUCCESS,
  USER_PLAN_DETAIL_FAILURE,
  SET_USER_CONNETION,
} = userDataConstants;

const {
  LOGOUT,
  DEACTIVATE_USER_SUCCESS,
  SET_REFERRAL_VISABILITY_CLOSE,
  SET_REFERRAL_VISABILITY_REQUEST,
  SET_REFERRAL_VISABILITY_SUCCESS,
  SET_REFERRAL_VISABILITY_FAIL
} = userConstants;


const initState = {
  loading: true, 
  data: undefined, 
  plan_detail: undefined,
  showReferralModal: true,
  isConnectionAvailable: true,
};

export default function userData(state = initState, action) {
  switch (action.type) {
    case USER_DATA_REQUEST:
      return {...state, loading: true};
    case USER_DATA_SUCCESS:
      return {...state, loading: false, data: action.data};
    case USER_DATA_FAILURE:
      return {...state, loading: false};
    case USER_DATA_RESET_LOADING:
      return {...state, loading: false};

    case USER_DATA_CLEAR_DATA:
    case LOGOUT:
    case DEACTIVATE_USER_SUCCESS:
      return {...state, loading: false, data: initState.data};

      //userPlanDetail
    case USER_PLAN_DETAIL_REQUEST:
      return {...state, loading: true};
    case USER_PLAN_DETAIL_SUCCESS:
      return {...state, loading: false, plan_detail: action.planDetail};
    case USER_PLAN_DETAIL_FAILURE:
      return {...state, loading: false};

    case SET_REFERRAL_VISABILITY_CLOSE:
      return {...state, showReferralModal: false};
    case SET_REFERRAL_VISABILITY_SUCCESS:
      return {...state, data: action.user};
    case SET_USER_CONNETION:
      return {...state, isConnectionAvailable: action.connected};
    default:
      return state;
  }
}

import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import ProgressBar from "./ProgressBar";
import SubscriptionPlanStep from "./SubscriptionPlan";
import PersonalDataStep from "./PersonalData";
import IdentityDataStep from "./IdentityData";
import CreditCardStep from "./CreditCard";
import Congratulations from "./congratulations";
import { toast } from "react-toastify";
import useRegistrationForms from "./useRegistrationForms";
import withProps from "../../HOCs/withProps";
import { saveAnswers, getSubscriptionPlans } from "../../api/registration";
import { useHistory } from "react-router";

const stepsNames = {
  SUBSCRIPTION_PLAN: "subscriptionPlan",
  PERSONAL_DATA: "personalData",
  IDENTITY_DATA: "identityData",
  CREDIT_CARD: "creditCard"
};

const {
  SUBSCRIPTION_PLAN,
  PERSONAL_DATA,
  IDENTITY_DATA,
  CREDIT_CARD
} = stepsNames;

const config = [
  {
    name: SUBSCRIPTION_PLAN,
    component: SubscriptionPlanStep,
    persistAnswersOnServer: false,
    requiredFields: ["plan_id"]
  },
  {
    name: PERSONAL_DATA,
    component: PersonalDataStep,
    requiredFields: ["username"]
  },
  {
    name: IDENTITY_DATA,
    component: IdentityDataStep,
    requiredFields: [
      "phone_number",
      "country_phone_code",
      "phone_verify",
      "email",
      "email_verify"
    ],
    //shouldBeTrue: ['is_user_registered'],
    shouldBeFalse: ["is_temp_user"]
  },
  {
    name: CREDIT_CARD,
    component: CreditCardStep
  }
];

const showErrorPopup = error => {
  toast.error(error || "Something went wrong");
};

function PayedRegistration({
  registerUser: sendAnswers,
  finishUserRegistration,
  userData,
  getSubscriptionPlans,
  logout,
  onBackToQuestion,
  referralHash,
}) {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  useEffect(() => {
    getSubscriptionPlans()
      .then(({ data: subscriptionPlans } = {}) => {
        setSubscriptionPlans(subscriptionPlans);
      })
      .catch(e => {
        console.error("Getting subscription plans error: ", e);
        showErrorPopup("Getting subscription plans error");
      });
  }, []);

  const history = useHistory();

  const isLoading = false; //TODO

  const navigateBack = useCallback(() => {
    onBackToQuestion()
  }, []);

  const {
    Step,
    onStepDone,
    saveAnswers,
    tickNum,
    stepNum,
    nextDisabled,
    setNextDisabled,
    isSendingAnswersInProgress,
    stepInitAnswers,
    onNext,
    onBack,
    answers,
    stepSendingAnswersError
  } = useRegistrationForms({
    sendAnswers,
    finishUserRegistration,
    config,
    initAnswers: userData.data,
    onFirstStepBack: navigateBack
  });

  useEffect(() => {
    if (!Step) {
      logout();
    }
  }, [Step]);

  const toLogin = useCallback(() => {
    history.push("/login");
  }, []);

  return (
    <>
        {Step ? (
          <>
            <ProgressBar step={stepNum} />
            <Step
              userData={userData}
              initAnswers={stepInitAnswers}
              tickNum={tickNum}
              saveAnswers={saveAnswers}
              onDone={onStepDone}
              showErrorPopup={showErrorPopup}
              setNextDisabled={setNextDisabled}
              nextDisabled={nextDisabled}
              subscriptionPlans={subscriptionPlans}
              sendingAnswersError={stepSendingAnswersError}
              isSendingAnswersInProgress={isSendingAnswersInProgress}
              onBack={onBack}
              answers={answers}
              referralHash={referralHash}
            />
          </>
        ) : (
          <Congratulations onToLogin={toLogin} isTrial={false}/>
        )}
    </>
  );
}

PayedRegistration.propTypes = {
  userData: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object
  }).isRequired,
  registerUser: PropTypes.func.isRequired,
  getSubscriptionPlans: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  finishUserRegistration: PropTypes.func.isRequired,
  welcomeLayoutLeftBar: PropTypes.string,
  referralHash: PropTypes.string,
};

export default withProps({ saveAnswers, getSubscriptionPlans })(PayedRegistration);

import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';

class UserSmallDesign extends Component {
  render(){
    let {loggeduser,followRequests,sparks} = this.props;

    if (!loggeduser) return null;

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const regDateStr = new Date(loggeduser.created).toLocaleDateString('en-US', DATE_OPTIONS);
    const totalSparks = sparks.items ? sparks.items.filter(spark => spark.user_id == loggeduser.id).length : loggeduser.totalSparks
    return (
      loggeduser != undefined ? (
      <div className="twiter-left1">
        <div className="user-top" style={loggeduser.banner_image && loggeduser.banner_image.indexOf('no-banner') < 0 ? {backgroundImage: `url(${loggeduser.banner_image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'} : {}}/>
        <div className="user-img">
          <div className="user-img-upload" id="user-img-view">
            <label>
              <img src={loggeduser.profile_image}  alt="photo"/>
            </label>
          </div>
          <div className="twet-nam">
            <h4>Sparks</h4>
            <h5>{totalSparks}</h5>
          </div>
        </div>
        <div className="user-name  text-overflow-ellipsis">
          <h3 title={loggeduser.name}>{loggeduser.name}</h3>
          {loggeduser.username ? (
            <h4>
              <Link to={"/"+loggeduser.username} title={loggeduser.at_username}>{loggeduser.at_username}</Link>
              {loggeduser.isPrivate == '1' ? (<i className="fas fa-lock"></i>) : ('')}
            </h4>
            ) : ''
          }
          <h4 className="join-date"><i className="far fa-calendar-alt"></i> Joined {regDateStr}</h4>
        </div>
      </div>
          ) : ('')
    )
  }
}


function mapStateToProps(state){
  return {
    loggeduser: state.authentication.user,
    sparks: state.sparks,
    followRequests: state.profileinfo.data != undefined ? state.profileinfo.data.followRequest : [],
  };
}
export default connect(mapStateToProps)(UserSmallDesign);

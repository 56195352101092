import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from '../../../containers/frontend/common/Header';
import SubscribersListSection from '../../../containers/frontend/userProfile/SubscribersListSection';
import NotFoundView from '../NotFoundView';
import LoadingIcon from '../../../containers/frontend/common/LoadingIcon';
import { alertActions,listsActions } from '../../../actions';

class SubscribersListDetail extends Component {
  constructor(props){
    super(props);
    $('html, body').css('overflow', '');
  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.profilename !== this.props.match.params.profilename) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { dispatch } = this.props;

    const { user, username, l_id } = this.props;
    if (username && l_id && user) {
        let rawData = {
          username: username,
          user_id: user.id,
          list_id: l_id
        }
        dispatch(listsActions.getAll(rawData));
    }
  }

render() {
  const { lists,user, notfoundstatus } = this.props;

  if (notfoundstatus) {
    return (
      <>
        <Header/>
        <NotFoundView />
      </>
    )
  }
    return (
      <div>
        {lists.listDetail != undefined && lists.loading == false && (lists.listDetail.isPrivate != 1 || lists.listDetail.id == user.id) ? (
          <div id="ListDetailView" className="SubscribersListDetail">
            <Header/>
            <SubscribersListSection username={this.props.match.params.profilename} l_id={this.props.match.params.l_id} owner_id={lists.listDetail.id}/>
          </div>
          ) : ( lists.listDetail != undefined && lists.listDetail.isPrivate == 1 && lists.loading == false ? (
                  <NotFoundView />
                ) : (
              <div>
                <Header/>
                <LoadingIcon/>
              </div>
            )
          )
        }
     </div>

    );
  }
}
function mapStateToProps(state,ownProps){
  return {
  loggedIn: state.authentication.loggedIn,
  user: state.authentication.user,
  username: ownProps.match.params.profilename,
  l_id: ownProps.match.params.l_id,
  notfoundstatus: state.profileinfo.notfoundstatus != undefined ? state.profileinfo.notfoundstatus : false,
  lists: state.lists
  };
}
export default connect(mapStateToProps)(SubscribersListDetail);

import React, { Component, useState, useCallback, useEffect, useRef } from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import LoadingIcon from './LoadingIcon';

const PHONE_POPUP = 'phone';
const EMAIL_POPUP = 'email';

export const PopupTypes = {
  PHONE_POPUP,
  EMAIL_POPUP,
};

class VerifyPopup extends Component {
  constructor(props) {
    super(props);
  }
}
export default withRouter(connect()(VerifyPopup));

function Popup({ title, onCloseClick, show, children }) {
  return (
    <div className={cx('popup', {'fade show': show})}>
      <div className="popup-verify">
        <div className="popup-title">
          <h1>{title}</h1>
          <button className="popup-verify-close-btn" onClick={onCloseClick}>
            <span>&times;</span>
          </button>
        </div>
        <div className="popup-body">
          {children}
        </div>
      </div>
    </div>
  );
}

Popup.propTypes = {
  title: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

function EmailPopupBody({ onClose }) {
  return (
    <div className="email-body">
      <img className="sended-mail" src="/frontend/images/sended-mail.png" alt="Mail has been sent" />
      <p className="email-was-sent">{"Email has been successfully sent."}<br/>{"Please, check your email and follow the instructions."}</p>
      <button className="sendCodeAgain" onClick={onClose}>Close</button>
    </div>
  );
}

EmailPopupBody.propTypes = {
  onClose: PropTypes.func.isRequired,
};

function getInitPhoneInputValue() {
  return ['', '', '', '', '', '',];
}

function initVerifyingCodeInputs() {
  const $verifyingCodeInputs = $('.verifyingCode');
  $verifyingCodeInputs.off();
  $verifyingCodeInputs.keyup(function(e) {
    if (!/^[0-9]*$/.test($(this).val())) {
      return $(this).val('');
    }
    if (this.value.length == this.maxLength) {
      $(this).css({ borderBottom: 'none', fontSize: 19 });
      $(this).next(".verifyingCode").attr('readonly', false);
      $(this).next(".verifyingCode").focus();
    }
    if (e.which === 8) {
      $(this).css({ borderBottom: '2px solid #999' });
      if ($(this).val()) {
        $(this).prev(".verifyingCode").css({ borderBottom: '2px solid #999' });
      }
      if ($(this).length !== 1) {
        $(this).attr('readonly', true);
      }
      // $(this).prev(".verifyingCode").val('');
      $(this).prev(".verifyingCode").focus();
    }
  });
}

function resetVerifyingCodeInputsStyle() {
  $(".verifyingCode").each(function() {
    $(this).css({borderBottom: '', fontSize: ''});
  });
}

function PhonePopupBody(props) {
  const {
    phoneNumber, onSendPhoneCodeAgainClick, isCodeWrong, onCodeChange, sendPhoneCodeAgainDisabled, disabled,
    clearInputCounter = 0, sendingSms,
  } = props;

  // const firstInputRef = useRef(null);
  const input = useRef([]);
  const [code, setCode] = useState(getInitPhoneInputValue);
  const [initClearInputCounter] = useState(clearInputCounter);

  useEffect(() => {
    if (input.current[0]) {
      input.current[0].focus();
    }
    initVerifyingCodeInputs();
    return () => {
      $(".verifyingCode").off();
    }
  }, []);

  useEffect(() => {
    if (clearInputCounter === initClearInputCounter) return;
    setCode(getInitPhoneInputValue());
    resetVerifyingCodeInputsStyle();
  }, [clearInputCounter]);

  const onChange = useCallback((index, e) => {
    const {target: { value }} = e;
    if(value.length > 1) {
      return;
    }
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    const finish = !newCode.some(v => Number.isNaN(Number.parseInt(v)));
    onCodeChange([...newCode], finish);
  }, [code]);

  const onKeyPress = (e) => {
    if (/\D/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
    {sendingSms && (
      <div className="sendingSms">
        <LoadingIcon />
      </div>
    )}
      <p className="enter-code">
        {`Please enter the code from the SMS sent to your phone number ${phoneNumber || ''}`}
      </p>
      <div className={cx('verify-code-container', {'disabled': disabled})}>
        <>
          {[0,1,2,3,4,5].map((i, index) => (
            <input
              ref={(element) => input.current.push(element)}
              type="number"
              className="verifyingCode"
              name="verifyingCode"
              onChange={onChange.bind(null, index)}
              maxLength="1"
              disabled={disabled}
              key={index}
              value={code[index]}
              onFocus={() => input.current[index].select()}
              onKeyPress={onKeyPress}
              onPaste={(e) => e.preventDefault()}
            />
          ))}
        </>
      </div>
      <div className="invalidCodeMsg">
        { isCodeWrong && 'Invalid code' }
      </div>
      <button
        disabled={sendPhoneCodeAgainDisabled}
        onClick={onSendPhoneCodeAgainClick}
        className="sendCodeAgain"
      >
        Send code again
      </button>
    </>
  );
}

PhonePopupBody.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  isCodeWrong: PropTypes.bool.isRequired,
  sendPhoneCodeAgainDisabled: PropTypes.bool,
  onSendPhoneCodeAgainClick: PropTypes.func.isRequired,
  onCodeChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  sendingSms: PropTypes.bool,
  clearInputCounter: PropTypes.number,
};

export function EmailPopup(props) {
  const {
    title = 'Check your email', onCloseClick, show, popupBody,
  } = props;
  return (
    <Popup
      title={title}
      onCloseClick={onCloseClick}
      show={show}
    >
      <EmailPopupBody onClose={onCloseClick} />
      {popupBody}
    </Popup>
  );
}

EmailPopup.propTypes = {
  title: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  show: PropTypes.bool,
  popupBody: PropTypes.node,
};

export function PhonePopup(props) {
  const {
    title = 'Enter your code', onCloseClick, show, popupBody, isCodeWrong, phoneNumber, onSendPhoneCodeAgainClick,
    onCodeChange, sendPhoneCodeAgainDisabled, disabled, clearInputCounter, sendingSms,
  } = props;

  return (
    <Popup
      title={title}
      onCloseClick={onCloseClick}
      show={show}
    >
      <PhonePopupBody
        isCodeWrong={isCodeWrong}
        phoneNumber={phoneNumber}
        onSendPhoneCodeAgainClick={onSendPhoneCodeAgainClick}
        onCodeChange={onCodeChange}
        sendPhoneCodeAgainDisabled={sendPhoneCodeAgainDisabled}
        disabled={disabled}
        sendingSms={sendingSms}
        clearInputCounter={clearInputCounter}
      />
      {popupBody}
    </Popup>
  );
}

PhonePopup.propTypes = {
  title: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  show: PropTypes.bool,
  sendPhoneCodeAgainDisabled: PropTypes.bool,
  phoneNumber: PropTypes.string.isRequired,
  isCodeWrong: PropTypes.bool.isRequired,
  onSendPhoneCodeAgainClick: PropTypes.func.isRequired,
  onCodeChange: PropTypes.func.isRequired,
  popupBody: PropTypes.node,
  disabled: PropTypes.bool,
  sendingSms: PropTypes.bool,
  clearInputCounter: PropTypes.number,
};

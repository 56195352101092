import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import { toast } from 'react-toastify';

import CCForm from './CCForm';
import { subscriptionPlans } from '../propTypes';

import s from './credit-card.module.scss';

const strings = {
  title: 'credit card information',
  per: '/Per Month',
};

function CreditCard({ subscriptionPlans, initAnswers, saveAnswers, onDone, showErrorPopup, userData }) {
  let intervalCount = '';
  let planAmount = '';
  let planIntervals = '';

  const { plan_id: currentPlanId } = initAnswers;

  if (subscriptionPlans && subscriptionPlans.length && currentPlanId) {
    const {
      interval_count,
      intervals,
      amount,
    } = subscriptionPlans.find((p) => (p.id == currentPlanId));

    intervalCount = interval_count;
    planAmount = `$${addZeroes(String(amount))}`;
    planIntervals = intervals;
  }

  function addZeroes(num) {
    const dec = num.split('.')[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    return Number(num).toFixed(len);
  }

  function onToken(token, isPaymentProceed) {
    const tokenId = typeof token === 'object' && token.id;

    if (!tokenId) {
      console.error('CreditCard tokenId is empty');
      isPaymentProceed(false);
      return;
    }

    saveAnswers({stripe_token: tokenId})
      .then(() => {
        isPaymentProceed(false);
        onDone();
      })
      .catch(e => {
        isPaymentProceed(false);
        //TODO
        toast.error(e);
        console.error('saveAnswers stripe_token error', e);
      });
  }

  return (
    <div className={s.container}>
      <div className={s.titleContainer}>
        <div className={s.title}>
          <h4>{strings.title.toLocaleUpperCase()}</h4>
        </div>
      </div>
      <div className={s.plan}>
        <span className={s.planName}>
          {`${intervalCount} ${planIntervals}`}
        </span>
        <span>
          <span className={s.priceText}>
            {planAmount}
          </span>
          <span className={s.priceSmallText}>
            {planAmount ? strings.per : ''}
          </span>
        </span>
        <i /*className='fas fa-angle-down'*/ />
      </div>
      <Elements locale='en'>
        <CCForm onToken={onToken} onError={showErrorPopup} />
      </Elements>
    </div>
  );
}

CreditCard.propTypes = {
  subscriptionPlans: subscriptionPlans.isRequired,
  initAnswers: PropTypes.object.isRequired,
  saveAnswers: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  showErrorPopup: PropTypes.func.isRequired,
};

export default CreditCard;

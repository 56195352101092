import { getUserData as getUserDataRequest } from '../api/userData';
import { registerUser as registerUserRequest } from '../api/registration';
import { extractAccessTokenFromBody, extractDataFromBody } from '../api/helpers';
import { userData as userDataConstants } from '../constants';
import { setAccessToken, } from '../services/accessTokenStorage';
import { userService } from '../services/user.service';
import { toast } from 'react-toastify';
import msgs from "../messages/en/main"

const {
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAILURE,
  USER_DATA_RESET_LOADING,
  USER_DATA_CLEAR_DATA,
  USER_PLAN_DETAIL_REQUEST,
  USER_PLAN_DETAIL_SUCCESS,
  USER_PLAN_DETAIL_FAILURE,
  SET_USER_CONNETION,
} = userDataConstants;


export function getUserData({useLocalStorageAccessToken} = {}) {
  return async (dispatch) => {
    dispatch({type: USER_DATA_REQUEST});
    try {
      const body = await getUserDataRequest({useLocalStorageAccessToken});
      const data = extractDataFromBody(body);

      const accessToken = extractAccessTokenFromBody(body);
      if (accessToken) {
        setAccessToken(accessToken);
      }
      dispatch(requestUserDataSuccess(data));
    } catch (error) {
      dispatch({type: USER_DATA_FAILURE});
    }
  }
}

export function registerUser(userData) {
  return async (dispatch) => {
    try {
      const body = await registerUserRequest(userData);
      const data = extractDataFromBody(body);
      const accessToken = extractAccessTokenFromBody(body);
      if (accessToken) {
        setAccessToken(accessToken);
      }
      dispatch(updateUserData(data));
    } catch (error) {
      throw error;
    }
  };
}

export function finishUserRegistration(userData) {
  return async (dispatch) => {
    try {
      const body = await registerUserRequest(userData);
      const data = extractDataFromBody(body);
      const accessToken = extractAccessTokenFromBody(body);
      if (accessToken) {
        setAccessToken(accessToken);
      }
      const alteredData = {...data, is_user_registered: false};
      dispatch(updateUserData(alteredData));
    } catch (error) {
      throw error;
    }
  };
}

function requestUserDataSuccess(data) {
  return {type: USER_DATA_SUCCESS, data};
}

export function updateUserData(data) {
  return requestUserDataSuccess(data);
}

export function resetUserDataLoadingFlag() {
  return {type: USER_DATA_RESET_LOADING};
}

export function clearUserData() {
  return {type: USER_DATA_CLEAR_DATA};
}

export function setUserConnection(isConnected) { return { type: SET_USER_CONNETION, connected: isConnected } };

import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { userData as userDataPropType } from '../misc/propTypes';

// it is useless checking, because user will not see congratulations page.
// const isUserRegistrationFinished = (userData) => !!userData && userData.expire_plan_sts;

function RegistrationRoute({ component: Component, ...restProps }) {
  // const { userData: { loading: userDataLoading, data: userData } } = restProps;

  return (
    <Route
      {...restProps}
      render={props => {
        // const isAllowed = userDataLoading || !isUserRegistrationFinished(userData);
        return (
          <Component {...props} />
        );
      }}
    />
  );
}

RegistrationRoute.propTypes = {
  userData: userDataPropType.isRequired,
};

const mapStateToProps = ({ userData }) => ({userData});

export default connect(mapStateToProps)(RegistrationRoute);

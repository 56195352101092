export const momentsConstants = {

  // CREATE_REQUEST: 'MOMENTS_CREATE_REQUEST',
  //CREATE_SUCCESS: 'MOMENTS_CREATE_SUCCESS',
  //CREATE_FAILURE: 'MOMENTS_CREATE_FAILURE',

  GETALLMOMENTS_REQUEST: 'MOMENTS_GETALL_REQUEST',
  GETALLMOMENTS_SUCCESS: 'MOMENTS_GETALL_SUCCESS',
  GETALLMOMENTS_FAILURE: 'MOMENTS_GETALL_FAILURE',

  UPDATEMOMENTS_REQUEST: 'MOMENTS_UPDATE_REQUEST',
  UPDATEMOMENTS_SUCCESS: 'MOMENTS_UPDATE_SUCCESS',
  UPDATEMOMENTS_FAILURE: 'MOMENTS_UPDATE_FAILURE',

  DELETEMOMENTS_REQUEST: 'MOMENTS_DELETE_REQUEST',
  DELETEMOMENTS_SUCCESS: 'MOMENTS_DELETE_SUCCESS',
  DELETEMOMENTS_FAILURE: 'MOMENTS_DELETE_FAILURE',

  LIKEMOMENTS_REQUEST: 'MOMENTS_LIKE_REQUEST',
  LIKEMOMENTS_SUCCESS: 'MOMENTS_LIKE_SUCCESS',
  LIKEMOMENTS_FAILURE: 'MOMENTS_LIKE_FAILURE',

  SINGLEMOMENTS_REQUEST: 'MOMENTS_SINGLE_REQUEST',
  SINGLEMOMENTS_SUCCESS: 'MOMENTS_SINGLE_SUCCESS',
  SINGLEMOMENTS_FAILURE: 'MOMENTS_SINGLE_FAILURE',

  UPDATESINGLEMOMENTS_REQUEST: 'MOMENTS_SINGLE_UPDATE_REQUEST',
  UPDATESINGLEMOMENTS_SUCCESS: 'MOMENTS_SINGLE_UPDATE_SUCCESS',
  UPDATESINGLEMOMENTS_FAILURE: 'MOMENTS_SINGLE_UPDATE_FAILURE',

  VOTEMOMENTS_REQUEST: 'MOMENTS_VOTE_REQUEST',
  VOTEMOMENTS_SUCCESS: 'MOMENTS_VOTE_SUCCESS',
  VOTEMOMENTS_FAILURE: 'MOMENTS_VOTE_FAILURE',

  COMMENTMOMENTS_REQUEST: 'MOMENTS_COMMENT_REQUEST',
  COMMENTMOMENTS_SUCCESS: 'MOMENTS_COMMENT_SUCCESS',
  COMMENTMOMENTS_FAILURE: 'MOMENTS_COMMENT_FAILURE',

  REPLYPOPUPDATAMOMENTS_REQUEST: 'MOMENTS_REPLY_POPUP_DATA_REQUEST',
  REPLYPOPUPDATAMOMENTS_SUCCESS: 'MOMENTS_REPLY_POPUP_DATA_SUCCESS',
  REPLYPOPUPDATAMOMENTS_FAILURE: 'MOMENTS_REPLY_POPUP_DATA_FAILURE',

  REPLYMOMENTS_REQUEST: 'MOMENTS_REPLY_REQUEST',
  REPLYMOMENTS_SUCCESS: 'MOMENTS_REPLY_SUCCESS',
  REPLYMOMENTS_FAILURE: 'MOMENTS_REPLY_FAILURE',

  CATEGORY_REQUEST: 'MOMENTS_CATEGORY_REQUEST',
  CATEGORY_SUCCESS: 'MOMENTS_CATEGORY_SUCCESS',
  CATEGORY_FAILURE: 'MOMENTS_CATEGORY_FAILURE',

  TWEETTOMOMENT_REQUEST: 'MOMENTS_TWEETTOMOMENT_REQUEST',
  TWEETTOMOMENT_SUCCESS: 'MOMENTS_TWEETTOMOMENT_SUCCESS',
  TWEETTOMOMENT_FAILURE: 'MOMENTS_TWEETTOMOMENT_FAILURE',

  SINGLE_DETAIL_REQUEST: 'MOMENT_SINGLE_DETAIL_REQUEST',
  SINGLE_DETAIL_SUCCESS: 'MOMENT_SINGLE_DETAIL_SUCCESS',
  SINGLE_DETAIL_FAILURE: 'MOMENT_SINGLE_DETAIL_FAILURE',

  CREATE_MOMENT_REQUEST: 'MOMENT_CREATE_REQUEST',
  CREATE_MOMENT_SUCCESS: 'MOMENT_CREATE_SUCCESS',
  CREATE_MOMENT_FAILURE: 'MOMENT_CREATE_FAILURE',

  SEARCH_USER_REQUEST: 'MOMENT_SEARCH_USER_REQUEST',
  SEARCH_USER_SUCCESS: 'MOMENT_SEARCH_USER_SUCCESS',
  SEARCH_USER_FAILURE: 'MOMENT_SEARCH_USER_FAILURE',

  SEARCH_TWEET_REQUEST: 'MOMENT_SEARCH_TWEET_REQUEST',
  SEARCH_TWEET_SUCCESS: 'MOMENT_SEARCH_TWEET_SUCCESS',
  SEARCH_TWEET_FAILURE: 'MOMENT_SEARCH_TWEET_FAILURE',

  GET_USER_REQUEST: 'MOMENT_GET_USER_REQUEST',
  GET_USER_SUCCESS: 'MOMENT_GET_USER_SUCCESS',
  GET_USER_FAILURE: 'MOMENT_GET_USER_FAILURE',

  GET_TWEET_REQUEST: 'MOMENT_GET_TWEET_REQUEST',
  GET_TWEET_SUCCESS: 'MOMENT_GET_TWEET_SUCCESS',
  GET_TWEET_FAILURE: 'MOMENT_GET_TWEET_FAILURE',

  DELETE_MOMENT_REQUEST: 'MOMENT_DELETE_REQUEST',
  DELETE_MOMENT_SUCCESS: 'MOMENT_DELETE_SUCCESS',
  DELETE_MOMENT_FAILURE: 'MOMENT_DELETE_FAILURE',

  DELETE_MOMENT_SPARKS_REQUEST: 'MOMENT_DELETE_SPARKS_REQUEST',
  DELETE_MOMENT_SPARKS_SUCCESS: 'MOMENT_DELETE_SPARKS_SUCCESS',
  DELETE_MOMENT_SPARKS_FAILURE: 'MOMENT_DELETE_SPARKS_FAILURE',

  UPDATE_ATTACHED_SPARK: 'UPDATE_ATTACHED_SPARK',
  DELETE_SINGLE_MOMENT_DATE: 'DELETE_SINGLE_MOMENT_DATE',
  DELETE_ATTACHED_SPARK: 'DELETE_ATTACHED_SPARK',

  MARK_ATTACHED_SPARK_AS_OBJECTIONABLE: 'MARK_ATTACHED_SPARK_AS_OBJECTIONABLE',
  UNMARK_ATTACHED_SPARK_AS_OBJECTIONABLE: 'UNMARK_ATTACHED_SPARK_AS_OBJECTIONABLE',

  SET_REPORT_ATTACHED_SPARK: 'SET_REPORT_ATTACHED_SPARK',
};

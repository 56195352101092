import { userConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loading: false, loggedIn: true, user, error: undefined } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loading: false,
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loading: false
      };
    case userConstants.LOGOUT:
      return {
        loading: false
      };
      // edit profile reducer update
    case userConstants.EDIT_PROFILE_REQUEST:
      return {
        loading: true,
        loggedIn: true,
        user: action.user
      };
    case userConstants.EDIT_PROFILE_SUCCESS:
      return {
        loading: false,
        loggedIn: true,
        user: action.user
      };
    case userConstants.EDIT_PROFILE_FAILURE:
      return {
        ...state,
        user: action.error
      };
      case userConstants.EDIT_IMAGE_REQUEST:
        return {
          ...state,
          loading: true,
          loggedIn: true,
        };
      case userConstants.EDIT_IMAGE_SUCCESS:
        return {
          ...state,
          loading: false,
          loggedIn: true,
          user: {
            ...state.user,
            profile_image: action.user.profile_image,
            banner_image: action.user.banner_image,
          }
        };
      case userConstants.EDIT_IMAGE_FAILURE:
        return {
          ...state,
          user: action.error
        };
      case userConstants.EDIT_SETTINGS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case userConstants.EDIT_SETTINGS_SUCCESS:
        return {
          loading: false,
          loggedIn: true,
          user: {
            ...state.user,
            ...action.user
          }
        };
      case userConstants.EDIT_SETTINGS_FAILURE:
        return {
          ...state,
          error: action.error,
          loading: false,
        };
      case userConstants.DEACTIVATE_USER_REQUEST:
        return {
          ...state
        };
      case userConstants.DEACTIVATE_USER_SUCCESS:
        return {
          loading: false,
        };
      case userConstants.DEACTIVATE_USER_FAILURE:
        return {
          ...state
        };
      case userConstants.PLAN_CANCEL_REQUEST:
        return {
          ...state,
          planCancelLoading: true,
        };
      case userConstants.PLAN_CANCEL_SUCCESS:
        return {
          ...state,
          planCancelLoading: false,
          user: {
            ...state.user,
            plan_status: 'cancel',
          },
        };
      case userConstants.PLAN_CANCEL_FAILURE:
        return {
          ...state,
          planCancelLoading: false,
        };
      case userConstants.USER_GET_LOGIN_REQUEST:
        return {
          ...state,
          loading: true
        };
      case userConstants.USER_GET_LOGIN_SUCCESS:
        return {
          ...state,
          loading: false,
          loggedIn: true,
          user: action.user
        };
      case userConstants.USER_GET_LOGIN_FAILURE:
        return {
          ...state,
          loading: false
        };
    default:
      return state
  }
}

import { combineReducers } from 'redux';
import activeChatData from './activeChatData';
import chatListData from './chatListData';
import chatUnreadMessages from './chatUnreadMessages';
import contentType from './contentType';
import showModal from './showModal';

export default combineReducers({
  showModal,
  contentType,
  activeChatData,
  chatListData,
  chatUnreadMessages,
});

import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ManageSparkType from './ManageSparkType';
import { momentsActions,alertActions } from '../../../actions';
const st = {
  width:'100%',
  height:'300px',
}

class sparkAccount extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      visible: 5
    };

    this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
      this.setState((prev) => {
        return {visible: prev.visible + 10};
    });
  }

  handleUserSelect(username) {
      const { dispatch, user } = this.props;
      const data = {
        username: username,
        user_id: user.id
      }

      dispatch(momentsActions.getUserByAccount(data));
  }

  handleKeyUp = (event) => {
    const { dispatch, user } = this.props;
    var stringVal = event.target.value;

    var val = !!stringVal.trim() ? stringVal : '';

    const data = {
      search: val,
      user_id: user.id
    };

    dispatch(momentsActions.searchUserByAccount(data));
  }

  myCallback = (dataFromChild) => {
      this.props.callbackFromMainParent(dataFromChild);
  }

  render()
  {
    const { momentstweetpost, searchUserList, user, selectedSparks = [] } = this.props;

    return(
        <div id="sparks2" className="tabcontent" style={{display: "none",}}>
          <div className="spark-account">
            <input type="text" className="account-input" placeholder="Search 2Cents accounts"
              name="searchtext"
              onChange={this.handleKeyUp} />
            <button type="submit"><i className="fas fa-search" /></button>
            <div className="srch-pop">
            	<ul className="users">
              {searchUserList.length != undefined ?
                searchUserList.map((userAccount, index) => (
                    <li key={index}>
                      <button onClick={() => this.handleUserSelect(userAccount.username)} >
                        <span>
                          <img src={userAccount.profile_image} alt=""/>
                        </span>
                        <h6 className="text-overflow-ellipsis">
                          <strong>{userAccount.full_name}</strong><br />
                          {userAccount.username}
                        </h6>
                      </button>
                    </li>
                )) : (<p>No User Found.</p>)
              }
              </ul>
            </div>
          </div>
          {momentstweetpost.tweetMomentData != undefined && momentstweetpost.tweetMomentData.allAccountPost.length > 0 ?
            (momentstweetpost.tweetMomentData.allAccountPost.slice(0,this.state.visible).map((account, index) => {
                const isSelected = selectedSparks.filter((spark) => spark.post_id === account.post_id).length !== 0;

                return <ManageSparkType key={index} rawData={account} index={index} type='account'
                                        callbackFromParent={this.myCallback} isSelected={isSelected}/>
              })
            ) : (<p>You haven't Spark yet. Once you do, you'll be able to use your Spark in a Instant.</p>)
          }
          {momentstweetpost.tweetMomentData != undefined ?
            (this.state.visible < momentstweetpost.tweetMomentData.allAccountPost.length ? (
               <button onClick={this.loadMore} type="button" className="load-more btn btn-danger">Load more</button>
            ) : ('')) : ('')
           }
        </div>
    )
  }
}

function mapStateToProps(state,ownProps) {
    return {
        user: state.authentication.user,
        momentstweetpost: state.momentstweetpost != undefined ? state.momentstweetpost : [],
        searchUserList: state.momentstweetpost.searchUserList != undefined ? state.momentstweetpost.searchUserList : [],
    };
}
export default connect(mapStateToProps)(sparkAccount);

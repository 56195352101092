import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import CreateMoment from '../../../containers/frontend/moments/CreateMoment';
import { alertActions,momentsActions } from '../../../actions';
import {Helmet} from "react-helmet";


class CreateMoments extends Component {
  constructor(props){
    super(props);
    this.getPageData();
  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps)
  {
    if (prevProps.user.id !== this.props.user.id) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { user } = this.props;
    const { dispatch } = this.props;
    if(user){
      dispatch(momentsActions.getAllCategory(user));
      dispatch(momentsActions.getTweetToMoment(user));
    }
  }

  render() {
    return (
     <main className="Fun CreateMoments">
         <Helmet>
           <title>Create Instant on 2cents</title>
         </Helmet>
        <Header/>
        <CreateMoment userid={this.props.user.id} />
     </main>
    );
  }
}
function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user
  };
}
export default connect(mapStateToProps)(CreateMoments);

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cs from 'classnames';
import debounce from 'lodash.debounce';
import { singleChatData as singleChatDataPropType, } from '../../../../misc/propTypes';
import s from './chat.module.scss';
import OptionMenu from '../../common/optionMenu/OptionMenu';
import setSubmenuPosition from '../../../../helpers/setSubmenuPosition';

export default function SingleChatLists({ loading, list, onChatClick, activeChatDataId, containerStyle, deleteSingleChat, setChatImg }) {
  const [isOptionMenuOpen, setMenuVisability] = useState(false);
  const [showPending, setShowChatPending] = useState(loading);
  const userChatList = useRef(null);
  const hideSkeletDebounce = debounce(() => setShowChatPending(false), 1000);
  const showSkeletDebounce = debounce(() => setShowChatPending(true), 1000);

  if (!loading) {
    hideSkeletDebounce();
  } else {
    showSkeletDebounce();
  }

  useEffect(() => {
    if (userChatList.current) {
      userChatList.current.addEventListener('click', (event) => setPosition(event, 'dropdown-menu-parent', 'dropdown-menu-wrapper'));
    }

    return () => {
      userChatList.current.removeEventListener('click', setPosition);
    };
  }, []);

  const setPosition = (event, parentClass, submenuClass) => setSubmenuPosition(event, parentClass, submenuClass);

  const setActiveChat = (chatId) => {
    if (showPending) {
      return;
    }

    onChatClick(chatId);
  };

  const onMenuClick = () => {
    setMenuVisability(true);
  }

  return (
    <div ref={userChatList} className={cs('overflow-list', 'user-list', containerStyle)}>
      {list.length > 0 ? (
        list.filter((chat) => (!chat.deleteChat || chat.deleteChat === "false") && !chat.notShow).map((chat) => (
          <div
            className={cs({
              [`${s.chatContainer}`]: true,
              [`${s.activeChat}`]: activeChatDataId === chat.id && !showPending, 
              [`${s.pendingChat}`]: showPending,
              [`${s.unread}`]: chat.countOfUnreadMessages > 0
            })}
            key={chat.id}
            onClick={() => setActiveChat(chat.id)}
          >
            <div className={s.redDot} />
            <div className={s.chatAvatarContainer}>
            {setChatImg(chat)}
            </div>

            <div className={`${s.chatNameMessageInfo}  text-overflow-ellipsis`}>
              <h3 title={chat.name}>{chat.name}</h3>
              <p>{chat.lastMessage ?
                chat.lastMessage :
                (chat.isLastMessageMedia ? (<span><i className="far fa-image icon-2x"></i> image</span>) : (''))
              }</p>
            </div>
            
            <div className={cs("dropdown-menu-parent", s.chatMenuContainer)} onClick={onMenuClick}>
              <i className="fas fa-ellipsis-v" />
              <OptionMenu
                active={false}
                styles={s.chatOptionMenu}
                options={
                  [{
                    title: "Delete Chat",
                    onClick: (e) => deleteSingleChat(chat.id, e),
                  }]
                }
              />
            </div>
          </div>
        ))
      ) : (
        null
      )}
    </div>
  );
}

SingleChatLists.propTypes = {
  loading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(singleChatDataPropType).isRequired,
  onChatClick: PropTypes.func.isRequired,
  activeChatDataId: PropTypes.string,
  lastMessage: PropTypes.string,
  containerStyle: PropTypes.string,
  singleChatListsLoading: PropTypes.bool,
};

SingleChatLists.defaultProps = {
  lastMessage: '',
  activeChatDataId: '',
  containerStyle: {},
  singleChatListsLoading: false
}

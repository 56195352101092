import React, {Component, PureComponent} from 'react';

class ImgPreview extends PureComponent {
    constructor(props){
        super(props);
        this.imgRef = React.createRef();
        this.state ={
            src: props.src,
        }
    }

    isImgPortrait(imgRef) {
        const img = imgRef.current;
        if (img.height > img.width) {
            img.classList.add('portrait');
        }
    }

    render() {
        const {src} = this.state;
        return (
            <img 
                src={src} 
                ref={this.imgRef} 
                alt="Instant preview" 
                onLoad={()=>{this.isImgPortrait(this.imgRef)}} 
                onError={() => this.setState({src: "/frontend/images/dummy.png"})}
            />
            )

    }
}

export default ImgPreview;

import React from 'react';
import {Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import {connect} from 'react-redux';
const links = [
  {to: '/instants/all', label: 'All'},
  {to: '/instants/today', label: 'Today'},
  {to: '/instants/news', label: 'News'},
  {to: '/instants/sports', label: 'Sports'},
  {to: '/instants/entertainment', label: 'Entertainment'},
  {to: '/instants/fun', label: 'Fun'},
  {to: '/instants/others', label: 'Others'},
];

function MomentsBar(props) {
  const {user} = props;
  let location = useLocation();
  let history = useHistory();

  const handleChange = (e) => {
    history.push(links.find(link => link.label === e.target.value).to)
  }

  return (
    <div className="nav2">
      <div className="container">
        <div className="nav2-left">
          <ul>
            {links.map(({to, label}, index) => (
              <li key={to + index}>
                <NavLink to={to} activeClassName="active">
                  {label}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <select 
          className="nav2-left mobile-moments-categories" 
          onChange={handleChange}
        >
          {links.map((link, i) => (
            <option 
              value={link.label} 
              key={link.to + i} 
              selected={location.pathname === link.to}
            >
              {link.label}
            </option>
          ))}
        </select>
        <Link to="/create-instants" className="button">Create an Instant</Link>
        <Link to={"/" + user.username + "/instants"} className="button-left-gap  button">My Instants</Link>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  user: state.authentication.user,
});

export default connect(mapStateToProps, null)(MomentsBar);
// mutate chat
export function pushMessageToChat(chat, messagesKeys) {
  const existingChatMessagesKeys = new Set(chat.unreadMessagesKeys);
  let isPushed = false;
  messagesKeys.forEach(messageKey => {
    if (!existingChatMessagesKeys.has(messageKey)) {
      chat.unreadMessagesKeys.push(messageKey);
      isPushed = true;
    }
  });
  return isPushed;
}

export function addChat(state, addedChat) {
  const { data: currData } = state;
  const newData = [...currData];

  const existingChatIndex = newData.findIndex(({key}) => key === addedChat.key);
  if (existingChatIndex === -1) {
    newData.push(addedChat);
    return {...state, data: newData};
  } else {
    const existingChat = newData[existingChatIndex];
    const {key, unreadMessagesKeys, ...restAddedChatParams} = addedChat;
    const alteredExistingChat = {...existingChat, ...addedChat};
    const isPushed = pushMessageToChat(alteredExistingChat, addedChat.unreadMessagesKeys);

    if (!isPushed && !restAddedChatParams.length) return state;
  }

  return {...state, data: newData};
}

export function removeChat(state, removedChatKey) {
  const {data: currData} = state;

  const newData = currData.filter(({key}) => key !== removedChatKey);
  return {...state, data: newData};
}

export function addChatMessage(state, {chatKey, messageKey}) {
  const {data: currData} = state;
  const newData = [...currData];

  const existingChatIndex = newData.findIndex(({key}) => key === chatKey);
  if (existingChatIndex === -1) {
    newData.push({key: chatKey, unreadMessagesKeys: [messageKey]});
  } else if (!newData[existingChatIndex].unreadMessagesKeys.find(i => i === messageKey)) {
    const currentUnreadMessagesKeys = newData[existingChatIndex].unreadMessagesKeys;
    newData[existingChatIndex].unreadMessagesKeys = [...currentUnreadMessagesKeys, messageKey];
  } else {
    return state;
  }

  return {...state, data: newData};
}

import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cs from 'classnames';
import s from './style.module.css';

const SimpleInput = (props) => {
  const {
    onChange, value, togglePasswordElement, type, name, id, placeholder, style, errMessage, disabled,
    tooltip, tooltipPlace, className, maxLength, minLength,
  } = props;

  const tooltipId = 'tooltip_' + id;
  const isPasswordField = name === 'password' || name === 'confirm_password';

  return (
    <div style={style} >
      <div className={cs(s.formLabelGroup, className) }>
        <input style={{opacity: '0', position: 'absolute', visibility:'hidden'}}/>  {/* Fix to disable autocomplete on inputs*/}
        <input
          type={type}
          id={id}
          name={name}
          value={value}
          className={cs(s.input, {[`${s.withError}`]: errMessage, [`${s.passwordInput}`]: isPasswordField})}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          autoComplete="new-password"
          data-tip
          data-for={tooltipId}
          data-event="click focus mouseover"
          data-event="focus"
          data-event-off="blur"
          data-place={tooltipPlace}
          data-class={s.reactTooltip}
          maxLength={maxLength}
          minLength={minLength}
        />
        <label className={s.inputLabel} htmlFor={id}>{placeholder}</label>
        {togglePasswordElement}
        {tooltip && (
          <ReactTooltip
            id={tooltipId}
            type="dark"
            effect="solid"
            multiline={true}
          >
            {tooltip}
          </ReactTooltip>
        )}
      </div>
      {
        errMessage ? <p className={s.inputError}>{errMessage}</p> : null
      }
    </div>
  )
}

SimpleInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  togglePasswordElement: PropTypes.object,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  errMessage: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.node,
  tooltipPlace: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
};

export default SimpleInput;

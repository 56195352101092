import React, {Component} from 'react';
import $ from 'jquery';


export default class LoginLeftContentBar extends Component {
  constructor(props){
    super(props);
    
    $(window).on('popstate', function() {
        $(".modal-backdrop").remove();
    });
  }

  render(){
    return (
      <div className="col-sm-7 padd-right">
        <div className="login-left">
          <div className="top-block">
            <img className="main-logo" src="/frontend/images/white-logo.png" alt="2cents" />
            <p className="twocents-tagline">
              A microblogging platform that<br/>
              respects the dignity of its community
            </p>
          </div>
          <h3 className="welcome">WELCOME</h3>
          <div className="advantages-block">
            <p>
              Your privacy is protected
              <span>•</span>
              No robots or fake accounts
            </p>
            <p>
              All voices given equal respect
              <span>•</span>
              No advertisements
            </p>
            <p>
              No shadow banning
              <span>•</span>
              No selling of your personal data
            </p>
            <p>
              No political or social biases pushed by 2cents
            </p>
          </div>
          <div className="copyright-block">
            {`©${new Date().getFullYear()} 2cents, LLC. All Rights Reserved.`}
          </div>
        </div>
      </div>
    )
  }
}

import { userConstants } from '../constants';

export function Checkuser(state = {}, action) {
  switch (action.type) {
    case userConstants.CHECKUSER_REQUEST:
      return { Checkuser: true };
    case userConstants.CHECKUSER_SUCCESS:
      return {
        Checkuser: false,
        data: action.data,
      };
    case userConstants.CHECKUSER_FAILURE:
      return {
        action
      };
    default:
      return state
  }
}
 
import { momentsConstants } from '../constants';

export function momentstweetpost(state = {}, action){
  //console.log(action.userData);
  switch (action.type)
  {
    case momentsConstants.TWEETTOMOMENT_REQUEST:
      return {
        loading: true,
      };
    case momentsConstants.TWEETTOMOMENT_SUCCESS:
      return {
        tweetMomentData: action.tweetMomentData
      };
    case momentsConstants.TWEETTOMOMENT_FAILURE:
      return {
        error: action.error
      };
    case momentsConstants.SEARCH_USER_REQUEST:
      return {
          ...state,
          loading: true,
      };
    case momentsConstants.SEARCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        searchUserList: action.userData
      };
    case momentsConstants.SEARCH_USER_FAILURE:
      return {
        ...state,
        searchUserList: action.userData,
        error: action.error
      };
    case momentsConstants.SEARCH_TWEET_REQUEST:
      return {
          ...state,
          loading: true,
      };
    case momentsConstants.SEARCH_TWEET_SUCCESS:
      return {
        ...state,
        loading: false,
        searchTweetList: action.userData
      };
    case momentsConstants.SEARCH_TWEET_FAILURE:
      return {
        ...state,
        searchTweetList: action.userData,
        error: action.error
      };
    case momentsConstants.GET_USER_REQUEST:
      return {
          ...state,
          loading: true,
      };
    case momentsConstants.GET_USER_SUCCESS:
        // state.tweetMomentData.allAccountPost=[];
        return {
        ...state,
        searchUserList:[],

        tweetMomentData :{
          ...state.tweetMomentData, allAccountPost:action.userData
        }
      };
    case momentsConstants.GET_USER_FAILURE:
      return {
        ...state,
        searchUserList: action.userData,
        error: action.error
      };

      case momentsConstants.GET_USER_REQUEST:
        return {
            ...state,
            loading: true,
        };
      case momentsConstants.GET_USER_SUCCESS:
          state.tweetMomentData.allAccountPost=[];
          return {
          ...state,
          searchUserList:[],
          tweetMomentData :{
            ...state.tweetMomentData, allAccountPost:action.userData
          }
        };
      case momentsConstants.GET_USER_FAILURE:
        return {
          ...state,
          searchUserList: action.userData,
          error: action.error
        };
      case momentsConstants.DELETE_MOMENT_SPARKS_REQUEST:
          return {
              ...state,
              loading: true,
          };
      case momentsConstants.DELETE_MOMENT_SPARKS_SUCCESS:
          if(action.postdata.type == 'like')
          {
            state.tweetMomentData.allPostLike.splice(action.postdata.index, 1);
          }
          else if(action.postdata.type == 'account')
          {
            state.tweetMomentData.allAccountPost.splice(action.postdata.index, 1);
          }
          else if(action.postdata.type == 'tweet')
          {
            state.tweetMomentData.searchTweets.splice(action.postdata.index, 1);
          }
          return {
            ...state
          };

      case momentsConstants.DELETE_MOMENT_SPARKS_FAILURE:
        return {
          ...state,
          error: action.error
      };
      case momentsConstants.GET_TWEET_REQUEST:
        return {
            ...state,
            loading: true,
        };
      case momentsConstants.GET_TWEET_SUCCESS:
          state.tweetMomentData.searchTweets=[];
          return {
          ...state,
          searchTweetList:[],
          tweetMomentData :{
            ...state.tweetMomentData, searchTweets:action.userData
          }
        };
    case momentsConstants.GET_TWEET_FAILURE:
      return {
        ...state,
        searchTweetList: action.userData,
        error: action.error
      };
    default:
    return state
  }
}

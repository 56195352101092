import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from 'react-stripe-elements';
import cs from 'classnames';

import s from './credit-card.module.scss';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';

const string = {
  cc: '* Card Number',
  date: '* MM/YY',
  cvv: '* CVV',
  pay: 'Pay',
  cardError: 'Sorry, it looks like you provide incorrect card data',
};

const CCForm = ({ onToken, stripe, onError, userData }) => {

  const [isPaymentStarted, isPaymentProceed] = useState(false);
  const [paymemtError, isPaymentError] = useState(false);


  const createOptions = () => {
    return {
      style: {
        base: {
          textAlign: 'center',
        },
      },
    };
  };

  const getStripeToken = () => {
    if(!navigator.onLine) {
      return toast.error(msgs.NO_INTERNET_CONNECTION);
    }

    isPaymentError(false);
    // get stripe token
    stripe.createToken().then((payload) => {
      if (payload.error) {
        isPaymentProceed(false);
        isPaymentError(true);
        // onError(string.cardError);
      } else {
        // pass result to callback
        onToken(payload.token, isPaymentProceed);
      }
    });
  }

  const renderButtonAppearance = () => {
    return isPaymentStarted ? (
      // <div className="preloader" />
      <i className='fas fa-spin fa-spinner' />
    ) : (
      <i className='fas fa-angle-right' />
    )
  }

  return (
    <div className={s.cardForm}>
      <div className={s.cc}>
        <div className={cs(s.fieldWrapper, { [`${s.hasError}`]: paymemtError })}>
          <span className={s.title}>
            {string.cc}
          </span>
          <CardNumberElement
            id="nb"
            placeholder=""
            className={s.field}
            onReady={(el) => el.focus()}
            onChange={() => isPaymentError(false)}
            disabled={isPaymentStarted}
            {...createOptions()}
          />
        </div>
        <div className={s.smallFields}>
          <div className={cs(s.fieldWrapper, { [`${s.hasError}`]: paymemtError })}>
            <span className={s.title}>
              {string.date}
            </span>
            <CardExpiryElement
              id="date"
              placeholder=""
              className={s.field}
              disabled={isPaymentStarted}
              {...createOptions()}
            />
          </div>
          <div className={cs(s.fieldWrapper, { [`${s.hasError}`]: paymemtError })}>
            <span className={s.title}>
              {string.cvv}
            </span>
            <CardCVCElement
              id="cvc"
              placeholder=""
              className={s.field}
              disabled={isPaymentStarted}
              {...createOptions()}
            />
          </div>
        </div>
        { paymemtError && <span style={{ fontSize: 12, color: '#ef4639' }}>{string.cardError}</span> }
      </div>
      <div className={s.buttonCont}>
        <button onClick={() => {
          isPaymentProceed(true);
          getStripeToken();
        }} className={s.payButton} disabled={isPaymentStarted}>
          {string.pay}
          <span className={s.buttonLoaderContainer}>
            {renderButtonAppearance()}
          </span>
        </button>
      </div>
    </div>
  );
};

CCForm.propTypes = {
  onToken: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  stripe: PropTypes.shape({
    createToken: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectStripe(CCForm);

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import cs from 'classnames';
import debounce from 'lodash.debounce';
import {
  groupChatData as groupChatDataPropType,
  singleChatData as singleChatDataPropType,
} from '../../../../misc/propTypes';
import s from './chat.module.scss';
import OptionMenu from '../../common/optionMenu/OptionMenu';
import setSubmenuPosition from '../../../../helpers/setSubmenuPosition';

export default function AllChatLists({ loading, list, onChatClick, activeChatDataId, containerStyle, leaveGroupChat, unreadGroupChats, setChatImg }) {
  const [isOptionMenuOpen, setMenuVisability] = useState(false);
  const [showPending, setShowChatPending] = useState(loading);
  const userChatList = useRef(null);
  const hideSkeletDebounce = debounce(() => setShowChatPending(false), 1000);
  const showSkeletDebounce = debounce(() => setShowChatPending(true), 1000);

  if (!loading) {
    hideSkeletDebounce();
  } else {
    showSkeletDebounce();
  }

  useEffect(() => {
    if (userChatList.current) {
      userChatList.current.addEventListener('click', (event) => setPosition(event, 'dropdown-menu-parent', 'dropdown-menu-wrapper'));
    }

    return () => {
      userChatList.current.removeEventListener('click', setPosition);
    };
  }, []);

  const setPosition = (event, parentClass, submenuClass) => setSubmenuPosition(event, parentClass, submenuClass);

  const onMenuClick = () => {
    setMenuVisability(true);
  };

  const getCountOfMembers = (members) => {
    let membersCount = 0;
    let memberPrefix = "participants";
    if (typeof members === "object") {
      membersCount = members.length;
    }
    if (membersCount === 0 || membersCount === 1) {
      memberPrefix = "participant";
    }

    return `${membersCount} ${memberPrefix}`;
  };

  return (
    <div ref={userChatList} className={cs('overflow-list', 'user-list', containerStyle)}>
      {list && list.length > 0 ? (
        list.filter((chat) => (!chat.deleteChat || chat.deleteChat === "false") && !chat.notShow).map((chat) => {
          let isSingleChat = chat.chatType === 1;

          return (
            <div
              className={cs({
                [`${s.chatContainer}`]: true,
                [`${s.activeChat}`]: activeChatDataId === chat.id && !showPending,
                [`${s.pendingChat}`]: showPending,
                [`${s.unread}`]: chat.countOfUnreadMessages > 0
              })}
              key={chat.id}
              onClick={() => onChatClick(chat.id)}
            >
              <div className={s.redDot} />
              <div className={s.chatAvatarContainer}>
                {setChatImg(chat)}
              </div>

              { isSingleChat ? (
                <div className={`text-overflow-ellipsis  ${s.chatNameMessageInfo}`}>
                  <h3 title={chat.name}>{chat.name}</h3>
                  <p>{chat.lastMessage ?
                    chat.lastMessage :
                    (chat.isLastMessageMedia ? (<span><i className="far fa-image icon-2x"></i> image</span>) : (''))
                  }</p>
                </div>
              ) : (
                <div className={`text-overflow-ellipsis  ${s.chatNameMessageInfo}`}>
                  <h3 title={chat.name}>{chat.name}</h3>
                  <p>{getCountOfMembers(chat.members)}</p>
                </div>
              )}

              <div className={cs("dropdown-menu-parent", s.chatMenuContainer)} onClick={onMenuClick}>
                <i className="fas fa-ellipsis-v" />
                <OptionMenu
                  active={false}
                  styles={s.chatOptionMenu}
                  options={
                    [{
                      title: isSingleChat ? "Delete Chat" : "Leave Chat",
                      onClick: (e) => leaveGroupChat(chat.id, e),
                    }]
                  }
                />
              </div>
            </div>
          )
        })
      ) : (
        null
      )}
    </div>
  );
}

AllChatLists.propTypes = {
  loading: PropTypes.bool.isRequired,
  list: PropTypes.oneOfType([singleChatDataPropType, groupChatDataPropType, PropTypes.array]).isRequired,
  onChatClick: PropTypes.func.isRequired,
  activeChatDataId: PropTypes.number,
  lastMessage: PropTypes.string,
  singleChatListsLoading: PropTypes.bool,
};

AllChatLists.defaultProps = {
  lastMessage: '',
  activeChatDataId: '',
};

import React, {Component} from 'react';
import { connect } from 'react-redux';
import { userActions,alertActions } from '../../../actions';
import $ from "jquery";
import CropPopUpModal from '../common/CropPopUpModal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {toast} from "react-toastify";
import msgs from '../../../messages/en/main';
import { fileSizeLimits } from '../../../misc/fileSizeLimits';
import { imageFormatChecker, acceptableImageFormats } from '../../../helpers';

class EditProfileImage extends Component {
  state = {
    src: null,
    // error: false,
    crop: {
      aspect: 1,
      width: 50,
      x: 0,
      y: 0,
    },
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (!file.type.match('image.*')) {
        toast.error(msgs.IMAGES_ARE_ALLOWED);
        return;
      }
      if (!imageFormatChecker(file)) {
        return toast.error(msgs.VALID_IMAGE_FORMATS);
      }
      if (file.size > fileSizeLimits.image && file.type.match('image.*')) {
        return  toast.error(msgs.MAX_IMG_SIZE);
      }

      $(".cropButton").trigger('click');
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  async makeClientCrop(crop, pixelCrop) {
    var base64data = '';
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        'newFile.jpeg',
      );

      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return new Promise((resolve, reject) => {
      var dataurl = canvas.toDataURL("image/jpeg", 0.7);
      resolve(dataurl);
      /*canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        console.log(this.fileUrl);
        resolve(this.fileUrl);
      }, 'image/jpeg');*/
    });
  }

  handleImageSave = e => {
    e.preventDefault();
    const { dispatch,user } = this.props;
    const {croppedImageUrl} = this.state;
    var type = "profile_image";
    let filedata = {
        file: croppedImageUrl,
        user_id:user.id,
        username: user.username,
    };

    $(".close").trigger('click');
    if(filedata)
    {
      dispatch(userActions.editImageNew(filedata,type));
    }
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    const { user } = this.props;

    return (
      <div>
        <div className="crop_wrapper">
          <div className="user-img-upload user-img-upload_edit-profile">
            <input id="file-upload" type="file" accept={acceptableImageFormats.join(', ')} onChange={this.onSelectFile} value=""/>
            <label htmlFor="file-upload" className="custom-file-upload">
              {user.profile_image ?
                (<img src={user.profile_image} alt="Profile Image" />)
                : ('')
              }
              <div className="overlay-edit-img">
                <img src="/frontend/images/add-header-icon.png" alt="Banner Image" />
              </div>
            </label>
          </div>
        </div>
        <button type="button" className="btn btn-primary cropButton" data-toggle="modal" data-target="#cropModal">
          Open image and Crop
        </button>
        <div className="modal fade" id="cropModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content edit-profile-img">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">Crop and Upload</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => this.setState({error: false, croppedImageUrl: null, src: null})}>
                  <span aria-hidden="true" >&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {src && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onImageError={() => {
                      this.setState({error: true, src: null, croppedImageUrl: null})
                      toast.error("This image is broken. Please choose another one.")}}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}
                  <div className="preview_crop">
                  {croppedImageUrl && (
                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                  )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button onClick={(e) => this.setState({error: false, croppedImageUrl: null})} type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button disabled={this.state.error} onClick={(e) => this.handleImageSave(e)} type="button" className="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
};

function mapStateToProps(state,ownProps) {
    const { user } = state.authentication;
    const { alert } = state;
    return {
        user,alert
    };
}

export default connect(mapStateToProps)(EditProfileImage);

import React from "react";
import PlanCard from "../../../../components/common/planCard";
import s from "./planRenewComponent.module.scss";
import LoadingIcon from "../../common/LoadingIcon";

function PlanRenewComponent(props) {
  const { getPlan, showForm, plan_id } = props;

  return (
    <div className={s.planRenewContainer}>
      {plan_id ? <div className={s.planRenewTitie}>
        Sorry, your plan has expired! <br />
        Choose a tariff you need!
      </div> : <div className={s.planRenewTitie}>
        Sorry, your trial period has expired! <br />
        Choose a tariff you need!
      </div>}
      <div className={s.planRenewInfo}>
        Full, unlimited access to our <br /> growing community in every plan
      </div>
      <div className={s.planRenewCardContainer}>
        {getPlan.data ? (
          getPlan.data.map(plan => (
            <PlanCard
              key={plan.id}
              interval_count={plan.interval_count}
              currency={plan.currency}
              amount={plan.amount}
              interval={plan.intervals}
              onChoose={() => {
                showForm(plan.id);
              }}
            />
          ))
        ) : (
          <div className="loading-tweet">
            <LoadingIcon />
          </div>
        )}
      </div>
      <div className={s.planRenewInfoTwo}>
        Cancel anytime during the trial without penalties or fees.
      </div>
    </div>
  );
}

export default PlanRenewComponent;

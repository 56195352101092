import { hashtagsConstants } from '../constants';
import { hashtagsService } from '../services';
import { alertActions } from './';

export const hashtagsActions = {
    getAll
};

function getAll(user) {
    return dispatch => {
        dispatch(request(user));
        hashtagsService.getAll(user)
            .then(
                hashtags => {
                  if(hashtags.status != undefined){
                    const status = hashtags.status;
                    const msg = hashtags.msg;

                    if (status) {
                      dispatch(success(hashtags.post_tags))
                    } else {
                      dispatch(failure(msg.toString()));
                      // dispatch(alertActions.error(msg.toString()));
                    }
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                }
            );
    };

    function request(user) { return { type: hashtagsConstants.HASHTAG_TRENDS_REQUEST, user } }
    function success(hashtags) { return { type: hashtagsConstants.HASHTAG_TRENDS_SUCCESS, hashtags } }
    function failure(error) { return { type: hashtagsConstants.HASHTAG_TRENDS_FAILURE, error } }
}

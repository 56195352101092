import { sparkConstants } from '../constants';

export function sparklike(state = {}, action) {
  switch (action.type) {
    case sparkConstants.LIKESPARKS_REQUEST:
      return {
        data: action.data,
        loading: true,
      };
    case sparkConstants.LIKESPARKS_SUCCESS:
      return {
        data: action.sparklike,
        loading: false,
      };
    case sparkConstants.LIKESPARKS_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state
  }
}

import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingIcon from '../common/LoadingIcon';
import { formatDateFromUTCtoLocal } from '../../../helpers';

class MomentGrid extends Component {
  constructor(props){
    super(props);
    this.state = {
      visible : 9
    };
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 10};
    });
  }

  generateMomentDesign = (moment,index) =>
  {
    if(moment)
    {
      // class process
      let alignment = '';
      let file = '';
      let innerBlock = '';
      let fileData = '';

      if(index === 0)
      {
        alignment = 'instant featured';
        innerBlock = 'instant-inn';
      }
      else if(index === 1 || index === 2) {
        alignment = 'instant regular right';
        innerBlock = 'instant-inn regular';
      }
      else if(index%2 === 0)
      {
        alignment = 'instant regular right';
        innerBlock = 'instant-inn regular';
      }
      else {
        alignment = 'instant regular left';
        innerBlock = 'instant-inn regular';
      }

      //get Moment formDat
      if(moment.filetype === 'video' )
      {
        fileData = <video src={moment.file}></video>
      }
      else {
        if(moment.file !== '')
        {
          file = moment.file;
        }
        else {
          file = 'http://salentro.quicquik.com/webservices/2cents/images/no-image.jpg';
        }

        fileData = <div className="bg-responsive" style={{backgroundImage:`url('${[file]}')`}} key={index}/>;
        /*<img key={index} className="img-responsive" style={{objectFit: 'cover'}} src={[file]} alt="img"/>*/
      }

      const title = moment.title;
      const moment_id = moment.moment_id;
      const description = moment.description;
      const likes = moment.totalLikes;
      const created = formatDateFromUTCtoLocal(moment.created);

      return  <div className={[alignment]}>
          <div className="instant-top-img">
            <Link to={'/instants/view/'+moment_id}>
              {[fileData]}
            </Link>
          </div>
          <div className={[innerBlock]}>

            <Link to={'/instants/view/'+moment_id}>
              <h3>{title}</h3>
            </Link>
            <p>{description.length > 250 ? description.substr(1,250)+'...' : description}</p>
            <h4>{likes > 1 ? likes+' Likes' : likes+' Like' } </h4>
            <h5>{created}</h5>
          </div>
        </div>
    }
    else {
      return '';
    }
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { momentsData,momentsType,loading } = this.props;
    return(
      <div>
        <div className="emptySpacer"></div>
        {loading != undefined && loading == true ? (
            <div className="loading-tweet">
              <LoadingIcon />
            </div>
            ) : ('')
        }
        { momentsData != undefined && loading == false ? ( momentsData.length > 0 ?
            momentsData.slice(0,this.state.visible).map((moment, index) => (
              <div className="moment-wrapper" key={index+momentsType}>
                  {this.generateMomentDesign(moment,index)}
              </div>
            )) : (<p className="text  text--centered">There are no posted {this.capitalizeFirstLetter(momentsType)} instants yet</p>)
          ) : ('')
        }
        {momentsData != undefined && momentsData.length > 0 ?
         (this.state.visible < momentsData.length && !loading ? (
            <button onClick={this.loadMore} type="button" className="load-more btn btn-danger">Load more</button>
          ) : ('')) : ('')
        }
      </div>
    )
  }
}

function mapStateToProps(state,ownProps) {
  const momentsData = ownProps.momentData != undefined ? ownProps.momentData : [];
  const momentsType = ownProps.type != undefined ? ownProps.type : '';
  const loading = state.moments.loading != undefined ? state.moments.loading : false;
  return {
    momentsData,momentsType,loading
  };
}

export default connect(mapStateToProps)(MomentGrid);

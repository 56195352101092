import React, {Component, createRef } from 'react';
import $ from 'jquery';
import cx from 'classnames';
import { batch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { userActions, urlActions, toggleChatModal, momentsActions, clearChatList, deleteActiveChat, sparksActions } from '../../../actions';
import PopUp from './PopUp';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const styLeft = {float : "left"}
const styWidth = {width: "100%"}

class Header extends Component {
  constructor(props){
    super(props);

    let user = JSON.parse(localStorage.getItem('user'));

    this.state = {
      userData: (user ? user : ''),
      searchVal: '',
      searchDropDownState: false,
    }

    $(window).on('popstate', function() {
        $(".modal-backdrop").remove();
    });
    this.navbarCollapse = createRef(null);
  }

  logout = (e) => {
    const { dispatch} = this.props;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    batch(() => {
      dispatch(urlActions.savePreloginUrl("/"));
      dispatch(clearChatList());
      dispatch(deleteActiveChat());
      dispatch(sparksActions.clearSparks());
      dispatch(userActions.logout());
      dispatch(userActions.filterNotification(null));
    });
  }

  handleKeyUp = (event) => {
    const { dispatch,user } = this.props;
    var stringVal = event.target.value;
    this.setState({searchVal: stringVal});

    if(stringVal.trim()) {
      var val = event.target.value;
    } else {
      var val = '';
    }

    const data = {
      search: val,
      user_id: user.id
    }

    if (stringVal.length > 2) {
      dispatch(userActions.searchProcess(data));
    }

    if(data.user_id) {
      this.dropDownStateToogler(true);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && prevState.userData !== nextProps.user) {
      return {
          ...prevState,
        userData: nextProps.user
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  componentDidMount() {
    const { dispatch, user } = this.props;

    if (user) {
      dispatch(userActions.getHeaderResponse(user));
    }
  }

  SearchGo = (event) => {
    event.preventDefault();
    const { history } = this.props;
    const { searchVal } = this.state;

    if(searchVal.trim())
    { var val = searchVal.trim(); }
    else { var val = ''; }
    if(val){
      val[0] === '#' ? history.push('/hashtag/'+val.substr(1)) : history.push('/search/'+val);
    }

    this.dropDownStateToogler(false);
  }

  handleUserSelect(username) {
    const { dispatch, user } = this.props;
    const data = {
      username: username,
      user_id: user.id
    }

    dispatch(momentsActions.getUserByAccount(data));
    this.dropDownStateToogler(false);
}

  dropDownStateToogler(value) {
    this.setState({ searchDropDownState: value });
  }
  
  searchBar = (mediaClass) => {
    const { searchVal } = this.state;

    return (
    <form className={`form-inline my-2 my-lg-0 ${mediaClass}`}>
      <div className="search-main">
        <input
          type="text"
          placeholder="Search..."
          className="search-input"
          name="searchtext"
          onChange={this.handleKeyUp}
          onBlur={() => setTimeout(() => this.dropDownStateToogler(false), 1000)}
          autoComplete="off"
          value={searchVal}
          maxLength="30"
          required
        />
        <button onClick={this.SearchGo}>
          <i className="fas fa-search" />
        </button>
      </div>
    </form>
  )};

  onLinkClick = (e) => {
    if(this.navbarCollapse && this.navbarCollapse.current) {
      this.navbarCollapse.current.className = "navbar-collapse collapse";
    }
  };

  render(){
    const { countUnreadMessages, searchProcess, user } = this.props;
    let { userData, searchDropDownState, searchVal } = this.state;
    const username = userData && userData.username ? userData.username.replace("@","") : "";

    return (
      <>
        <div className="nav-main">
          <ToastContainer/>
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link to="/" className="navbar-brand" href><img src="/frontend/images/home-logo.png" alt="logo" /></Link>
              {this.searchBar("mobile-search")}
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>
              {user ? (
                <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={this.navbarCollapse}>
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <NavLink to="/" exact className="nav-link" href activeClassName="active" onClick={this.onLinkClick}><i className="fas fa-home"/>Home</NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/instants/all" className="nav-link" activeClassName="active" onClick={this.onLinkClick}><i className="fas fa-smile" />Instants</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/notifications" className="nav-link disabled" activeClassName="active" onClick={this.onLinkClick}><i className="fas fa-bell" />Notifications</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/chat"
                      className={cx("nav-link disabled", { "unread-counter": countUnreadMessages })}
                      activeClassName="active"
                      onClick={this.onLinkClick}
                    >
                      <i className="fas fa-envelope" />Messages
                      <span className="counter">{countUnreadMessages}</span>
                    </NavLink>
                  </li>
              </ul>
              {this.searchBar("desktop-search")}
              <div className="nav-right">
                <div className="dropdown">
            		  <button className="btn1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            			<span>
                  {user.profile_image && user ?
                    (<img src={user.profile_image} alt="Profile Image" />)
                    : (<i className="fas fa-user"></i>)
                  }
                  </span>
            		  </button>
                  {username && <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <div className="text-overflow-ellipsis  user">
                        <h3 title={userData.name}>{userData.name}</h3>
                        <h4 title={userData.at_username}>{userData.at_username}</h4>
                      </div>
                    <Link to={"/"+username} className="dropdown-item" >Profile</Link>
                    <Link to={"/"+username+"/lists"} className="dropdown-item" >Lists</Link>
                    <Link to={"/"+username+"/instants"} className="dropdown-item" >Instants</Link>
                    <Link to="/settings/account-setting" className="dropdown-item" >Settings and Privacy Policy</Link>
                    <Link to="/pages/help-centers" className="dropdown-item" >Help Center</Link>
                    <Link to="/pages/contact-information" className="dropdown-item" >Contact Information</Link>
                    <Link to="" onClick={this.logout} className="dropdown-item" >Log out</Link>
            		  </div>}
            		</div>
                <button type="button" data-toggle="modal" data-target="#exampleModal" className="spark_link" data-dismiss="modal">
            	   Spark
            	  </button>
              </div>
            </div>
            ) : ('')
            }
            
            {searchDropDownState && searchProcess.searchData && (
              <div className="srch-pop" >
                { searchProcess.searchData.username.length > 0 && (
                  <ul className="users" >
                    {searchProcess.searchData.username.map((userAccount) => (
                      <li>
                        <Link 
                          to={"/"+userAccount.username.replace("@","")}
                          style={styWidth}
                          onClick={() => this.handleUserSelect(userAccount.username)}
                          
                        >
                          <span style={styLeft}>
                            <img src={userAccount.profile_image} alt={userAccount.username}/>
                          </span>
                          <h6 className="text-overflow-ellipsis" style={styLeft}>
                            <strong title={userAccount.full_name}>{userAccount.full_name}</strong><br />
                            <p title={userAccount.username}>{userAccount.username}</p>
                          </h6>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div> )}
          </nav>
        </div>
        </div>
        {user != undefined ? (
          <PopUp/>
          ) : ('')
        }
      </>
    )
  }
}

Header.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { chatUnreadMessages: { countUnreadMessages } } = state.chat;

  return {
    user: state.authentication.user,
    searchProcess: state.searchprocess != undefined ? state.searchprocess : [],
    countUnreadMessages,
  };
}

const mapDispatchToProps = dispatch => {
  const boundActionCreators = bindActionCreators({toggleChatModal}, dispatch);
  return {
    dispatch,
    ...boundActionCreators,
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

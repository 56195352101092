import { APP2_URL } from '../mainurl';
import { getAppHeaders, handleResponse } from "../helpers";

export const reportReasonsService = {
    getReportReasons
};

function getReportReasons() {

  const data = {
    type: 'post',
  };

  return fetch(`${APP2_URL}getReportReasons`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: getAppHeaders(true)
  })
    .then(handleResponse)
    .then(raw => {
      let data = raw.data;
      return data;
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import withProps from '../../../HOCs/withProps';
import {getSubscriptionPlans} from '../../../api/registration';
import PlanCard from '../../common/planCard';
import s from './subscription-plan.module.scss';
import { subscriptionPlans as subscriptionPlansPropType } from '../propTypes';
import msgs from '../../../messages/en/main';

function SubscriptionPlan({ subscriptionPlans, showErrorPopup, onDone, saveAnswers, onBack }) {

  const onChoose = (plan_id) => {
    if(!navigator.onLine) {
      return showErrorPopup(msgs.NO_INTERNET_CONNECTION);
    }

    saveAnswers({ plan_id });
    onDone();
  }

  return (
    <div className={s.container}>
      <div className={s.titleContainer}>
        <div className={s.title}>
          <h4>CHOOSE A PLAN</h4>
        </div>
        <div className={s.planDescription}>
          Full, unlimited access to our growing community in every plan
        </div>
      </div>

      <div className={s.plansContainer}>
        {
          subscriptionPlans.map((plan) => (
            <PlanCard
              key={plan.id}
              interval_count={plan.interval_count}
              profitMessage={plan.interval_count === '12' ? 'The first 3 months are FREE!' : '-'}
              currency={plan.currency}
              amount={plan.amount}
              interval={plan.intervals}
              onChoose={() => onChoose(plan.id)}
            />
          ))
        }
      </div>

      <div className={s.cancelInfo}>Cancel anytime during the trial without penalties or fees.</div>
    </div>
  );
}

SubscriptionPlan.propTypes = {
  getSubscriptionPlans: PropTypes.func.isRequired,
  showErrorPopup: PropTypes.func.isRequired,
  saveAnswers: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  subscriptionPlans: subscriptionPlansPropType.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default withProps({getSubscriptionPlans})(SubscriptionPlan);

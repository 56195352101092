import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {listsActions, momentsActions } from '../../../actions';

class UserListAddMemberWidget extends PureComponent {

  constructor(props){
    super(props);

    this.state = {
      showSearchUserList: false,
    }
  }

  handleUserSelect = (member_id, list_id) => {
    // this.setState({ submitted: true });
    const { dispatch, loggeduser } = this.props;

    if (loggeduser.id && member_id && list_id && dispatch) {
      const data = {
        user_id: loggeduser.id,
        list_id: list_id,
        member_id: member_id,
        action: "add"
      }

      dispatch(listsActions.addDeleteMembers(data));
    }
  }

  handleKeyUp = event => {
    const { dispatch, loggeduser } = this.props;
    let stringVal = event.target.value;
    let val = stringVal.trim();

    if (val.length > 2) {
      const data = {
        search: val,
        user_id: loggeduser.id
      }

      this.setState({ showSearchUserList: true });

      dispatch(momentsActions.searchUserByAccount(data));
    }
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick);
  }

  handleBodyClick = event => {
    const usersFoundList = document.querySelector('.users.users-found-list');
    const path = event.path || event.composedPath();

    if (!path.includes(usersFoundList)) {
      this.setState({ showSearchUserList: false });
    }
  }

  render(){
    const { l_id, searchUserList } = this.props;
    const { showSearchUserList } = this.state;

    return (
      <div className="twiter-left1 access-mid sett-cents">
        <div className="mobile-app demo-tweet-cen">
          <h4>Find people to add to your list</h4>
          <input
            type="text"
            className="demo-search"
            placeholder="find users by name"
            name="searchtext"
            autoComplete="off"
            onChange={this.handleKeyUp}
            onBlur={this.handleBlur}
          />
          {showSearchUserList &&
            <div className="srch-pop">
              <ul className="users users-found-list">
                {searchUserList.length != undefined ?
                  searchUserList.map((userAccount, index) => (
                    <li key={index}>
                      <button className="d-flex pl-1" onClick={() => this.handleUserSelect(userAccount.id, l_id)}>
                        <span><img src={userAccount.profile_image} alt=""/></span>
                        <h6 className="text-overflow-ellipsis">
                          <strong>{userAccount.full_name}</strong><br/>
                          {userAccount.username}
                        </h6>
                      </button>
                    </li>
                  )) : (<p>No User Found.</p>)
                }
              </ul>
            </div>
          }
          <p>Search for a username, first or last name, business or brand.</p>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state,ownProps){
  return {
    loggeduser: state.authentication.user,
    lists: state.lists ? state.lists : [] ,
    l_id: ownProps.l_id,
    username: ownProps.username,
    searchUserList: state.momentstweetpost.searchUserList != undefined ? state.momentstweetpost.searchUserList : [],
  };
}
export default connect(mapStateToProps)(UserListAddMemberWidget);

import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducers';
import history from './history';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

const store = createStore(
  rootReducer(history),
  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history))),
);

export default store;

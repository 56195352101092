import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';

import { alertActions,suggestionActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class WhoHaveToFollowWidget extends Component {
  constructor(props){
    super(props);
    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    const { user } = this.props;
    if (user) {
        dispatch(suggestionActions.getAll(user.id));
    }
  }

  FollowUnfollowUser = (follower_id) => {
    const { dispatch } = this.props;
    const { user } = this.props;

    if(user && follower_id){
          const sendRaw = {
            follower_id : follower_id,
            user_id: user.id,
          }
          dispatch(suggestionActions.FollowUnfollow(sendRaw,'suggestion'));
    }
    else {
       toast.error(msgs.SOMETHING_WENT_WRONG);
    }
  }

  FollowResponse = (other_id,action) => {
    const { dispatch } = this.props;
    const { user } = this.props;

    if(user && other_id && action){
          const sendRaw = {
            other_id : other_id,
            user_id: user.id,
            action: action,
          }
          dispatch(suggestionActions.FollowResponse(sendRaw));
    }
    else {
       toast.error(msgs.SOMETHING_WENT_WRONG);
    }
  }


  RefreshList = (event) => {
    const { dispatch } = this.props;
    const { user } = this.props;
    if (user) {
        dispatch(suggestionActions.getAll(user.id));
    }
  }


  render(){
    const { suggestion } = this.props;
    return (
      <div id="suggestion" className="twiter-left1 pic-profile trend who-follow padd">
        <h4>Suggestions</h4>
        <button className="action-suggestion" onClick={this.RefreshList}>
          <i className="fas fa-sync-alt"></i>
        </button>
        {suggestion.items != undefined ? (
            suggestion.items.map((raw,index) =>
            <div className="who-follow d-flex" key={index}>
              <Link to={'/'+raw.username}><span className="twit-mid-img"><img src={raw.profile_image} alt="logo" /></span></Link>
              <div className="who-follow-right">
                <h3 title={raw.name} className="word-break d-mw-160">
                  <Link
                    to={'/'+raw.username}
                    className="text-overflow-ellipsis"
                  >
                    {raw.name != undefined &&raw.name.trim() ? raw.name : raw.at_username}
                  </Link>
                </h3>
                {raw.status == '0' ? (
                    <button data-id={raw.user_id} onClick={() => this.FollowUnfollowUser(raw.user_id)}>Follow</button>
                  ) : (
                      raw.status == '1' ? (
                        <button data-id={raw.user_id} onClick={() => this.FollowUnfollowUser(raw.user_id)}>
                          <span className="active">Following</span>
                          <span className="hover-active">Unfollow</span>
                        </button>
                      ) : (
                        <button data-id={raw.user_id} onClick={() => this.FollowResponse(raw.user_id,'cancel')}>
                          <span className="active">Pending</span>
                          <span className="hover-active">Cancel</span>
                        </button>
                      )
                  )
                }
              </div>
            </div>
            )
          ) : (
            <div className="who-follow">
              <p className="text-center">{suggestion.loading == true ? ('Loading...') : ('No Suggestion yet!')}</p>
            </div>
          )
        }

      </div>
    )
  }
}

WhoHaveToFollowWidget.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    alert: state.alert,
    suggestion: state.suggestion
  };
}
export default withRouter(connect(mapStateToProps)(WhoHaveToFollowWidget));

import { database } from '../firebase';
import Firebase from 'firebase';

export function formMessageKey(userId, partnerUserId) {
  return userId + '-' + partnerUserId;
}

export function formFriendListRef(userId) {
  return database.ref('/FriendList').child(userId);
}

function formGroupListRef(userId) {
  return database.ref('/Users')
    .child(userId)
    .child('group_list');
}

export function formGroupChatsDataRef(userId) {
  return database.ref('/GroupChatsData').child(userId);
}

export function markSingleChatMessageAsRead(userId, friendUserId, messageKey) {
  const chatKey = formMessageKey(userId, friendUserId)
  return database.ref('/Messages')
    .child(chatKey)
    .child('data')
    .child(messageKey)
    .update({seen: 'true'});
}

export function markSingleChatMessagesAsRead(userId, friendUserId, messageKeys) {
  return Promise.all(messageKeys.map(markSingleChatMessageAsRead.bind(undefined, userId, friendUserId)));
}

export function markSingleChatAsRead(hostUserId, friendUserId) {
  return formFriendListRef(hostUserId)
    .child(friendUserId)
    .update({unreadMessages: false});
}

export function markSingleChatAsUnread(hostUserId, friendUserId) {
  // we add lastMessageTimestamp to trigger update event in case readStatus was false
  return formFriendListRef(hostUserId)
    .child(friendUserId)
    .update({unreadMessages: true, lastMessageTimestamp: Firebase.database.ServerValue.TIMESTAMP});
}

async function fetchSingleChatUnreadMessagesKeys(userId, friendKey) {
  const messageKey = formMessageKey(userId, friendKey);

  const messagesDataRef = database.ref('Messages')
    .child(messageKey)
    .child('data');
  const messagesDataSnap = await messagesDataRef.once('value');

  const unreadMessages = [];

  messagesDataSnap.forEach((messageSnapshot) => {
    const key = messageSnapshot.key;
    const message = messageSnapshot.val();
    if (message && message.seen !== 'true' && message.userId !== userId) {
      unreadMessages.push(key);
    }
  });
  return unreadMessages;
}

export async function getUnreadSingleChatMessages(userId) {
  const friendListSnapshot = await formFriendListRef(userId).once('value');
  const friendList = friendListSnapshot && friendListSnapshot.val();

  if (!friendList) return [];

  const promises = Object.keys(friendList).map(async friendKey => {
    if (friendList[friendKey].unreadMessages === false) return;

    const unreadMessagesKeys = await fetchSingleChatUnreadMessagesKeys(userId, friendKey);
    const messageKey = formMessageKey(userId, friendKey);
    return {key: messageKey, unreadMessagesKeys};
  });

  const chatsUnreadMessages = await Promise.all(promises);

  return chatsUnreadMessages.filter(i => !!i);
}

export function subscribeOnSingleChatMessage(chatKey, onChatMessageAdded, onChatMessageChanged) {
  const messagesRef = database.ref('Messages')
    .child(chatKey)
    .child('data');
  messagesRef.limitToLast(1).on('child_added', onChatMessageAdded);
  messagesRef.on('child_changed', onChatMessageChanged);

  return () => {
    messagesRef.off('child_added', onChatMessageAdded);
    messagesRef.off('child_changed', onChatMessageChanged);
  }
}

export async function subscribeOnUnreadSingleChatMessages(userId, callbacks) {
  const {
    onChatAdded, onChatRemoved, onChatMessageAdded: onChatMessageAddedCb, onChatMessageRead,
  } = callbacks;

  const friendListRef = formFriendListRef(userId);
  const friendListSnapshot = await friendListRef.once('value');
  const friendList = friendListSnapshot && friendListSnapshot.val();

  const unsubscribeFns = [];
  const chatsUnsubscribeFnsMap = {};

  const processOnChatMessagesSubscription = (messageKey) => {
    if (Array.isArray(chatsUnsubscribeFnsMap[messageKey])) return;
    // let firstInvoke = true;

    const onChatMessageAdded = (messageSnapshot) => {
      // if (firstInvoke) {
      //   firstInvoke = false;
      // } else {
        const message = messageSnapshot && messageSnapshot.val();
        if (message && message.userId !== userId && message.seen !== 'true') {
          onChatMessageAddedCb({chatKey: messageKey, messageKey: messageSnapshot.key, timestamp: message.timestamp});
        }
      // }
    };

    const onChatMessageChanged = (messageSnapshot) => {
      const message = messageSnapshot && messageSnapshot.val();
      if (message && message.userId !== userId && message.seen === 'true') {
        onChatMessageRead({chatKey: messageKey, messageKey: messageSnapshot.key});
      }
    };

    const unsubscribe = subscribeOnSingleChatMessage(messageKey, onChatMessageAdded, onChatMessageChanged);

    chatsUnsubscribeFnsMap[messageKey] = [unsubscribe];
    unsubscribeFns.push(unsubscribe);
  };

  if (friendList) {
    const promises = Object.keys(friendList).map(async friendKey => {
      if (friendList[friendKey].unreadMessages === false) return;

      const messageKey = formMessageKey(userId, friendKey);
      processOnChatMessagesSubscription(messageKey);
    });

    await Promise.all(promises);
  }

  const onFriendListChildAdded = async snapshot => {
    const friendKey = snapshot.key;
    const unreadMessagesKeys = await fetchSingleChatUnreadMessagesKeys(userId, friendKey);
    const messageKey = formMessageKey(userId, friendKey);
    onChatAdded({key: messageKey, unreadMessagesKeys});
    processOnChatMessagesSubscription(messageKey);
  };

  const onFriendListChildRemoved = snapshot => {
    const chatKey = formMessageKey(userId, snapshot.key);
    onChatRemoved(chatKey);
    const unsubscribeFns = chatsUnsubscribeFnsMap[chatKey];
    if (unsubscribeFns) {
      unsubscribeFns.forEach(fn => fn());
    }
    chatsUnsubscribeFnsMap[chatKey] = undefined;
  };

  const onFriendListChildChanged = snapshot => {
    const friend = snapshot && snapshot.val();

    if (!friend || friend.unreadMessages === false) return;

    const friendKey = snapshot.key;
    const messageKey = formMessageKey(userId, friendKey);

    processOnChatMessagesSubscription(messageKey);
  };

  friendListRef.on('child_added', onFriendListChildAdded);
  friendListRef.on('child_removed', onFriendListChildRemoved);
  friendListRef.on('child_changed', onFriendListChildChanged);

  const unsubscribe = () => {
    friendListRef.off('child_added', onFriendListChildAdded);
    friendListRef.off('child_removed', onFriendListChildRemoved);
    friendListRef.off('child_changed', onFriendListChildChanged);
    unsubscribeFns.forEach(fn => typeof fn === 'function' && fn());
  };
  return unsubscribe;
}

export async function getUnreadGroupChatMessages(userId) {
  const userGroupListReg = formGroupListRef(userId);
  const groupChatsDataRef = formGroupChatsDataRef(userId);

  const [userGroupListSnapshot, groupChatsDataSnapshot] = await Promise.all([
    userGroupListReg.once('value'),
    groupChatsDataRef.once('value'),
  ]);

  const userGroupList = userGroupListSnapshot && userGroupListSnapshot.val();
  const groupChatsData = groupChatsDataSnapshot && groupChatsDataSnapshot.val();

  if (!userGroupList || !groupChatsData) return [];

  const promises = Object.keys(groupChatsData).map(async groupKey => {
    if (groupChatsData[groupKey].readStatus === true) return;

    const messages = await fetchGroupChatMessages(groupKey);
    const { lastReadTimestamp = 0 } = groupChatsData[groupKey];
    const unreadMessagesKeys = Object.keys(messages || {})
      .filter((key) => {
        const message = messages[key];
        return message.timestamp > lastReadTimestamp && userId !== message.userId;
      })
      .map((key) => key);
    return {key: groupKey, unreadMessagesKeys};
  })

  const chatsUnreadMessages = await Promise.all(promises);

  return chatsUnreadMessages.filter(i => !!i);
}

export function markGroupChatAsRead(userId, chatKey) {
  const groupChatsDataRef = formGroupChatsDataRef(userId);
  const groupChatDataRef = groupChatsDataRef.child(chatKey);
  return groupChatDataRef.update({readStatus: true, lastReadTimestamp: Firebase.database.ServerValue.TIMESTAMP});
}

// export function markGroupChatMessageAsRead(chatId, userId, messageKey) {
//   return database.ref('/Groups')
//     .child(chatId)
//     .child('data')
//     .child(messageKey)
//     .child('membersWhoSaw')
//     .update({ [userId]: "true" })
// }

// export function markGroupChatMessagesAsRead(chatId, userId, messageKeys=[]) {
//   return Promise.all(messageKeys.map(markGroupChatMessageAsRead.bind(this, chatId, userId)));
// }

export async function markGroupChatAsUnread(userId, chatKey) {
  const groupChatsDataRef = formGroupChatsDataRef(userId);
  const groupChatDataRef = groupChatsDataRef.child(chatKey);
  // we add lastMessageTimestamp to trigger update event in case readStatus was false
  return groupChatDataRef.update({readStatus: false, lastMessageTimestamp: Firebase.database.ServerValue.TIMESTAMP});
}

async function fetchGroupChatMessages(chatKey) {
  const messagesSnapshot = await database.ref('Groups').child(chatKey).child('data').once('value');

  return messagesSnapshot && messagesSnapshot.val();
}

export function subscribeOnGroupChatMessage(chatKey, onChatMessageAdded) {
  const messagesRef = database.ref('Groups')
    .child(chatKey)
    .child('data');
  messagesRef.limitToLast(1).on('child_added', onChatMessageAdded);
  return () => {
    messagesRef.off('child_added', onChatMessageAdded);
  }
}

export async function subscribeOnUnreadGroupChatMessages(userId, callbacks) {
  const {
    onChatAdded, onChatRemoved, onChatMessageAdded: onChatMessageAddedCb, onChatReadingDataChange,
  } = callbacks;

  const userGroupListReg = database.ref('/Users')
    .child(userId)
    .child('group_list');

  const groupChatsDataRef = formGroupChatsDataRef(userId);

  const [userGroupListSnapshot, groupChatsDataSnapshot] = await Promise.all([
    userGroupListReg.once('value'),
    groupChatsDataRef.once('value'),
  ]);

  const userGroupList = userGroupListSnapshot && userGroupListSnapshot.val();
  const groupChatsData = groupChatsDataSnapshot && groupChatsDataSnapshot.val();

  const unsubscribeFns = [];
  const chatsUnsubscribeFnsMap = {};

  const processOnChatMessagesSubscription = (groupKey, chatMessages = true) => {
    if (Array.isArray(chatsUnsubscribeFnsMap[groupKey])) return;
    let firstInvoke = chatMessages;

    const onChatMessageAdded = (messageSnapshot) => {
      if (firstInvoke) {
        firstInvoke = false;
      } else {
        const message = messageSnapshot && messageSnapshot.val();
        /**
         * todo: Have an error if create a new group chat with new user (user who has no group chats)
         */
        if (message && message.userId !== userId) {

          // const lastReadTimestamp = groupChatsData && groupChatsData[groupKey]
          //   ? groupChatsData[groupKey].lastReadTimestamp
          //   : Firebase.database.ServerValue.TIMESTAMP;

          onChatMessageAddedCb({
            chatKey: groupKey,
            // lastReadTimestamp: lastReadTimestamp,
            message: {key: messageSnapshot.key, timestamp: message.timestamp}
          });
        }
      }
    };

    const unsubscribe = subscribeOnGroupChatMessage(groupKey, onChatMessageAdded);

    chatsUnsubscribeFnsMap[groupKey] = [unsubscribe];
    unsubscribeFns.push(unsubscribe);
  };

  if (groupChatsData) {
    const groupChatsKeys = new Set(Object.values(userGroupList || {}));
    const promises = Object.keys(groupChatsData)
      .filter(groupChatKey => groupChatsKeys.has(groupChatKey))
      .map(async groupChatKey => {
        if (groupChatsData[groupChatKey].readStatus === true) return;
        processOnChatMessagesSubscription(groupChatKey);
      });

    await Promise.all(promises);
  }

  const onGroupChatsDataAdded = async snapshot => {
    const chatsData = snapshot && snapshot.val();
console.log('chatsData (onGroupChatsDataAdded (before first condition)): ', chatsData);
console.log('snapshot (onGroupChatsDataAdded (before first condition)): ', snapshot);
    if (!chatsData || chatsData.readStatus === true) return;
console.log('chatsData (onGroupChatsDataAdded): ', chatsData);
    const chatKey = snapshot.key;

    const userGroupListSnapshot = await userGroupListReg.once('value');
    const userGroupList = userGroupListSnapshot && userGroupListSnapshot.val();
    if (!userGroupList) return;
console.log('userGroupList (onGroupChatsDataAdded): ', userGroupList);
    const groupChatsKeys = new Set(Object.values(userGroupList || {}));
    if (!groupChatsKeys.has(chatKey)) return;
console.log('groupChatsKeys (onGroupChatsDataAdded): ', groupChatsKeys);
    const messages = await fetchGroupChatMessages(chatKey);
    const { lastReadTimestamp = 0 } = chatsData;
    const unreadMessagesKeys = Object.keys(messages || {})
      .filter((key) => {
        const message = messages[key];
        // return message.membersWhoSaw && message.membersWhoSaw[userId] === 'false';
        return message.timestamp > lastReadTimestamp && userId !== message.userId;
      })
      .map((key) => key);

    onChatAdded({key: chatKey, lastReadTimestamp, unreadMessagesKeys});
    processOnChatMessagesSubscription(chatKey, messages);
  };

  const onGroupChatsDataChanged = async snapshot => {
    const chatData = snapshot && snapshot.val();

    if (!chatData) return;

    const chatKey = snapshot.key;
    const {lastReadTimestamp, readStatus} = chatData;
    onChatReadingDataChange({key: chatKey, lastReadTimestamp, readStatus});

    processOnChatMessagesSubscription(chatKey);
  };

  const onGroupChatsRemoved = async snapshot => {
    const chatKey = snapshot && snapshot.val();

    onChatRemoved(chatKey);
    const unsubscribeFns = chatsUnsubscribeFnsMap[chatKey];
    if (unsubscribeFns) {
      unsubscribeFns.forEach(fn => fn());
    }
    chatsUnsubscribeFnsMap[chatKey] = undefined;
  };
console.log('onGroupChatsDataAdded: ', onGroupChatsDataAdded)
  groupChatsDataRef.on('child_added', onGroupChatsDataAdded);
  groupChatsDataRef.on('child_changed', onGroupChatsDataChanged);
  userGroupListReg.on('child_removed', onGroupChatsRemoved);

  const unsubscribe = () => {
    groupChatsDataRef.off('child_added', onGroupChatsDataAdded);
    groupChatsDataRef.off('child_changed', onGroupChatsDataChanged);
    userGroupListReg.off('child_removed', onGroupChatsRemoved);
    unsubscribeFns.forEach(fn => typeof fn === 'function' && fn());
  };
  return unsubscribe;
}

import { userConstants } from '../constants';

export function Getplan(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_PLAN_REQUEST:
      return { planData: true };
    case userConstants.GET_PLAN_SUCCESS:
      return {
        planData: false,
        data: action.data,
      };
    case userConstants.GET_PLAN_FAILURE:
      return {};
    default:
      return state
  }
}

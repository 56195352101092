import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import MemberOfListSection from '../../../containers/frontend/userProfile/MemberOfListSection';
import NotFoundView from '../NotFoundView';
import { alertActions,sparksActions } from '../../../actions';

class UserMemberLists extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.profilename !== this.props.match.params.profilename) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { dispatch } = this.props;

    const { username } = this.props;
    if (username) {
        dispatch(sparksActions.getProfileData(username));
    }
  }
  render() {

    if (this.props.notfoundstatus) {
      return (
        <>
          <Header/>
          <NotFoundView />
        </>
      )
    }

    return (
      <div id="UserProfile" className="UserMemberLists">
        <Header/>
        <MemberOfListSection username={this.props.match.params.profilename}/>
     </div>
    );
  }
}
function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    notfoundstatus: state.profileinfo.notfoundstatus != undefined ? state.profileinfo.notfoundstatus : false,
    username: ownProps.match.params.profilename,
  };
}
export default connect(mapStateToProps)(UserMemberLists);

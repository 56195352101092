import React, {Component} from 'react';
import {connect} from 'react-redux';
import MomentGrid from './MomentGrid';
import {momentsActions, alertActions} from '../../../actions';

class todayContent extends Component {
  constructor(props) {
    super(props);
    this.getPageData();
  }

  componentDidMount() {
    this.getPageData();
  }

  getPageData = () => {
    const {user} = this.props;
    const {dispatch} = this.props;
    if (user) {
      dispatch(momentsActions.getAllMoments(user.id));
    }
  };

  render() {
    const {user, momentData, alert} = this.props;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;
    return (
      <div className="sports-main content-part">
        <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          { momentData.today
            ? <MomentGrid momentData={momentData.today} type='today'/> 
            : <p className="text  text--centered">There are no instants for today</p>}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    momentData: state.moments.items != undefined ? state.moments.items : [],
    alert: state.alert,
  };
}

export default connect(mapStateToProps)(todayContent);

import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link, NavLink} from 'react-router-dom';
import LoadingIcon from '../common/LoadingIcon';

import { alertActions,suggestionActions, userActions, urlActions, setChatContentType, setActiveChat, chatActions, getChatLists } from '../../../actions';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createSingleChat } from '../../../services';
import { getExistChat } from '../../../helpers';
import { CHAT_CONTENT_CHAT_MODULE } from '../../../constants';
class UserProfileTopSection extends Component {

  constructor(props){
    super(props);
  }

  FollowUnfollowUser = (follower_id) => {
    const { loggeduser,username, history, dispatch } = this.props;

    if(!loggeduser) {
      dispatch(urlActions.savePreloginUrl());
      history.push("/login");
    }
    else {
      if (loggeduser && follower_id) {
        const sendRaw = {
          follower_id: follower_id,
          user_id: loggeduser.id,
        }
        dispatch(suggestionActions.FollowUnfollowOther(sendRaw, username));
      }
      else {
        toast.error(msgs.SOMETHING_WENT_WRONG);
      }
    }
  }

  FollowResponse = (other_id,action) => {
    const { dispatch } = this.props;
    const { loggeduser, username, history } = this.props;

    if(!loggeduser) {
      dispatch(urlActions.savePreloginUrl());
      history.push("/login");
    } else {
      if (loggeduser && other_id && action) {
        const sendRaw = {
          other_id: other_id,
          user_id: loggeduser.id,
          action: action,
        }
        dispatch(suggestionActions.FollowResponse(sendRaw, username, 'profile_main'));
      }
      else {
        toast.error(msgs.SOMETHING_WENT_WRONG);
      }
    }
  }

  getButtonByCondition(profileinfo, loggeduser, notShowContent) {
    if(loggeduser && profileinfo.user_id == loggeduser.id) {
      return <Link to="/settings/edit-profile" className="button">Edit Profile</Link>;
    } else {
      if(profileinfo.followStatus == '0') {
        return !notShowContent && (
          <button 
            className="button" 
            data-id={profileinfo.user_id} 
            onClick={() => this.FollowUnfollowUser(profileinfo.user_id)}
          >
            Follow
          </button>
        );
      } else if(profileinfo.followStatus == '1' 
          && profileinfo.at_username !== "@2cents" 
          && profileinfo.username !== "2cents") {
        return !notShowContent && (
          <button 
            className="button active button-unfollow" 
            data-id={profileinfo.user_id} 
            onClick={() => this.FollowUnfollowUser(profileinfo.user_id)}
          >
            <span className="active">Following</span>
            <span className="hover-active">Unfollow</span>
          </button>
        );
      } else if(profileinfo.at_username !== "@2cents" && profileinfo.username !== "2cents") {
        return (
          <button 
            className="button" 
            data-id={profileinfo.user_id}  
            onClick={() => this.FollowResponse(profileinfo.user_id,'cancel')}
          >
            <span className="active">Pending</span>
            <span className="hover-active">Cancel</span>
          </button>
        );
      }
      return null;
    }
  }

  checkAutorized = (url) =>{
    const {dispatch, loggeduser} = this.props;

    if(!loggeduser)
      dispatch(urlActions.savePreloginUrl(url));
  }


  chatOpen = async (recipientId) => {
    const friendId = Number(recipientId);
    const { dispatch, loggeduser, chatListData: { chats } } = this.props;
    const data = { user_id: friendId, type: 1 };

    if ( friendId === loggeduser.id) {
      return;
    }

    const chat = getExistChat(chats, friendId) || await dispatch(chatActions.createSingleChat(data)) || [];

    setActiveChat(chat)(dispatch);
    setChatContentType(CHAT_CONTENT_CHAT_MODULE)(dispatch);
  };

  render(){
    const {
      loggeduser, 
      profileinfo, 
      banner_image, 
      profile_image, 
      username, 
      alert, 
      isHidden,
      isHiddenByYou,
      history,
    } = this.props;
    const listUrls = {
      sparks: "/"+username,
      following: "/"+username + "/following",
      followers: "/"+username+"/followers",
      likes: "/"+username+"/like",
      instants: "/"+username+"/instants",
      lists: "/"+username+"/lists",
      chat: "/chat",
    };
    const onLoggedUserProfile = loggeduser && (loggeduser.id == profileinfo.user_id);
    const notShowContent = isHidden || isHiddenByYou;
    return (
      
      <div className="edit-profile-header">
          <div 
            className="edit-top" 
            style={banner_image.indexOf('no-banner') < 0 
              ? {backgroundImage: `url(${!isHidden 
                ? banner_image 
                : '/frontend/images/banner_image.jpg'})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'} 
              : {}}
          >
          </div>
          <div className="edit-pro-nav">
            <div className="container">
              <div className="user-img-upload" id="user-img-view">
                <label>
                  {(profile_image && !isHidden) ? (
                      <img src={profile_image} alt="img" className="profile-image-show"/>
                    ) : (
                    <img src="/frontend/images/no-image.jpg" alt="img" className="profile-image-show"/>
                  )
                  }
                </label>
              </div>
              <div className={`list-nav-wrapper ${!isHidden ? '' : 'justify-content-end'}`}>
                {!isHidden && (
                  <ul className="list-nav">
                    <li onClick={(e)=>this.checkAutorized(listUrls.sparks)}>
                      <NavLink to={listUrls.sparks} activeClassName="active" exact>
                        <strong>{profileinfo.totalSparks || 0}</strong>Sparks
                      </NavLink>
                    </li>
                    <li onClick={(e)=>this.checkAutorized(listUrls.following)}>
                      <NavLink to={listUrls.following} activeClassName="active">
                        <strong>{profileinfo.totalFollowing || 0}</strong>Following
                      </NavLink>
                    </li>
                    <li onClick={(e)=>this.checkAutorized(listUrls.followers)}>
                      <NavLink to={listUrls.followers} activeClassName="active">
                        <strong>{profileinfo.totalFollowers || 0}</strong>Followers
                      </NavLink>
                    </li>
                    <li onClick={(e)=>this.checkAutorized(listUrls.likes)}>
                      <NavLink to={listUrls.likes} activeClassName="active">
                        <strong>{profileinfo.totalLikes || 0}</strong>Likes
                      </NavLink>
                    </li>
                    <li onClick={(e)=>this.checkAutorized(listUrls.instants)}>
                      <NavLink to={listUrls.instants} activeClassName="active">
                        <strong>{profileinfo.myMoments ? profileinfo.myMoments.length : profileinfo.totalMoments || 0}</strong>Instants
                      </NavLink>
                    </li>
                    <li onClick={(e)=>this.checkAutorized(listUrls.lists)}>
                      <NavLink to={listUrls.lists} activeClassName="active">
                        <strong>{profileinfo.totalLists || 0}</strong>Lists
                      </NavLink>
                    </li>
                  </ul>
                )}
                {alert.type !== 'alert-danger' &&
                  <div className="edit-top-btn">
                    {!onLoggedUserProfile && !notShowContent && <div className="open-chat-from-profile"
                      onClick={() => {
                        this.checkAutorized(listUrls.chat);
                        history.push(listUrls.chat);
                        this.chatOpen(profileinfo.user_id);
                      }}
                    >
                      <i className="far fa-envelope" />
                  </div>}
                  {this.getButtonByCondition(profileinfo, loggeduser, notShowContent)}
                </div>}
              </div>
            </div>
          </div>
      </div>
    )
  }
}

UserProfileTopSection.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    sparks: state.sparks.items,
    alert: state.alert,
    loggeduser: state.authentication.user,
    loadingstatus: state.profileinfo.data != undefined ? true : false,
    profileinfo: state.profileinfo.data != undefined ? state.profileinfo.data : '',
    banner_image: state.profileinfo.data != undefined ? state.profileinfo.data.banner_image : '',
    profile_image: state.profileinfo.data != undefined ? state.profileinfo.data.profile_image : '',
    username: ownProps.username,
    isHidden: state.profileinfo.data != undefined && state.profileinfo.data.blockByUser == 1 ? true : false,
    isHiddenByYou: state.profileinfo.data != undefined && state.profileinfo.data.blockByYou,
    chatListData: state.chat.chatListData,
  };
}
export default withRouter(connect(mapStateToProps)(UserProfileTopSection));

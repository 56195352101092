import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import UserProfileSection from '../../../containers/frontend/userProfile/UserProfileSection';
import { Helmet } from "react-helmet";
import NotFoundView from '../NotFoundView';
import { sparksActions } from '../../../actions';

class UserProfile extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.profilename !== this.props.match.params.profilename ||
      prevProps.profileinfo !== this.props.profileinfo) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { dispatch } = this.props;

    const { username } = this.props;
    if (username) {
        dispatch(sparksActions.getProfileData(username));
    }
  }

  render() {
    const { notfoundstatus, blockByYou, blockByUser, username, alert } = this.props;

    return (
      <div id="UserProfile" className="UserProfile">
        <Helmet>
          <title>{`@${username} | 2cents`}</title>
        </Helmet>
        <Header/>
          {
            notfoundstatus && alert.message !== 'TypeError: Failed to fetch' ?
              <NotFoundView /> :
              <UserProfileSection blockByUser={blockByUser} blockByYou={blockByYou} username={this.props.match.params.profilename}/>
          }
      </div>
    );
  }
}
function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    username: ownProps.match.params.profilename,
    notfoundstatus: state.profileinfo.notfoundstatus != undefined ? state.profileinfo.notfoundstatus : false,
    blockByYou: state.profileinfo.data != undefined && state.profileinfo.data.blockByYou == 1 ? true : false,
    blockByUser: state.profileinfo.data != undefined && state.profileinfo.data.blockByUser == 1 ? true : false,
    name: state.profileinfo.data != undefined ? state.profileinfo.data.name : '',
    alert: state.alert,
  };
}
export default connect(mapStateToProps)(UserProfile);

export const getChatUnreadMessagesData = (status) => {
  const chatData = {};

  if (!status) {
    return {
      data: {
        status: status,
        msg: "Request failed",
        chatData: {},
      }
    };
  }

  chatData.singleUnreadMessages = 23;
  chatData.groupUnreadMessages = 10;

  return {
    data: {
      status: status,
      msg: "Chat unread amount",
      chatData: chatData,
    }
  };
}
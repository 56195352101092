import React from 'react';
import s from './styles.module.scss';
import cx from 'classnames';


export default function LeftBar({className}) {
  return (
    <div className={cx('col-xl-7', s.resetPadding, className)}>
      <div className={s.leftBar}>
        <div className={s.topBlock}>
          <img className={s.mainLogo} src="/frontend/images/white-logo.png" alt="2cents" />
          <p className={s.tagLine}>
            A microblogging platform that<br/>
            respects the dignity of its community
          </p>
        </div>
        <h3 className={s.welcome}>WELCOME</h3>
        <div className={s.advantagesBlock}>
          <p>
            Your privacy is protected
            <span>•</span>
            No robots or fake accounts
          </p>
          <p>
            All voices given equal respect
            <span>•</span>
            No advertisements
          </p>
          <p>
            No shadow banning
            <span>•</span>
            No selling of your personal data
          </p>
          <p>
            No political or social biases pushed by 2cents
          </p>
        </div>
        <div className={s.copyrightBlock}>
          {`©${new Date().getFullYear()} 2cents, LLC. All Rights Reserved.`}
        </div>
      </div>
    </div>
  );
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import { momentsActions } from '../../../actions';
import SparkLike from './SparkLike';
import SparkAccount from './SparkAccount';
import SparkTweet from './SparkTweet';
import { toast } from 'react-toastify';
import msgs from "../../../messages/en/main";
import ManageSelectedSpark from './ManageSelectedSpark';
import { fileSizeLimits } from '../../../misc/fileSizeLimits';
import { imageFormatChecker, acceptableImageFormats } from '../../../helpers';

class CreateMoment extends Component {
  constructor(props){
    super(props);
    this.state = {
      momentData: {
        category: '',
        title: '',
        description: '',
        userid: this.props.user.id,
      },
      file: [],
      PreviewUrls: [],
      submitted: false,
      listDataFromChild: [],
      // isFinishLoading: false,
      // isCreateLoading: false,
      fieldIsEmpty: {
        category: null,
        title: null,
        description: null,
        file: null,
        listDataFromChild: null,
      },
    };
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const {
  //     isCreateLoading,
  //     isFinishLoading,
  //   } = prevState;
  //   const { isMomentLoading: { momentCreating, momentUploadComplete } } = this.props;

  //   if (isFinishLoading && !momentCreating && !momentUploadComplete) {
  //     this.setState({isFinishLoading: false});
  //   }

  //   if (isCreateLoading && !momentCreating && !momentUploadComplete) {
  //     this.setState({isCreateLoading: false});
  //   }
  // }

  openCity = (evt, cityName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    if(document.getElementById(cityName) != null) {
      document.getElementById(cityName).style.display = "block";
    }
    evt.currentTarget.className += " active";
  }

  handleChange = (event) => {
      const { name, value } = event.target;
      const { momentData, fieldIsEmpty } = this.state;
      this.setState({
          momentData: {
              ...momentData,
              [name]: value,
          }
      });

      this.setState(prevState => ({
        fieldIsEmpty: {
            ...fieldIsEmpty,
            [name]: !prevState.momentData[name],
        }
      }));
  }

  handleInputTrigger = () => {
      this.fileInput.click();
  }

  handleImageChange = e => {
     e.preventDefault();
     const file = e.target.files[0];

     if(file) {
      if (file.size > fileSizeLimits.image) {
        return toast.error(msgs.MAX_IMG_SIZE);
      }
      
      if (!imageFormatChecker(file)) {
        return toast.error(msgs.VALID_IMAGE_FORMATS);
      }
        let reader = new FileReader();
        let fType = file.type;
         if(fType.match('image.*')) {

             if(fType.match('image.*')){
               this.setState({ type: 'image' });
             }
             let item1 = [];
             let item2 = [];

             reader.onloadend = () => {

                item1.push(file);
                item2.push(reader.result);

                 this.setState({
                     file: item1,
                     PreviewUrls: item2,
                 });
             }

             reader.readAsDataURL(file);

             this.setState(prevState => ({
              fieldIsEmpty: {
                  ...prevState.fieldIsEmpty,
                  file: false,
              }
            }));
         }
         else {
            toast.error(msgs.IMAGES_ARE_ALLOWED);
         }

     }
     else {
        return;
     }
  }

  createMomentProcess = async (action) => {
    let assignPost = [];
    const { listDataFromChild } = this.state;

    if (listDataFromChild.length > 0) {
      for (let i = 0; i < listDataFromChild.length; i++) {
        assignPost.push(listDataFromChild[i].post_id);
      }
    }

    this.setState({ submitted: true });
    const { momentData, file, type } = this.state;
    const { dispatch } = this.props;

    if (momentData) {
      const sendRaw = {
        momentData,
        file,
        assignPost,
        action,
        type,
      };
      if (!this.isMomentDataValid(sendRaw)) return;

      // this.setState({isFinishLoading: action === 0 && true, isCreateLoading: action === 1 && true});
      await dispatch(momentsActions.createMoment(sendRaw));
      this.clearState();
   }
  };

  isMomentDataValid = ({
    momentData,
    file,
    assignPost,
    action,
  }) => {

    const {
      category,
      title,
      description,
      userid
    } = momentData;

    switch(action) {
      case 0:
        this.fieldIsEmptyCheck(category, 'category');
        this.fieldIsEmptyCheck(title, 'title', action);

        if (!category || !title) {
          toast.error(msgs.FILL_REQUIRED_INFORMATION);
          return false;
        }
      return true;
      case 1:
        this.fieldIsEmptyCheck(category, 'category');
        this.fieldIsEmptyCheck(title, 'title');
        this.fieldIsEmptyCheck(description, "description");
        this.fieldIsEmptyCheck(file.length, "file");
        this.fieldIsEmptyCheck(assignPost.length, 'listDataFromChild');
  
  
        if (!description || !file.length || !assignPost.length) {
          toast.error(msgs.FILL_REQUIRED_INFORMATION);
          return false;
        }

        if (!userid) {
          toast.error(msgs.SOMETHING_WENT_WRONG);
          
          return false;
        }
        return true;
        default:
          break;
    }
  }

  fieldIsEmptyCheck = (field, name, action) => {
    if (!field) {
      this.setState(prevState => ({
        fieldIsEmpty: {
            ...prevState.fieldIsEmpty,
            [name]: true,
        }
      }));
    }

    if (action === 0) {
      this.setState(prevState => ({
        fieldIsEmpty: {
            ...prevState.fieldIsEmpty,
            description: false,
            file: false,
            listDataFromChild: false,
        }
      }));
    }
  }

  myCallback = (dataFromChild, index, type) => {
    const { dispatch } = this.props;
    const { user } = this.props;
    const newStateArray = this.state.listDataFromChild;
    var index = newStateArray.findIndex(x => x.post_id==dataFromChild.post_id);
    if (index === -1){
      newStateArray.push(dataFromChild);
      if(user)
      {
          const sendRaw = {
            user_id: user.id,
            index: index,
            type: type,
          }
          dispatch(momentsActions.removeTweetMoment(sendRaw));
      }
    }
    else
    {
      toast.error(msgs.SPARK_IS_ALREADY_IN_INSTANT);
    }
    this.setState({ listDataFromChild: newStateArray });
  }

  myCallbackDelete = (dataArr) => {
      var array = this.state.listDataFromChild.filter(function(item) {
        return item.post_id !== dataArr.post_id
      });
      this.setState({
        listDataFromChild: array
      });
  }

  clearState = () => {
    this.setState ({
      momentData: {
        category: '',
        title: '',
        description: '',
        userid: this.props.user.id,
      },
      file: [],
      PreviewUrls: [],
      submitted: false,
      listDataFromChild: [],
      fieldIsEmpty: {
        category: null,
        title: null,
        description: null,
        file: null,
        listDataFromChild: null,
      },
    });
  }

  render () {
    const { categoryData, tweetMomentData, alert, loading } = this.props;
    const { 
      momentData,
      listDataFromChild,
      PreviewUrls, 
      // isFinishLoading, 
      // isCreateLoading,
      fieldIsEmpty,
      file,
    } = this.state;

    // const isLoading = isMomentLoading && (isMomentLoading.momentCreating || isMomentLoading.momentUploadComplete);

    return (
      <div className="create-wrapper">
        <div className="nav2">
            <div className="container">
                <button
                    disabled={loading}
                    style={{"position": "relative"}}
                    className="button"
                    title="Publish"
                    onClick={() => this.createMomentProcess(1)}
                >
                  Publish { loading && (<i className="fas fa-spin fa-spinner" />) }
                </button>
                <button
                   disabled={loading}
                   className="button-left-gap  button"
                   style={{"position": "relative"}}
                   title="Finish Later"
                   onClick={() => this.createMomentProcess(0)}
                >
                  Finish Later { loading && (<i className="fas fa-spin fa-spinner" />) }
                </button>
            </div>
        </div>
        <div className="create-moment">
          <div className="content-part">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-8 mx-auto">
                  <div className="edit-pro-inn">
                    <form>
                      <div className="form-group">
                        <select 
                          value={momentData.category}
                          onChange={this.handleChange}
                          name="category" 
                          className={`form-control 
                            ${fieldIsEmpty.category === false || fieldIsEmpty.category === null || momentData.category
                              ? '' 
                              : 'error'
                          }`}
                          id="exampleInputEmail1" 
                          aria-describedby="emailHelp"
                        >
                          <option value="" >
                            Selecting Category *
                          </option>
                          {categoryData != undefined ?
                            categoryData.map((category, index) => (
                                <option key={index} value={category.category_id}>
                                  {category.title}
                                </option>
                            )) : ('')
                          }
                        </select>
                      </div>
                      <div className="form-group">
                        <input 
                          value={momentData.title}
                          onChange={this.handleChange}
                          name="title" 
                          type="text" 
                          className={`form-control 
                            ${fieldIsEmpty.title === false || fieldIsEmpty.title === null || momentData.title
                              ? '' 
                              : 'error'
                          }`}
                          id="exampleInputEmail1" 
                          aria-describedby="emailHelp" 
                          placeholder="Title your instant *"
                          maxLength="50"
                        />
                      </div>
                      <div className="form-group">
                        <textarea 
                          value={momentData.description}
                          onChange={this.handleChange}
                          name="description"
                          className={`form-control 
                            ${fieldIsEmpty.description === false 
                            || fieldIsEmpty.description === null 
                            || momentData.description
                              ? '' 
                              : 'error'
                          }`}
                          id="exampleFormControlTextarea1"
                          rows={3} 
                          placeholder="Add a description *"
                          defaultValue={""}
                          maxLength="280"
                        />
                      </div>
                      <div className="use-photo-moment">
                        <p className={`${fieldIsEmpty.file === false 
                          || fieldIsEmpty.file === null 
                          || file === 0
                              ? '' 
                              : 'error'
                          }`}
                        >
                          Upload any image *
                        </p>
                        <div className="set-cover">
                          {/* <input 
                            type="file" 
                            id="file-add" 
                            accept="image/*" 
                          /> */}
                          <input 
                            ref={fileInput => this.fileInput = fileInput} 
                            accept={acceptableImageFormats.join(', ')}
                            name="file"
                            value={momentData.file}
                            id="banner_image" 
                            className="inputfile inputfile-3 hidefile" 
                            data-multiple-caption="files selected" 
                            type="file" 
                            onChange={this.handleImageChange} 
                            style={{display: "none"}} 
                          />
                          <label onClick={() => this.handleInputTrigger()}>
                            Set Cover
                          </label>
                          <p>&nbsp;</p>
                          {PreviewUrls != undefined ?
                            (PreviewUrls.map((raw,index) =>
                                <img 
                                  src={raw} 
                                  key={index} 
                                  style={{maxWidth: '100%'}} 
                                />
                            )) : ('')
                          }
                        </div>
                      </div>
                    </form>

                    <div id="sparks1 selectedData" className="selectedSpark">
                      {listDataFromChild.length > 0 ?
                        (listDataFromChild.map((like, index) => (
                            <ManageSelectedSpark 
                              key={index} 
                              rawData={like} 
                              index = {index} 
                              callbackFromParentDelete={this.myCallbackDelete}
                            />
                        ))) : ('')
                      }
                    </div>

                    <div className="add-spark">
                      <h3 className={`${fieldIsEmpty.listDataFromChild === false 
                          || fieldIsEmpty.listDataFromChild === null 
                          || listDataFromChild.length !== 0
                              ? '' 
                              : 'error'
                          }`}
                        >
                        Add Sparks to your Instant *
                      </h3>
                      <div className="add-spark-inn">
                        <div className="tab2">
                          <button className="tablinks active" onClick={(e) => this.openCity(e, 'sparks1')}>Sparks I have liked</button>
                          <button className="tablinks" onClick={(e) => this.openCity(e, 'sparks2')}>Sparks by account</button>
                          <button className="tablinks" onClick={(e) => this.openCity(e, 'sparks3')}>Sparks search </button>
                        </div>
                        <SparkLike callbackFromMainParent={this.myCallback} selectedSparks={listDataFromChild}/>
                        <SparkAccount callbackFromMainParent={this.myCallback} selectedSparks={listDataFromChild}/>
                        <SparkTweet callbackFromMainParent={this.myCallback} selectedSparks={listDataFromChild}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    categoryData: state.momentscategory.categoryData != undefined ? state.momentscategory.categoryData : [],
    user: state.authentication.user,
    alert: state.alert,
    // isMomentLoading: state.momentsCreate || false,
    loading: state.momentsCreate.loading,
  };
}

export default connect(mapStateToProps)(CreateMoment);

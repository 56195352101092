import { toast } from 'react-toastify';
import {
  SET_ACTIVE_CHAT_SUCCESS,
  UPDATE_ACTIVE_CHAT_SUCCESS,
  SHOW_CHAT_MODAL,
  HIDE_CHAT_MODAL,
  TOGGLE_CHAT_MODAL,
  SET_CHAT_CONTENT,
  CHAT_CONTENT_CHAT_MODULE,
  CHAT_CONTENT_DELETE,
  UPDATE_SINGLE_CHATS,
  UPDATE_GROUP_CHATS,

  CHAT_LIST_REQUEST,
  CHAT_LIST_SUCCESS,
  CHAT_LIST_FAIL,
  CHAT_MESSAGES_REQUEST,
  CHAT_MESSAGES_SUCCESS,
  CHAT_MESSAGES_FAIL,

  UNREAD_MESSAGES_AMOUNT_REQUEST,
  UNREAD_MESSAGES_AMOUNT_SUCCESS,
  UNREAD_MESSAGES_AMOUNT_FAIL,
  DELETE_CHAT_REQUEST,
  DELETE_CHAT_SUCCESS,
  DELETE_CHAT_FAIL,
  CREATE_SINGLE_CHAT_REQUEST,
  CREATE_SINGLE_CHAT_SUCCESS,
  CREATE_SINGLE_CHAT_FAIL,
  CREATE_GROUP_CHAT_REQUEST,
  CREATE_GROUP_CHAT_SUCCESS,
  CREATE_GROUP_CHAT_FAIL,
  SEND_MEDIA_REQUEST,
  SEND_MEDIA_SUCCESS,
  SEND_MEDIA_FAIL,
  INVITE_USER_TO_CHAT_REQUEST,
  INVITE_USER_TO_CHAT_SUCCESS,
  INVITE_USER_TO_CHAT_FAILURE,
  EDIT_CHAT_REQUEST,
  EDIT_CHAT_SUCCESS,
  EDIT_CHAT_FAIL,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAIL,
  UPDATE_CHATS,
  PUSH_ACTIVE_CHAT_MESSAGE,
  MARK_MESSAGES_AS_READ_REQUEST,
  MARK_MESSAGES_AS_READ_SUCCESS,
  MARK_MESSAGES_AS_READ_FAIL,
  UPDATE_UNREAD_MESSAGES_AMOUNT,
  CLEAR_CHAT_LIST,
  UPDATE_ACTIVE_CHAT_MEMBERS,
} from '../constants';
import * as chatService from '../services/chat.service';
import { replaceObject } from '../helpers'

export const setActiveChat = (data) => {
  return (dispatch) => {
    dispatch({type: SET_ACTIVE_CHAT_SUCCESS, data})
  }
};

export const updateActiveChat = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_ACTIVE_CHAT_SUCCESS, data})
  }
};

export const pushActiveChatMessage = data => dispatch => dispatch({type: PUSH_ACTIVE_CHAT_MESSAGE, data})


export const handleChatCreated = chat => {
  return (dispatch, getState) => {
    const chats = getState().chat.chatListData.chats;
    const newChats = replaceObject(chat, chats)

    if (newChats){
      dispatch(updateChats(newChats));
    } else {
      dispatch(updateChats([...chats, chat]));
    }
  }
};

export const handleChatMemberRemoved = chat => {
  return (dispatch, getState) => {
    const { chat: { chatListData: { chats }, activeChatData: { data } } } = getState()
    const newChats = replaceObject(chat, chats)

    if (data && data.id === chat.id) {
      dispatch(updateActiveChatMembers(chat));
    }

    if (newChats){
      dispatch(updateChats(newChats))
    }
  }
}

export const handleChatEdited = chat => {
  return (dispatch, getState) => {
    const chats = getState().chat.chatListData.chats;
    const newChats = replaceObject(chat, chats)

    if (newChats){
      dispatch(updateChats(newChats));
    } else {
      dispatch(updateChats([...chats, chat]));
    }
  }
}

const updateActiveChatMembers = chat => dispatch => dispatch({type: UPDATE_ACTIVE_CHAT_MEMBERS, chat});

export const updateChats = data => dispatch => dispatch({type: UPDATE_CHATS, data});
export const updateGroupChats = data => dispatch => dispatch({type: UPDATE_GROUP_CHATS, data})

export const deleteActiveChat = () => (dispatch) => dispatch({type: CHAT_CONTENT_DELETE});
export const clearChatList = () => (dispatch) => dispatch({type: CLEAR_CHAT_LIST});
export const showChatModal = () => (dispatch) => dispatch({type: SHOW_CHAT_MODAL});
export const hideChatModal = () => (dispatch) => dispatch({type: HIDE_CHAT_MODAL});
export const toggleChatModal = () => (dispatch) => dispatch({type: TOGGLE_CHAT_MODAL});
export const setChatContentType = (data) => (dispatch) => dispatch({type: SET_CHAT_CONTENT, data});
export const showChatModule = () => (dispatch) => dispatch({type: SET_CHAT_CONTENT, data: CHAT_CONTENT_CHAT_MODULE});


export const getChatLists = () => {
  return dispatch => {
    dispatch(request());
    chatService.getChatLists()
      .then((response) => {
          if (response.status === 200) {
            dispatch(success(response.chatList));
          } else {
            dispatch(failure(response.msg));
          }
        },
        error => {
          console.log("error", error)
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() {
    return {type: CHAT_LIST_REQUEST}
  }

  function success(chats) {
    return {type: CHAT_LIST_SUCCESS, chats}
  }

  function failure() {
    return {type: CHAT_LIST_FAIL}
  }
}

export const getChatMessages = (chatId, countSkipMessages = 0) => {
  // const jsonData = JSON.stringify({chatId});
  return dispatch => {
    dispatch(request());
    chatService.getChatMessages(chatId, countSkipMessages)
      .then((response) => {
          if (response.status === 200) {
            dispatch(success(response.messages));
          } else {
            dispatch(failure(response.msg));
          }
        },
        error => {
          console.log("error", error)
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() {
    return {type: CHAT_MESSAGES_REQUEST}
  }

  function success(messages) {
    return {type: CHAT_MESSAGES_SUCCESS, messages}
  }

  function failure(errMsg) {
    return {type: CHAT_MESSAGES_FAIL, errMsg}
  }
}


export const getChatUnreadMessages = () => {
  return dispatch => {
    dispatch(request());
    chatService.getUnreadMessages()
      .then((response) => {
          if (response.status) {
            dispatch(success(response.countUnreadMessages));
          } else {
            dispatch(failure(response.msg));
          }
        },
        error => {
          console.log("error", error)
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() {
    return {type: UNREAD_MESSAGES_AMOUNT_REQUEST}
  }

  function success(countUnreadMessages) {
    return {type: UNREAD_MESSAGES_AMOUNT_SUCCESS, countUnreadMessages}
  }

  function failure() {
    return {type: UNREAD_MESSAGES_AMOUNT_FAIL}
  }
}

export const updateUnreadCounter = (countOfUnread) => ({type: UPDATE_UNREAD_MESSAGES_AMOUNT, countOfUnread});

export const leaveChat = (chatId) => {
  return dispatch => {
    dispatch(request());
    chatService.leaveChat(chatId)
      .then((response) => {
          if (response.status === 200) {
            dispatch(success(chatId));
            toast.success(response.message);
          } else {
            dispatch(failure(response.msg));
          }
        },
        error => {
          console.log("error", error)
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() { return { type: DELETE_CHAT_REQUEST } }
  function success(chatId) { return { type: DELETE_CHAT_SUCCESS, chatId } }
  function failure(errMsg) { return { type: DELETE_CHAT_FAIL, errMsg } }
}

function createSingleChat(chatData) {
  const jsonData = JSON.stringify(chatData);

    return async dispatch => {
      dispatch(request(jsonData));
      const response = await chatService.createSingleChat(jsonData)
      let status = response.status === 200;
      const msg = response.message;
      if(status){
        dispatch(success(response));
        return response.chatData
      }
      else {
        dispatch(failure(msg.toString()));
        toast.error(msg.toString());
      }
        // .then(
        //   data => {
        //     let status = data.status === 200 ? true : false;
        //     const msg = data.message;
        //     if(status){
        //       dispatch(success(data));
        //     }
        //     else {
        //       dispatch(failure(msg.toString()));
        //       toast.error(msg.toString());
        //     }
        //   },
        //   error => {
        //     dispatch(failure(error.toString()))
        //   }
        // );
  };

  function request() { return { type: CREATE_SINGLE_CHAT_REQUEST } }
  function success(data) { return { type: CREATE_SINGLE_CHAT_SUCCESS, data } }
  function failure(errMsg) { return { type: CREATE_SINGLE_CHAT_FAIL, errMsg } }
}

export const createGroupChat = (chatData) => {
  return async dispatch => {
    dispatch(request());
    try {
      const response = await chatService.createGroupChat(chatData);
      if (response.status === 200) {
        dispatch(success(response.chatData));
        setActiveChat(response.chatData)(dispatch);
      } else {
        dispatch(failure(response.msg));
      }
    } catch(error) {
      console.log("error", error)
      dispatch(failure(error.toString()))
    }
  };

  function request() { return { type: CREATE_GROUP_CHAT_REQUEST } }
  function success(chatData) { return { type: CREATE_GROUP_CHAT_SUCCESS, chatData } }
  function failure(errMsg) { return { type: CREATE_GROUP_CHAT_FAIL, errMsg } }
}

export const sendMedia = (chatData) => {
  const jsonData = JSON.stringify(chatData);
  return async dispatch => {
    dispatch(request());
    try {
      const response = await chatService.sendMedia(jsonData);

      if (response.status === 200) {
        dispatch(success(response.chatData));
        setActiveChat(response.chatData)(dispatch);
      } else {
        dispatch(failure(response.msg));
      }
    } catch(error) {
      console.log("error", error)
      dispatch(failure(error.toString()))
    }
  };

  function request() { return { type: SEND_MEDIA_REQUEST } }
  function success(files) { return { type: SEND_MEDIA_SUCCESS, files } }
  function failure(errMsg) { return { type: SEND_MEDIA_FAIL, errMsg } }
}

export const inviteUserToChat = (chatData, chatId) => {
  const jsonData = JSON.stringify(chatData);
  return async dispatch => {
    dispatch(request());
    try {
      const response = await chatService.inviteUserToChat(jsonData, chatId);

      if (response.status === 200) {
        dispatch(success(response.chatData));
        setActiveChat(response.chatData)(dispatch);
      } else {
        dispatch(failure(response.msg));
      }
    } catch(error) {
      console.log("error", error)
      dispatch(failure(error.toString()))
    }
  };

  function request() { return { type: INVITE_USER_TO_CHAT_REQUEST } }
  function success(chatData) { return { type: INVITE_USER_TO_CHAT_SUCCESS, chatData } }
  function failure(errMsg) { return { type: INVITE_USER_TO_CHAT_FAILURE, errMsg } }
}

export const editChat = (chatData, chatId) => {
  return async dispatch => {
    dispatch(request());
    try {
      const response = await chatService.editChat(chatData, chatId);

      if (response.status === 200) {
        dispatch(success(response.chatData));
        setActiveChat(response.chatData)(dispatch);
      } else {
        dispatch(failure(response.msg));
      }
    } catch(error) {
      console.log("error", error)
      dispatch(failure(error.toString()))
    }
  };

  function request() { return { type: EDIT_CHAT_REQUEST } }
  function success(chatData) { return { type: EDIT_CHAT_SUCCESS, chatData } }
  function failure(errMsg) { return { type: EDIT_CHAT_FAIL, errMsg } }
}

export const sendMessage = ({data, message, chatId}) => {
  return async dispatch => {
    dispatch(request());
    try {
      const response = await chatService.sendMessage(data, message, chatId);

      if (response.status) {
        dispatch(success(response.message));
      } else {
        dispatch(failure(response.msg));
      }
    } catch(error) {
      console.log("error", error)
      dispatch(failure(error.toString()))
    }
  };

  function request() { return { type: SEND_MESSAGE_REQUEST } }
  function success(message) { return { type: SEND_MESSAGE_SUCCESS, message, chatId } }
  function failure(errMsg) { return { type: SEND_MESSAGE_FAIL, errMsg } }
}

export const chatActions = {
  createSingleChat,
};

export const markMessagesAsRead = (messages, chatId) => {
  const jsonData = JSON.stringify(messages);

  return async dispatch => {
    dispatch(request());
    try {
      const response = await chatService.markAsRead(jsonData, chatId);

      if (response.status === 200) {
        dispatch(success(messages));
      } else {
        dispatch(failure(response.message));
      }
    } catch(error) {
      console.log("error", error)
      dispatch(failure(error.toString()))
    }
  };

  function request() { return { type: MARK_MESSAGES_AS_READ_REQUEST } }
  function success(messages) { return { type: MARK_MESSAGES_AS_READ_SUCCESS, messages } }
  function failure(errMsg) { return { type: MARK_MESSAGES_AS_READ_FAIL, errMsg } }
}
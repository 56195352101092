import React, { Component } from 'react';
import { connect } from 'react-redux';
import VerifyPopup from './VerifyPopup';
import { APP_URL } from "../../../mainurl";
import { userService } from "../../../services";
import { toast } from "react-toastify";
import msgs from '../../../messages/en/main';
import { getAppHeaders } from "../../../helpers";

class FirebaseVerification extends Component {
  constructor(props){
    super(props);

    this.state = {
      isShowed: false,
      reload: false,
      //isVerified: false,
    };

    this.closePopup = this.closePopup.bind(this);
    this.firebaseVerify = this.firebaseVerify.bind(this);
  }

  verifyPopup() {
    this.setState({ isShowed: true });
  }

  closePopup(isReset) {
    this.setState({ isShowed: false });
    isReset && this.reload();
  }

  async firebaseVerify(loginType) {

    const { phoneNum, email } = this.props;

    const sendData = {
      type: loginType.toLowerCase(),
      email: email ? email : '',
      phone: phoneNum ? phoneNum : '',
    };

      await userService.acountVerify(sendData);
      toast.success(loginType === 'PHONE' ? msgs.MOBILE_HAS_BEEN_VERIFIED : msgs.EMAIL_HAS_BEEN_VERIFIED);
      // TODO: Replace with dispatch action to rerender parent component
      // For now just use callback function
      this.props.verifyCheck();
  }

  reload() {
    // setTimeout(function() {
      window.location.reload();
    // }.bind(this), 1000)
  }

  parseQuery(queryString) {
    let query = {};
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  setEmailIsVerified(token) {

    const requestOptions = {
      method: 'POST',
      headers: getAppHeaders(true),
      body: JSON.stringify(token)
    };

    return fetch(`${APP_URL}setEmailIsVerifyed/`, requestOptions);
  }


  componentDidMount () {
    const { loginType } = this.props;

    if (loginType !== "EMAIL") {
      return;
    }

    const params = this.parseQuery(window.location.search);
    const search = window.location.search;
    const hash = search.substr(search.indexOf("=")+1, 64);

    // console.log('hash', search.substr(search.indexOf("=")+1,64));

    if (params.verify) {
      this.setEmailIsVerified(hash)
        .then(()=>{
        this.firebaseVerify(this.props.loginType);
      });
    }
  }

  getConfirmationMessage() {
      return this.props.loginType === 'PHONE' ? msgs.MOBILE_HAS_BEEN_VERIFIED : msgs.EMAIL_HAS_BEEN_VERIFIED;
  }

  render(){
    const { phoneNum, loginType, email, icon } = this.props;
    const { isShowed } = this.state;

    return (
      <div>
          <button id="verifyBtn" className="verif-btn" onClick={ () => this.verifyPopup() }>
              <i className={"fas "+icon}></i> Verify { loginType }
          </button>
          <VerifyPopup showPopup={isShowed} phoneNum={ phoneNum } email={ email } user_id={this.props.user_id} closePopup={this.closePopup} isVerify={ this.firebaseVerify.bind(this) }/>
      </div>
    )
  }
}

function mapStateToProps(state,ownProps) {
  const { loginType } = ownProps;
  return {
    loginType
  };
}
export default connect(mapStateToProps)(FirebaseVerification);

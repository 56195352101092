import { momentsConstants } from '../constants';

export function moments(state = {}, action) {
  switch (action.type) {
    case momentsConstants.GETALLMOMENTS_REQUEST:
      return {
          loading: true,
          items: state.items,
      };
    case momentsConstants.GETALLMOMENTS_SUCCESS:
      return {
        items: action.globalmoments
      };
    case momentsConstants.GETALLMOMENTS_FAILURE:
      return {
        error: action.error,
        items: state.items,
      };
    case momentsConstants.DELETEMOMENTS_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(moment =>
          moment.post_id === action.id
            ? { ...moment, deleting: true }
            : moment
        )
      };
    case momentsConstants.DELETEMOMENTS_SUCCESS:
        return {
          items: state.items.filter(moment => moment.post_id !== action.id)
      };
    case momentsConstants.DELETEMOMENTS_FAILURE:
        return {
          ...state,
          items: state.items.map(moment => {
            if (moment.post_id === action.id) {
              // make copy of user without 'deleting:true' property
              const { deleting, ...momentCopy } = moment;
              // return copy of user with 'deleteError:[error]' property
              return { ...momentCopy, deleteError: action.error };
            }

            return moment;
          })
      };
    case momentsConstants.UPDATEMOMENTS_REQUEST:
      // add 'updating:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(moment =>
          moment.post_id === action.id
            ? { ...moment, updating: true }
            : moment
        )
      };
    case momentsConstants.UPDATEMOMENTS_SUCCESS:
      return {
          ...state,
          items: state.items.map(moment =>
            moment.post_id === action.id.updated_post[0].post_id
              ? { ...moment, ...action.id.updated_post[0] }
              : moment
          )
        };
    case momentsConstants.UPDATEMOMENTS_FAILURE:
      return {
        ...state,
        items: state.items.map(moment => {
          if (moment.post_id === action.id.updated_post[0].post_id) {
            // make copy of user without 'updating:true' property
            const { updating, ...momentCopy } = moment;
            // return copy of user with 'updateError:[error]' property
            return { ...momentCopy, updateError: action.error };
          }

          return moment;
        })
    };
    default:
    return state
  }
}

import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { userService } from '../../services';
import { ToastContainer, toast } from 'react-toastify';
import msgs from "../../messages/en/main";
import LogoWithTextSectiom from '../../containers/frontend/common/LogoWithTextSectiom';
import {useFormik} from 'formik';
import { isStrongPassword, passwordValidatorErrorsCodes } from '../../misc/validators';
import { PASSWORD_RESTRICTIONS } from '../../config';
import { PasswordInput } from '../common/input';
import { useHistory } from 'react-router';
import s from "../Login/login.module.scss";
import WelcomeLayout from "../welcomeLayout/WelcomeLayout";
import { passwordValidationErrorsCodesToMessages } from '../registration/PersonalData/PersonalData';

function ResetPassword({ otp, email }) {
  const history = useHistory();
  const [tokenConfirmed, setTokenConfirmed] = useState(null);

  useEffect(() => {
    userService.confirmOtp({otp, email})
      .then(res => {
        if (res.status){
          setTokenConfirmed(true);
        } else {
          setTokenConfirmed(false);
        }
      })
      .catch(err => {
        console.log('confirmOtp error', err);
        setTokenConfirmed(false);
      });
  }, []);


  const handleSubmitForm = useCallback((password) => {
    const dataToSend = {password, confirm_password: password, email, otp,};

    userService.resetPassword(dataToSend)
      .then(res => {
        if (res.status) {
          history.push('/login');
          toast.success(msgs.PASSWORD_RESET_SUCCESSFULLY);
        } else {
          setTokenConfirmed(false);
        }
      })
      .catch(e => {
        console.log('Resetting password error', e);
      });
  }, [history, email, otp]);

  return (
    <WelcomeLayout rightBarLogoClassName={s.loginRightBarLogo} leftBarClassName={s.welcomeLayoutLeftBar}>
      <ToastContainer />
      {tokenConfirmed === true && (
        <div className={s.loginRightInn} id="email-input-section">
          <h4>RESET PASSWORD</h4>
          <ChangePasswordForm onSubmit={handleSubmitForm} />
        </div>
      )}
      {tokenConfirmed === false && (
        <h2><span className={s.loginSplText}>Current session was expired. Please try again to log in.</span></h2>
      )}
      <div className={s.dontHaveAccount}>
        <span>Join 2cents today.</span>
        <p>Don’t have an account? <Link to="/registration">SIGN UP</Link></p>
      </div>
    </WelcomeLayout>
  );
}

ResetPassword.propTypes = {
  otp: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default ResetPassword;


const confirmPasswordStyle = {marginTop: '16px'};

function ChangePasswordForm({ onSubmit }) {
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: (values, formikHelpers) => {
      const { password, confirmPassword, } = values;

      const errors = {
        password: validatePassword(password),
        confirmPassword: password ? validateConfirmPassword(password, confirmPassword) : '',
      };

      formikHelpers.setErrors(errors);
      if (hasError(errors)) return;

      onSubmit(password);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <PasswordInput
        value={formik.values.password}
        type="password"
        name="password"
        id="password"
        placeholder="* Password"
        onChange={formik.handleChange}
        errMessage={formik.errors.password}
        showTooltipForPass={true}
        tooltip="Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character from ( @$!%*?&# )"
        tooltipPlace="bottom"
      />
      <PasswordInput
        value={formik.values.confirmPassword}
        type="password"
        name="confirmPassword"
        id="confirmPassword"
        placeholder="* Confirm password"
        onChange={formik.handleChange}
        style={confirmPasswordStyle}
        errMessage={formik.errors.confirmPassword}
      />
      <button type="submit" className="float-right"><i className="fas fa-angle-right" /></button>
    </form>
  );
}

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

function validatePassword(value) {
  let error;
  if (!value) {
    error = 'Password is required';
  } else {
    const result = isStrongPassword(value);
    if (Array.isArray(result) && result.length) {
      error = passwordValidationErrorsCodesToMessages[result[0]] || 'invalid';
    }
  }

  return error;
}


function validateConfirmPassword(password, confirmPassword) {
  let error;
  if (!confirmPassword) {
    error = 'Confirm password is required';
  } else if (password && password !== confirmPassword) {
    error = 'Password does not match';
  }
  return error;
}

function hasError(errors) {
  return Object.values(errors).find(i => !!i);
}

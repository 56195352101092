import React from 'react';
import LeftBar from './LeftBar';
import Logo from './Logo';
import s from './styles.module.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';



export default function WelcomeLayout({ children, onRightBarLogoClick, leftBarClassName, rightBarLogoClassName, onAbortRegistration }) {
  return (
    <div className={s.welcomeLayout}>
      <div className={s.bg}>
        <img src="/frontend/images/bg2.png" alt="bg" />
      </div>
      <div className={`container ${s.container}`}>
        <div className={cx('row', s.layout)}>
          <LeftBar className={leftBarClassName}/>
          <div className={cx('col-xl-5', s.resetPadding, s.rightBar)} >
           {onAbortRegistration && <button onClick={onAbortRegistration} className={s.abortRegistration}><i className="fas fa-times"></i></button>}
            <Logo onClick={onRightBarLogoClick} className={rightBarLogoClassName}/>
            {children}
          </div>
        </div>
      </div>
      <div className={s.copyrightBlockBottom}>
        {`©${new Date().getFullYear()} 2cents, LLC. All Rights Reserved.`}
      </div>
    </div>
  )
}

WelcomeLayout.propTypes = {
  onRightBarLogoClick: PropTypes.func,
  leftBarClassName: PropTypes.string,
  rightBarLogoClassName: PropTypes.string,
};

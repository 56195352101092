import { userConstants } from '../constants';

const persistFilterOptions = localStorage.getItem('notifyFilters') !== 'undefined' ? JSON.parse(localStorage.getItem('notifyFilters')) : null;

const initState = {
  filterOptions: {
    retweet: persistFilterOptions ? persistFilterOptions.retweet : true,
    post_like: persistFilterOptions ? persistFilterOptions.post_like : true,
    post_comment: persistFilterOptions ? persistFilterOptions.post_comment : true,
    send_request: persistFilterOptions ? persistFilterOptions.send_request : true,
    follow: persistFilterOptions ? persistFilterOptions.follow : true,
    send_message: persistFilterOptions ? persistFilterOptions.send_message : true,
    add_to_list: persistFilterOptions ? persistFilterOptions.add_to_list : true,
    tweet_in_moment: persistFilterOptions ? persistFilterOptions.tweet_in_moment : true,
  }
};

export function notificationdata(state = initState, action) {
  switch (action.type) {
    case userConstants.FILTER_NOTIFICATION:
      return {
        ...state,
        filterOptions: action.filterOptions,
      }
    case userConstants.GET_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationData: action.users.notificationData,
        emailData: action.users.emailData,
      };
    case userConstants.GET_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case userConstants.SET_SETTINGS_REQUEST:
      return {
        ...state
      };
    case userConstants.SET_SETTINGS_SUCCESS:

      if(action.user.type == 'notify'){
        let data = {...state};
        const raw = {
          ...state,
          loading: false,
          notificationData: action.user,
          emailData: data.emailData,
        }
        return raw;
      }
      else if(action.user.type == 'email'){
        let data = {...state};
        const raw = {
          ...state,
          loading: false,
          notificationData: data.notificationData,
          emailData: action.user,
        }
        return raw;
      }
      else {
        return {
          ...state,
        };
      }
    case userConstants.SET_SETTINGS_FAILURE:
      return {
        error: action.error,
        ...state
      };
    case userConstants.GETALL_NOTIFICATION_REQUEST:
      return {
        loading: true,
        ...state
      };
    case userConstants.GETALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        userNotification: action.notifyData
      };
    case userConstants.GETALL_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state
  }
}

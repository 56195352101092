import { momentsConstants } from '../constants';
import { momentsService,sparksService } from '../services';
import { alertActions, sparksActions } from './';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import msgs from "../messages/en/main"

export const momentsActions = {
    getAllMoments,
    getAllCategory,
    getTweetToMoment,
    getMomentDetail,
    createMoment,
    editMoment,
    searchUserByAccount,
    getUserByAccount,
    searchUserByTweet,
    getTweetBySearch,
    getEditMoment,
    removeTweetMoment,
    deleteMoment,
    LikeUnlikeSpark,
    deleteSingleMomentData
};

function getAllMoments(user) {
    return dispatch => {
        dispatch(request(user));
        momentsService.getAllMoments(user)
            .then(
                moments => {
                  const status = moments.status;
                  const msg = moments.msg;
                  if(status){
                    dispatch(success(moments.globalMoments))
                  }
                  else {
                    dispatch(failure(msg.toString()));
                    //toast.error(msg.toString());
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                  //toast.error(error.toString());
                }
            );
    };

    function request(user) { return { type: momentsConstants.GETALLMOMENTS_REQUEST, user } }
    function success(globalmoments) { return { type: momentsConstants.GETALLMOMENTS_SUCCESS, globalmoments } }
    function failure() { return { type: momentsConstants.GETALLMOMENTS_FAILURE } }
}

function getAllCategory(user) {
    return dispatch => {
        dispatch(request(user));
        momentsService.getAllCategory(user)
            .then(
                category => {
                  const status = category.status;
                  const msg = category.msg;
                  if(status){
                    dispatch(success(category.moments_category))
                  }
                  else {
                    dispatch(failure(msg.toString()));
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                }
            );
    };

    function request(user) { return { type: momentsConstants.CATEGORY_REQUEST, user } }
    function success(categoryData) { return { type: momentsConstants.CATEGORY_SUCCESS, categoryData } }
    function failure() { return { type: momentsConstants.CATEGORY_FAILURE } }
}

function getEditMoment(data) {
    return dispatch => {
        dispatch(request(data));
        momentsService.getEditMoment(data)
            .then(
                moments => {
                  const status = moments.status;
                  const msg = moments.msg;
                  if(status){
                    dispatch(success(moments))
                  }
                  else {
                    dispatch(failure(msg.toString()));
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                }
            );
    };

    function request(data) { return { type: momentsConstants.SINGLE_DETAIL_REQUEST, data } }
    function success(payload) { return { type: momentsConstants.SINGLE_DETAIL_SUCCESS, payload } }
    function failure() { return { type: momentsConstants.SINGLE_DETAIL_FAILURE } }
}

function deleteSingleMomentData() {
  return dispatch => {
      dispatch(success());
  };

  function success() { return { type: momentsConstants.DELETE_SINGLE_MOMENT_DATE } }
}

function getTweetToMoment(user) {
    return dispatch => {
        dispatch(request(user));
        momentsService.getTweetToMoment(user)
        .then(
            tweetMoment => {
              const status = tweetMoment.status;
              const msg = tweetMoment.msg;
              if(status)
              {
                dispatch(success(tweetMoment.userData))
              }
              else {
                dispatch(failure(msg.toString()));
              }
            },
            error => {
              dispatch(failure(error.toString()))
            }
        );
    };

    function request(user) { return { type: momentsConstants.TWEETTOMOMENT_REQUEST, user } }
    function success(tweetMomentData) { return { type: momentsConstants.TWEETTOMOMENT_SUCCESS, tweetMomentData } }
    function failure() { return { type: momentsConstants.TWEETTOMOMENT_FAILURE } }
}

function getMomentDetail(momentdata) {
    return dispatch => {
        momentsService.getMomentDetail(momentdata)
          .then(
              singleMoment => {
                const status = singleMoment.status;
                const msg = singleMoment.msg;
                if(status){
                  dispatch(success(singleMoment))
                  if(singleMoment.momentsDetail.posts.length > 0){
                    dispatch(sparksActions.updateMomentAllSpark(singleMoment.momentsDetail.posts,singleMoment.moment_id));
                  }
                  else {
                    dispatch(sparksActions.updateMomentAllSpark([],singleMoment.moment_id));
                  }
                }
                else {
                  dispatch(failure(msg.toString()));
                }
              },
              error => {
                dispatch(failure(error.toString()))
              }
          );
    };

    function request(user) { return { type: momentsConstants.SINGLE_DETAIL_REQUEST, user } }
    function success(singleMoment) { return { type: momentsConstants.SINGLE_DETAIL_SUCCESS, payload: singleMoment } }
    function failure(error) { return { type: momentsConstants.SINGLE_DETAIL_FAILURE, error } }
}

function createMoment(sendRaw) {
    return dispatch => {
      dispatch(request());
      momentsService.createMoment(sendRaw).then( insertedMoment => {
        const status = insertedMoment.status;
        const msg = insertedMoment.msg;

        if (status) {
          toast.success(msg);
          dispatch(success(insertedMoment));
          dispatch(alertActions.success(msg.toString()));

          setTimeout(() => dispatch(push('/instants/today')), 2000);
        } else {
          toast.error(msg.toString());
          dispatch(failure(msg.toString()));
        }
      },
      error => {
        toast.error(msgs.SOMETHING_WENT_WRONG);
        dispatch(failure(error.toString()));
      });
    };

    function request() { return { type: momentsConstants.CREATE_MOMENT_REQUEST } }
    function success(insertedMoment) { return { type: momentsConstants.CREATE_MOMENT_SUCCESS, insertedMoment } }
    function failure() { return { type: momentsConstants.CREATE_MOMENT_FAILURE } }
}

function editMoment(sendRaw) {
    return dispatch => {
        dispatch(request());
        momentsService.editMoment(sendRaw)
            .then(
                insertedMoment => {
                  console.log(insertedMoment);
                  const status = insertedMoment.status;
                  const msg = insertedMoment.msg;
                  if(status){
                    dispatch(success(insertedMoment));
                    dispatch(alertActions.success(msg.toString()));
                    dispatch(push('/instants/today'));
                    toast.success(msg);
                  }
                  else {
                    dispatch(failure(msg.toString()));
                    toast.error(msg.toString());
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                  toast.error(msgs.SOMETHING_WENT_WRONG);
                }
            );
    };

    function request() { return { type: momentsConstants.CREATE_MOMENT_REQUEST } }
    function success(insertedMoment) { return { type: momentsConstants.CREATE_MOMENT_SUCCESS, insertedMoment } }
    function failure() { return { type: momentsConstants.CREATE_MOMENT_FAILURE } }
}

function searchUserByAccount(data) {
  //console.log(user);
    return dispatch => {
        dispatch(request(data));
        momentsService.searchUserByAccount(data)
          .then(
              users => {
                //console.log(category);
                const status = users.status;
                const msg = users.msg;
                if(status){
                  dispatch(success(users.username))
                }
                else {
                  dispatch(failure(msg.toString()));
                }
              },
              error => {
                dispatch(failure(error.toString()))
              }
          );
    };

    function request(user) { return { type: momentsConstants.SEARCH_USER_REQUEST, user } }
    function success(userData) { return { type: momentsConstants.SEARCH_USER_SUCCESS, userData } }
    function failure() { return { type: momentsConstants.SEARCH_USER_FAILURE } }
}

function searchUserByTweet(data) {
  // console.log(user);
    return dispatch => {
        dispatch(request(data));
        momentsService.searchUserByTweet(data)
          .then(
              users => {
                //console.log(category);
                const status = users.status;
                const msg = users.msg;
                if(status){
                  dispatch(success(users.string))
                }
                else {
                  dispatch(failure(msg.toString()));
                }
              },
              error => {
                dispatch(failure(error.toString()))
              }
          );
    };

    function request(user) { return { type: momentsConstants.SEARCH_TWEET_REQUEST, user } }
    function success(userData) { return { type: momentsConstants.SEARCH_TWEET_SUCCESS, userData } }
    function failure() { return { type: momentsConstants.SEARCH_TWEET_FAILURE } }
}

function getUserByAccount(data) {
  //console.log(user);
    return dispatch => {
        dispatch(request(data));
        momentsService.getUserByAccount(data)
          .then(
              users => {
                // console.log(users);
                const status = users.status;
                const msg = users.msg;
                if(status){
                  dispatch(success(users.allpost));
                }
                else {
                  dispatch(success([]))
                  toast.error(msg.toString());
                }
              },
              error => {
                dispatch(failure(error.toString()))
              }
          );
    };

    function request(user) { return { type: momentsConstants.GET_USER_REQUEST, user } }
    function success(userData) { return { type: momentsConstants.GET_USER_SUCCESS, userData } }
    function failure() { return { type: momentsConstants.GET_USER_FAILURE } }
}

function getTweetBySearch(data) {
  //console.log(user);
    return dispatch => {
        dispatch(request(data));
        momentsService.getTweetBySearch(data)
          .then(
              tweet => {
                //console.log(category);
                const status = tweet.status;
                const msg = tweet.msg;
                if(status){
                  dispatch(success(tweet.allpost))
                }
                else {
                  dispatch(failure(msg.toString()));
                }
              },
              error => {
                dispatch(failure(error.toString()))
              }
          );
    };

    function request(user) { return { type: momentsConstants.GET_TWEET_REQUEST, user } }
    function success(userData) { return { type: momentsConstants.GET_TWEET_SUCCESS, userData } }
    function failure() { return { type: momentsConstants.GET_TWEET_FAILURE } }
}

function removeTweetMoment(postdata) {
  //console.log(postdata);
    return dispatch => {
        dispatch(request());
        if(postdata){
            dispatch(success(postdata));
        }
        else {
            dispatch(failure('something wrong'));
        }
    };

    function request() { return { type: momentsConstants.DELETE_MOMENT_SPARKS_REQUEST } }
    function success(postdata) { return { type: momentsConstants.DELETE_MOMENT_SPARKS_SUCCESS, postdata } }
    function failure(error) { return { type: momentsConstants.DELETE_MOMENT_SPARKS_FAILURE, error } }
}

function deleteMoment(data) {
  //console.log(user);
    return dispatch => {
        dispatch(request(data));
        momentsService.deleteMoment(data)
          .then(
              tweet => {
                //console.log(category);
                const status = tweet.status;
                const msg = tweet.msg;
                if(status){
                  toast.success(msg);
                  dispatch(success(data));
                }
                else {
                  dispatch(failure(msg.toString()));
                  toast.error(msg.toString());
                }
              },
              error => {
                dispatch(failure(error.toString()))
                toast.error(msgs.SOMETHING_WENT_WRONG);
              }
          );
    };

    function request(user) { return { type: momentsConstants.DELETE_MOMENT_REQUEST, user } }
    function success(momentdata) { return { type: momentsConstants.DELETE_MOMENT_SUCCESS, momentdata } }
    function failure() { return { type: momentsConstants.DELETE_MOMENT_FAILURE } }
}

function LikeUnlikeSpark(rawData,type){
  return dispatch => {
    dispatch(request(rawData));
    momentsService.LikeUnlikeSpark(rawData)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;
          if(status){
              toast.success(msg);
              dispatch(success(data));
          }
          else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()))
        }
      );
  };
  function request(data) { return{ type: momentsConstants.LIKEMOMENTS_REQUEST, data } }
  function success(likedata) { return{ type: momentsConstants.LIKEMOMENTS_SUCCESS, likedata } }
  function failure(error) { return{ type: momentsConstants.LIKEMOMENTS_FAILURE, error } }
}

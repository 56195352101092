import React, { useEffect, useState } from "react";
import s from "./subscriptionDropdown.module.scss";

function SubscriptionDropdown(props) {
  const { getPlan, chosenPlan, showForm, disabled } = props;
  const planData = getPlan.data;
  const [chosenPlanObject, setChosenPlanObject] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const chosenPlanObject = getByValue(planData, chosenPlan);
    setChosenPlanObject(chosenPlanObject);
  }, [chosenPlan]);

  const getByValue = (arr, value) => {
    let result = arr.filter(function(o) {
      return o.id == value;
    });

    return result ? result[0] : null; // or undefined
  };


  return (
    <div className={s.dropdownBox}>
      <div
        className={s.dropdownChosen}
        onClick={() => disabled ? null : setShowDropdown(!showDropdown)}
      >
        <div className={s.dropdownText}>
          <div className={s.dropdownTextName}>{chosenPlanObject.plan_name}</div>
          <div className={s.dropdownTextPrice}>
            ${chosenPlanObject.amount}<span className={s.dropdownPer}>/Per {chosenPlanObject.intervals}</span>
          </div>
        </div>
        {!disabled && (<div className={s.dropdownArrow}>
          {showDropdown ? (
            <i className="fas fa-angle-up" />
          ) : (
            <i className="fas fa-angle-down" />
          )}
        </div>)}
      </div>
      {showDropdown ? (
        <div className={s.dropdown}>
          <div className={s.dropdownTitle}>Plans</div>
          <div className={s.dropdownPlans}>
            {planData.map(plan => {
              return (
                <div className={s.dropdownPlan} onClick={() => {showForm(plan.id); setShowDropdown(!showDropdown)}}>
                  <div className={s.dropdownText}>
                    <div className={s.dropdownTextName}>{plan.plan_name}</div>
                    <div className={s.dropdownTextPrice}>
                      ${plan.amount}<span className={s.dropdownPer}>/Per {plan.intervals}</span>
                    </div>
                    <div className={s.dropDownTrial}>{plan.id == 10 ? "3 months for free" : "-"} </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default SubscriptionDropdown;

import React, { Component } from 'react';
import Header from '../../../containers/frontend/common/Header';
import ReferralProgramSection from '../../../containers/frontend/userProfile/ReferralProgramSection';

export default class ReferralProgram extends Component {
  render() {
    return (
      <div id="AccountSetting" className="YourAccountData">
        <Header/>
        <ReferralProgramSection/>
     </div>
    );
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import LoginLeftContentBar from "./LoginLeftContentBar";
import LogoWithTextSectiom from "./LogoWithTextSectiom";

import $ from "jquery";

import { userActions, alertActions } from "../../../actions";
import { getCurrentYear } from "../../../helpers";

class AccountExpired extends Component {
  
    render(){
        const { user_id,plan_id } = this.props;
        return (
            <div className="login-main">
              <div className="bg">
                <img src="/frontend/images/bg2.png" alt="bg" />
              </div>
              <ToastContainer />
              <div className="container">
                <div className="login-inn">
                  <div className="row">
                    <LoginLeftContentBar />
                    <div className="col-sm-5 padd-left">
                      <div className="login-right">
                        <LogoWithTextSectiom />
                          <div className="login-right-inn plan-top congratulation">
                            <h3>Your Plan is expired.</h3>
                            <Link to={'/renew-plan/'+btoa(user_id)+'/'+btoa(plan_id)}>
                              <h5 className="renew-sub">Please, click here to renew your subscription.</h5>
                            </Link>
                            <h5 className="copy">{`${getCurrentYear()} 2cents, LLC. All Rights Reserved.`}</h5>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
    }
}

function mapStateToProps(state,ownProps){
  return {
    alert: state.alert,
    user_id: ownProps.user_id,
    plan_id: ownProps.plan_id,
  };
}
export default connect(mapStateToProps)(AccountExpired);

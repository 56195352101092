import React, {Component} from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import msgs from "../../../messages/en/main";

import { alertActions,sparksActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class VoteActionView extends Component {
  constructor(props){
    super(props);

    this.state = {
        user: '',
        poll_id: '',
        post_id: '',
        checked: false,
        submitted: false
    };

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    const { user } = this.props;
  }

  handleChange = (event) => {
      const { name, value } = event.target;
      const post_id = event.target.dataset.post_id;

      this.setState({
        ...this.state,
        checked: true,
        [name]: value,
        post_id,
      });
    console.log(this.state.poll_id, '  ', value )

  }

  VoteSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { poll_id, post_id } = this.state;
    const {user} = this.props;
    if(post_id && poll_id && user.id){
      const { dispatch } = this.props;
      let data = {
        user_id : user.id,
        poll_id : poll_id,
        post_id : post_id,
      }
      this.setState({ checked: false });
      dispatch(sparksActions.VotePoll(data));
    }
    else {
       toast.error(msgs.ENTER_VALID_INPUT);
    }
  }

  render(){
    let {singlePollData} = this.props;
    return (
      <div className="vote">
        <form>
          <fieldset>
            <ul>
              {singlePollData.pollData.length > 0 ? (
                  singlePollData.pollData.map((data,index) =>
                    (
                        <li key={index}>
                            <label className="radio"> 
                              {data.choice_option}
                              <input 
                                onChange={this.handleChange} 
                                data-post_id={data.post_id} 
                                name="poll_id" 
                                type="radio" 
                                value={data.poll_id || data.id}
                                checked={this.state.poll_id == data.poll_id}
                              />
                              <span className="checkmark" />
                          </label>
                        </li>
                    )
                  )
                ) : ('')
              }
            </ul>
          </fieldset>
        </form>
        <button 
          onClick={this.VoteSubmit} 
          disabled={!this.state.checked} 
        >
          Vote
        </button>
        <p className="poll-msg">
          {singlePollData.pollTotalVotes ? singlePollData.pollTotalVotes : '0'} Votes *
          {singlePollData.poll_expiry == 1 ? (singlePollData.expiry_days_left != 0 ? singlePollData.expiry_days_left+' days left' : ' last day') : ('Poll ended')}
        </p>
      </div>

    )
  }
}

VoteActionView.propTypes = {
  history: PropTypes.object.isRequired,
};


function mapStateToProps(state,ownProps){
  return {
    alert: state.alert,
    user: state.authentication.user,
    singlePollData: ownProps.singlePollData,
    votespark: state.votespark
  };
}
export default withRouter(connect(mapStateToProps)(VoteActionView));

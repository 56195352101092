import React, {Component} from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import {createDisplayedSpark, charCounter} from '../../../helpers';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';
import { sparksActions } from '../../../actions';
import Params from '../../../config/Params';

class ReSparkAction extends Component {

  static defaultProps = {
    maxCharCount: Params.textareaMaxCharCount
  }

  constructor(props){
    super(props);

    this.state = {
      title : '',
      button: false
    };
  }


  ReSparkPost = (p_id, type) => {
    const { dispatch } = this.props;
    const { user } = this.props;
    const {title} = this.state;

    if(user && p_id){
          const sendRaw = {
            post_id : p_id,
            user_id: user.id,
            title: title,
            retweet_status: 'retweet_title',
          }
          dispatch(sparksActions.ReSparkPost(sendRaw, type));
          $('div#respark-modal button.close').click();
          this.setState({
            title : '',
            button: false
          });
    }
    else {
       toast.error(msgs.SOMETHING_WENT_WRONG);
    }
  }

  ButtonActiveOrNot = (event) => {
    let { value } = (event || window.event).target;
    value = value.trim();

    if (value && value.length > 0) {
      this.setState({ button: true });
    }
    else {
      this.setState({ button: false });
    }
  }

  handleChange = (event) => {
      const { name, value } = event.target;
      this.setState({
              [name]: value
      });
      this.ButtonActiveOrNot(event);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render(){

    const { raw, maxCharCount } = this.props;
    const {title, button} = this.state;
    return (
      <div className="modal fade retweet-modal" id="respark-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog create-new" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">ReSpark this to your followers?</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {raw != undefined ? (
              <div className="modal-body">
                  <div className="reply-btm">
                    <textarea maxLength={maxCharCount} placeholder="Text your reply" name="title" onChange={this.handleChange} value={title} />
                    { charCounter(title, maxCharCount) }
                  </div>
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="tweet-img">
                        <div className="twit-mid-img">
                          <img src={raw.profile_image ? raw.profile_image : '/frontend/images/user-img.png'} alt="logo" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-10 padd-left">
                      <div className="tweet-right">
                        <h5>{raw.name}</h5>
                        <a href=""><h4>{raw.at_username}</h4></a>
                          <div className="spark-text">
                              { raw.post_title && createDisplayedSpark(raw.post_title) }
                          </div>
                      </div>
                    </div>
                    <div className="col-sm-12 mid-icon">
                      <button 
                        className="retweet" 
                        onClick={() => this.ReSparkPost(raw.post_id, raw.type)}
                      >
                        ReSpark
                      </button>
                    </div>
                  </div>
                </div>
              ) : ('')
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    raw: state.replydata ? state.replydata.data : '',
    user: state.authentication.user,
  };
}
export default connect(mapStateToProps)(ReSparkAction);

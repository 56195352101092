import React, {Component} from 'react';
import { connect } from 'react-redux';
import UserSideBarSection from '../common/UserSideBarSection';
import { userActions, alertActions } from '../../../actions';
import LoadingIcon from '../common/LoadingIcon';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class PrivacySafetySettingSection extends Component {
  constructor(props){
    super(props);

    const { dispatch, user, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

    if(user){
      dispatch(userActions.userSettings(user.id));
    }
  }

  handleChange = (event) => {
      const { name, value } = event.target;
      const { dispatch, user } = this.props;
      this.setState({ submitted: true });

      if(user.id && value && name) {
        let data = {
            user_id: user.id,
            [name]: value
        };

        dispatch(userActions.setPrivacy(data));
      }
  }

  shouldComponentUpdate(prevProps,nextProps){
    return true;
  }

  render(){

    const { privacyData, alert } = this.props;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;

    return (
      <div id="NotificationSettingSection" className="content-part sports-main">
        <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          <div className="row">
            <UserSideBarSection/>
            <div className="col-sm-6 feed-container">
              <div className="twiter-left1 access-mid sett-block">
                <h3>Privacy & Safety </h3>
                <p>Expand your experience, get closer, and stay current.</p>
                { privacyData != undefined ? (
                <div>
                  <h6 className="h6">Protect your sparks : </h6>
                  <div className="add-your-phone">
                    <form>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="noti-right">
                            <div className="noti-right1">
                              <span>Protected Sparks</span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  value={privacyData != undefined && privacyData.tweet == 1 ? '0' : '1'}
                                  checked={privacyData != undefined && privacyData.tweet == 1 ? true : false}
                                  name="tweet"
                                  id="tweet"
                                  onClick={this.handleChange}
                                />
                                <span className="slider round"></span>
                              </label>
                              <p>
                                Your Sparks are currently protected, only those you approve will see your Sparks and Profile.
                                Your future Instants can not published until you disable it from account settings.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <h6 className="h6">Safety : </h6>
                  <div className="add-your-phone">
                    <form>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="noti-right">
                            <div className="noti-right1">
                              <span>Discoverability : Let others didn't find you </span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  value={privacyData != undefined && privacyData.search == 1 ? '0' : '1'}
                                  checked={privacyData != undefined && privacyData.search == 1 ? true : false}
                                  name="search"
                                  id="search"
                                  onClick={this.handleChange}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                            <div className="noti-right1">
                              <span>Don't show objectionable content </span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  value={ privacyData != undefined && privacyData.showObjectionableContent == 1 ? 0 : 1 }
                                  checked={ privacyData != undefined && privacyData.showObjectionableContent == 1 ? true : false }
                                  name="showObjectionableContent"
                                  id="showObjectionableContent"
                                  onClick={this.handleChange}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                ) : (
                  <LoadingIcon />
                )
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

PrivacySafetySettingSection.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        alert: state.alert,
        privacyData: state.usersettings.data != undefined ? state.usersettings.data.privacyData : undefined,
    };
}

export default withRouter(connect(mapStateToProps)(PrivacySafetySettingSection));

import React, {Component} from 'react';
import {connect} from 'react-redux';
import ManageSparkType from './ManageSparkType';
import {momentsActions, alertActions} from '../../../actions';

const st = {
  width: '100%',
  height: '300px',
}

class sparkLike extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: 5
    };

    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 10};
    });
  }

  myCallback = (dataFromChild, index, type) => {
    this.props.callbackFromMainParent(dataFromChild, index, type);
  }

  render() {
    const {momentstweetpost, selectedSparks = []} = this.props;

    return (
      <div id="sparks1" className="tabcontent">
        {momentstweetpost.tweetMomentData != undefined ?
          (momentstweetpost.tweetMomentData.allPostLike.slice(0, this.state.visible).map((like, index) => {
            const isSelected = selectedSparks.filter((spark) => spark.post_id === like.post_id).length !== 0;

            return <ManageSparkType key={index} rawData={like} index={index} type='like'
                                    callbackFromParent={this.myCallback} isSelected={isSelected}/>
          })) : <h4 className="text-center"> You have no liked spark.</h4>
        }

        {momentstweetpost.tweetMomentData != undefined ?
          (this.state.visible < momentstweetpost.tweetMomentData.allPostLike.length ? (
            <button onClick={this.loadMore} type="button" className="load-more btn btn-danger">Load more</button>
          ) : ('')) : ('')
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
//  console.log(state);
  return {
    user: state.authentication.user,
    momentstweetpost: state.momentstweetpost != undefined ? state.momentstweetpost : [],
  };
}

export default connect(mapStateToProps)(sparkLike);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import UserSideBarSection from '../common/UserSideBarSection';
import { userActions,alertActions } from '../../../actions';

 class BlockAccountSettingSection extends Component {
   constructor(props){
     super(props);
   }

   unblockRequest(index,receiver_id) {
       const { dispatch,user } = this.props;
       const blockData = {
          user_id : user.id,
          receiver_id : receiver_id,
          index : index
       }

       if(user)
       {
         dispatch(userActions.unblock(blockData));
       }
   }

   unmuteRequest(index,muted_user_id) {
       const { dispatch,user } = this.props;
       const unmuteData = {
          user_id : user.id,
          muted_user_id : muted_user_id,
          index : index
       }

       if(user)
       {
         dispatch(userActions.muteUnmuteAccount(unmuteData));
       }
   }

  render() {
    const {blockedUser,user,mutedUser,alert} = this.props;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;
    return (
      <div className="content-part sports-main">
        <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          <div className="row">
            <UserSideBarSection/>
            <div className="col-sm-6 feed-container">
              <div className="twiter-left1 access-mid sett-block">
                <h3>Blocked/Muted Users</h3>
                <p>Expand your experience, get closer, and stay current.</p>
                <h6 className="h6">Accounts you are blocking</h6>
                <p>You have blocked these accounts. You will not see their Sparks in your
                  timeline. Additionally, blocked accounts cannot follow you or view your
                  profile while logged in to 2cents. Learn more about blocking.</p>
                <div className="block-acc-btm">
                {blockedUser.length > 0 ?
                  (blockedUser.map((blocked,index) => (
                    <div className="block-acc-btm1" key={index}>
                      <div className="row">
                        <div className="col-sm-2">
                          <span className="twit-mid-img"><img src={blocked.profile_image} alt="logo" /></span>
                        </div>
                        <div className="col-sm-5 padd-left">
                          <div className="block-acc-info">
                            <h4 className="word-break"><strong>{blocked.name}</strong> {blocked.at_username}</h4>
                            <h5>Blocked</h5>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <button onClick={() => this.unblockRequest(index, blocked.block_user_id)}>UNBLOCK</button>
                        </div>
                      </div>
                    </div>
                  ))) : (<p className="no-blocked">No Blocked User Found</p>)}
                </div>
                <h6 className="h6">Muted Accounts</h6>
                <p>You have mute these accounts. You will not see their Sparks in your
                  timeline.</p>
                <div className="block-acc-btm">
                {mutedUser.length > 0 ?
                  (mutedUser.map((muted,index) => (
                    <div className="block-acc-btm1" key={index}>
                      <div className="row">
                        <div className="col-sm-2">
                          <span className="twit-mid-img"><img src={muted.profile_image} alt="logo" /></span>
                        </div>
                        <div className="col-sm-5 padd-left">
                          <div className="block-acc-info">
                            <h4 className="word-break"><strong>{muted.name}</strong> {muted.at_username}</h4>
                            <h5>Muted</h5>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <button onClick={() => this.unmuteRequest(index, muted.muted_user_id)}>UNMUTE</button>
                        </div>
                      </div>
                    </div>
                  ))) : (<p className="no-blocked">No Muted User Found</p>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

function mapStateToProps(state){
  return {
    blockedUser: (state.users.blockedList ? state.users.blockedList : []),
    mutedUser: (state.users.mutedUser ? state.users.mutedUser : []),
    user: state.authentication.user,
    alert: state.alert,
  };
}
export default connect(mapStateToProps)(BlockAccountSettingSection);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class UserAvatar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errored: false,
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.src !== this.props.src) {
      this.setState({ errored: false })
    }
  }

  onError = () => {
    if (!this.state.errored) {
      this.setState({ errored: true });
    }
  }

  render() {
    const { ...props } = this.props;
    const { errored } = this.state;
console.log('UserAvatar.js, props: ', props);
    return !errored 
      ? (<img
          onError={this.onError}
          alt={this.props.alt}
          {...props}
        />)
      : (<img
          src={"/frontend/images/no-image.jpg"}
          alt="error on load"
        />
        );
  }
}

UserAvatar.propTypes = {
  src: PropTypes.string,
};
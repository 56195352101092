import React from 'react';
import { Link } from 'react-router-dom';
import s from './styles.module.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';

export default function Logo({ onClick, className }) {
  return (
    <div className={cx(s.logo, className)}>
      <Link to="/" onClick={onClick} >
        <img src="/frontend/images/login-logo.png" alt="logo" />
      </Link>
      <span className={s.logoText}>Responsible Social Media</span>
    </div>
  );
}

Logo.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

import queryString from 'query-string';
import { getAccessToken, ACCESS_TOKEN } from '../services/accessTokenStorage';
import { onLogoutDataCleanup } from '../helpers/handleResponse';
import store from '../store';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

export function handleResponse(response) {
  if (!response) {
    toast.error("No Internet Connection. Check your network and try again.")
    return Promise.reject("TypeError: Failed to fetch");
  }
  if (response.status === 413) {
    return Promise.reject(response);
  }
  return response.text()
    .then(text => {
      let body;
      try {
        body = text && JSON.parse(text);
      } catch (e) {
        throw new Error('Server error! Bad JSON response');
      }

      if (!response.ok) {
        if (response.status === 401) {
          onLogoutDataCleanup();
          toast.info('Please sing in');
          store.dispatch(push('/login'));
        }
        const errorMessage = (body && Array.isArray(body.errors) && body.errors.join(', ')) || response.statusText;
        return Promise.reject(errorMessage);
      } if ((body && body.errors) || body.status === 'error') {
        if (Array.isArray(body.errors) && body.errors.length) {
          const errorMessage = body.errors.join(', ');
          return Promise.reject(errorMessage);
        } else if (typeof body.errors === 'string') {
          return Promise.reject(body.errors);
        } else if (body && body.msg && typeof(body.msg) === 'string') {
          return Promise.reject(body.msg);
        }
      }

      return body;
    });
}

export function extractDataFromBody(body) {
  const { data } = body || {};
  return data;
}

export function extractAccessTokenFromBody(body) {
  const { [ACCESS_TOKEN]: accessToken } = body || {};
  return accessToken;
}

export function getAuthHeaders({useLocalStorageAccessToken = false} = {}) {
  const token = getAccessToken(useLocalStorageAccessToken);
  return token ? {Authorization: `Basic "${token}"`} : {};
}

export async function get(uri, headers = {}, queryParams = {}) {
  const query = queryString.stringify(queryParams);
  if (query) {
    uri += '?' + query;
  }
  return await fetch(uri, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      ...headers,
    },
  });
}

export async function remove(uri, headers = {}, queryParams = {}) {
  const query = queryString.stringify(queryParams);
  if (query) {
    uri += '?' + query;
  }
  return await fetch(uri, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      ...headers,
    },
  });
}

function postPut(method) {
  return async function(uri, body, headers = {}, queryParams = {}) {
    const query = queryString.stringify(queryParams);
    if (query) {
      uri += '?' + query;
    }
    return await fetch(uri, {
      method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
    });
  }
}

export const post = postPut('POST');

export const put = postPut('PUT');

//export const extractResponseData = ({ data }) => data;

export const delay = (fn, msecs) => {
  return new Promise(((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(fn());
      } catch (e) {
        reject(e);
      }
    }, msecs);
  }));
};


import * as constants from '../../constants';

const initialState = {
  loading: false,
  status: true,
  msg: "",
  countUnreadMessages: 0,
};

export default function chatUnreadMessages(state = initialState, action) {
  switch (action.type) {
    case constants.UNREAD_MESSAGES_AMOUNT_REQUEST:{
      return {
        ...state, loading: true,
      };
    }
    case constants.UNREAD_MESSAGES_AMOUNT_SUCCESS:{
      return {
        ...state, countUnreadMessages: action.countUnreadMessages, loading: false
      };
    }
    case constants.UNREAD_MESSAGES_AMOUNT_FAIL:{
      return {
        ...state, status: false, msg: action.msg, loading: false
      };
    }
    case constants.UPDATE_UNREAD_MESSAGES_AMOUNT: {
      return {
        ...state, countUnreadMessages: action.countOfUnread,
      };
    }
    default:
      return state;
  }
}
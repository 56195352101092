'use strict';

import React from 'react';
import LoadingIcon from '../../common/LoadingIcon';
import * as moment from 'moment';
import { createDisplayedSpark, makeUserName, timestampToDate } from '../../../../helpers';
import PropTypes from 'prop-types';

import cx from 'classnames';
import s from './chatComponent.module.scss';

import LightBox from "../../common/LightBox";
import { Link } from 'react-router-dom';
class ChatMessagesList extends React.Component {
  constructor(props) {
    super(props);
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.messages.length < this.props.messages.length) {
      return this.props.messages.length - prevProps.messages.length;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const messagesContainer = this.props.chatContainerRef.current;
    const { messages } = this.props;


    // When we get new portion of messages we should stay on last visible messages.
    // I have found index of last visible element and do scroll to it.
    if (snapshot) {
      // On new message condition do not nothing with scroll.
      if (prevProps.messages.length &&
        messages.length &&
        prevProps.messages[prevProps.messages.length - 1]._id !==
        messages[messages.length - 1]._id
      ) {
        const myNewMessage = messagesContainer.lastChild.className.includes('my');
        const ofsetToBottom = messagesContainer.scrollHeight - messagesContainer.clientHeight - messagesContainer.scrollTop - messagesContainer.lastChild.offsetHeight;
        
        if (ofsetToBottom === 0 || myNewMessage) {
          messagesContainer.lastChild.scrollIntoView(true);
        }
        return;
      }

      messagesContainer.children[snapshot - 1].scrollIntoView(true);
    }
  }

  scrollToBottom = () => {
    const { chatContainerRef } = this.props;
    const scrollHeight = chatContainerRef.current.scrollHeight - chatContainerRef.current.clientHeight;
    chatContainerRef.current.scrollTo(0, scrollHeight);
  }

  render() {
    const { user, activeChatLastUnreadMessageId, chatData, user: { id: userId }, loadingMessages,
    loadingPrevMessages, messages, showScrollBottomButton } = this.props;

    if(loadingMessages && messages.length === 0) {
      return <LoadingIcon width={'50px'} height={'50px'}/>;
    }

    return (
      <>
        {loadingPrevMessages && messages.length > 0 && <LoadingIcon width={'50px'} height={'50px'}/>}
        {chatData && !chatData.lastMessageTimestamp && (
          <div className={s.noActiveChat}>
            <p className="text-center">Send a message to start the conversation.</p>
          </div>
        )}
        {messages.length > 0 && (
          messages.map((message, i, arr) => {
            const currentUserIsAuthor = message.user._id === user.id;
            const atUserName = makeUserName(currentUserIsAuthor ? user.username : message.user.username);
            const nextMessageOwner = arr[i + 1] && arr[i + 1].user._id;
            const prevMessageOwner = arr[i - 1] && arr[i - 1].user._id;
            const date = timestampToDate(message.timestamp);
            const dateOfMessage = moment(date).format('LT');
            let currentMessageDate = moment(date).format('LL');
            const prevMessageDate = arr[i - 1] && moment(timestampToDate(arr[i - 1].timestamp)).format('LL');
            let showDate = (currentMessageDate !== prevMessageDate && Boolean(prevMessageDate)) || i === 0;
            currentMessageDate = date.getDate() === (new Date()).getDate() ? 'Today' : currentMessageDate;
            const isSeen = message.user._id != userId
              ? message.seen === false
              : false;

            return (
              <div
                className={cx(
                  {[`${s.my}`]: message.user._id === user.id},
                  {[`${s.his}`]: message.user._id !== user.id},
                  {[`${s.unread}`]: isSeen},
                )}
                key={i}
              >
                {
                  showDate && (
                    <div className={cx(s.conversationDay)}>
                      {currentMessageDate}
                    </div>
                  )
                }
                {
                  <div
                    className={cx(s.unreadMessagesBelow, { [`${s.showUnreadMessagesBelow}`]: activeChatLastUnreadMessageId === message._id })}
                  >
                    <span>{"New messages"}</span>
                  </div>
                }
                <div
                  className={cx(
                    s.chat,
                    {[`${s.myMessage}`]: message.user._id === user.id},
                    {[`${s.friendMessage}`]: message.user._id !== user.id},
                    {[`${s.chatBubbleStop}`]: nextMessageOwner !== message.user._id},
                  )}
                  key={message.timestamp}
                >
                  <div className={cx(s.userAvatarContainer)}>
                    <Link to={currentUserIsAuthor ? '/' + user.username : '/' + message.user.username}>
                      <img
                        className={cx(
                          {[`${s.showSideAvatar}`]: (prevMessageOwner && prevMessageOwner !== message.user._id) || prevMessageOwner === undefined}
                        )}
                        src={currentUserIsAuthor ? user.profile_image : message.user.avatar}
                        alt={atUserName}
                        title={atUserName}
                      />
                    </Link>
                  </div>
                  <div onClick={this.scrollToBottom} className={cx(s.scrollBottom, {[`${s.scrollBottomAppear}`]: showScrollBottomButton })}></div>
                  <div className={s.userMessageContainer}>
                    {message.mediaUrl && message.mediaUrl.length > 0 ? (
                        <div className={s.messageImageBlock}>
                          <LightBox dataImage={message.mediaUrl.length > 0 ? message.mediaUrl.map(img => ({src: img, width: 1, height: 1})) : []}/>
                        </div>
                      ) : ('')
                    }

                    {message.text && (
                      <div>
                        <div className={s.messageText}>
                          <p className='message-text'>
                            {createDisplayedSpark(message.text)}
                          </p>
                        </div>
                      </div>
                    )}

                    <div className={cx(s.displayTime)}>
                      { dateOfMessage || "" }
                    </div>
                  </div>
                </div>
              </div>
              )
            })
          )}
      </>
    )
  }
}

export default ChatMessagesList;

ChatMessagesList.defaultProps = {
  messages: [],
}

// ChatComponent.propTypes = {
//   user: userPropType.isRequired,
//   chatData: PropTypes.oneOfType([activeSingleChatDataPropType, activeGroupChatDataPropType, isUndefined]),
//   goToProfile: PropTypes.func.isRequired,
//   removeSharedLink: PropTypes.func.isRequired,
//   sendMessage: PropTypes.func.isRequired,
//   messages: PropTypes.arrayOf(messagePropType).isRequired,
//   sendingDisabled: PropTypes.bool.isRequired,
//   sendingTick: PropTypes.number.isRequired,
//   updateGroupChat: PropTypes.func.isRequired,
//   leaveGroupChat: PropTypes.func.isRequired,
//   loadingMessages: PropTypes.bool.isRequired,
// };

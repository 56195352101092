import React, { Component } from 'react';
import AccountExpired from '../../../containers/frontend/common/AccountExpired';

class AccountExpiredComponent extends Component{
  render() {
    return (
      <main className="home AccountExpiredComponent">
        <AccountExpired user_id={this.props.location.state.id} plan_id={this.props.location.state.plan_id} />
	   </main>
    )
  }
}
export default AccountExpiredComponent;

export const sparkConstants = {

    CREATE_REQUEST: 'SPARKS_CREATE_REQUEST',
    CREATE_SUCCESS: 'SPARKS_CREATE_SUCCESS',
    CREATE_FAILURE: 'SPARKS_CREATE_FAILURE',

    GETALLSPARKS_REQUEST: 'SPARKS_GETALL_REQUEST',
    GETALLSPARKS_SUCCESS: 'SPARKS_GETALL_SUCCESS',
    GETALLSPARKS_FAILURE: 'SPARKS_GETALL_FAILURE',
    
    UPDATESPARKS_REQUEST: 'SPARKS_UPDATE_REQUEST',
    UPDATESPARKS_SUCCESS: 'SPARKS_UPDATE_SUCCESS',
    UPDATESPARKS_FAILURE: 'SPARKS_UPDATE_FAILURE',

    DELETESPARKS_REQUEST: 'SPARKS_DELETE_REQUEST',
    DELETESPARKS_SUCCESS: 'SPARKS_DELETE_SUCCESS',
    DELETESPARKS_FAILURE: 'SPARKS_DELETE_FAILURE',

    DELETECOMMENT_REQUEST: 'COMMENT_DELETE_REQUEST',
    DELETECOMMENT_SUCCESS: 'COMMENT_DELETE_SUCCESS',
    DELETECOMMENT_FAILURE: 'COMMENT_DELETE_FAILURE',

    REPORTSPARKS_REQUEST: 'SPARKS_REPORT_REQUEST',
    REPORTSPARKS_SUCCESS: 'SPARKS_REPORT_SUCCESS',
    REPORTSPARKS_FAILURE: 'SPARKS_REPORT_FAILURE',

    LIKESPARKS_REQUEST: 'SPARKS_LIKE_REQUEST',
    LIKESPARKS_SUCCESS: 'SPARKS_LIKE_SUCCESS',
    LIKESPARKS_FAILURE: 'SPARKS_LIKE_FAILURE',

    SINGLESPARKS_REQUEST: 'SPARKS_SINGLE_REQUEST',
    SINGLESPARKS_SUCCESS: 'SPARKS_SINGLE_SUCCESS',
    SINGLESPARKS_FAILURE: 'SPARKS_SINGLE_FAILURE',

    UPDATESINGLESPARKS_REQUEST: 'SPARKS_SINGLE_UPDATE_REQUEST',
    UPDATESINGLESPARKS_SUCCESS: 'SPARKS_SINGLE_UPDATE_SUCCESS',
    UPDATESINGLESPARKS_FAILURE: 'SPARKS_SINGLE_UPDATE_FAILURE',

    VOTESPARKS_REQUEST: 'SPARKS_VOTE_REQUEST',
    VOTESPARKS_SUCCESS: 'SPARKS_VOTE_SUCCESS',
    VOTESPARKS_FAILURE: 'SPARKS_VOTE_FAILURE',

    COMMENTSPARKS_REQUEST: 'SPARKS_COMMENT_REQUEST',
    COMMENTSPARKS_SUCCESS: 'SPARKS_COMMENT_SUCCESS',
    COMMENTSPARKS_FAILURE: 'SPARKS_COMMENT_FAILURE',

    REPLYPOPUPDATASPARKS_REQUEST: 'SPARKS_REPLY_POPUP_DATA_REQUEST',
    REPLYPOPUPDATASPARKS_SUCCESS: 'SPARKS_REPLY_POPUP_DATA_SUCCESS',
    REPLYPOPUPDATASPARKS_FAILURE: 'SPARKS_REPLY_POPUP_DATA_FAILURE',

    REPLYSPARKS_REQUEST: 'SPARKS_REPLY_REQUEST',
    REPLYSPARKS_SUCCESS: 'SPARKS_REPLY_SUCCESS',
    REPLYSPARKS_FAILURE: 'SPARKS_REPLY_FAILURE',

    BLOCK_USER_REQUEST: 'SPARKS_BLOCK_USER_REQUEST',
    BLOCK_USER_SUCCESS: 'SPARKS_BLOCK_USER_SUCCESS',
    BLOCK_USER_FAILURE: 'SPARKS_BLOCK_USER_FAILURE',

    GETALL_LIST_SPARKS_REQUEST: 'GET_LIST_ALL_SPARKS_REQUEST',
    GETALL_LIST_SPARKS_SUCCESS: 'GET_LIST_ALL_SPARKS_SUCCESS',
    GETALL_LIST_SPARKS_FAILURE: 'GET_LIST_ALL_SPARKS_FAILURE',

    MESSAGE_PUSH_NOTIFICATION_REQUEST: 'MESSAGE_PUSH_NOTIFICATION_REQUEST',

    MARK_AS_OBJECTIONABLE_REQUEST: 'MARK_AS_OBJECTIONABLE_REQUEST',
    MARK_AS_OBJECTIONABLE_SUCCESS: 'MARK_AS_OBJECTIONABLE_SUCCESS',
    MARK_AS_OBJECTIONABLE_FAILURE: 'MARK_AS_OBJECTIONABLE_FAILURE',

    UNMARK_AS_OBJECTIONABLE_REQUEST: 'UNMARK_AS_OBJECTIONABLE_REQUEST',
    UNMARK_AS_OBJECTIONABLE_SUCCESS: 'UNMARK_AS_OBJECTIONABLE_SUCCESS',
    UNMARK_AS_OBJECTIONABLE_FAILURE: 'UNMARK_AS_OBJECTIONABLE_FAILURE',

    CLEAR_SPARK_LIST: 'CLEAR_SPARK_LIST',
};

import React from 'react';
import s from './progress-bar.module.scss';
import cs from 'classnames';

export default function ProgressBar({ step, isTrial }) {
  return (
    <div className={s.container}>
      {isTrial ? 
      <ul className={s.progressBarTrial}>
        <li className={cs({ [`${s.active}`]: step > 0, [`${s.current}`]: step === 0 })}>Account</li>
        <li className={cs({ [`${s.active}`]: step > 1, [`${s.current}`]: step === 1 })}>Contacts</li>
      </ul> : 
      <ul className={s.progressBar}>
        <li className={cs({ [`${s.active}`]: step > 0, [`${s.current}`]: step === 0 })}>Plan</li>
        <li className={cs({ [`${s.active}`]: step > 1, [`${s.current}`]: step === 1 })}>Account</li>
        <li className={cs({ [`${s.active}`]: step > 2, [`${s.current}`]: step === 2 })}>Contacts</li>
        <li className={cs({ [`${s.active}`]: step > 3, [`${s.current}`]: step === 3 })}>Pay</li>
      </ul>}
    </div>
  );
}

ProgressBar.propTypes = {
  //step
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import EditProfileSection from '../../../containers/frontend/userProfile/EditProfileSection';

class EditProfile extends Component {
  constructor(props){
    super(props);
    const {user} = this.props;
  }

  render() {
    return (
      <div id="EditProfile" className="EditProfile">
        <Header/>
        <EditProfileSection />
     </div>
    );
  }
}

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user
  };
}
export default connect(mapStateToProps)(EditProfile);

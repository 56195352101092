import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import EditProfileImage from '../../../containers/frontend/userProfile/EditProfileImage';
//import Cropper from 'cropperjs';
import $ from "jquery";
import { toast } from 'react-toastify';
import { userActions,alertActions } from '../../../actions';

var imgSrc = '';
class EditProfileNew1 extends Component {
  constructor(props){
    super(props);
    const {user} = this.props;
  }

  render() {
    return (
      <div id="EditProfile" className="EditProfileNew1">
        <Header/>
        <EditProfileImage/>
     </div>
    );
  }
}

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user
  };
}
export default connect(mapStateToProps)(EditProfileNew1);

import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';

import { getCurrentYear } from "../../../helpers";

class BottomWidget extends Component {
  constructor(props){
    super(props);
  }
  render(){
    return (
      <div className="twiter-left1 pic-profile about-help bottom-widget">
        <ul>
          <li>
            <Link to="/pages/about-us"> About </Link>
          </li>
          <li>|</li>
          <li>
            <Link to="/pages/contact-information"> Contact Information </Link>
          </li>
          <li>|</li>
          <li>
            <Link to="/pages/help-centers"> Help Center </Link>
          </li>
          <li>|</li>
          <li>
            <Link to="/pages/terms"> Terms </Link>
          </li>
          <li>|</li>
          <li>
            <Link to="/pages/privacy-policy"> Privacy Policy </Link>
          </li>
          <li>|</li>
          <li>
            <Link to="/pages/cookies"> Cookies </Link>
          </li>
        </ul>
        <h4>{`©${getCurrentYear()} 2cents, LLC.`}</h4>
      </div>
    )
  }
}


function mapStateToProps(state){
  return {
    user: state.authentication.user,
  };
}
export default connect(mapStateToProps)(BottomWidget);

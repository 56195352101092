import React, {Component} from 'react';
import { connect } from 'react-redux';
import PureList from '../sparks/PureList';
import ReplyPopUpText from '../common/ReplyPopUpText';
import ReSparkAction from '../common/ReSparkAction';
import LoadingIcon from '../common/LoadingIcon';


class ListSparksData extends Component {
  constructor(props)
  {
    super(props);

    const { sparks } = this.props;

    this.state = {
      sparks : sparks,
      visible : 10
    };
      this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 10};
    });
  }

  shouldComponentUpdate(prevProps,nextProps){
    return true;
  }

  render()
  {
    const { sparks, loading } = this.props;
    return (
      <div className="home-feed-main">

      <div className="twiter-left1 access-mid sett-block list-mem">
        <h3>Sparks</h3>
        <div className="block-acc-btm bor-top">
          {loading != undefined && loading ? (
            <div className="loading-tweet">
              <LoadingIcon />
            </div>
            ) : ('')
          }
          {sparks.allpost != undefined && sparks.allpost.length > 0 ? (
            sparks.allpost.slice(0,this.state.visible).map((raw,index) =>
              <PureList key={raw.post_id} rawData={raw} index={index}/>
              )
            ) : (<div><br/><p className="text-center">N/A</p><br/></div>)
          }
          {sparks.allpost != undefined && sparks.allpost.length > 0 ?
           (this.state.visible < sparks.allpost.length ? (
              <button onClick={this.loadMore} type="button" className="load-more btn btn-danger">Load more</button>
            ) : ('')) : ('')
          }
        </div>
      </div>
       <ReplyPopUpText />
       <ReSparkAction />
      </div>
    )
  }
}

function mapStateToProps(state,ownProps){
  return {
    sparks: state.lists,
    loading: state.lists != undefined ? state.lists.loading : false,
    user: state.authentication.user,
  };
}
export default connect(mapStateToProps)(ListSparksData);

import React, { Component } from 'react';
//import '../App.css';
import Header from '../../../containers/frontend/common/Header';
import MomentsBar from '../../../containers/frontend/common/momentsBar';
import FunContent from '../../../containers/frontend/moments/funContent';
import {Helmet} from "react-helmet";

export default class Fun extends Component {
  render() {
    return (
     <main className="Fun InstantByCategory">
         <Helmet>
           <title>Fun - 2cents Moments</title>
         </Helmet>
        <Header/>
        <MomentsBar/>
        <FunContent/>
     </main>
    );
  }
}

import ReactGA from 'react-ga';

export class gaTracking{

  static init = (history, trackingId) => {
    ReactGA.initialize(trackingId);

    history.listen(location => {
      ReactGA.set({ page: location.pathname });
      gaTracking.pageview(location.pathname);
    });

  }

  static pageview = (path) => {
    ReactGA.pageview(path);
  }

}

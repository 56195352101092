import React, { useState, useCallback, } from 'react';

function isStepPassed(answers, stepConfig = {}) {
  const {requiredFields = [], shouldBeTrue = [], shouldBeFalse = []} = stepConfig;
  //filter fields with null and undefined value
  const isPassed = !requiredFields.some(fieldName => answers[fieldName] == undefined)
    && !shouldBeTrue.some(fieldName => answers[fieldName] !== true)
    && !shouldBeFalse.some(fieldName => answers[fieldName] !== false);

  return isPassed;
}

function figureOutInitStepNum(answers, config) {
  const notPassedStepNum = config.findIndex(stepConfig => !isStepPassed(answers, stepConfig));
  return notPassedStepNum === -1 ? config.length - 1 : notPassedStepNum;
}


export default function useRegistrationForms(options) {
  const { config, initAnswers, sendAnswers, onFirstStepBack, finishUserRegistration } = options;

  const [answers, setAnswers] = useState(initAnswers || {});
  const [stepNum, setStepNum] = useState(() => figureOutInitStepNum(initAnswers || {}, config));
  const [nextDisabled, setNextDisabled] = useState(false);
  const [tickNum, setTickNum] = useState(0);
  const [stepSendingAnswersError, setStepSendingAnswersError] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const onNext = useCallback(() => setTickNum((tickNum) => tickNum + 1), []);

  const saveAnswers = useCallback((stepAnswers) => {
    const stepConfig = config[stepNum];
    const { persistAnswersOnServer = true } = stepConfig || {};

    setAnswers(curAnswers => ({...curAnswers, ...stepAnswers}));
    setStepSendingAnswersError('');

    //send answers to the server
    const dataToSend = {...answers, ...stepAnswers};

    const isLastStep = !config[stepNum + 1];
    if (!isLastStep && !persistAnswersOnServer) return Promise.resolve();

    const saveAnswersOnServer = isLastStep ? finishUserRegistration : sendAnswers;

    setIsLoading(true);
    return saveAnswersOnServer(dataToSend)
      .catch(error => {
        setStepSendingAnswersError(error.message);
        throw error;
      })
      .finally(() => setIsLoading(false));
  }, [config, stepNum, answers]);

  const onStepDone = useCallback(() => {
    setStepSendingAnswersError('');
    setStepNum((stepNum) => stepNum + 1);
  }, []);

  const onBack = useCallback(() => {
    setStepSendingAnswersError('');
    setStepNum((stepNum) => stepNum - 1 >= 0 ? stepNum - 1 : 0);
    if (stepNum === 0) {
      onFirstStepBack();
    }
  }, [onFirstStepBack, stepNum]);

  const stepConfig = config[stepNum];

  let Step;
  if (stepConfig) {
    ({ component: Step } = stepConfig);
  }

  const stepInitAnswers = initAnswers || {};

  const result = {
    Step,
    onStepDone,
    saveAnswers,
    tickNum,
    onNext,
    stepNum,
    nextDisabled,
    setNextDisabled,
    isSendingAnswersInProgress: isLoading,
    stepInitAnswers,
    onBack,
    answers,
    stepSendingAnswersError,
  };
  return result;
}

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './optionMenu.module.scss';

export default function OptionMenu(props) {
  const { active, options, styles } = props;

  return (
    <div className={cx("dropdown-menu-wrapper", s.optionMenuContainer, {[`${s.active}`]: active}, styles )}>
      {options &&
      options.length > 0 &&
      options.map((option, index) => (
        <div className={cx(s.option, option.style)} key={index} onClick={option.onClick}>
          {option.title}
        </div>
      ))}
    </div>
  );
};

OptionMenu.propTypes = {
  active: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    style: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  })).isRequired,
  styles: PropTypes.string,
};

OptionMenu.defaultProps = {
  styles: {},
};



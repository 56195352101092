import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from '../../../containers/frontend/common/Header';
import SingleView from '../../../containers/frontend/sparks/SingleView';
import ReSparkSingleAction from '../../../containers/frontend/common/ReSparkSingleAction';
import MomentsBar from '../../../containers/frontend/common/momentsBar';
import UserListDetailSection from '../../../containers/frontend/userProfile/UserListDetailSection';
import MomentSingleView from '../../../containers/frontend/moments/MomentSingleView';
import HomeContent from '../../../containers/frontend/HomeContent';
import NotFoundView from '../NotFoundView';
import {Helmet} from "react-helmet";
import UserProfileSection from '../../../containers/frontend/userProfile/UserProfileSection';
import { sparksActions } from '../../../actions';

class SparkSingleView extends Component{
  constructor(props){
    super(props);
    $('html, body').css('overflow', 'hidden');

    const { username, dispatch } = this.props;

    if (username) {
      dispatch(sparksActions.getProfileData('@'+username));
    }
  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.post_id !== this.props.match.params.post_id) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { dispatch } = this.props;

    const { user,post_id } = this.props;
    if (post_id) {
          const sendRaw = {
            post_id : post_id,
            user_id: user ? user.id : 0,
          }
          dispatch(sparksActions.GetSparkData(sendRaw));
    }

  }

  render() {
    const { at_username, post_title, username, sparksingle, location, notfoundstatus } = this.props;
    const isList = location.pathname.includes('/list-detail');

    if (notfoundstatus) {
      return (
        <>
          <Header/>
          <NotFoundView />
        </>
      )
    }
    
    return (
      <main className="home SparkSingleView">
        {!sparksingle.loading && (
            <>
              <Helmet>
                <title>{at_username ? '('+at_username+')' : ''} on 2cents | {'"'+post_title+'"'} </title>
              </Helmet>
              <Header/>
              {username != undefined && !isList ? (
                <div>
                  <UserProfileSection blockByUser={false} blockByYou={false} username={username}/>
                  
                  <ReSparkSingleAction/>
                </div>
              ) : location.pathname.startsWith('/instants') ? (
                <>
                  <MomentsBar/>
                  <MomentSingleView/>
                </>
              ) : isList ? (
                <>
                  <UserListDetailSection username={this.props.match.params.profilename} l_id={this.props.match.params.l_id}/>
                </>
              ) : (
                <HomeContent holdflag={true}/>
              )
              }
            </>
          )
        }
        {!sparksingle.loading && <SingleView match={this.props.match} post_id={this.props.match.params.post_id ? this.props.match.params.post_id : ''}/>}
	   </main>
    )
  }
}

function mapStateToProps(state,ownProps){
  //localStorage.setItem("user", JSON.stringify())

  let user = /*state.authentication.user ? state.authentication.user :*/ state.profileinfo.data;
  if(user && !user.id)
    user.id = user.user_id;

  // if(!state.authentication.user && state.profileinfo.data)
  //   localStorage.setItem('user', JSON.stringify(user));

  return {
    user: state.authentication.user,
    // profileinfo: state.profileinfo.data != undefined ? state.profileinfo.data : [],
    post_id: ownProps.match.params.post_id,
    sparksingle: state.sparksingle,
    notfoundstatus: state.profileinfo.notfoundstatus != undefined ? state.profileinfo.notfoundstatus : false,
    name: state.sparksingle.items != undefined ? state.sparksingle.items[0].name : '',
    at_username: state.sparksingle.items != undefined ? state.sparksingle.items[0].at_username : '',
    post_title: state.sparksingle.items != undefined ? state.sparksingle.items[0].post_title : '',
    username: ownProps.match.params.profilename,
  };
}
export default connect(mapStateToProps)(SparkSingleView);

import React from 'react';
import Registration from '../containers/Registration';
import { Helmet } from 'react-helmet';

export default function RegistrationPage(props) {
  return (
    <>
      <Helmet>
        <title>Sign up for 2cents</title>
      </Helmet>
      <Registration referral={props.match.params.ref} />
    </>
  );
}

import React, { useState, useCallback } from 'react';
import { SimpleInput } from './index';
import PropTypes from 'prop-types';
import cs from 'classnames';
import {ReactComponent as Eye} from './eye-filled.svg';
import {ReactComponent as CrossedOutEye} from './eye-invisible-filled.svg';
import s from './style.module.css'

const PasswordInput = ({ onChange, value, type, name, id, placeholder, errMessage, disabled, showTooltipForPass=false, ...restProps }) => {
  const [showPass, setShowPass] = useState(false);

  const toggleEye = useCallback(() => setShowPass(v => !v), []);

  const showPassElement = (
    <label className={cs(s.showHidePass, s.inputLabel)} htmlFor={id}>
      <div onClick={toggleEye} className={cs(s.fieldIcon, name)}>
        { showPass ? <CrossedOutEye /> : <Eye /> }
      </div>
    </label>
  );
  
  return (
    <div className={s.passwordContainer}>
      <SimpleInput 
        type={showPass ? 'text' : type}
        onChange={onChange}
        value={value}
        name={name}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        errMessage={errMessage}
        togglePasswordElement={showPassElement}
        {...restProps}
      />
    </div>
  )
}

PasswordInput.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  errMessage: PropTypes.string,
  disabled: PropTypes.bool,
  showTooltipForPass: PropTypes.bool,
};

export default PasswordInput;

import { sparkConstants,profileinfoConstants,userConstants,momentsConstants } from '../constants';
import { sparksService,userService } from '../services';
import { alertActions } from './';
import { getCurrentLocation } from './helpers';
import { toast } from 'react-toastify';
import msgs from '../messages/en/main';

export const sparksActions = {
    CreateSpark,
    getAll,
    getProfileData,
    LikeUnlikeSpark,
    GetSparkData,
    VotePoll,
    CommentPost,
    ReplyPopUpData,
    ReSparkPost,
    MomentSparkPost,
    deleteSpark,
    deleteComment,
    updateAllSpark,
    updateMomentAllSpark,
    updateSpark,
    updateGetSingleSpark,
    blockUser,
    markAsObjectionable,
    unmarkAsObjectionable,
    muteUnmuteAccount,
    reportSpark,
    messagePushNotification,
    clearSparks,
};

function CreateSpark(rawData,type){
  return dispatch => {
    dispatch(request(rawData));
    sparksService.CreateSpark(rawData)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;

          if (status) {
            dispatch(success(data));

            if (type == 'profile') {
              dispatch(getProfileData(rawData.username));
            } else {
              dispatch(getAll(rawData.createspark.user_id));
            }
          } else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()))
        }
      );
  };
  function request(data) { return{ type: sparkConstants.CREATE_REQUEST, data } }
  function success(sparkcreate) { return{ type: sparkConstants.CREATE_SUCCESS, sparkcreate } }
  function failure(error) { return{ type: sparkConstants.CREATE_FAILURE, error } }
}

function getAll(user, initLocation) {
    initLocation = initLocation || getCurrentLocation();
    const initLocationPathname = typeof initLocation === 'object' ? initLocation.pathname : '';

    return dispatch => {
        dispatch(request());
        if(user.hashtag != undefined){
          sparksService.getTagAllData(user)
              .then(
                  sparks => {
                    const status = sparks.status;
                    const msg = sparks.msg;
                    if(status){
                      dispatch(success(sparks.allpost))
                    }
                    else {
                      dispatch(failure(msg.toString()));
                      toast.error(msg.toString());
                    }
                  },
                  error => {
                    dispatch(failure(error.toString()))
                  }
              );
          }
          else {
            sparksService.getAll(user)
                .then(
                    sparks => {
                      if(sparks.status != undefined)
                      {
                        const status = sparks.status;
                        const msg = sparks.msg;
                        if(status){
                          const currentLocation = getCurrentLocation();
                          const currentLocationPathname = typeof currentLocation === 'object' ? currentLocation.pathname : '';
                          if (initLocationPathname === currentLocationPathname) {
                            dispatch(success(sparks.allpost));
                          } else {
                            console.warn('sparks.actions.getAll locationPathname mismatch', initLocationPathname, currentLocationPathname);
                          }
                        }
                        else {
                          dispatch(failure(msg.toString()));
                          toast.error(msg.toString());
                        }
                      }
                      else {
                        dispatch(failure(msgs.SOMETHING_WENT_WRONG));
                        toast.error(msgs.SOMETHING_WENT_WRONG);
                      }
                    },
                    error => {
                      dispatch(failure(error.toString()))
                    }
                );
          }
    };

    function request(user) { return { type: sparkConstants.GETALLSPARKS_REQUEST } }
    function success(sparks) { return { type: sparkConstants.GETALLSPARKS_SUCCESS, sparks } }
    function failure(error) { return { type: sparkConstants.GETALLSPARKS_FAILURE, error } }
}

function getProfileData(user) {
    return dispatch => {
        dispatch(request(user));
            sparksService.getProfileData(user)
                .then(
                    profilesparks => {
                      if(profilesparks.status != undefined)
                      {
                        const status = profilesparks.status;
                        const msg = profilesparks.msg;
                        if(status){
                          dispatch(success(profilesparks.profileData.myTweets));
                          dispatch(successprofile(profilesparks.profileData));
                        }
                        else {
                          dispatch(failure(msg.toString()));
                        }
                      }
                      else {
                        toast.error(msgs.SOMETHING_WENT_WRONG);
                      }
                    },
                    error => {
                      dispatch(failure(error.toString()))
                    }
                );
    };

    function request(user) { return { type: profileinfoConstants.PROFILE_GETALLINFO_REQUEST, user } }
    function success(sparks) { return { type: sparkConstants.GETALLSPARKS_SUCCESS, sparks } }
    function successprofile(profileinfo) { return { type: profileinfoConstants.PROFILE_GETALLINFO_SUCCESS, profileinfo } }
    function failure(error) { return { type: profileinfoConstants.PROFILE_GETALLINFO_FAILURE, error } }
}

function LikeUnlikeSpark(rawData, type, myPost){
  return dispatch => {
    dispatch(request(rawData));
    sparksService.LikeUnlikeSpark(rawData)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;
          if(status){
            dispatch(success(data));
            !myPost && dispatch(changeTotalLikesCount(data));
            if(type === 'single'){
              dispatch(updateGetSingleSpark(data));
            }
            else {
              dispatch(updateSpark(data));
            }
          }
          else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()))
        }
      );
  };
  function request(data) { return{ type: sparkConstants.LIKESPARKS_REQUEST, data } }
  function success(likedata) { return{ type: sparkConstants.LIKESPARKS_SUCCESS, likedata } }
  function changeTotalLikesCount(likedata) { return{ type: profileinfoConstants.CHANGE_TOTAL_LIKES_COUNT, likedata } }
  function failure(error) { return{ type: sparkConstants.LIKESPARKS_FAILURE, error } }
}

function GetSparkData(rawData){
  return dispatch => {
    dispatch(request(rawData));
    sparksService.GetSparkData(rawData)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;
          if(status){
            dispatch(success(data.allpost, msg));
          }
          else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()))
        }
      );
  };
  function request(data) { return{ type: sparkConstants.SINGLESPARKS_REQUEST, data } }
  function success(sparksingle, msg) { return{ type: sparkConstants.SINGLESPARKS_SUCCESS, sparksingle, msg } }
  function failure(error) { return{ type: sparkConstants.SINGLESPARKS_FAILURE, error } }
}

function VotePoll(rawData, type){
  return dispatch => {
    dispatch(request(rawData));
    sparksService.VotePoll(rawData)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;
          if(status){
            dispatch(success(data));
            dispatch(GetSparkData(rawData));
            if(type == 'home') {
              dispatch(getAll(rawData.user_id));
            }
            else
              dispatch(updateSpark(data));
          }
          else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()))
        }
      );
  };
  function request(data) { return{ type: sparkConstants.VOTESPARKS_REQUEST, data } }
  function success(votespark) { return{ type: sparkConstants.VOTESPARKS_SUCCESS, votespark } }
  function failure(error) { return{ type: sparkConstants.VOTESPARKS_FAILURE, error } }
}


function CommentPost(rawData,type){
  return dispatch => {
    dispatch(request(rawData));
    sparksService.CommentPost(rawData)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;
          if(status){
            toast.success(msg);
            dispatch(success(data));
            if(type === 'single'){
              dispatch(updateGetSingleSpark(data));
            }
            else {
              dispatch(updateSpark(data));
            }
          }
          else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };
  function request(data) { return{ type: sparkConstants.COMMENTSPARKS_REQUEST, data } }
  function success(commentspark) { return{ type: sparkConstants.COMMENTSPARKS_SUCCESS, commentspark } }
  function failure(error) { return{ type: sparkConstants.COMMENTSPARKS_FAILURE, error } }
}

function ReSparkPost(rawData,type){
  return dispatch => {
    dispatch(request(rawData));
    sparksService.ReSparkPost(rawData)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;
          if(status){
            toast.success(msg);
            dispatch(success(data));
            // dispatch(GetSparkData(rawData));
            // dispatch(getAll(rawData.user_id));
            if(type === 'single'){
              dispatch(updateGetSingleSpark(data));
              dispatch(updateSpark(data));
            }
            else {
              dispatch(updateSpark(data));
            }
          }
          else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };
  function request(data) { return{ type: sparkConstants.REPLYSPARKS_REQUEST, data } }
  function success(replyspark) { return{ type: sparkConstants.REPLYSPARKS_SUCCESS, replyspark } }
  function failure(error) { return{ type: sparkConstants.REPLYSPARKS_FAILURE, error } }
}

function MomentSparkPost(rawData){
  return dispatch => {
    dispatch(request(rawData));
    sparksService.MomentSparkPost(rawData)
      .then(
        data => {
          const status = data.status;
          const msg = data.message;
          if(status === 200){
            toast.success(msg);
            dispatch(success(data));
            //dispatch(GetSparkData(rawData));
            dispatch(getAll(rawData.user_id));
          }
          else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };
  function request(data) { return{ type: sparkConstants.REPLYSPARKS_REQUEST, data } }
  function success(replyspark) { return{ type: sparkConstants.REPLYSPARKS_SUCCESS, replyspark } }
  function failure(error) { return{ type: sparkConstants.REPLYSPARKS_FAILURE, error } }
}

function ReplyPopUpData(rawData){
  return dispatch => {
    dispatch(request(rawData));
    if(rawData){
      dispatch(success(rawData));
    }
    else {
      toast.success(msgs.SOMETHING_WENT_WRONG);
    }
  };
  function request(data) { return{ type: sparkConstants.REPLYPOPUPDATASPARKS_REQUEST, data } }
  function success(replydata) { return{ type: sparkConstants.REPLYPOPUPDATASPARKS_SUCCESS, replydata } }
  function failure(error) { return{ type: sparkConstants.REPLYPOPUPDATASPARKS_FAILURE, error } }
}


function deleteSpark(postdata) {
    return dispatch => {
      dispatch(request(postdata));
      sparksService.deleteSpark(postdata)
          .then(
            data => {
              const status = data.status;
              const msg = data.msg;
              if(status){
                toast.success(msg);
                dispatch(success(postdata.index));
                dispatch(decreaseSparksCount());
                if ((getCurrentLocation()).pathname.includes('/instants/view')) {
                  dispatch(deleteAttachedSpark(postdata));
                }
              }
              else {
                dispatch(failure(msg.toString()));
                toast.error(msg.toString());
              }
            },
            error => {
              dispatch(failure(error.toString()));
              toast.error(msgs.SOMETHING_WENT_WRONG);
            }
        );
    };
    function deleteAttachedSpark(data) { return { type: momentsConstants.DELETE_ATTACHED_SPARK, data } }
    function request(postdata) { return { type: sparkConstants.DELETESPARKS_REQUEST, postdata } }
    function success(index) { return { type: sparkConstants.DELETESPARKS_SUCCESS, index } }
    function decreaseSparksCount() { return { type: profileinfoConstants.DECREASE_SPARKS_COUNT } }
    function failure(error) { return { type: sparkConstants.DELETESPARKS_FAILURE, error } }
}

function deleteComment(postdata) {
  return dispatch => {
    dispatch(request(postdata));
    sparksService.deleteComment(postdata)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;

          if (status) {
            toast.success(msg);
            dispatch(success(postdata.index));
            dispatch(updateGetSingleSpark(data));
          } else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()));
          toast.error(msgs.SOMETHING_WENT_WRONG);
        }
      );
  };

  function request(postdata) { return { type: sparkConstants.DELETECOMMENT_REQUEST, postdata } }
  function success(index) { return { type: sparkConstants.DELETECOMMENT_SUCCESS, index } }
  function failure(error) { return { type: sparkConstants.DELETECOMMENT_FAILURE, error } }
}

function clearSparks() {
  return dispatch => {
    dispatch(success());
  }
  function success() { return { type: sparkConstants.CLEAR_SPARK_LIST } }
}

function updateAllSpark(rawData,l_id) {
    return dispatch => {
      dispatch(request(l_id));
      if(rawData.allpost.length != undefined && rawData.allpost.length > 0){
        dispatch(success(rawData.allpost));
      }
      else {
        dispatch(failure(rawData, "No sparks found... Please try later"));
        //toast.success("No sparks found.... Please try later");
      }
    };

    function request(sparks) { return { type: sparkConstants.GETALL_LIST_SPARKS_REQUEST, sparks } }
    function success(sparks) { return { type: sparkConstants.GETALL_LIST_SPARKS_SUCCESS, sparks } }
    function failure(sparks, error) { return { type: sparkConstants.GETALL_LIST_SPARKS_FAILURE, sparks, error } }
}

function updateMomentAllSpark(rawData,m_id) {
    return dispatch => {
      dispatch(request(m_id));
      if(rawData.length != undefined && rawData.length > 0){
        dispatch(success(rawData));
      }
      else {
        dispatch(failure(rawData, "No sparks found... Please try later"));
        //toast.success("No sparks found.... Please try later");
      }
    };

    function request(sparks) { return { type: sparkConstants.GETALL_LIST_SPARKS_REQUEST, sparks } }
    function success(sparks) { return { type: sparkConstants.GETALL_LIST_SPARKS_SUCCESS, sparks } }
    function failure(sparks, error) { return { type: sparkConstants.GETALL_LIST_SPARKS_FAILURE, sparks, error } }
}

function updateSpark(rawData) {
    return dispatch => {
      dispatch(request(rawData.updated_post[0].post_id));
      if(rawData.updated_post.length != undefined && rawData.updated_post.length > 0){
        const currentLocation = getCurrentLocation();
        if (currentLocation.pathname.includes('/instants/view')) {
          dispatch(updateAttachedSpark(rawData.updated_post[0]));
        }
        dispatch(success(rawData));
      }
      else {
        dispatch(failure(rawData, msgs.SOMETHING_WENT_WRONG));
        toast.success(msgs.SOMETHING_WENT_WRONG);
      }
    };
    function updateAttachedSpark(data) { return { type: momentsConstants.UPDATE_ATTACHED_SPARK, data } }
    function request(id) { return { type: sparkConstants.UPDATESPARKS_REQUEST, id } }
    function success(id) { return { type: sparkConstants.UPDATESPARKS_SUCCESS, id } }
    function failure(id, error) { return { type: sparkConstants.UPDATESPARKS_FAILURE, id, error } }
}

function updateGetSingleSpark(rawData) {
    return dispatch => {
      dispatch(request(rawData.updated_post[0].post_id));
      if(rawData.updated_post.length != undefined && rawData.updated_post.length > 0){
        dispatch(success(rawData));
      }
      else {
        dispatch(failure(rawData, msgs.SOMETHING_WENT_WRONG));
        toast.success(msgs.SOMETHING_WENT_WRONG);
      }
    };

    function request(id) { return { type: sparkConstants.UPDATESINGLESPARKS_REQUEST, id } }
    function success(id) { return { type: sparkConstants.UPDATESINGLESPARKS_SUCCESS, id } }
    function failure(id, error) { return { type: sparkConstants.UPDATESINGLESPARKS_FAILURE, id, error } }
}

function blockUser(blockData,type,rawInfo) {
    return dispatch => {
      dispatch(request(blockData));
      sparksService.blockUser(blockData)
          .then(
            data => {
              const status = data.status;
              const msg = data.msg;
              if(status){
                toast.success(msg);
                dispatch(success(data));

                const currentLocation = getCurrentLocation();

                if(currentLocation.pathname == '/'){
                  dispatch(getAll(blockData.user_id));
                }
                // else {
                //   setTimeout(function() {
                //     window.location.replace(currentLocation.pathname);
                //   }.bind(this), 200);
                // }
              }
              else {
                dispatch(failure(msg.toString()));
                toast.error(msg.toString());
              }
            },
            error => {
              dispatch(failure(error.toString()));
              toast.error(msgs.SOMETHING_WENT_WRONG);
            }
        );
    };

    function request(blockData) { return { type: sparkConstants.BLOCK_USER_REQUEST, blockData } }
    function success(blockData) { return { type: sparkConstants.BLOCK_USER_SUCCESS, blockData } }
    function failure(error) { return { type: sparkConstants.BLOCK_USER_FAILURE, error } }
}

function markAsObjectionable(objectionableData) {
  return dispatch => {
    dispatch(request(objectionableData));
    sparksService.markAsObjectionable(objectionableData)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;

          if (status) {
            toast.success(msg);
            dispatch(success(data));
            const currentLocation = getCurrentLocation();
            if (currentLocation.pathname.includes('/instants/view')) {
              dispatch(markAttachedSparkAsObjectionable(objectionableData));
            }
          } else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()));
          toast.error(msgs.SOMETHING_WENT_WRONG);
        }
      );
  };

  function markAttachedSparkAsObjectionable(objectionableData) { return { type: momentsConstants.MARK_ATTACHED_SPARK_AS_OBJECTIONABLE, objectionableData } }
  function request(objectionableData) { return { type: sparkConstants.MARK_AS_OBJECTIONABLE_REQUEST, objectionableData } }
  function success(objectionableData) { return { type: sparkConstants.MARK_AS_OBJECTIONABLE_SUCCESS, objectionableData } }
  function failure(error) { return { type: sparkConstants.MARK_AS_OBJECTIONABLE_FAILURE, error } }
}

function unmarkAsObjectionable(objectionableData) {
  return dispatch => {
    dispatch(request(objectionableData));
    sparksService.unmarkAsObjectionable(objectionableData)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;

          if (status) {
            toast.success(msg);
            dispatch(success(data));
            if ((getCurrentLocation()).pathname.includes('/instants/view')) {
              dispatch(unmarkAttachedSparkAsObjectionable(objectionableData));
            }
          } else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()));
          toast.error(msgs.SOMETHING_WENT_WRONG);
        }
      );
  };

  function unmarkAttachedSparkAsObjectionable(objectionableData) { return { type: momentsConstants.UNMARK_ATTACHED_SPARK_AS_OBJECTIONABLE, objectionableData } }
  function request(objectionableData) { return { type: sparkConstants.UNMARK_AS_OBJECTIONABLE_REQUEST, objectionableData } }
  function success(objectionableData) { return { type: sparkConstants.UNMARK_AS_OBJECTIONABLE_SUCCESS, objectionableData } }
  function failure(error) { return { type: sparkConstants.UNMARK_AS_OBJECTIONABLE_FAILURE, error } }
}

function muteUnmuteAccount(unmuteData,type) {
    return dispatch => {
        dispatch(request(unmuteData));
        userService.muteUnmuteAccount(unmuteData)
          .then(
              data => {
                const status = data.status;
                const msg = data.msg;
                if(status){
                  dispatch(success(data));
                  if(type == 'home'){
                    dispatch(getAll(unmuteData.user_id));
                  }
                  dispatch(alertActions.success(msg.toString()));
                  toast.success(msg.toString());

                  // const currentLocation = getCurrentLocation();
                  // setTimeout(function() {
                  //   window.location.replace(currentLocation.pathname);
                  // }.bind(this), 200);
                }
                else {
                  dispatch(failure(msg.toString()));
                  dispatch(alertActions.error(msg.toString()));
                  toast.success(msg.toString());
                }
              },
              error => {
                dispatch(failure(error.toString()))
              }
          );
    };

    function request(unmuteData) { return { type: userConstants.DIRECT_UNMUTE_REQUEST, unmuteData } }
    function success(unmuteData) { return { type: userConstants.DIRECT_UNMUTE_SUCCESS, unmuteData } }
    function failure(error) { return { type: userConstants.DIRECT_UNMUTE_FAILURE, error } }
}

function reportSpark(postdata, type) {
  return dispatch => {
    dispatch(request(postdata));
    sparksService.reportSpark(postdata)
        .then(
            data => {
              const status = data.status;
              const msg = data.msg;
              if (status) {
                dispatch(success(data));
                if (type === 'single') {
                  dispatch(updateGetSingleSpark(data));
                } else {
                  if (postdata.updated_post) {
                    dispatch(updateSpark(data));
                  } else if (!postdata.pagetype) {
                    dispatch(getAll(postdata.user_id));
                    if ((getCurrentLocation()).pathname.includes('/instants/view')) {
                      dispatch(setReportAttachedSpark(data.updated_post[0].post_id));
                    }
                  } else {
                    dispatch(getProfileData(postdata.username));
                  }
                }
              } else {
                dispatch(failure(msg.toString()));
                toast.error(msg.toString());
              }
            },
            error => {
              dispatch(failure(error.toString()))
            }

        );
  };

  function setReportAttachedSpark(post_id) { return { type: momentsConstants.SET_REPORT_ATTACHED_SPARK, post_id }}
  function request(postdata) { return { type: sparkConstants.REPORTSPARKS_REQUEST, postdata } }
  function success(index) { return { type: sparkConstants.REPORTSPARKS_SUCCESS, index } }
  function failure(error) { return { type: sparkConstants.REPORTSPARKS_FAILURE, error } }
}

function messagePushNotification(data){
  return dispatch => {
    dispatch(request(data));
    sparksService.messagePushNotification(data);
  };
  function request(data) { return{ type: sparkConstants.MESSAGE_PUSH_NOTIFICATION_REQUEST, data } }
}

const setSubmenuPosition = (event, menuParentClass, menuClass) => {
  const documentClickListener = (element) => {
    element.style.display = 'none';
  }
  const targetClass = event.target.className;
  let menuItem;

  if (typeof targetClass === 'string' && targetClass.indexOf('fas') !== -1) {
    menuItem = event.target.parentNode;
  } else if (typeof targetClass === 'string' && targetClass.indexOf(menuParentClass) !== -1) {
    menuItem = event.target;
  } else {
    return;
  }

  const submenuWrapper = menuItem.querySelector(`.${menuClass}`);
  if (submenuWrapper) {
    let topOffset;
    let leftOffset;
    const windowWidth = window.innerWidth;

    if (windowWidth <= 575.98) {
      topOffset = 176;
      leftOffset = 116;
    } else if (windowWidth >= 576 && windowWidth <= 767) {
      topOffset = 190;
      leftOffset = 18;
    } else {
      topOffset = 206;
      leftOffset = 18;
    }
    submenuWrapper.style.top = `${menuItem.getBoundingClientRect().y - topOffset}px`;
    submenuWrapper.style.left = `${menuItem.offsetLeft - leftOffset}px`;
    submenuWrapper.style.display = 'flex';

    document.addEventListener('click', () => documentClickListener(submenuWrapper), { once: true, capture: true });
    document.addEventListener('touchmove', () => documentClickListener(submenuWrapper), { once: true });

    event.stopPropagation();
  }
}

export default setSubmenuPosition;
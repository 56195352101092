import { asyncLocalStorage } from './localStorage';

export const STORAGE_KEY = 'x_user_email_bunch';

//primitive encryption
export const encrypt = (str) => window.btoa(str);
export const decrypt = (str) => window.atob(str);

export const stringifyValue = (userId, email, uid) => {
  return JSON.stringify({userId, email, uid});
};

//return {userId: ..., email: '', uid: ''}
export const parseValue = (value) => {
  return JSON.parse(value);
};

const tryParseJSON = (v) => {
  try {
    return JSON.parse(v);
  } catch (e) {
    return v;
  }
}

export const removeUserEmailBunchAsync = () => {
  return asyncLocalStorage.removeItem(STORAGE_KEY)
    .catch(error => {
      throw error;
    });
};

export const getUserEmailBunch = () => {
  const rawValue = localStorage.getItem(STORAGE_KEY);
  return tryParseJSON(rawValue);
};

export const setUserEmailBunch = (value) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import SearchSection from '../../../containers/frontend/userProfile/SearchSection';
import {Helmet} from "react-helmet";

import { userActions,alertActions } from '../../../actions';

class Search extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    this.getPageData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.slug !== this.props.match.params.slug) {
      this.getPageData(nextProps.match.params.slug);
    }
  }

  // componentDidUpdate(prevProps)
  // {
  //   if (prevProps.user.id !== this.props.user.id) {
  //     this.getPageData();
  //   }
  // }

  getPageData = (searchWord=this.props.match.params.slug) => {
    var slug = searchWord;
    const { user } = this.props;
    const { dispatch } = this.props;
    const searchData = {
      user_id : user.id,
      search : slug
    }

    if(slug){
      dispatch(userActions.getSearchData(searchData));
    }
  }

  jsUcfirst = (string) =>
  {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
  const { slug } = this.props;
    return (
      <div id="AccountSetting" className="Search">
        <Helmet>
          <title>{this.jsUcfirst(slug)} - 2cents Search</title>
        </Helmet>
        <Header/>
        <SearchSection slug={slug}/>
     </div>
    );
  }
}

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    slug: ownProps.match.params.slug
  };
}
export default connect(mapStateToProps)(Search);

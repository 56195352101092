import { sparkConstants } from '../constants';

export function replydata(state = {}, action) {
  switch (action.type) {
    case sparkConstants.REPLYPOPUPDATASPARKS_REQUEST:
      return {
        loading: true,
      };
    case sparkConstants.REPLYPOPUPDATASPARKS_SUCCESS:
      return {
        data: action.replydata,
        loading: false,
      };
    case sparkConstants.REPLYPOPUPDATASPARKS_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state
  }
}

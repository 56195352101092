import React, {Component} from 'react';
import { connect } from 'react-redux';
import LightBox from '../../../containers/frontend/common/LightBox';



class SparkImageSection extends Component {
  constructor(props){
    super(props);

    let {sparkImageData} = this.props ? this.props : [];
    let data = [];

    sparkImageData.postImgData.length > 0 && sparkImageData.postImgData.map((raw,i) =>
      {
        data[i] = {
          src : raw.file,
          width : 4,
          height : 2,
        }
      }
    );
    this.state = {
      photos: data,
    };
  }

  render(){
    let {photos} =this.state;
    let {sparkImageData} =this.props;
    return (
      <div className={"tweet-right-img "+(sparkImageData.postImgData.length > 0 ? (sparkImageData.postImgData.length == 1 ? 'single-image' : 'multiple-image') : 'no-image')}>
        {sparkImageData.postImgData.length > 0 ? (
            <LightBox dataImage={photos.length > 0 ? photos : []}/>
          ) : ('')
        }
      </div>
    )
  }
}


function mapStateToProps(state,ownProps){
  return {
    alert: state.alert,
    user: state.authentication.user,
    sparkImageData: ownProps.sparkImageData
  };
}
export default connect(mapStateToProps)(SparkImageSection);

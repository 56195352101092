export const listsConstants = {

    CREATE_REQUEST: 'LISTS_CREATE_REQUEST',
    CREATE_SUCCESS: 'LISTS_CREATE_SUCCESS',
    CREATE_FAILURE: 'LISTS_CREATE_FAILURE',

    GETALL_REQUEST: 'LISTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'LISTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'LISTS_GETALL_FAILURE',

    DELETE_REQUEST: 'LISTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'LISTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'LISTS_DELETE_FAILURE',

    LIST_SUBSCRIBE_REQUEST: 'LIST_SUBSCRIBE_REQUEST',
    LIST_SUBSCRIBE_SUCCESS: 'LIST_SUBSCRIBE_SUCCESS',
    LIST_SUBSCRIBE_FAILURE: 'LIST_SUBSCRIBE_FAILURE',

    UPDATE_USER_IN_LIST_REQUEST: 'UPDATE_USER_IN_LIST_REQUEST',
    UPDATE_USER_IN_LIST_SUCCESS: 'UPDATE_USER_IN_LIST_SUCCESS',
    UPDATE_USER_IN_LIST_FAILURE: 'UPDATE_USER_IN_LIST_FAILURE',

};

'use strict';

import React, {useCallback, useEffect, useState} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  setChatContentType,
  updateChats,
  handleChatCreated,
  handleChatMemberRemoved,
  handleChatEdited,
  deleteActiveChat
} from '../../../actions';
import {
  CHAT_CONTENT_ACTIVE_OR_NEW as ACTIVE_OR_NEW_CHAT,
  CHAT_CONTENT_CHATS_LISTS as CHATS_LISTS,
  CHAT_CONTENT_CHAT_MODULE as CHAT_MODULE,
  CHAT_CONTENT_SEARCH_MODULE as SEARCH_MODULE,
  CHAT_CONTENT_CREATE_GROUP_CHAT as CREATE_GROUP_CHAT,
} from '../../../constants';
import ChatLists from './chatLists';
import ChatModule from './chat';
import cx from 'classnames';
import s from './chatEngine.module.scss';
import ChatEmptyMessage from './ChatEmptyMessage'

import { SingleChatType, GroupChatType } from '../../../constants/chat.types'

import {Echoer} from '../../../services/echo'
import {UserChatsChannel} from '../../../constants/broadcasting/channels'
import {SingleChatCreated, GroupChatCreated, ChatEditedEvent, ChatMemberRemovedEvent, ChatAddedMessage} from '../../../constants/broadcasting/events'

function ChatEngine(props) {
  const {
    user,
    contentType = ACTIVE_OR_NEW_CHAT,
    setContentType,
    activeChatData = {},
    alert,
    chats,
    updateChats,
    handleChatCreated,
    handleChatMemberRemoved,
    handleChatEdited,
  } = props

  let errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;
  errorMessage = errorMessage === "data not found" ? null : errorMessage;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize)
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);

  const [singleChatsLoading, setSingleChatsLoading] = useState(true);
  const [chatsLoading, setChatsLoading] = useState(true);

  const showChatModule = useCallback(() => { setContentType(CHAT_MODULE); }, []);

  const reportWindowSize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    Echoer.getInstance()
      .private(UserChatsChannel(user.id))
        .listen(SingleChatCreated, onChatCreated)
        .listen(GroupChatCreated, onChatCreated)
        .listen(ChatEditedEvent, onChatEdited)
        .listen(ChatMemberRemovedEvent, onChatMemberRemoved)
        .listen(ChatAddedMessage, onChatEdited)

      return () => Echoer.getInstance().leave(UserChatsChannel(user.id))

  }, [])

  const onChatCreated = ({ chat }) => handleChatCreated(chat)
  const onChatMemberRemoved = ({ chat }) => handleChatMemberRemoved(chat)
  const onChatEdited = ({ chat } ) => handleChatEdited(chat)

  return (
    <div className={s.Chat} id="chat">
      <div className={cx(s.contentPart, "content-part chat-content")}>
        <div  id='spot'className="container">
          <div className={cx(s.chatRow, "row no-gutters")}>
            <div className={cx(
              s.chatColSm3,
              "col-sm-4 col-xl-3 col-xl-3",
              { [`${s.hideChatList}`]: contentType === CHAT_MODULE && windowWidth <= 575.98 && activeChatData.data }
            )}>
              <ChatLists onChatClick={showChatModule} setChatsLoading={setChatsLoading} setSingleChatsLoading={setSingleChatsLoading}/>
            </div>
            <div className={cx(s.chatColSm9, "col-sm-8 col-xl-9 col-xl-9")}>
              {alert.type == 'alert-danger' && errorMessage &&<div className={`alert ${alert.type}`} style={{margin: '25px 15px 0'}}>{errorMessage}</div>}
               {(chats && chats.length === 0 && !activeChatData.data && !chatsLoading && !singleChatsLoading) ? <ChatEmptyMessage onChatClick={showChatModule}/> :
                 contentType === CHAT_MODULE ? (
                   <ChatModule onChatClick={showChatModule} />
               ): chatsLoading && singleChatsLoading ? (
                 <h4>Please choose any chat to start a conversation</h4>
               ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ChatEngine.propTypes = {
  contentType: PropTypes.oneOf([
    ACTIVE_OR_NEW_CHAT,
    CHATS_LISTS,
    SEARCH_MODULE,
    CHAT_MODULE,
    CREATE_GROUP_CHAT,
  ]).isRequired,
  setContentType: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
  const {
    chat: {
      contentType,
      activeChatData,
      chatListData: {
        chats
      }
    },
    alert
  } = state;

  const { user } = state.authentication;

  return { contentType, activeChatData, chats, alert, user };
};

const mapDispatchToProps = (dispatch) => ({
  setContentType: bindActionCreators(setChatContentType, dispatch),
  updateChats: bindActionCreators(updateChats, dispatch),
  handleChatCreated: bindActionCreators(handleChatCreated, dispatch),
  handleChatEdited: bindActionCreators(handleChatEdited, dispatch),
  handleChatMemberRemoved: bindActionCreators(handleChatMemberRemoved, dispatch),
})


export default connect(mapStateToProps, mapDispatchToProps)(ChatEngine);



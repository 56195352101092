import React, {Component} from 'react';
import { connect } from 'react-redux';
import TrendsForYou from '../sparks/TrendsForYou';
import BottomWidget from '../common/BottomWidget';
import UserListActionWidget from '../userElements/UserListActionWidget';
import UserProfileTopSection from '../userElements/UserProfileTopSection';
import UserListWidget from '../userElements/UserListWidget';
import ListMemberSubscriberWidget from '../userElements/ListMemberSubscriberWidget';

import { userActions,alertActions,sparksActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserListAddMemberWidget from "../userElements/UserListAddMemberWidget";

class MembersListSection extends Component {
  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });
  }
  render(){
    const { loggedIn,alert,lists,username,user,profileinfo,l_id,owner_id } = this.props;
    return (
      <div className="edit-profile">
        <div className="content-part list-detail-view">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 left-sidebar">
              <UserListActionWidget username={username} l_id={l_id} />
              </div>
              <div className="col-sm-6 feed-container">
                <div className="twiter-left1 access-mid sett-block list-mem">
                    <h3>List members</h3>
                  {lists.allmembers.length !== 0 ? (
                    <ListMemberSubscriberWidget listData={lists.allmembers} l_id={l_id} owner_id = {owner_id}/>
                    ) :
                    <p>There are no members</p>
                  }
                </div>
              </div>
              <div className="col-sm-3 right-sidebar">
                {lists.listDetail != undefined && lists.listDetail.user_id == user.id ? (
                  <UserListAddMemberWidget username={username} l_id={l_id} />
                ) : ('')
                }
                <BottomWidget/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

MembersListSection.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    alert: state.alert,
    profileinfo: state.profileinfo.data != undefined ? state.profileinfo.data : [],
    lists: state.lists != undefined ? state.lists : [],
    username: ownProps.username,
    l_id: ownProps.l_id,
    owner_id: ownProps.owner_id,
  };
}
export default withRouter(connect(mapStateToProps)(MembersListSection));

import { sparkConstants } from '../constants';

export function sparkcreate(state = {}, action) {
  switch (action.type) {
    case sparkConstants.CREATE_REQUEST:
      return {
        creating: true,
        uploadcomplete: false
      };
    case sparkConstants.CREATE_SUCCESS:
      return {
        creating: false,
        uploadcomplete: true
      };
    case sparkConstants.CREATE_FAILURE:
      return {
        creating: false,
        uploadcomplete: false
      };
    default:
      return state
  }
}

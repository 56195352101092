import {
  ALL_UNREAD_SINGLE_CHATS_REQUEST,
  ALL_UNREAD_SINGLE_CHATS_SUCCESS,
  ALL_UNREAD_SINGLE_CHATS_FAILURE,
  ADDED_UNREAD_SINGLE_CHAT,
  REMOVED_UNREAD_SINGLE_CHAT,
  ADDED_UNREAD_SINGLE_CHAT_MESSAGE,
  READ_UNREAD_SINGLE_CHAT_MESSAGE,
} from "../../constants";
import { addChat, removeChat, addChatMessage } from './helpers';

const initialState = {
  isFetching: false,
  data: [],
  error: false,
};

export function unreadSingleChatMessages(state = initialState, action) {
  switch (action.type) {
    case ALL_UNREAD_SINGLE_CHATS_REQUEST: {
      return {...state, isFetching: true};
    }
    case ALL_UNREAD_SINGLE_CHATS_SUCCESS: {
      const {data} = action;
      return {...state, isFetching: false, error: false, data};
    }
    case ALL_UNREAD_SINGLE_CHATS_FAILURE: {
      return {...state, isFetching: false, error: true};
    }
    case ADDED_UNREAD_SINGLE_CHAT: {
      return addChat(state, action.data);
    }
    case REMOVED_UNREAD_SINGLE_CHAT: {
      return removeChat(state, action.data);
    }
    case ADDED_UNREAD_SINGLE_CHAT_MESSAGE: {
      return addChatMessage(state, action.data);
    }
    case READ_UNREAD_SINGLE_CHAT_MESSAGE: {
      const {data: {chatKey, messageKey}} = action;
      const {data: currData} = state;

      const existingChatIndex = currData.findIndex(({key}) => key === chatKey);
      if (existingChatIndex === -1) {
        return state;
      }

      const existingMessagesKeyIndex = currData[existingChatIndex].unreadMessagesKeys.findIndex(i => i === messageKey);
      if (existingMessagesKeyIndex === -1) {
        return state;
      }

      const newData = [...currData];
      newData[existingChatIndex] = {...currData[existingChatIndex]};
      const currentUnreadMessagesKeys = currData[existingChatIndex].unreadMessagesKeys;
      newData[existingChatIndex].unreadMessagesKeys = currentUnreadMessagesKeys.filter(i => i !== messageKey);

      return {...state, data: newData};
    }
    default:
      return state;
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from '../../../containers/frontend/common/Header';
import UserListDetailSection from '../../../containers/frontend/userProfile/UserListDetailSection';
import NotFoundView from '../NotFoundView';
import LoadingIcon from '../../../containers/frontend/common/LoadingIcon';

import { alertActions,listsActions } from '../../../actions';

class ListDetailView extends Component {
  constructor(props){
    super(props);
    $('html, body').css('overflow', '');
  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.l_id !== this.props.match.params.l_id) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { dispatch } = this.props;

    const { user, username, l_id } = this.props;
    if (username && l_id && user) {
        let rawData = {
          username: username,
          user_id: user.id,
          list_id: l_id
        }
        dispatch(listsActions.getAll(rawData));
    }
  }

  render() {
    const { lists,user, notfoundstatus } = this.props;

    if (notfoundstatus) {
      return (
        <>
          <Header/>
          <NotFoundView />
        </>
      )
    }

    return (
      <>
        {lists.listDetail != undefined && lists.loading == false && (lists.listDetail.isPrivate != 1 || lists.listDetail.id == user.id) ? (
          <div id="ListDetailView" className="ListDetailView">
            <Header/>
            <UserListDetailSection username={this.props.match.params.profilename} l_id={this.props.match.params.l_id}/>
          </div>
        ) : ( (lists.listDetail != undefined && lists.listDetail.isPrivate == 1 && lists.loading == false) || (lists.error != undefined) ? (
                  <NotFoundView />
                ) : (
              <>
                <Header/>
                <LoadingIcon pTop='pTop100'/>
              </>
            )
          )
        }
     </>

    );
  }
}
function mapStateToProps(state,ownProps){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    username: ownProps.match.params.profilename,
    notfoundstatus: state.profileinfo.notfoundstatus != undefined ? state.profileinfo.notfoundstatus : false,
    l_id: ownProps.match.params.l_id,
    lists: state.lists
  };
}
export default connect(mapStateToProps)(ListDetailView);

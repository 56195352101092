import React, {Component} from 'react';
import { connect } from 'react-redux';
import LightBox from '../../../containers/frontend/common/LightBox';


class ImageWidget extends Component {
  constructor(props){
    super(props);

    this.state = {
      photos: [],
    };

    let dataImage = this.props.dataImage ? this.props.dataImage : [];
    let data = [];
    dataImage.map((raw,i) =>
      {
        if(raw.file != undefined)
        {
          data.push({
            src : raw.file,
            width : 4,
            height : 2,
          });
        }
    });


    setTimeout(
      function(){
        this.setState({
          photos: data,
        });
      }
      .bind(this),
      1000);
  }

  render(){
    let {photos} =this.state;
    let {dataImage} =this.props;
    return (
      <div className={"tweet-right-img "+(photos.length > 0 ? (photos.length == 1 ? 'single-image' : 'multiple-image') : 'no-image')} >
        {photos.length > 0 ? (
            <LightBox dataImage={photos.length > 0 ? photos : []}/>
          ) : ('')
        }
      </div>
    )
  }
}


function mapStateToProps(state,ownProps){
  return {
    alert: state.alert,
    user: state.authentication.user,
    dataImage: ownProps.dataImage != undefined ? ownProps.dataImage : []
  };
}
export default connect(mapStateToProps)(ImageWidget);

import React, { useState, useCallback, useEffect, useRef } from 'react';
import firebase from 'firebase';


const FIREBASE_IS_ALREADY_INITIALIZED = 'FIREBASE_IS_ALREADY_INITIALIZED';
const PHONE_NUMBER_SIGN_IN_ERROR = 'PHONE_NUMBER_SIGN_IN_ERROR';
const PHONE_NUMBER_IS_OCCUPIED = 'PHONE_NUMBER_IS_OCCUPIED';
const VERIFICATION_SERVICE_IS_NOT_INIT = 'VERIFICATION_SERVICE_IS_NOT_INIT';
const SENDING_SMS_ERROR = 'SENDING_SMS_ERROR';
const PHONE_NUMBER_IS_INVALID = 'PHONE_NUMBER_IS_INVALID';
const EMAIL_PASS_ARE_REQUIRED = 'EMAIL_PASS_ARE_REQUIRED';
const EMAIL_IS_OCCUPIED = 'EMAIL_IS_OCCUPIED';
const INVALID_PHONE_VERIFICATION_CODE = 'INVALID_PHONE_VERIFICATION_CODE';
const EMAIL_VERIFICATION_TOO_MANY_REQUESTS = 'EMAIL_VERIFICATION_TOO_MANY_REQUESTS';
const PHONE_VERIFICATION_TOO_MANY_REQUESTS = 'PHONE_VERIFICATION_TOO_MANY_REQUESTS';

export const ERRORS_CODES = Object.freeze({
  FIREBASE_IS_ALREADY_INITIALIZED,
  PHONE_NUMBER_IS_OCCUPIED,
  VERIFICATION_SERVICE_IS_NOT_INIT,
  SENDING_SMS_ERROR,
  PHONE_NUMBER_IS_INVALID,
  EMAIL_PASS_ARE_REQUIRED,
  EMAIL_IS_OCCUPIED,
  INVALID_PHONE_VERIFICATION_CODE,
  EMAIL_VERIFICATION_TOO_MANY_REQUESTS,
  PHONE_VERIFICATION_TOO_MANY_REQUESTS,
});

class CustomError extends Error {
  constructor(message, code, originError) {
    super(message);
    this.name = this.constructor.name;
    this.code = code;
  }
}

//Error Codes
//https://firebase.google.com/docs/reference/js/firebase.auth.PhoneAuthProvider

//recaptcha-container  - specifying the ID of the container for recaptcha (not sure if it is necessary for invisible recaptcha)

export default function useFirebaseVerification({ checkPhoneOccupied, checkEmailOccupied, recaptchaContainerId }) {
  const recaptchaVerifierRef = useRef(null);
  const firebaseAuthRef = useRef(null);

  const signInWithEmailAndPassword = useCallback((email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }, []);

  const initFirebase = useCallback(async (email, password) => {
    if (firebaseAuthRef.current) {
      throw new CustomError('firebase is already initialized', FIREBASE_IS_ALREADY_INITIALIZED);
    }
    if (!recaptchaContainerId) {
      const message = 'parameter recaptchaContainerId is required';
      throw new Error(message);
    }

    firebase.auth().languageCode = 'en';

    const recaptchaVerifierParams = {
      size: 'invisible',
      callback: function (response) {
        return false;
      }
    };
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(recaptchaContainerId, recaptchaVerifierParams);

    recaptchaVerifierRef.current = recaptchaVerifier;

    if (email && password) {
      try {
        await signInWithEmailAndPassword(email, password);
      } catch (e) {
        console.error('signInWithEmailAndPassword error', e);
      }
    }

    firebaseAuthRef.current = firebase.auth();
  }, [recaptchaContainerId, signInWithEmailAndPassword]);

  const getFirebaseAuth = useCallback(() => {
    const firebaseAuth = firebaseAuthRef.current;
    if (!firebaseAuth) {
      const message = 'Failed to initialize verification service provider'
      throw new CustomError(message, VERIFICATION_SERVICE_IS_NOT_INIT);
    }
    return firebaseAuth;
  }, []);



  const initPhoneVerification = useCallback(async (phoneNumber) => {
    if (!phoneNumber) {
      throw new CustomError('Phone number is invalid', PHONE_NUMBER_IS_INVALID);
    }

    const firebaseAuth = getFirebaseAuth();
    const recaptchaVerifier = recaptchaVerifierRef.current;

    const status = await checkPhoneOccupied(phoneNumber);

    if (status) {
      throw new CustomError('Phone number is already occupied', PHONE_NUMBER_IS_OCCUPIED);
    }

    let confirmationResult;
    try {
      confirmationResult = await firebaseAuth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier);
    } catch (e) {
      if (e.code === 'auth/invalid-phone-number') {
        console.error(`firebaseAuth.signInWithPhoneNumber: invalid phone number: "${phoneNumber}", error: `, e);
        throw new CustomError('sign in with phone number error: invalid phone number', PHONE_NUMBER_IS_INVALID);
      } else if (e.code === 'auth/too-many-requests') {
        throw new CustomError('sign in with phone number error: too many requests', PHONE_VERIFICATION_TOO_MANY_REQUESTS, e);
      } else {
        console.error(`firebaseAuth.signInWithPhoneNumber: phoneNumber: "${phoneNumber}", error: `, e);
        throw new CustomError('sign in with phone number error', PHONE_NUMBER_SIGN_IN_ERROR, e);
      }
    }

    const verifyPhoneNumber = async (smsCode) => {
      try {
        return await confirmationResult.confirm(smsCode);
      } catch (e) {
        if (e.code === 'auth/invalid-verification-code') {
          throw new CustomError('SMS verification code is invalid', INVALID_PHONE_VERIFICATION_CODE, e);
        } else {
          throw new CustomError('Sending sms code error', SENDING_SMS_ERROR, e);
        }
      }
    };

    const getAuthCredential = (smsCode) => (
      firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, smsCode)
    );

    //const credential = getAuthCredential(smsCode);
    //then
    //signInWithCredential(credential);
     
    return {verifyPhoneNumber, getAuthCredential};
  }, [getFirebaseAuth]);

  const verifyEmail = useCallback(async ({email, password, afterVerificationUrl, getAfterVerificationUrl}) => {
    if (!email || !password) {
      throw new CustomError('Password and  email are required', EMAIL_PASS_ARE_REQUIRED);
    }

    const firebaseAuth = getFirebaseAuth();

    const status = await checkEmailOccupied(email);

    if (status) {
      throw new CustomError('Email is already taken', EMAIL_IS_OCCUPIED);
    }

    async function logInFirebase(numOfTries = 3) {
      console.log('logInFirebase numOfTries', numOfTries)
      try {
        return await firebaseAuth.signInWithEmailAndPassword(email, password);
      } catch (e) {
        console.log('signInWithEmailAndPassword error, e.code: ', e.code)
        if (e.code === 'auth/user-not-found') {
          // try to create a user

          try {
            await firebase.auth().createUserWithEmailAndPassword(email, password);
            if (numOfTries > 0) {
              return await logInFirebase(numOfTries - 1);
            }
          } catch (e) {
            console.log('createUserWithEmailAndPassword error, e.code: ', e.code)
            if (numOfTries > 0) {
              return await logInFirebase(numOfTries - 1);
            } else {
              throw e;
            }
          }
        }
      }
    }

    try {
      await logInFirebase();

      let url = afterVerificationUrl;
      if (typeof getAfterVerificationUrl === 'function') {
        const currentUser = firebaseAuth.currentUser;
        url = getAfterVerificationUrl(currentUser);
      }

      await firebaseAuth.currentUser.sendEmailVerification({url});
    } catch (e) {
      if (e.code === 'auth/too-many-requests') {
        throw new CustomError('Email verification error: too many requests', EMAIL_VERIFICATION_TOO_MANY_REQUESTS, e);
      } else {
        throw e;
      }
    }
  }, []);


  const signInWithCredential = useCallback((credential) => {
    const firebaseAuth = getFirebaseAuth();
    return firebaseAuth.signInWithCredential(credential);
  }, [getFirebaseAuth]);

  const getCurrentAuthUser = useCallback(() => {
    const firebaseAuth = getFirebaseAuth();
    return firebaseAuth.currentUser;//.toJSON()
  }, [getFirebaseAuth]);

  return {initPhoneVerification, signInWithCredential, getCurrentAuthUser, verifyEmail, initFirebase, signInWithEmailAndPassword};
}

import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const isAuthenticatedUser = (userData) => !!userData;
const isUserRegistrationFinished = (userData) => !!userData && userData.is_user_registered;

function PublicRoute({ component: Component, ...restProps }) {
  const { userData: { loading: userDataLoading, data: userData } } = restProps;

  return (
    <Route
      {...restProps}
      render={props => {
        if (userDataLoading) return null;

        const isAllowed = !isAuthenticatedUser(userData);

        let redirectPath = '';
        if (!isAllowed) {
          redirectPath = isUserRegistrationFinished(userData) ? '/' : '/registration';
        }

        return (
          isAllowed ? (
            <Component {...props} />
          ) : (
            <Redirect to={{pathname: redirectPath, state: { from: props.location }}} />
          )
        )
      }}
    />
  );
}

const mapStateToProps = ({ userData }) => ({userData});

export default connect(mapStateToProps)(PublicRoute);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const isUserRegistrationFinished = (userData) => !!userData && userData.is_user_registered;
const isAuthenticatedUser = (userData) => !!userData;

function PrivateRoute({ component: Component, ...restProps }) {
  const { userData: { loading: userDataLoading, data: userData } } = restProps;
  return (
    <Route
      {...restProps}
      render={props => {
        const loading = userDataLoading && (!userData || !isUserRegistrationFinished(userData));

        if (loading) return null;

        const isAllowed = isUserRegistrationFinished(userData);

        let redirectPath = '';
        if (!isAllowed) {
          redirectPath = isAuthenticatedUser(userData) ? '/registration' : '/login';
        }

        return (
          isAllowed ? (
            <Component {...props} />
          ) : (
            <Redirect to={{pathname: redirectPath, state: { from: props.location }}} />
          )
        )
      }}
    />
  );
}

const mapStateToProps = ({ userData }) => ({userData});

export default connect(mapStateToProps)(PrivateRoute);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';
import EditProfileImage from './EditProfileImage';
import EditBannerImage from './EditBannerImage';
import LoadingIcon from '../common/LoadingIcon';
import { toast } from 'react-toastify';
import msgs from "../../../messages/en/main"
import { userActions, alertActions } from '../../../actions';
import * as moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const userNameFieldMaxLength = 30;

class EditProfileSection extends Component {

  constructor(props) {
    super(props);

    const userDob = this.props.user.dob ? (this.props.user.dob) : false;

    this.state = {
      user: {
        user_id: this.props.user.id,
        name: this.props.user.name,
        firstname: this.props.user.firstname,
        lastname: this.props.user.lastname,
        // user_bio: this.props.user.user_bio,
        location: this.props.user.address,
        // address_2: this.props.user.address_2,
        // city: this.props.user.city,
        // state: this.props.user.state,
        // zipcode: this.props.user.zipcode,
        // website_url: this.props.user.website_url,
        dob: userDob ? new Date(userDob) : '',
        day: '',
        year: '',
        month: '',
      },
      file: '',
      PreviewUrl: '',
      filedata: '',
      submitted: false,
      canSave: false,
    };

  }

  handleChangeDate = value => {
    const date1 = moment(value, "YYYY-MM-DD");
    const { user } = this.state;
    value = value || '';

    if (value === '' || date1.isValid()) {
      const userData = {
        ...user,
        dob: value ? new Date(value) : false,
      };

      this.setState({
        user: userData
      });

      this.ButtonActiveOrNot(userData);
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { user } = this.state;
    const userData = {
      ...user,
      [name]: value.trim()
    };

    this.setState({
      user: userData
    });

    this.ButtonActiveOrNot(userData);
  }

  ButtonActiveOrNot = (userData) => {
    const user = userData;

    const userDob = this.props.user.dob ? new Date(this.props.user.dob) : '';

    if (user.firstname !== this.props.user.firstname
      || user.lastname !== this.props.user.lastname
      // || user.user_bio !== this.props.user.user_bio
      || user.dob.toString() !== userDob.toString()
      || user.location !== this.props.user.address
      // || user.address_2 !== this.props.user.address_2
      // || user.city !== this.props.user.city
      // || user.state !== this.props.user.state
      // || user.zipcode !== this.props.user.zipcode
      // || user.website_url !== this.props.user.website_url
    ) {
      this.setState({ canSave: true });
    } else {
      this.setState({ canSave: false });
    }

  }

  handleSubmit = (event) => {
    const { user } = this.state;
    const { dispatch } = this.props;

    event.preventDefault();
    this.setState({ submitted: true });

    if (user.dob) {
      user.dob = moment(user.dob, "YYYY-MM-DD").format("YYYY-MM-DD");
    }

    if (user.firstname && user.lastname) {
      dispatch(userActions.editProfile({
        ...user,
        firstname: user.firstname.replace(/\s+/g, " "),
        lastname: user.lastname.replace(/\s+/g, " "),
      }));

      this.setState({
        canSave: false
      });

    } else {
      toast.error(msgs.FILL_CHECK_INFORMATION);
    }
  }

  render() {
    const { user, authentication } = this.props;
    const { canSave, submitted } = this.state;

    const userD = this.state.user;
    if(authentication.loading) {
      return (
        <LoadingIcon />
      );
    }

    return (
      <div className="content-part">
        <div>
          <EditBannerImage />
          <div className="edit-pro-nav">
            <div className="container">
              <EditProfileImage />
              <div className="edit-top-btn">
                {<Link to={"/" + user.username} className="button">Cancel</Link>}
                {canSave ? <button className="button" onClick={this.handleSubmit}>Save Changes</button> : <button className="button" disabled={true}>Save Changes</button>}
              </div>
            </div>
          </div>
        </div>
        <div className="edit-profile">
          <div className="edit-pro-btm">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 offset-3">
                  <div className="edit-pro-inn">
                    <form name="edit-profile-form">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">FIRST NAME</label>
                        <input 
                          name="firstname"
                          type="text"
                          className={"form-control" + (!userD.firstname && submitted ? ' has-error' : '') }
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="* First Name"
                          maxLength={userNameFieldMaxLength}
                          defaultValue={user.firstname ? user.firstname : ''}
                          onChange={this.handleChange}
                        />
                        {submitted && !userD.firstname && <p className="inputError"> {msgs.FIRST_NAME_REQUIRED}</p>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">LAST NAME</label>
                        <input
                          name="lastname"
                          type="text"
                          className={"form-control" + (!userD.lastname && submitted ? ' has-error' : '') }
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="* Last Name"
                          maxLength={userNameFieldMaxLength}
                          defaultValue={!!user.lastname ? user.lastname : ''}
                          onChange={this.handleChange} 
                        />
                        {submitted && !userD.lastname && <p className="inputError">{msgs.LAST_NAME_REQUIRED}</p>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">BIRTHDAY</label>

                        <DatePicker
                          name="dob" className="form-control" id="exampleInputEmail1"
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          maxDate={new Date()}
                          autoComplete="off"
                          input={false}
                          selected={this.state.user.dob ? new Date(this.state.user.dob) : ''}
                          onChange={this.handleChangeDate}
                          placeholderText="DOB (YYYY-MM-DD)"
                        />

                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">LOCATION</label>
                        <input name="location" type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="LOCATION" defaultValue={!!user.address ? user.address : ''} onChange={this.handleChange} />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};



function mapStateToProps(state, ownProps) {

  const { user } = state.authentication;
  const { authentication } = state;
  const { alert } = state;
  return {
    authentication, user, alert
  };
}

export default connect(mapStateToProps)(EditProfileSection);

import React, {Component} from 'react';
import { connect, batch } from 'react-redux';
import cs from 'classnames';
import {userActions, alertActions, setActiveChat, showChatModule} from '../../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  user as userPropType,
  singleChatData as activeSingleChatDataPropType,
  groupChatData as activeGroupChatDataPropType,
} from '../../../../misc/propTypes';
import { bindActionCreators } from 'redux';

import s from './chatSearch.module.scss';

class ChatSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchval: '',
    };

    const { dispatch, history, alert } = this.props;

    const unlisten = history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });
    this.onWillUnmountFns = [unlisten];
  }

  componentWillUnmount(){
    this.onWillUnmountFns.forEach(f => f());
  }

  filterSingleChatList = (chatLists, value) => {
    return chatLists.filter( (chat) => {
      if (
        chat.name.toLowerCase().includes(value.toLowerCase())
        // || chat.members.find(member => member.userName.toLowerCase().includes(value)
        //   || member.fullName.toLowerCase().includes(value))
      ) {
        chat.notShow = false;
      } else {
        chat.notShow = true;
      }
      return chat;
    });
  }

  filterGroupChatList = (chatLists, value) => {
    return chatLists.filter( (chat) => {
      if (chat.name.toLowerCase().includes(value.toLowerCase())) {
        chat.notShow = false;
      } else {
        chat.notShow = true;
      }
      return chat;
    });
  }

  searchFriendsFromFirebase = (value) => {
    const { chatType, singleChatLists, groupChatLists, setListFoundChat } = this.props;
    let filteredSingleChatList = singleChatLists;
    let filteredGroupChatList = groupChatLists;

    this.setState({searchval: value});

    switch(chatType) {
      case 'People':
        filteredSingleChatList = this.filterSingleChatList(singleChatLists, value);
        break;
      case 'Group':
        filteredGroupChatList = this.filterGroupChatList(groupChatLists, value);
        break;
      case 'All':
        filteredSingleChatList = this.filterSingleChatList(singleChatLists, value);
        filteredGroupChatList = this.filterGroupChatList(groupChatLists, value);
        break;
      default:
        break;
    }

    return setListFoundChat({
      foundSingleChatList: filteredSingleChatList,
      foundGroupChatList: filteredGroupChatList,
    });

    // this.setState({
    //     searchval: value,
    // });
    //
    // const { dispatch, user, onGetChatLists, friends } = this.props;
    //
    // if(user.id && value){
    //   let rawUser = {
    //       user_id: user.id,
    //       search: value,
    //   };
    //   dispatch(userActions.searchUserChat(rawUser));
    // }
    //
    // const foundChats = chatList.filter((i) => {
    //   if (chatType === 'People') {
    //     if (~(i.name.toLowerCase().indexOf(value)) || ~(i.atUsername.toLowerCase().indexOf(value))) {
    //       return i;
    //     }
    //   }
    //
    //   if (chatType === 'Group') {
    //     if (~(i.groupName.toLowerCase().indexOf(value))) {
    //       return i;
    //     }
    //   }
    // }, value, chatType)
    //
    // onGetChatLists(foundChats);
  };

  


  render() {
    const {searchval} = this.state;
    const isSearchVal = searchval !== '';

    return (
      <>
        <div className={cs(s.inputContainer, 'col-sm-12')}>
          <i className={cs("fas fa-search", {[`${s.searchIcon}`]: true})} />
          <input
            value={this.state.searchval}
            type="text"
            className={s.searchInput}
            name="searchval"
            onChange={
              (event) => this.searchFriendsFromFirebase(event.target.value)
            }
            placeholder="Search chat name"
            // defaultValue={this.state.searchval}
          />
          <div onClick={() => this.searchFriendsFromFirebase('')} className={`${s.closeButtonContainer} ${isSearchVal && 'visible'}`}>
            <div className={s.closeButtonStickOne}></div>
            <div className={s.closeButtonStickTwo}></div>
          </div>
        </div>
      </>
    );
  }
}

ChatSearch.propTypes = {
  history: PropTypes.object.isRequired,
  setActiveChat: PropTypes.func.isRequired,
  user: userPropType.isRequired,
  onGetChatLists: PropTypes.func,
  list: PropTypes.arrayOf(activeSingleChatDataPropType),
  chatList: PropTypes.oneOfType([activeSingleChatDataPropType, activeGroupChatDataPropType]),
  chatType: PropTypes.string.isRequired,
};

function mapStateToProps(state){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    alert: state.alert,
    friends: state.chatusersearch.data != undefined ? state.chatusersearch.data : [],
  };
}

const mapDispatchToProps = dispatch => {
  const boundActionCreators = bindActionCreators({setActiveChat, showChatModule}, dispatch);
  return {
    dispatch,
    ...boundActionCreators,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatSearch));

import React, {Component} from 'react';
import { connect } from 'react-redux';
import UserSideBarSection from '../common/UserSideBarSection';
import { toast } from 'react-toastify';
import { userActions,alertActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class LoginIpHistorySection extends Component {
  constructor(props){
    super(props);

    const { dispatch,user, history } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
        dispatch(alertActions.clear());
    });

    if(user){
      dispatch(userActions.userSettings(user.id));
    }

  }

  render(){
    const { user,usersettings } = this.props;
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    return (
      <div className="content-part sports-main">
        <div className="container">
          <div className="row">
            <UserSideBarSection/>
              <div className="col-sm-6">
                <div className="twiter-left1 access-mid cents-data">
                  <h3>Account login history</h3>
                  <div className="cents-data-mid bor-botm">
                    <div className="cents-data-account">
                      {usersettings.ip_history != undefined && usersettings.ip_history.length > 0 ? (
                        usersettings.ip_history.map((raw,index) =>
                          <div className="data-acco1">
                            <div className="row">
                              <div className="col-sm-5">
                                <h4>Location {index+1}</h4>
                              </div>
                              <div className="col-sm-7">
                                <p>{raw.ip} ( {raw.updated} )</p>
                              </div>
                            </div>
                          </div>
                          )
                          ) : ('N/A')
                        }
                    </div>
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
    );
  }
};

LoginIpHistorySection.propTypes = {
  history: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        usersettings: state.usersettings.data != undefined ? state.usersettings.data.sparkData : '',
    };
}

export default withRouter(connect(mapStateToProps)(LoginIpHistorySection));

import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions, urlActions, } from './';
import { updateUserData, } from './userData';
import { toast } from 'react-toastify';
import msgs from "../messages/en/main"
import { batch } from 'react-redux';
import get from 'lodash.get';
import firebase from 'firebase';
import { push } from 'connected-react-router';
import { getCurrentLocation } from './helpers';

export const userActions = {
  login,
  logout,
  getAll,
  editProfile,
  editImage,
  editImageNew,
  getBlockedUser,
  unblock,
  muteUnmuteAccount,
  accountSettings,
  deactivateAccount,
  getNotifySetting,
  setNotifySetting,
  setPrivacy,
  userSettings,
  getAllNotification,
  filterNotification,
  searchProcess,
  getSearchData,
  searchUserChat,
  clearSearchResults,
  getPagesData,
  getPlan,
  checkUser,
  PlanRenew,
  getHeaderResponse,
  eraseSearchResults,
  setReferralModalVisability,
  setCongratsReferralModalVisability,
};

function logUserInFireBase(user,  dispatch, numOfTries = 3) {
      firebase.auth().signInWithEmailAndPassword(user.email, user.password)
        .then(() => dispatch({type: userConstants.USER_SAVE_FIREBASE_AUTH, user: firebase.auth().currentUser}))
        .catch(error => {
          if (error.code === "auth/user-not-found") {
            // try to create user again
            firebase.auth().createUserWithEmailAndPassword(user.email, user.password)
              .then(() => {
                if (numOfTries > 0) {
                  logUserInFireBase(user, dispatch, numOfTries - 1);
                }
              })
              .catch(() => {
                if (numOfTries > 0) {
                  logUserInFireBase(user, dispatch, numOfTries - 1);
                } else {
                  console.log('error');
                  toast.error("Something went wrong with registration. Please contact info@2cents.com");
                }
              })
          }
        });
}

function login(userData, prevUrl = false){
    return dispatch => {
        dispatch(request({ userData }));
        userService.login(userData)
            .then(
                user => {
                    const isUserRegistered = get(user, 'response.is_user_registered');
                    const letIntoApp = () => {
                      let data = {
                        user_id: user.response.id,
                        otherId: user.response.id,
                        type: 'single',
                        status: true,
                        msg: "fetching info in process",
                      };
                      const msg = user.msg;
                      localStorage.setItem('user', JSON.stringify(user.response));
                      localStorage.setItem('chatOpen', JSON.stringify(data));

                      batch(() => {
                        dispatch(success(user.response));
                        dispatch(alertActions.success(msg.toString()));
                        dispatch(updateUserData(user.response));
                      });

                      if(prevUrl) {
                        urlActions.savePreloginUrl('/');
                        dispatch(push(prevUrl));
                      } else {
                        dispatch(push('/'));
                      }
                    };

                    if (user.status && isUserRegistered) {
                        // Try to login user 3 times

                        // get user's email from server's response
                        // because we may have username in userData.email field
                        logUserInFireBase({email: user.response.email, password: userData.password}, dispatch, 3);
                    }

                    if(user.response && user.status && user.response.expire_plan_sts == 1) {
                      if(user.response.email_verify == '0' || user.response.phone_verify == '0') {
                        dispatch(push('/verification/'+user.response.encode_phone+'/'+user.response.encode_email+'/'+btoa(user.response.id)));
                      } else {
                        letIntoApp();
                      }
                    } else {
                        if(user.status && (user.response.plan_status === 'expired'
                         || user.response.expire_plan_sts == 0 
                         || user.response.plan_expiry === null
                         )) {
                          const msg = user.msg || 'Success';
                          dispatch(failure());
                          // dispatch(alertActions.success(msg.toString()));
                          toast.error(msgs.PLAN_IS_EXPIRED);
                          dispatch(push({
                              pathname: '/renew-plan/'+btoa(user.response.id)+'/'+btoa(user.response.plan_id),
                              state: {
                                id: user.response.id,
                                plan_id: user.response.plan_id,
                              }
                            }
                          ));
                        } else if(user.response && !user.response.expire_plan_sts){
                          letIntoApp();
                        } 
                      }
                      if(!user.status) {
                        console.log("error", user)
                        const msg = user.msg || 'Error';
                        dispatch(failure(msg.toString()));
                        dispatch(alertActions.error(msg.toString()));
                      }
                },
            )
            .catch(error => {
              const message = error.message === 'Failed to fetch' ? 'No internet connection.' : error.toString();
              batch(() => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(message));
              });
            });
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getAll(user) {
    return dispatch => {
        dispatch(request());
        userService.getAll(user)
            .then(
                homeData => {
                  const status = homeData.status;
                  const msg = homeData.msg;
                  if(status){
                    dispatch(success(homeData.allpost))
                    dispatch(alertActions.success(msg.toString()));
                  }
                  else {
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// edit profile
function editProfile(user) {
  return dispatch => {
      dispatch(request(user));
      //console.log(user);
      userService.editProfile(user)
        .then(
            user => {
                const status = user.status;
                const msg = user.msg;
                if(status){
                  localStorage.setItem('user', JSON.stringify(user.userdata));
                  dispatch(success(user.userdata));
                  dispatch(alertActions.success(msg.toString()));
                  if(user.userdata && user.userdata.username) {
                    dispatch(push(`/${user.userdata.username}`));
                  }

                  toast.success(msg.toString());
                }
                else {
                  dispatch(failure(msg.toString()));
                  toast.error(msg.toString());
                  dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
  };

  function request(user) { return { type: userConstants.EDIT_PROFILE_REQUEST, user } }
  function success(user) { return { type: userConstants.EDIT_PROFILE_SUCCESS, user } }
  function failure(error) { return { type: userConstants.EDIT_PROFILE_FAILURE, error } }
}

// edit image
function editImage(user,type) {
  return dispatch => {
      dispatch(request());
      userService.editImage(user,type)
        .then(
            user => {
                const status = user.status;
                const msg = user.msg;
                if(status){
                  localStorage.setItem('user', JSON.stringify(user.userdata));
                  dispatch(success(user.userdata));
                  dispatch(alertActions.success(msg.toString()));
                }
                else {
                  dispatch(failure(msg.toString()));
                  dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
  };

  function request() { return { type: userConstants.EDIT_IMAGE_REQUEST } }
  function success(user) { return { type: userConstants.EDIT_IMAGE_SUCCESS, user } }
  function failure(error) { return { type: userConstants.EDIT_IMAGE_FAILURE, error } }
}

// edit image
function editImageNew(rawUser,type) {
  return dispatch => {
      dispatch(request(rawUser));
      // console.log(rawUser);
      userService.editImageNew(rawUser,type)
        .then(
            user => {
                const status = user.status;
                const msg = user.msg;
                if(status){
                  localStorage.setItem('user', JSON.stringify(user.userdata));
                  dispatch(success(user.userdata));
                  dispatch(alertActions.success(msg.toString()));
                  toast.success(msg.toString());
                  // window.location.reload();
                  //dispatch(push('/'+rawUser.username));
                }
                else {
                  dispatch(failure(msg.toString()));
                  dispatch(alertActions.error(msg.toString()));
                  toast.error(msgs.SOMETHING_WENT_WRONG);
                  //dispatch(push('/'+rawUser.username));
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                dispatch(push('/'+rawUser.username));
            }
        );
  };

  function request(user) { return { type: userConstants.EDIT_IMAGE_REQUEST, user } }
  function success(user) { return { type: userConstants.EDIT_IMAGE_SUCCESS, user } }
  function failure(error) { return { type: userConstants.EDIT_IMAGE_FAILURE, error } }
}

function getBlockedUser(user) {
    return dispatch => {
        dispatch(request());
        userService.getBlockedUser(user)
            .then(
                blockedData => {
                  const status = blockedData.status;
                  const msg = blockedData.msg;
                  if(status){
                    dispatch(success(blockedData))
                    dispatch(alertActions.success(msg.toString()));
                  }
                  else {
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: userConstants.GETALL_BLOCKED_REQUEST } }
    function success(data) { return { type: userConstants.GETALL_BLOCKED_SUCCESS, data } }
    function failure(error) { return { type: userConstants.GETALL_BLOCKED_FAILURE, error } }
}

function unblock(blockData) {
    return dispatch => {
        dispatch(request(blockData));
        userService.unblock(blockData)
          .then(
              blockedData => {
                const status = blockedData.status;
                const msg = blockedData.msg;
                if(status){
                  dispatch(success(blockData))
                  dispatch(getBlockedUser(blockData.user_id));
                  dispatch(alertActions.success(msg.toString()));
                  toast.success(msg.toString());
                }
                else {
                  dispatch(failure(msg.toString()));
                  dispatch(alertActions.error(msg.toString()));
                  toast.success(msg.toString());
                }
              },
              error => {
                dispatch(failure(error.toString()))
              }
          );
    };

    function request(blockData) { return { type: userConstants.UNBLOCK_REQUEST, blockData } }
    function success(blockData) { return { type: userConstants.UNBLOCK_SUCCESS, blockData } }
    function failure(error) { return { type: userConstants.UNBLOCK_FAILURE, error } }
}

function muteUnmuteAccount(unmuteData) {
    return dispatch => {
        dispatch(request(unmuteData));
        userService.muteUnmuteAccount(unmuteData)
          .then(
              data => {
                const status = data.status;
                const msg = data.msg;
                if(status){
                  dispatch(success(data))
                  dispatch(getBlockedUser(unmuteData.user_id));
                  dispatch(alertActions.success(msg.toString()));
                  toast.success(msg.toString());
                }
                else {
                  dispatch(failure(msg.toString()));
                  dispatch(alertActions.error(msg.toString()));
                  toast.success(msg.toString());
                }
              },
              error => {
                dispatch(failure(error.toString()))
              }
          );
    };

    function request(unmuteData) { return { type: userConstants.UNMUTE_REQUEST, unmuteData } }
    function success(unmuteData) { return { type: userConstants.UNMUTE_SUCCESS, unmuteData } }
    function failure(error) { return { type: userConstants.UNMUTE_FAILURE, error } }
}

// account settings
function accountSettings(userRequest, clearFields, onPasswordCheckFail) {
  return dispatch => {
      dispatch(request(userRequest));
      userService.accountSettings(userRequest)
        .then(
            user => {
                const status = user.status;
                const msg = user.msg;
                if(status) {
                  localStorage.setItem('user', JSON.stringify(user.userdata));
                  dispatch(success(user.userdata));

                  if (clearFields && typeof clearFields === 'function') {
                    clearFields();
                  }

                  toast.success(msg.toString());
                }
                else {
                  dispatch(failure(msg.toString()));
                  if (msg === "Old password does not match.") {
                    onPasswordCheckFail();
                  }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        )
  };

  function request(user) { return { type: userConstants.EDIT_SETTINGS_REQUEST, user } }
  function success(user) { return { type: userConstants.EDIT_SETTINGS_SUCCESS, user } }
  function failure(error) { return { type: userConstants.EDIT_SETTINGS_FAILURE, error } }
}

// deactivate Account
function deactivateAccount(data) {
  return dispatch => {
      dispatch(request(data));
      userService.deactivateAccount(data)
        .then(
            user => {
                const status = user.status;
                const msg = user.msg;

                if(status){
                  userService.logout(status);
                  batch(() => {
                    dispatch(push('/login'));
                    dispatch(success());
                  });
                } else {
                  dispatch(failure(msg.toString()));
                  toast.error(msg.toString());
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
  };

  function request(user) { return { type: userConstants.DEACTIVATE_USER_REQUEST, user } }
  function success() { return { type: userConstants.DEACTIVATE_USER_SUCCESS } }
  function failure(error) { return { type: userConstants.DEACTIVATE_USER_FAILURE, error } }
}

//getNotifySetting
function getNotifySetting(user) {
    return dispatch => {
        dispatch(request());
        userService.getNotifySetting(user)
            .then(
                data => {
                  const status = data.status;
                  const msg = data.msg;
                  if(status){
                    dispatch(success(data.notify_settings))
                    dispatch(alertActions.success(msg.toString()));
                  }
                  else {
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                  }
                },
                error => {
                  dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: userConstants.GET_SETTINGS_REQUEST } }
    function success(users) { return { type: userConstants.GET_SETTINGS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_SETTINGS_FAILURE, error } }
}

//setNotifySetting
function setNotifySetting(user) {
  return dispatch => {
      dispatch(request(user));
      userService.setNotifySetting(user)
        .then(
            user => {
                const status = user.status;
                const msg = user.msg;
                if(status){
                  dispatch(success(user.response));
                  toast.success(msg.toString());
                }
                else {
                  dispatch(failure(msg.toString()));
                  toast.error(msg.toString());
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
  };

  function request(user) { return { type: userConstants.SET_SETTINGS_REQUEST, user } }
  function success(user) { return { type: userConstants.SET_SETTINGS_SUCCESS, user } }
  function failure(error) { return { type: userConstants.SET_SETTINGS_FAILURE, error } }
}

//setPrivacy
function setPrivacy(rawUser) {
  return dispatch => {
      dispatch(request(rawUser));
      userService.setPrivacy(rawUser)
        .then(
            user => {
                const status = user.status;
                const msg = user.msg;
                if(status){
                  dispatch(success(user));
                  toast.success(msg.toString());
                }
                else {
                  dispatch(failure(msg.toString()));
                  toast.error(msg.toString());
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
  };

  function request(user) { return { type: userConstants.SET_PRIVACY_REQUEST, user } }
  function success(user) { return { type: userConstants.SET_PRIVACY_SUCCESS, user } }
  function failure(error) { return { type: userConstants.SET_PRIVACY_FAILURE, error } }
}

//user Settings
function userSettings(user) {
  return dispatch => {
      dispatch(request(user));
      userService.userSettings(user)
        .then(
            user => {
                const status = user.status;
                const msg = user.msg;
                if(status){
                  dispatch(success(user.settings));
                }
                else {
                  dispatch(failure(msg.toString()));
                  toast.error(msg.toString());
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
  };

  function request(user) { return { type: userConstants.GET_USER_DATA_SETTINGS_REQUEST, user } }
  function success(user) { return { type: userConstants.GET_USER_DATA_SETTINGS_SUCCESS, user } }
  function failure(error) { return { type: userConstants.GET_USER_DATA_SETTINGS_FAILURE, error } }
}

function getAllNotification(user) {
    return dispatch => {
        dispatch(request());
        userService.getAllNotification(user)
          .then(
            notificationData => {
              if(notificationData.status != undefined){
                const status = notificationData.status;
                const msg = notificationData.msg;
                if(status){
                  dispatch(success(notificationData.response))
                  dispatch(alertActions.success(msg.toString()));
                }
                else {
                  dispatch(failure(msg.toString()));
                  dispatch(alertActions.error(msg.toString()));
                }
              }
              else {
                  dispatch(failure(msgs.SOMETHING_WENT_WRONG));
                  dispatch(alertActions.error(msgs.SOMETHING_WENT_WRONG));
              }
            },
            error => {
              dispatch(failure(error.toString()))
            }
        );
    };

    function request() { return { type: userConstants.GETALL_NOTIFICATION_REQUEST } }
    function success(notifyData) { return { type: userConstants.GETALL_NOTIFICATION_SUCCESS, notifyData } }
    function failure(error) { return { type: userConstants.GETALL_NOTIFICATION_FAILURE, error } }
}

function filterNotification(filterOptions) {
  return dispatch => {
    filterOptions && localStorage.setItem('notifyFilters', JSON.stringify(filterOptions));
    if (!filterOptions) {
      dispatch(changeFilters({
        retweet: true,
        post_like: true,
        post_comment: true,
        send_request: true,
        follow: true,
        send_message: true,
        add_to_list: true,
        tweet_in_moment: true,
      }));
    } else {
      dispatch(changeFilters(filterOptions));
    }
  }
  function changeFilters(filterOptions) { return { type: userConstants.FILTER_NOTIFICATION, filterOptions } }
}

function searchProcess(data) {
    return dispatch => {
      if (!(getCurrentLocation()).pathname.includes('/search')) {
        dispatch(request());
      }
      userService.searchProcess(data)
        .then(
            users => {
              // console.log(users);
              const status = users.status;
              const msg = users.msg;
              if(status){
                dispatch(success(users))
              }
              else {
                dispatch(failure(msg.toString()));
              }
            },
            error => {
              dispatch(failure(error.toString()))
            }
        );
    };

    function request() { return { type: userConstants.SEARCH_REQUEST } }
    function success(userData) { return { type: userConstants.SEARCH_SUCCESS, userData } }
    function failure() { return { type: userConstants.SEARCH_FAILURE } }
}

function eraseSearchResults() {
  return dispatch => {
    dispatch(success());
  };
  function success() { return { type: userConstants.DELETE_SEARCH_RESULTS } }
}

function getSearchData(user) {
    return dispatch => {
        dispatch(request());
        userService.getSearchData(user)
          .then(
            searchData => {
            //  console.log(searchData);
              const status = searchData.status;
              const msg = searchData.msg;
              if(status){
                dispatch(success(searchData))
                dispatch(alertActions.success(msg.toString()));
              }
              else {
                dispatch(failure(msg.toString()));
                toast.error(msgs.SOMETHING_WENT_WRONG);
                // dispatch(alertActions.error(msg.toString()));
              }
            },
            error => {
              dispatch(failure(error.toString()))
            }
        );
    };

    function request() { return { type: userConstants.GET_SEARCH_REQUEST } }
    function success(searchData) { return { type: userConstants.GET_SEARCH_SUCCESS, searchData } }
    function failure(error) { return { type: userConstants.GET_SEARCH_FAILURE, error } }
}

function emptySearchProcess() {
    return dispatch => {
      dispatch(success());
    };
    function success() { return { type: userConstants.EMPTY_SEARCH_SUCCESS } }
}

function searchUserChat(user) {
    return dispatch => {
        dispatch(request());
        userService.searchUserChat(user)
          .then(
            searchData => {
              const status = searchData.status;
              const msg = searchData.msg;
              if(status){
                dispatch(success(searchData.response))
                dispatch(alertActions.success(msg.toString()));
              }
              else {
                dispatch(failure(msg.toString()));
                dispatch(alertActions.error(msg.toString()));
              }
            },
            error => {
              dispatch(failure(error.toString()))
            }
        );
    };

    function request() { return { type: userConstants.GET_SEARCH_USER_REQUEST } }
    function success(searchData) { return { type: userConstants.GET_SEARCH_USER_SUCCESS, searchData } }
    function failure(error) { return { type: userConstants.GET_SEARCH_USER_FAILURE, error } }
}

function clearSearchResults() {
  return dispatch => {
    dispatch(success());
  };

  function success() { return { type: userConstants.CLEAR_SEARCH_RESULTS } }
}

function getPagesData() {
    return dispatch => {
        dispatch(request());
        userService.getPagesData()
          .then(
            pageData => {
              const status = pageData.status;
              const msg = pageData.msg;
              if(status){
                dispatch(success(pageData.response))
              }
              else {
                dispatch(failure(msg.toString()));
                dispatch(alertActions.error(msg.toString()));
              }
            },
            error => {
              dispatch(failure(error.toString()))
            }
        );
    };

    function request() { return { type: userConstants.GET_PAGE_DATA_REQUEST } }
    function success(pageData) { return { type: userConstants.GET_PAGE_DATA_SUCCESS, pageData } }
    function failure(error) { return { type: userConstants.GET_PAGE_DATA_FAILURE, error } }
}

function getPlan() {
  return dispatch => {
      dispatch(request());
      userService.getPlan()
        .then(
          data => {
            // console.log(data);
            const status = data.status;
            const msg = data.msg;
            if(status){
              dispatch(success(data.response))
              dispatch(alertActions.success(msg.toString()));
            }
            else {
              dispatch(failure(msg.toString()));
              dispatch(alertActions.error(msg.toString()));
            }
          },
          error => {
            dispatch(failure(error.toString()))
          }
      );
  };
  function request() { return { type: userConstants.GET_PLAN_REQUEST } }
  function success(data) { return { type: userConstants.GET_PLAN_SUCCESS, data } }
  function failure(error) { return { type: userConstants.GET_PLAN_FAILURE, error } }
}

function checkUser(user){
  return dispatch => {
    dispatch(request());
    userService.checkUser(user)
      .then(
        data => {
          const userStatus = data.user;
          const msg = data.msg;
          if(userStatus){
            dispatch(success(data))
            dispatch(alertActions.success(msg.toString()));
          }
          else {
            const errorCode = data.errorCode
            dispatch(failure(msg.toString(), errorCode));
            dispatch(alertActions.error(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()))
        }
      )
  }

  function request() { return { type: userConstants.CHECKUSER_REQUEST } }
  function success(data) { return { type: userConstants.CHECKUSER_SUCCESS, data } }
  function failure(error, errorCode) { return { type: userConstants.CHECKUSER_FAILURE, error, errorCode } }


}

function setReferralModalVisability(userId, status) {
  return dispatch => {
    if (!status) return dispatch(closeReferralModal());
    dispatch(request());
    userService.setReferralModalVisability()
      .then(data => {
          if(data.status === 200){
            dispatch(success(data.user))
          } else {
            const errorCode = data.msg
            dispatch(failure(true, errorCode.toString()));
            dispatch(alertActions.error(errorCode.toString()));
            toast.error(errorCode.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()))
        }
      )
  }

  function closeReferralModal() { return { type: userConstants.SET_REFERRAL_VISABILITY_CLOSE } }
  function request() { return { type: userConstants.SET_REFERRAL_VISABILITY_REQUEST } }
  function success(user) { return { type: userConstants.SET_REFERRAL_VISABILITY_SUCCESS, user } }
  function failure(error, errorCode) { return { type: userConstants.SET_REFERRAL_VISABILITY_FAIL, error, errorCode } }
}


function setCongratsReferralModalVisability(userId) {
    return dispatch => {
        dispatch(request());
        userService.setCongratsReferralModalVisability()
            .then(data => {
                    if(data.status === 200){
                        dispatch(success(data.user))
                    } else {
                        const errorCode = data.msg
                        dispatch(failure(true, errorCode.toString()));
                        dispatch(alertActions.error(errorCode.toString()));
                        toast.error(errorCode.toString());
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            )
    }

    function request() { return { type: userConstants.SET_CONGRATS_REFERRAL_VISABILITY_REQUEST } }
    function success(user) { return { type: userConstants.SET_CONGRATS_REFERRAL_VISABILITY_SUCCESS, user } }
    function failure(error, errorCode) { return { type: userConstants.SET_CONGRATS_REFERRAL_VISABILITY_FAIL, error, errorCode } }
}


function PlanRenew(user){
  return dispatch => {
    dispatch(request());
    userService.PlanRenew(user)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;
          if(status){
            dispatch(alertActions.success(msg.toString()));
            dispatch(push('/login'));
            toast.success(msg.toString());
            dispatch(success(data));
          } else {
            dispatch(failure(msg.toString()));
            dispatch(alertActions.error(msg.toString()));
            toast.error(msg.toString());
            dispatch(push('/login'));
          }
        },
        error => {
          dispatch(failure(error.toString()));
          toast.error(error.toString());
        }
      )
  }

  function request() { return { type: userConstants.PLAN_RENEW_REQUEST } }
  function success(data) { return { type: userConstants.PLAN_RENEW_SUCCESS, data } }
  function failure(error) { return { type: userConstants.PLAN_RENEW_FAILURE, error } }

}

function getHeaderResponse(user){
    return dispatch => {
        dispatch(request());
        userService.getHeaderResponse(user)
            .then(
                user => {
                    if(user && user.response && user.status && user.response.status == 1 && user.response.expire_plan_sts == 1){
                      if(user.response.email_verify=='0' || user.response.phone_verify=='0'){
                        userService.logout();
                        dispatch(push('/verification/'+user.response.encode_phone+'/'+user.response.encode_email+'/'+btoa(user.response.id)));
                        toast.error('Your account is not verified. Please verify your account for continue use this account.');
                      } else {
                        localStorage.setItem('user', JSON.stringify(user.response));

                        dispatch(success(user.response));
                      }
                    } else {
                      if(user.status) {
                        if(user.response.status != 1) {
                            const msg = user.msg;
                            dispatch(alertActions.success(msg.toString()));
                            userService.logout();
                            dispatch(push('/login'));
                            toast.error('Your account is not active. Please contact to support for re-activate account');
                        } else if(user.response.expire_plan_sts == 0 || user.response.plan_status === "expired") {
                          const msg = user.msg;
                          dispatch(alertActions.success(msg.toString()));
                          userService.logout();
                          dispatch(push({
                            pathname: '/renew-plan/'+btoa(user.response.id)+'/'+btoa(user.response.plan_id),
                            state: {
                                id: user.response.id,
                                plan_id: user.response.plan_id,
                              }
                            }
                          ));
                          toast.error(msgs.PLAN_IS_EXPIRED);
                        }

                      } else {
                        const msg = user.msg;
                        dispatch(failure(msg.toString()));
                        dispatch(alertActions.error(msg.toString()));
                        userService.logout();
                        dispatch(push('/login'));
                        toast.error('Your account is not active. Please contact to support for re-activate account');
                      }
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
          );
    };

    function request() { return { type: userConstants.USER_GET_LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.USER_GET_LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_GET_LOGIN_FAILURE, error } }
}

export function userPressedVerifyButton() {
    return { type: userConstants.USER_PRESSED_VERIFY_EMAIL_BUTTON }
}

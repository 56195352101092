export const phoneValidate = value => {

  if (typeof value !== "undefined"){
    let num = '' + value.replace(/\D+/g, '');
    let numLength = num.length;

    if (numLength < 11) {
      return false;
    } else {
      return true;
    }
  }
}
import { asyncLocalStorage } from './localStorage';

export const ACCESS_TOKEN = 'access_token';

let isInit;
let accessToken;

export const setAccessToken = (token) => {
  accessToken = token;
  localStorage.setItem(ACCESS_TOKEN, JSON.stringify(token));
  isInit = true;
};

const tryParseJSON = (v) => {
  try {
    return JSON.parse(v);
  } catch (e) {
    return v;
  }
}

export const getAccessToken = (useLocalStorage = false) => {
  if (useLocalStorage || !isInit) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    accessToken = tryParseJSON(token);
    isInit = true;
  }
  return accessToken;
};

export const removeAccessToken = () => {
  accessToken = undefined;
  localStorage.removeItem(ACCESS_TOKEN);
  isInit = true;
};

export const setAccessTokenAsync = (token) => {
  let prevAccessToken = accessToken;
  accessToken = token;
  return asyncLocalStorage.setItem(ACCESS_TOKEN, JSON.stringify(token))
    .then(() => {
      isInit = true;
    })
    .catch(error => {
      accessToken = prevAccessToken;
      throw error;
    });
};

export const getAccessTokenAsync = () => {
  return asyncLocalStorage.getItem(ACCESS_TOKEN)
    .then(token => {
      accessToken = token;
      isInit = true;
      return token;
    });
};

export const removeAccessTokenAsync = () => {
  let prevAccessToken = accessToken;
  return asyncLocalStorage.removeItem(ACCESS_TOKEN)
    .then(() => {
      isInit = true;
    })
    .catch(error => {
      accessToken = prevAccessToken;
      throw error;
    });
};

const eventListeners = new Map();

export const onAccessTokenChange = (fn) => {
  if (eventListeners.has(fn)) return;

  const listener = (e) => {
    if (e.key !== null && e.key !== ACCESS_TOKEN) return;

    const newValue = e.newValue;
    fn(newValue);
  }
  eventListeners.set(fn, listener)
  window.addEventListener('storage', listener);
}

export const offAccessTokenChange = (fn) => {
  if (eventListeners.has(fn)) return;
  window.removeEventListener('storage', eventListeners.get(fn));
}

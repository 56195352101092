import React, { Component } from 'react';
import Header from '../../../containers/frontend/common/Header';
import AccountSettingSection from '../../../containers/frontend/userProfile/AccountSettingSection';

export default class AccountSetting extends Component {
  render() {
    return (
      <div id="AccountSetting" className="AccountSetting">
        <Header/>
        <AccountSettingSection/>
     </div>
    );
  }
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import MomentGrid from './MomentGrid';
import {momentsActions, alertActions} from '../../../actions';

class othersContent extends Component {
  constructor(props) {
    super(props);
    this.getPageData();
  }

  componentDidMount() {
    this.getPageData();
  }

  /*componentDidUpdate(prevProps)
  {
    if (prevProps.user.id !== this.props.user.id) {
      this.getPageData();
    }
  }*/

  getPageData = () => {
    const {user} = this.props;
    const {dispatch} = this.props;
    if (user) {
      dispatch(momentsActions.getAllMoments(user.id));
    }
  }

  render() {
    const {user, momentData, alert} = this.props;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;
    return (
      <div className="sports-main content-part">
        <div className="container">
          {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
          <MomentGrid momentData={momentData.others} type='others'/>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    momentData: state.moments.items != undefined ? state.moments.items : [],
    alert: state.alert,
  };
}

export default connect(mapStateToProps)(othersContent);

/**
 *
 * @param object
 * @param objects
 * @param comparisonKey
 * @returns {boolean|*[]}
 * Find and replace the object in array of objects using common key
 */
export const replaceObject = (object, objects, comparisonKey = 'id') => {
	const newObjects = [...objects]
	const index = newObjects.findIndex(_object => {
		return parseInt(_object[comparisonKey]) === parseInt(object[comparisonKey])
	})

	if (index < 0) {
		console.error('Object index is not found')
		return false;
	}

	newObjects[index] = object;

	return newObjects
}
import {
  getUnreadSingleChatMessages as getUnreadSingleChatMessagesReq,
  getUnreadGroupChatMessages as getUnreadGroupChatMessagesReq,
  subscribeOnUnreadSingleChatMessages as subscribeOnUnreadSingleChatMessagesReq,
  subscribeOnUnreadGroupChatMessages as subscribeOnUnreadGroupChatMessagesReq,
} from '../services/firebaseAPI';

import {
  ALL_UNREAD_SINGLE_CHATS_REQUEST,
  ALL_UNREAD_SINGLE_CHATS_SUCCESS,
  ALL_UNREAD_SINGLE_CHATS_FAILURE,
  ADDED_UNREAD_SINGLE_CHAT,
  REMOVED_UNREAD_SINGLE_CHAT,
  ADDED_UNREAD_SINGLE_CHAT_MESSAGE,
  READ_UNREAD_SINGLE_CHAT_MESSAGE,
  ALL_UNREAD_GROUP_CHATS_REQUEST,
  ALL_UNREAD_GROUP_CHATS_SUCCESS,
  ALL_UNREAD_GROUP_CHATS_FAILURE,
  ADDED_UNREAD_GROUP_CHAT,
  REMOVED_UNREAD_GROUP_CHAT,
  ADDED_UNREAD_GROUP_CHAT_MESSAGE,
  CHANGE_UNREAD_GROUP_CHAT_DATA,
} from "../constants";

import Soundfile from '../audio_notification/notification.mp3';

const NotificationSound = new Audio(Soundfile);

export function getUnreadSingleChatMessages(userId) {
  return async (dispatch) => {
    dispatch({type: ALL_UNREAD_SINGLE_CHATS_REQUEST});

    try {
      const data = await getUnreadSingleChatMessagesReq(userId);
      dispatch({type: ALL_UNREAD_SINGLE_CHATS_SUCCESS, data});
    } catch (error) {
      console.log('error: ', error);
      dispatch({type: ALL_UNREAD_SINGLE_CHATS_FAILURE});
    }
  };
}

export function getUnreadGroupChatMessages(userId) {
  return async (dispatch) => {
    dispatch({type: ALL_UNREAD_GROUP_CHATS_REQUEST});

    try {
      const data = await getUnreadGroupChatMessagesReq(userId);
      dispatch({type: ALL_UNREAD_GROUP_CHATS_SUCCESS, data});
    } catch (error) {
      dispatch({type: ALL_UNREAD_GROUP_CHATS_FAILURE});
    }
  };
}

const unreadSingleChatMessagesUnsubscribeFns = [];

export function subscribeOnUnreadSingleChatMessages(userId) {
  return async (dispatch) => {
    const onChatAdded = (data) => {
      dispatch({type: ADDED_UNREAD_SINGLE_CHAT, data});
    };
    const onChatRemoved = (data) => {
      dispatch({type: REMOVED_UNREAD_SINGLE_CHAT, data});
    };
    const onChatMessageAdded = (data) => {
      dispatch({type: ADDED_UNREAD_SINGLE_CHAT_MESSAGE, data});

      NotificationSound.play();
    };
    const onChatMessageRead = (data) => {
      dispatch({type: READ_UNREAD_SINGLE_CHAT_MESSAGE, data});
    };
    try {
      const callbacks = {onChatAdded, onChatRemoved, onChatMessageAdded, onChatMessageRead};
      const unsubscribeFn = await subscribeOnUnreadSingleChatMessagesReq(userId, callbacks);
      unreadSingleChatMessagesUnsubscribeFns.push(unsubscribeFn);
    } catch (error) {
      console.error('subscribeOnUnreadSingleChatMessages error: ', error);
    }
  };
}

export function unsubscribeOnUnreadSingleChatMessages() {
  unreadSingleChatMessagesUnsubscribeFns.forEach(unsubscribeFns => unsubscribeFns());
}

const unreadGroupChatMessagesUnsubscribeFns = [];

export function subscribeOnUnreadGroupChatMessages(userId) {
  return async (dispatch) => {
    const onChatAdded = (data) => {
      dispatch({type: ADDED_UNREAD_GROUP_CHAT, data});
    };
    const onChatRemoved = (data) => {
      dispatch({type: REMOVED_UNREAD_GROUP_CHAT, data});
    };
    const onChatMessageAdded = (data) => {
      dispatch({type: ADDED_UNREAD_GROUP_CHAT_MESSAGE, data});

      NotificationSound.play();
    };
    const onChatReadingDataChange = (data) => {
      dispatch({type: CHANGE_UNREAD_GROUP_CHAT_DATA, data});
    };
    try {
      const callbacks = {onChatAdded, onChatRemoved, onChatMessageAdded, onChatReadingDataChange};
      const unsubscribeFn = await subscribeOnUnreadGroupChatMessagesReq(userId, callbacks);
      unreadGroupChatMessagesUnsubscribeFns.push(unsubscribeFn);
    } catch (error) {
      console.error('subscribeOnUnreadGroupChatMessages error: ', error);
    }
  };

}

export function unsubscribeOnUnreadGroupChatMessages() {
  unreadGroupChatMessagesUnsubscribeFns.forEach(unsubscribeFns => unsubscribeFns());
}

import { userConstants } from '../constants';

export function searchprocess(state = {}, action){
  //console.log(action);
  switch (action.type)
  {
    case userConstants.SEARCH_REQUEST:
      return {
          ...state,
          loading: true,
      };
    case userConstants.SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchData: action.userData
      };
    case userConstants.SEARCH_FAILURE:
      return {
        ...state,
        searchData: action.userData,
        error: action.error
      };
    case userConstants.GET_SEARCH_REQUEST:
      return {
          ...state,
          loading: true,
      };
    case userConstants.GET_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        getsearchData: action.searchData
      };
      case userConstants.DELETE_SEARCH_RESULTS:
        return { };
    case userConstants.GET_SEARCH_FAILURE:
      return {
        ...state,
        getsearchData: action.searchData,
        error: action.error
      };
    case userConstants.EMPTY_SEARCH_SUCCESS:
      return {
        ...state,
        searchData: action.userData,
      };
    default:
    return state
  }
}

import {
  ALL_UNREAD_GROUP_CHATS_REQUEST,
  ALL_UNREAD_GROUP_CHATS_SUCCESS,
  ALL_UNREAD_GROUP_CHATS_FAILURE,
  ADDED_UNREAD_GROUP_CHAT,
  REMOVED_UNREAD_GROUP_CHAT,
  ADDED_UNREAD_GROUP_CHAT_MESSAGE,
  CHANGE_UNREAD_GROUP_CHAT_DATA,
} from "../../constants";
import { addChat, removeChat, addChatMessage } from './helpers';

const initialState = {
  isFetching: false,
  data: [],
  error: false,
};

export function unreadGroupChatMessages(state = initialState, action) {
  switch (action.type) {
    case ALL_UNREAD_GROUP_CHATS_REQUEST: {
      return {...state, isFetching: true};
    }
    case ALL_UNREAD_GROUP_CHATS_SUCCESS: {
      const {data} = action;
      return {...state, isFetching: false, error: false, data};
    }
    case ALL_UNREAD_GROUP_CHATS_FAILURE: {
      return {...state, isFetching: false, error: true};
    }
    case ADDED_UNREAD_GROUP_CHAT: {
      return addChat(state, action.data);
    }
    case REMOVED_UNREAD_GROUP_CHAT: {
      return removeChat(state, action.data);
    }
    case ADDED_UNREAD_GROUP_CHAT_MESSAGE: {
      const {chatKey, lastReadTimestamp: lastReadChatTimestamp = 0, message: {key: messageKey, timestamp: messageTimestamp}} = action.data;
      const {data: currentChats} = state;
      const chat = currentChats.find(({key}) => chatKey === key);
      const { lastReadTimestamp = lastReadChatTimestamp } = chat || {};
      return lastReadTimestamp < messageTimestamp ? addChatMessage(state, {chatKey, messageKey}) : state;
    }
    case CHANGE_UNREAD_GROUP_CHAT_DATA: {
      const {key: chatKey, readStatus, lastReadTimestamp} = action.data;

      const {data: currentData} = state;
      const chatIndex = currentData.findIndex(({key}) => chatKey === key);

      let newData;
      if (chatIndex === -1) {
        return addChat(state, {key: chatKey, lastReadTimestamp, unreadMessagesKeys: []});
      } else {
        newData = [...currentData];
        const currentChat = currentData[chatIndex];
        newData[chatIndex] = {...currentChat, lastReadTimestamp};
        // TODO to filter read messages you need to have a timestamp of each message, for now just clear all unreadMessagesKeys
        if (readStatus) {
          newData[chatIndex].unreadMessagesKeys = [];
        }
      }

      return {...state, data: newData};
    }
    default:
      return state;
  }
}

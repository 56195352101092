import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import BlockAccountSettingSection from '../../../containers/frontend/userProfile/BlockAccountSettingSection';
import { alertActions,userActions } from '../../../actions';

class BlockAccountSetting extends Component {
  constructor(props){
    super(props);
    this.getPageData();
  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps)
  {
    if (prevProps.user.id !== this.props.user.id) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { user } = this.props;
    const { dispatch } = this.props;
    if(user){
      dispatch(userActions.getBlockedUser(user.id));
    }
  }

  render(){
    const {blocked} = this.props;
    return (
      <div id="BlockAccountSetting" className="BlockAccountSetting">
        <Header/>
        <BlockAccountSettingSection/>
     </div>
    );
  }
}

function mapStateToProps(state){
  //console.log(state);
  return {
    user: state.authentication.user
  };
}
export default connect(mapStateToProps)(BlockAccountSetting);

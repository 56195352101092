import React, { Component } from 'react';
import Header from '../../../containers/frontend/common/Header';
import LoginIpHistorySection from '../../../containers/frontend/userProfile/LoginIpHistorySection';

export default class LoginIpHistory extends Component {
  render() {
    return (
      <div id="AccountSetting" className="LoginIpHistory">
        <Header/>
        <LoginIpHistorySection/>
     </div>
    );
  }
}

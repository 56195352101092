import React from 'react';

export default function BlockedContent({ username, isHiddenByYou, isHidden }) {

    return (
      <div className="blockedContent">
        {isHidden && (
          <h2 className="text-center">
            User @{username} has blocked you
          </h2>
        )}
        {isHiddenByYou && (
          <h2 className="text-center">
            You have blocked user @{username}
          </h2>
        )}
      </div>
    );
}

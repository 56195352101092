import { userConstants } from '../constants';

export function renewplan(state = {}, action) {
  switch (action.type) {
    case userConstants.PLAN_RENEW_REQUEST:
      return {
        loading: true,
      };
    case userConstants.PLAN_RENEW_SUCCESS:
      return {
        loading: false,
        data: action.data
      };
    case userConstants.PLAN_RENEW_FAILURE:
      return {
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}

import { listsConstants } from '../constants';
import { listsService } from '../services';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import msgs from "../messages/en/main";
import { sparksActions } from '../actions';
import $ from 'jquery';

export const listsActions = {
    createEditList,
    getAll,
    deleteList,
    listSubscribe,
    addDeleteMembers
};

function createEditList(rawData){
  return dispatch => {
    dispatch(request(rawData));
    listsService.createEditList(rawData)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;
          if(status){
            dispatch(success(data));
            if(rawData.list_id){
              dispatch(getAll(rawData));
              if (msg) {
                toast.success(msg.toString());
              }
            }
            else if(rawData.username != undefined){

              $('html, body').css('overflow', '');
              dispatch(push('/'+rawData.username+'/lists'));
              toast.success(msg.toString());
            }
          }
          else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()))
        }
      );
  };
  function request(data) { return{ type: listsConstants.CREATE_REQUEST, data } }
  function success(sparkcreate) { return{ type: listsConstants.CREATE_SUCCESS, sparkcreate } }
  function failure(error) { return{ type: listsConstants.CREATE_FAILURE, error } }
}

function getAll(user) {
    return dispatch => {
        dispatch(request(user));
            listsService.getAll(user)
                .then(
                    lists => {
                      if(lists.status != undefined && lists.status)
                      {
                        const status = lists.status;
                        const msg = lists.msg;
                        if(status){
                          dispatch(success(lists));
                          dispatch(sparksActions.updateAllSpark(lists));
                      }
                      else {
                        if(msg != undefined && msg){
                          dispatch(failure(msg.toString()));
                          toast.error(msg.toString());
                        }
                      }
                      }
                      else {
                        dispatch(failure(msgs.SOMETHING_WENT_WRONG));
                        toast.error(msgs.SOMETHING_WENT_WRONG);
                      }
                    },
                    error => {
                      dispatch(failure(error.toString()))
                    }
                );
    };

    function request(user) { return { type: listsConstants.GETALL_REQUEST, user } }
    function success(lists) { return { type: listsConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listsConstants.GETALL_FAILURE, error } }
}

function deleteList(rawData){
  return dispatch => {
    dispatch(request(rawData));
    listsService.deleteList(rawData)
      .then(
        data => {
          const status = data.status;
          const msg = data.msg;
          if(status){
            dispatch(success(rawData));
            dispatch(push('/'+rawData.username+'/lists'));
            toast.success(msg.toString());
          }
          else {
            dispatch(failure(msg.toString()));
            toast.error(msg.toString());
          }
        },
        error => {
          dispatch(failure(error.toString()))
        }
      );
  };
  function request(data) { return{ type: listsConstants.DELETE_REQUEST, data } }
  function success(listdelete) { return{ type: listsConstants.DELETE_SUCCESS, listdelete } }
  function failure(error) { return{ type: listsConstants.DELETE_FAILURE, error } }
}

function listSubscribe(rawData) {
    return dispatch => {
        dispatch(request(rawData));
            listsService.listSubscribe(rawData)
                .then(
                    lists => {
                      if(lists.status != undefined && lists.status)
                      {
                        const status = lists.status;
                        const msg = lists.msg;
                        if(status){
                          dispatch(success(lists));
                          dispatch(getAll(rawData));
                          toast.success(msg.toString());
                      }
                      else {
                        if(msg != undefined && msg){
                          dispatch(failure(msg.toString()));
                          toast.error(msg.toString());
                        }
                      }
                      }
                      else {
                        dispatch(failure(msgs.SOMETHING_WENT_WRONG));
                        toast.error(msgs.SOMETHING_WENT_WRONG);
                      }
                    },
                    error => {
                      dispatch(failure(error.toString()))
                    }
                );
    };

    function request(user) { return { type: listsConstants.LIST_SUBSCRIBE_REQUEST, user } }
    function success(lists) { return { type: listsConstants.LIST_SUBSCRIBE_SUCCESS, lists } }
    function failure(error) { return { type: listsConstants.LIST_SUBSCRIBE_FAILURE, error } }
}

function addDeleteMembers(rawData) {
    return dispatch => {
        dispatch(request(rawData));
            listsService.addDeleteMembers(rawData)
                .then(
                    lists => {
                      if(lists.status != undefined && lists.status)
                      {
                        const status = lists.status;
                        const msg = lists.msg;
                        if(status){
                          dispatch(success(lists));
                          toast.success(msg.toString());
                          dispatch(getAll(rawData));
                      }
                      else {
                        if(msg != undefined && msg){
                          dispatch(failure(msg.toString()));
                          toast.error(msg.toString());
                        }
                      }
                      }
                      else {
                        dispatch(failure(msgs.SOMETHING_WENT_WRONG));
                        toast.error(msgs.SOMETHING_WENT_WRONG);
                      }
                    },
                    error => {
                      dispatch(failure(error.toString()))
                    }
                );
    };

    function request(user) { return { type: listsConstants.UPDATE_USER_IN_LIST_REQUEST, user } }
    function success(lists) { return { type: listsConstants.UPDATE_USER_IN_LIST_SUCCESS, lists } }
    function failure(error) { return { type: listsConstants.UPDATE_USER_IN_LIST_FAILURE, error } }
}

'use strict';

import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import s from './chatListsTab.module.scss';


class ChatListsTab extends Component {
  render() {
    const { activeTab, tabIndex, label, onTabClick } = this.props;
    const onClick = () => {
      onTabClick(tabIndex);
    }

    return (

      <li
        className={cx(s.tabListItem, { [`${s.tabListActive}`]: activeTab === label })}
        onClick={onClick}
      >
        {label}
      </li>
    );
  }
}

ChatListsTab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
};

function mapStateToProps(state){
  return {
    alert: state.alert,
  };
};

export default withRouter(connect(mapStateToProps)(ChatListsTab));

import { userConstants } from '../constants';

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users,
        loading: false,
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    case userConstants.GETALL_BLOCKED_REQUEST:
      return {
          ...state
      };
    case userConstants.GETALL_BLOCKED_SUCCESS:
      return {
        ...state,
        blockedList: action.data.blockedUser,
        mutedUser: action.data.mutedUser
      };
    case userConstants.GETALL_BLOCKED_FAILURE:
      return {
        error: action.error
      };
    case userConstants.UNBLOCK_REQUEST:
      return {
          ...state
      };
    case userConstants.UNBLOCK_SUCCESS:
      state.blockedList.splice(action.index, 1)
      return state;
    case userConstants.UNBLOCK_FAILURE:
      return {
        error: action.error
      };
    case userConstants.UNMUTE_REQUEST:
      return {
          ...state
      };
    case userConstants.UNMUTE_SUCCESS:
      state.mutedUser.splice(action.index, 1);
      return state;
    case userConstants.UNMUTE_FAILURE:
      return {
        error: action.error
      };
    case userConstants.DIRECT_UNMUTE_REQUEST:
      return {
          ...state,
          muteData: action.unmuteData,
          muteLoading: true,
        };
    case userConstants.DIRECT_UNMUTE_SUCCESS:
      return {
          ...state,
          muteData: action.unmuteData,
          muteLoading: false,

        };
    case userConstants.DIRECT_UNMUTE_FAILURE:
      return {
        ...state,
        muteData: action.unmuteData,
        error: action.error,
        muteLoading: false,
      };

    default:
      return state
  }
}

import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';

import { alertActions,suggestionActions } from '../../../actions';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';
import BlockedContent from '../common/BlockedContent';

class UserMainWidget extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      visible : 10
    };
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 10};
    });
  }

  FollowUnfollowUser = (follower_id) => {
    const { dispatch } = this.props;
    const { loggeduser,username,type,slug } = this.props;

    if(loggeduser && follower_id){
          const sendRaw = {
            follower_id : follower_id,
            user_id: loggeduser.id,
          }
          dispatch(suggestionActions.FollowUnfollowOther(sendRaw,username,type,slug));
    }
    else {
       toast.error(msgs.SOMETHING_WENT_WRONG);
    }
  }

  getButtonByCondition(user, loggeduser) {

    if(user.statusMsg != 'accept_reject' && user.user_id != loggeduser.id) {
      return (
        <div className="pro-foll-right">
          { this.followUnfollowBtn(user) }
        </div>
      );
    } else {
      return (
        <div className="pro-foll-right">
          {user.status == '0' && user.user_id != loggeduser.id? (
            <div>
              <button className="accept-btn" data-id={user.user_id} onClick={() => this.FollowResponse(user.user_id,'accept')}>
                <span className="active">Accept</span>
                <span className="hover-active">Accept</span>
              </button>
              <button className="decline-btn" data-id={user.user_id} onClick={() => this.FollowResponse(user.user_id,'decline')}>
                <span className="active">Decline</span>
                <span className="hover-active">Decline</span>
              </button>
            </div>
          ) : ('')
          }
        </div>
      );
    }
  }

  followUnfollowBtn(user) {
    if(user.status == '0') {
      return (
        <button data-id={user.user_id} onClick={() => this.FollowUnfollowUser(user.user_id)}>Follow</button>
      );
    } else if(user.status == '1' && user.username !== '2cents' && user.at_username !== '@2cents') {
      return (
        <button data-id={user.user_id} onClick={() => this.FollowUnfollowUser(user.user_id)}>
          <span className="active">Following</span>
          <span className="hover-active">Unfollow</span>
        </button>
      );
    } else if (user.username !== '2cents' && user.at_username !== '@2cents') {
      return (
        <button data-id={user.user_id} onClick={() => this.FollowResponse(user.user_id,'cancel')}>
          <span className="active">Pending</span>
          <span className="hover-active">Cancel</span>
        </button>
      );
    }
    return null;
  }

  FollowResponse = (other_id,action) => {
    const { dispatch } = this.props;
    const { loggeduser,username,type,slug } = this.props;

    if(loggeduser && other_id && action){
          const sendRaw = {
            other_id : other_id,
            user_id: loggeduser.id,
            action: action,
          }
          const rawSend = type ? type : 'profile_main';
          // console.log(rawSend);
          dispatch(suggestionActions.FollowResponse(sendRaw,username,rawSend,slug));
    }
    else {
       toast.error(msgs.SOMETHING_WENT_WRONG);
    }
  }

  render(){
    const {userslist,loggeduser, username, isHidden, isHiddenByYou } = this.props;

    if((isHidden || isHiddenByYou ) && username) {
      return (
        <BlockedContent
          username={username}
          isHidden={isHidden} 
          isHiddenByYou={isHiddenByYou} 
        />
      );
    }

    if(userslist.length === 0) {
      return (
        <div className="profile">
          <h3>N/A</h3>
        </div>
      );
    }

    return (
      <div className="row">
        {userslist.length > 0 ?
          (
            userslist.slice(0,this.state.visible).map((user,index) =>
            <div key={index} className="col-sm-6">
              <div className="pro-foll">
                <div className="pro-foll-cover" style={user.banner_image.indexOf('no-banner') < 0 ? {backgroundImage: `url(${user.banner_image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'} : {}} />
                <div className="pro-foll-inn">
                  <Link to={'/'+user.username} className="avter-link">
                    <img src={user.profile_image} alt={user.username} />
                  </Link>
                  { this.getButtonByCondition(user, loggeduser) }
                  <div className="text-overflow-ellipsis  full-width  pro-foll-text">
                    <Link title={user.name} to={'/'+user.username}>{user.name}</Link>
                    <h4 title={`@${user.username}`}>
                      @{user.username}
                      {user.isPrivate == '1' ? (<i className="fas fa-lock"></i>) : ('')}‏
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            )
          ) : ('')}
        {userslist != undefined && userslist.length > 0 ?
         (this.state.visible < userslist.length ? (
            <button onClick={this.loadMore} type="button" className="load-more btn btn-danger">Load more</button>
          ) : ('')) : ('')
        }
      </div>
    )
  }
}


function mapStateToProps(state,ownProps){
  return {
    loggeduser: state.authentication.user,
    userslist: ownProps.userslist ? ownProps.userslist : [],
    username: ownProps.username,
    type: ownProps.type != undefined ? ownProps.type : '',
    slug: ownProps.slug != undefined ? ownProps.slug : '',
    isHidden: state.profileinfo.data != undefined && state.profileinfo.data.blockByUser == 1 ? true : false,
    isHiddenByYou: state.profileinfo.data != undefined && state.profileinfo.data.blockByYou,
  };
}
export default connect(mapStateToProps)(UserMainWidget);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/frontend/common/Header';
import GetHashtagAllData from '../../../containers/frontend/hashtag/GetHashtagAllData';
import {Helmet} from "react-helmet";

import { alertActions,sparksActions } from '../../../actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class HashTag extends Component{
  constructor(props){
    super(props);

    const { dispatch, history, alert } = this.props;

    history.listen((location, action) => {
        // clear alert on location change
      if (alert.message && alert.message !== 'TypeError: Failed to fetch') dispatch(alertActions.clear());
    });

  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.getPageData();
    }
  }

  getPageData = () => {
    const { dispatch } = this.props;
    const hashtagslug = this.props.match.params.slug;
    const {user, history} = this.props;

    if (user && hashtagslug) {
        const data = {
          hashtag: hashtagslug,
          user_id: user.id,
        };
        dispatch(sparksActions.getAll(data, history.location));
    }

  }

  render() {
    const hashtagslug = this.props.match.params.slug;
    return (
      <main className="HashTag">
        <Helmet>
          <title>#{ hashtagslug } hashtag on 2cents</title>
        </Helmet>
        <Header/>
        <GetHashtagAllData/>
      </main>
    )
  }
}

HashTag.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    alert: state.alert,
  };
}

export default withRouter(connect(mapStateToProps)(HashTag));

import React, {Component} from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import {createDisplayedSpark, charCounter} from '../../../helpers';
import { toast } from 'react-toastify';
import msgs from '../../../messages/en/main';
import { sparksActions } from '../../../actions';
import Params from '../../../config/Params';

class ReplyPopUpText extends Component {

  static defaultProps = {
    maxCharCount: Params.textareaMaxCharCount
  };

  constructor(props){
    super(props);

    this.state = {
      comment_text : '',
      button: false,
    };

  }

  trimMessage = (message) => {
    return message.trim().substr(0, this.props.maxLength)
  };

  CommentPost = (p_id) => {
    const { dispatch } = this.props;
    const { user } = this.props;
    const comment_text = this.trimMessage(this.state.comment_text);

    if(user && p_id && comment_text){
          const sendRaw = {
            post_id : p_id,
            user_id: user.id,
            comment_text: comment_text,
          };
          dispatch(sparksActions.CommentPost(sendRaw,'home'));

          this.setState({
            comment_text : '',
            button: false
          });
          $('div#reply-modal button.close').click();
    }
    else {
       toast.error(msgs.ENTER_A_COMMENT_TO_REPLY);
    }
  };

  handleChange = (event) => {
      const { name, value } = event.target;
      this.setState({
              [name]: value
      });
      this.ButtonActiveOrNot(event);
  }

  ButtonActiveOrNot = (event) => {
    let { value } = (event || window.event).target;
    value = value.trim();

      if (value && value.length > 0) {
        this.setState({ button: true });
      }
      else {
        this.setState({ button: false });
      }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render(){

    const { raw, maxCharCount } = this.props;
    const {comment_text, button} = this.state;

    return (
      <div className="modal fade retweet-modal" id="reply-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog create-new" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Reply to this user?</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {raw != undefined ? (
              <div className="modal-body">
                <div>
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="tweet-img">
                        <div className="twit-mid-img">
                          <img src={raw.profile_image ? raw.profile_image : '/frontend/images/user-img.png'} alt="logo" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-10 padd-left">
                      <div className="tweet-right">
                        <a href="">{raw.name}</a>
                        {/*<div dangerouslySetInnerHTML={{__html: raw.post_title}} />*/}
                          <div className="spark-text">
                              { raw.post_title && createDisplayedSpark(raw.post_title) }
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="reply-btm">
                    <h4>Replying to <a href="">{raw.at_username}</a></h4>
                    <textarea maxLength={maxCharCount} placeholder="Text your reply" name="comment_text" onChange={this.handleChange} value={comment_text} />
                    { charCounter(comment_text, maxCharCount) }
                    <div className="mid-icon">
                      {button ? <button onClick={() => this.CommentPost(raw.post_id)}>Reply</button> : <button disabled={true}>Reply</button>}
                    </div>
                  </div>
                </div>
              </div>
              ) : ('')
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    raw: state.replydata ? state.replydata.data : '',
    user: state.authentication.user,
  };
}
export default connect(mapStateToProps)(ReplyPopUpText);

const isDev = process.env.NODE_ENV === 'development';

const mainurl = isDev ?
  process.env.REACT_APP_LOCAL_URL || "http://localhost:3000/" :
  process.env.REACT_APP_URL || "https://2cents.com/";

const APP_URL = isDev ?
  process.env.REACT_APP_API1_LOCAL_URL || "http://api.2cents.lan/" :
  process.env.REACT_APP_API1_URL || "https://api.2cents.com/";

const APP2_URL = isDev ?
  process.env.REACT_APP_API2_LOCAL_URL || "http://api2.2cents.lan/api/" :
  process.env.REACT_APP_API2_URL || "https://api2.2cents.com/api/";

export {
  mainurl,
  APP_URL,
  APP2_URL,
};

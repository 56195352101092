import React, {Component, useCallback} from 'react';
import {connect} from 'react-redux';
import NoSparkSection from '../sparks/NoSparkSection';
import CreateSpark from '../sparks/CreateSpark';
import TrendsForYou from '../sparks/TrendsForYou';
import BottomWidget from '../common/BottomWidget';
import UserTinyWidget from '../userElements/UserTinyWidget';
import UserProfileTopSection from '../userElements/UserProfileTopSection';
import SparkList from '../sparks/SparkList';
import ProtectedMessage from '../common/ProtectedMessage';
import {alertActions, reportReasonsActions, setActiveChat, setChatContentType, sparksActions} from '../../../actions';
import LoadingIcon from "../common/LoadingIcon";
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

class UserProfileSection extends Component {
  constructor(props) {
    super(props);

    const {dispatch, history, alert} = this.props;

    dispatch(reportReasonsActions.getReportReasons());

    history.listen((location, action) => {
      // clear alert on location change
      if (alert.message) {
        dispatch(alertActions.clear());
      }
    });
  }

  componentWillUnmount() {
    this.props.dispatch(sparksActions.clearSparks());
  }



  render() {
    const {sparks, username, user, profileinfo, alert, blockByUser, blockByYou, loading} = this.props;
    const errorMessage = alert.message === 'TypeError: Failed to fetch' ? 'No internet connection' : alert.message;
    const fullNameToShow = user && (profileinfo.user_id == user.id) ? user.name : profileinfo.full_name;
    if(loading) {
      return <LoadingIcon />;
    }


    return (
      <>
        <div className="edit-profile">
          <div className="content-part">
            <UserProfileTopSection username={username}/>
            <div className="container">
              {alert.type == 'alert-danger' && <div className={`alert ${alert.type}`}>{errorMessage}</div>}
              { profileinfo && !loading ?
                (
              <div className="row">
                <div className="col-sm-3 left-sidebar">
                  <UserTinyWidget
                    profileInfo={profileinfo}
                    isPrivate={profileinfo.isPrivate}
                    full_name={fullNameToShow}
                    username={profileinfo.username}
                    created={profileinfo.created}
                  />
                </div>
                <div className="col-sm-6 feed-container">
                  {profileinfo.visible != '1' ?
                    (
                      <ProtectedMessage username={username}/>
                    ) : (
                      user && username == user.username ? (
                        <CreateSpark type="profile"/>
                      ) : ('')
                    )
                  } 
                  {profileinfo.visible == '1' ?
                    (sparks ? (
                        <SparkList
                          username={username}
                          pagetype="profile"
                        />
                      ) : (
                        <div>
                          <div className="profile">
                            <h3>Send your first Spark</h3>
                            <p>We have got your first Spark ready to go. The hashtag #myfirstSpark will help others
                              find
                              and chat with you. </p>
                          </div>
                          <NoSparkSection/>
                        </div>
                      )
                    ) : ''
                  }
                </div>
                <div className="col-sm-3 right-sidebar">
                  <TrendsForYou userId={profileinfo.user_id}/>
                  <BottomWidget/>
                </div>
              </div>
                ) : (
                  <LoadingIcon />
                )
              }
            </div>
          </div>
        </div>
      </>
    );
  }
};

UserProfileSection.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {

  return {
    loggedIn: state.authentication.loggedIn,
    user: state.authentication.user,
    loading: state.authentication.loading,
    alert: state.alert,
    sparks: state.sparks.items != undefined ? state.sparks.items : [],
    profileinfo: state.profileinfo.data != undefined ? state.profileinfo.data : [],
    holdflag: ownProps.holdflag != undefined ? ownProps.holdflag : false,
    username: ownProps.username,
    isHidden: state.profileinfo.data != undefined && state.profileinfo.data.blockByUser == 1 ? true : false,
    blockByYou: state.profileinfo.data != undefined && state.profileinfo.data.blockByYou,
  };
}

export default withRouter(connect(mapStateToProps)(UserProfileSection));

import React from 'react';
import { Link } from 'react-router-dom';


export default function LogoWithTextSectiom() {
    return (
      <div className="logo">
        <Link to="/">
          <img src="/frontend/images/login-logo.png" alt="logo"/>
        </Link>
        <span className="login-spl-text">Responsible Social Media</span>
      </div>
    );
};

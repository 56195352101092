import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';

import { alertActions, listsActions } from '../../../actions';

class UserListActionWidget extends PureComponent {

  deleteList = (list_id) => {
    this.setState({ submitted: true });
    const { dispatch, loggeduser } = this.props;

    if (loggeduser.id && list_id && dispatch) {
      const raw = {
        user_id: loggeduser.id,
        list_id: list_id,
        username: loggeduser.username
      };
      //console.log(raw);
      dispatch(listsActions.deleteList(raw));
    }
  }

  listSubscribe = (list_id) => {
    this.setState({ submitted: true });
    const { dispatch, loggeduser } = this.props;

    if (loggeduser.id && list_id && dispatch) {
      const raw = {
        user_id: loggeduser.id,
        list_id: list_id,
        username: loggeduser.username
      };
      //console.log(raw);
      dispatch(listsActions.listSubscribe(raw));
    }
  }

  render() {
    let { lists, loggeduser, username, l_id } = this.props;

    return (
      <>
        <div className="twiter-left1 sett-left demo-left">
          <div className="demo-top-text">
            <h2 className="word-wrap">{lists.listDetail != undefined ? " " + lists.listDetail.title : ('----')}</h2>
            <h3>A {lists.listDetail != undefined && lists.listDetail.isPrivate == 1 ? "private" : ('public')} list by
              <Link to={"/" + (lists.listDetail != undefined ? lists.listDetail.username : (''))}>
                {lists.listDetail != undefined ? " @" + lists.listDetail.username : ('')}
              </Link>
            </h3>
            <p className="word-wrap">  {lists.listDetail != undefined ? " " + lists.listDetail.description : ('')}</p>
            <a href="" className="demo-img">
              <img src={lists.listDetail != undefined ? " " + lists.listDetail.profile_image : ('/frontend/images/user-img.png')} alt="user-logo"/>
            </a>
            <ul>
              <li>
                <Link to={"/" + username + "/list-detail/" + l_id + "/members"}>Members
                  <strong>{lists.listDetail != undefined ? lists.listDetail.total_member : '0'}</strong>
                </Link>
              </li>
              <li>
                <Link to={"/" + username + "/list-detail/" + l_id + "/subscribers"}>Subscribers
                  <strong>{lists.listDetail != undefined ? lists.listDetail.total_subscription : '0'}</strong>
                </Link>
              </li>
            </ul>
            {lists.listDetail != undefined && lists.listDetail.user_id == loggeduser.id ? (
              <div className="demo-btm">
                <Link to={"/" + username + "/edit-list/" + l_id}>Edit</Link>
                <button onClick={() => this.deleteList(l_id)} className="delete-item">Delete</button>
              </div>
            ) : (
                lists.listDetail != undefined && lists.listDetail.subscribeStatus == 0 ? (
                  <div className="demo-btm">
                    <button onClick={() => this.listSubscribe(l_id)} className="delete-item">Subscribe</button>
                  </div>
                )
                  : (
                    <div className="demo-btm">
                      <button onClick={() => this.listSubscribe(l_id)} className="delete-item">Unsubscribe</button>
                    </div>
                  )
              )
            }
          </div>
        </div>
        <div className="twiter-left1 sett-left mar-top actions-bottom">
          <ul>
            <li>
              <Link to={"/" + username + "/list-detail/" + l_id}>
                Sparks<i className="fas fa-chevron-right"></i>
              </Link>
            </li>
            <li>
              <Link to={"/" + username + "/list-detail/" + l_id + "/members"}>
                List members<i className="fas fa-chevron-right"></i>
              </Link>
            </li>
            <li>
              <Link to={"/" + username + "/list-detail/" + l_id + "/subscribers"}>
                List subscribers<i className="fas fa-chevron-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </>
    )
  }
}


function mapStateToProps(state, ownProps) {
  return {
    loggeduser: state.authentication.user,
    lists: state.lists ? state.lists : [],
    l_id: ownProps.l_id,
    username: ownProps.username
  };
}
export default connect(mapStateToProps)(UserListActionWidget);

import { Echoer } from '../services/echo';
import { getAuthHeaders } from '../api/helpers';

export function getAppHeaders(isContentHeaderPresent = false) {
    const headers = {...getAuthHeaders()};

    if (isContentHeaderPresent) {
        headers['Content'] = 'json';
        headers['Content-Type'] = 'application/json';
    } else {
        headers['Accept'] = 'application/json';
    }

    return new Headers(headers);
}

export function getAppHeadersWithSockets(isContentHeaderPresent = false) {
    const headers = {...getAuthHeaders()};
    let socketId = Echoer.getSocketId();
    if (isContentHeaderPresent) {
            headers['Content'] = 'json';
            headers['Content-Type'] = 'application/json';
            headers['X-Socket-Id'] = socketId;
        
    } else {
            headers['Accept'] = 'application/json';
            headers['X-Socket-Id'] = socketId;

}
    return new Headers(headers);
}
